// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/menu-active.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebarMenu {
  width: 80px;
}
.sidebarMenu .nav .nav-link.active {
  background-color: #322e86 !important;
  border-radius: 12px;
}
.sidebarMenu .nav .nav-link {
  font-size: 12.9px;
  font-weight: 400;
}
.sidebarMenu .nav .nav-link.active svg path {
  fill-opacity: 1 !important;
}
.sidebarMenu .nav .nav-link.active::after {
  content: "";
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  right: -24px;
  display: block;
  height: 86px;
  width: 14px;
  background-repeat: no-repeat;
  top: -14px;
}

.sidebarMenu .nav .nav-link.active span {
  font-size: 12.9px;
  font-weight: 400;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/Sidebar/Sidebarr.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,oCAAoC;EACpC,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,WAAW;EACX,yDAAgE;EAChE,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,YAAY;EACZ,WAAW;EACX,4BAA4B;EAC5B,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".sidebarMenu {\n  width: 80px;\n}\n.sidebarMenu .nav .nav-link.active {\n  background-color: #322e86 !important;\n  border-radius: 12px;\n}\n.sidebarMenu .nav .nav-link {\n  font-size: 12.9px;\n  font-weight: 400;\n}\n.sidebarMenu .nav .nav-link.active svg path {\n  fill-opacity: 1 !important;\n}\n.sidebarMenu .nav .nav-link.active::after {\n  content: \"\";\n  background-image: url(../../../../public/images/menu-active.svg);\n  position: absolute;\n  right: -24px;\n  display: block;\n  height: 86px;\n  width: 14px;\n  background-repeat: no-repeat;\n  top: -14px;\n}\n\n.sidebarMenu .nav .nav-link.active span {\n  font-size: 12.9px;\n  font-weight: 400;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
