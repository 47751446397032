import { Form, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { APPOINTEMENT_TITLE, FREQUAENCY } from "Data";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getParticipant,
  requestAppointment,
  getAssignUserAssignTherapist,
  getallTherapistAndPatients,
} from "lib/api/service";
import MultiselectAutocomplete from "components/FormComponents/MultiselectAutocomplete";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useLocalStorageGet } from "utils/hooks/useLoacalStorage";
import Loader from "components/loader/Loader";
import {
  appointmentSchema,
  appointmentSchemaWithFrequency,
} from "utils/schemas";
import toast from "react-hot-toast";

const TabForms = ({ onCancel, isReoccurring, refetch, defaultdata, isScheduleEditMode }) => {
  const initialSchema = !isReoccurring
    ? appointmentSchema
    : appointmentSchemaWithFrequency;
  const [schema, setSchema] = useState(initialSchema);

  const { control, register, handleSubmit, reset, watch, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultdata,
  });
  const userRole = localStorage.getItem("userRole");
  const { errors } = formState;
  const [loader, setLoader] = useState(false);
  const [option, setOptions] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [notification, setNotification] = useState(!!defaultdata);
  const [date, time, participants] = watch(["date", "time", "participants"]);
  const {
    rows: { email, Name },
  } = useLocalStorageGet("userDetails");


  const fetchData = async () => {
    setLoader(true);
    try {
      const timedate = convertDateTime({ date: date, time: time });
      let filterlist = [];
      if (userRole === "Doctor") {
        const { allPatientsAndDoctors } = await getParticipant({
          doctorEmail: email,
          ...timedate,
        });
        filterlist = allPatientsAndDoctors?.map((item) => ({
          name: item.firstName,
          email: item.email,
          isAvailable: item.available,
        }));
      }
      else if(userRole === "SuperAdmin"){
        const { allPatientsAndDoctors } = await getallTherapistAndPatients({
          ...timedate,
        });
        filterlist = allPatientsAndDoctors?.map((item) => ({
          name: item.firstName,
          email: item.email,
          isAvailable: item.available,
        }));
      }
      else {
        const response = await getAssignUserAssignTherapist();
        filterlist = response?.results.map((item) => ({
          name: item.doctor_name,
          email: item.doctor_email,
          isAvailable: true,
        }));
      }
      setOptions(filterlist);
    } catch (error) {
      console.error("Error fetching participants:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (date && time) {
      fetchData();
    }
  }, [date, time]);

  useEffect(() => {
    reset();
    setSchema(initialSchema);
  }, [isReoccurring]);

  useEffect(() => {
    const currentSchema = !isReoccurring
      ? appointmentSchema
      : appointmentSchemaWithFrequency;
    setSchema(
      Yup.object().shape({
        ...currentSchema.fields,
        ...(notification && {
          addemail: Yup.string()
            .email("Invalid email address")
            .required("Email is required if notification is checked"),
        }),
      })
    );
  }, [notification, isReoccurring]);

  // const onSubmit = async (formData) => {
  //   setLoader(true);
  //   try {
  //     const selectedTime = formData.time;
  //     const currentTime = new Date();
  //     const currentHours = currentTime.getHours();
  //     const currentMinutes = currentTime.getMinutes();
  //     const [selectedHours, selectedMinutes] = selectedTime.split(':').map(Number);
  //     const isPastTime = selectedHours < currentHours || (selectedHours === currentHours && selectedMinutes < currentMinutes);
  //     if (isPastTime) {
  //       if(isScheduleEditMode == "isActive" || isScheduleEditMode == undefined){
  //       setErrorMsg('Cannot select a past time');
  //       setTimeout(() => {
  //         setErrorMsg('');
  //       }, 2000);
  //       return;
  //     }
  //     }

  //     formData.participants = (formData.participants || [])
  //       .filter((item) => item)
  //       .map((item) => ({
  //         name: item.name,
  //         email: item.email,
  //       }));
  //     if (!defaultdata) {
  //       formData.participants.push({
  //         name: Name,
  //         email: email,
  //       });
  //     }
  //     const finalValue = {
  //       ...formData,
  //       name: Name,
  //       email: email,
  //       date: dayjs(formData.date).format("YYYY-MM-DD"),
  //       stype: "schedule",
  //       addemail: [formData?.addemail],
  //     };
  //     const res = await requestAppointment(finalValue);
  //     if (res?.success) {
  //       reset();
  //       refetch && refetch();
  //       onCancel();
  //     }
  //   } catch (err) {
  //     toast.error(err?.message || "Error scheduling appointment");
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  const onDateChange = (selectedDate) => {
    const currentDate = new Date();
    const isToday = dayjs(selectedDate).isSame(currentDate, 'day');
  
    // Enable/disable time input accordingly
    if (isToday) {
      const currentTime = currentDate.toTimeString().slice(0, 5); // HH:MM format
      document.getElementById('time').setAttribute('min', currentTime);
    } else {
      document.getElementById('time').removeAttribute('min');
    }
  };
  
  const onSubmit = async (formData) => {
    setLoader(true);
    try {
      const selectedTime = formData.time;
      const selectedDate = formData.date;
      const currentTime = new Date();
      const currentHours = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const [selectedHours, selectedMinutes] = selectedTime.split(':').map(Number);
  
      const isToday = dayjs(selectedDate).isSame(currentTime, 'day');
      const isPastTime =
        isToday &&
        (selectedHours < currentHours || (selectedHours === currentHours && selectedMinutes < currentMinutes));
  
      if (isPastTime) {
        if (isScheduleEditMode === "isActive" || isScheduleEditMode === undefined) {
          setErrorMsg('Cannot select a past time');
          setTimeout(() => {
            setErrorMsg('');
          }, 2000);
          return;
        }
      }

      formData.participants = (formData.participants || [])
        .filter((item) => item)
        .map((item) => ({
          name: item.name,
          email: item.email,
        }));
      if (!defaultdata) {
        formData.participants.push({
          name: Name,
          email: email,
        });
      }
      
      const finalValue = {
        ...formData,
        name: Name,
        email: email,
        date: dayjs(formData.date).format("YYYY-MM-DD"),
        stype: "schedule",
        addemail: [formData?.addemail],
      };
  
      const res = await requestAppointment(finalValue);
      if (res?.success) {
        reset();
        refetch && refetch();
        onCancel();
      }
    } catch (err) {
      toast.error(err?.message || "Error scheduling appointment");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-single"
            role="tabpanel"
            aria-labelledby="pills-single-tab">
            <Form.Group className="mb-3">
              <Form.Label>Appointment title:</Form.Label>
              <Form.Control
                {...register("title")}
                id="title"
                type="text"
                placeholder="Clinical Consultation"
                name="title"
              />
              <div className="text-danger">{errors.title?.message}</div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Appointment :</Form.Label>
              <Form.Select
                aria-label="appointmenttype"
                {...register("appointmenttype")}
                id="appointmenttype"
                name="appointmenttype">
                <option value="Clinical Consultations">
                  Clinical Consultations
                </option>
                <option value="Individual Therapy">Individual Therapy</option>
                <option value="Insurance Meeting">Insurance Meeting</option>
                <option value="Clinical Team Meeting">
                  Clinical Team Meeting
                </option>
                <option value="Group Therapy">Group Therapy</option>
              </Form.Select>
              <div className="text-danger">
                {errors.appointmenttype?.message}
              </div>
            </Form.Group>
            <Row>
             <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Date:</Form.Label>
                  <div>
                    <Controller
                      control={control}
                      name="date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          placeholderText="Select date"
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                            onDateChange(date); // Trigger min time logic
                          }}
                          dateFormat="dd/MM/yyyy"
                          className="form-control w-100 date_input"
                          minDate={new Date()}
                        />
                      )}
                    />
                  </div>
                  <div className="text-danger">{errors.date?.message}</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Time:</Form.Label>
                  <Form.Control
                    {...register("time")}
                    id="time"
                    name="time"
                    type="time"
                  />
                  <div className="text-danger">{errors.time?.message || errorMsg}</div>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Participant(s):</Form.Label>
              <Controller
                control={control}
                name="participants"
                render={({ field }) => (
                  <MultiselectAutocomplete
                    {...field}
                    name="participants"
                    options={option}
                    selectedOptions={participants}
                    placeholder="Search options"
                    onChange={(participants) => field.onChange(participants)}
                    isScheduleEditMode={isScheduleEditMode}
                  />
                )}
              />
              <div className="text-danger">{errors.participants?.message}</div>
            </Form.Group>
            {isReoccurring && (
              <Form.Group className="mb-3 asdasda">
                <Form.Label>Frequency:</Form.Label>
                <div className="d-flex gap-3 align-items-center">
                  {FREQUAENCY.map((item) => (
                    <Form.Check
                      {...register("frequency")}
                      type="radio"
                      key={item.title}
                      label={item.title}
                      value={item.title}
                      name="frequency"
                      id="frequency"
                      className="d-flex gap-2 align-items-center"
                    />
                  ))}
                </div>
                <div className="text-danger">{errors.frequency?.message}</div>
              </Form.Group>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Duration:</Form.Label>
              <div className="d-flex gap-3 align-items-center">
                {APPOINTEMENT_TITLE.map((item) => (
                  <Form.Check
                    {...register("duration")}
                    type="radio"
                    key={item.title}
                    label={item.title}
                    value={item.title}
                    name="duration"
                    id="duration"
                    className="d-flex gap-2 align-items-center"
                  />
                ))}
              </div>
              <div className="text-danger">{errors.duration?.message}</div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Appointment description:</Form.Label>
              <Form.Control
                {...register("description")}
                name="description"
                id="description"
                as="textarea"
                placeholder="Clinical consult on a new patient"
                rows={2}
              />
              <div className="text-danger">{errors.description?.message}</div>
            </Form.Group>

            <Form.Group id="formGridCheckbox">
              <div className="d-flex gap-2 mb-3">
                <Form.Check
                  type="checkbox"
                  label="Notify me via different from main account email"
                  name="notification"
                  checked={notification}
                  onChange={(e) => setNotification(e.target.checked)}
                />
                <Form.Label className="mb-0">Enter email address</Form.Label>
              </div>
            </Form.Group>
            {notification && (
              <Form.Group className="mb-3">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  {...register("addemail")}
                  id="addemail"
                  type="text"
                  placeholder="Enter your email"
                  name="addemail"
                />
                <div className="text-danger">{errors.addemail?.message}</div>
              </Form.Group>
            )}
          </div>
          <div className="gap-4 d-flex justify-content-center">
            <button
              type="button"
              className="btn scheduleBtn btn-outline-secondary"
              onClick={onCancel}>
              Cancel
            </button>
            <Button type="submit" className="btn scheduleBtn btn-primary">
              Schedule appointment
            </Button>
          </div>
        </div>
      </Form>
      {loader && <Loader />}
    </div>
  );
};

export default TabForms;

const convertDateTime = (input) => {
  const { date, time } = input;

  // Parse the input date
  const parsedDate = dayjs(date);
  // Extract the desired date format
  const formattedDate = parsedDate.format("YYYY-MM-DD");

  return {
    date: formattedDate,
    time: time,
  };
};
