import React, { useState } from 'react';
import Modal from 'react-modal';
import AddOrdresModal from '../../../../modals/Medical/Orders/AddOrdresModal';
import OrdersDataModal from '../../../../modals/Medical/Orders/OrdersDataModal';
import AddNotesDataModal from '../../../../modals/Medical/Orders/AddNotesDataModal';

const Orders = () => {

    const [isAddOrderModalOpen, setAddOrderModalOpen] = useState(false);
    const [isOrdersModalOpen, setIsOrdersModalOpen] = useState(false);
    const [selectedOrdersData, setSelectedOrdersData] = useState(false);
    const [isAddNotesModalOpen, setAddNotesModalOpen] = useState(false);

    const orderData = [
        {
            name: "Med refill",
            providerImage: "images/doctor-pic.png",
            provider: "Dr. Jones",
            reason: "Med refill",
            sendDate: "Jun 10, 2023",
            recipient: "CVS"
        },
        {
            name: "Blood work",
            providerImage: "images/doctor-pic.png",
            provider: "Dr. Jones",
            reason: "Thyroid",
            sendDate: "May 04, 2023",
            recipient: "CPL"
        },
    ]

    return (
        <>
            <div className="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab-tab">

                {/* //////////////////// */}
                <div className="headingTable mb-3">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <h1 className="mb-0">Orders</h1>
                        </div>
                        <div className='col'>
                            <div className='row justify-content-end'>

                                <div className='col-md-3'>
                                    <button type="button" className="btn btn-success border-0"
                                        onClick={() => {
                                            // setSelectedUploadDocData("");
                                            setAddNotesModalOpen(true);
                                        }}
                                    >
                                        <i className="fas fa-list-alt"></i> Add new note</button>
                                </div>

                                <div className='col-md-3'>
                                    <button type="button" className="btn btn-success border-0"
                                        onClick={() => {
                                            // setSelectedUploadDocData("");
                                            setAddOrderModalOpen(true);
                                        }}
                                    >
                                        <i className="fas fa-list-alt"></i> Add new orders</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* //////////////////// */}

                <div className='tableList'>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Document name <img src='images/short-icon.svg' alt='' /></th>
                                <th scope="col">Provider <img src='images/short-icon.svg' alt='' /></th>
                                <th scope="col">Reason </th>
                                <th scope="col">Date sent <img src='images/short-icon.svg' alt='' /></th>
                                <th scope="col">Recipient</th>
                            </tr>
                        </thead>
                        <tbody>

                            {orderData.map(document => (
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src="images/doc-icon.png" alt="..." />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <span className='curser-pointer'
                                                    onClick={() => {
                                                        setSelectedOrdersData(document);
                                                        setIsOrdersModalOpen(true);
                                                    }}
                                                >
                                                    {document.name}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <h6 className='mb-0'><img src={document.providerImage} alt='' /> {document.provider}</h6>
                                    </td>
                                    <td> {document.reason} </td>
                                    <td> {document.sendDate} </td>
                                    <td> {document.recipient} </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal isOpen={isAddNotesModalOpen} onRequestClose={() => setAddNotesModalOpen(false)} >
                <AddNotesDataModal
                    addNotesInfo={{
                        setAddNotesModalOpen: setAddNotesModalOpen
                    }}
                />
            </Modal>

            <Modal isOpen={isAddOrderModalOpen} onRequestClose={() => setAddOrderModalOpen(false)} >
                <AddOrdresModal
                    OrderInfo={{
                        setAddOrderModalOpen: setAddOrderModalOpen,
                        // selectedUploadDocData:selectedUploadDocData
                    }}
                />
            </Modal>

            <Modal isOpen={isOrdersModalOpen} onRequestClose={() => setIsOrdersModalOpen(false)} >
                <OrdersDataModal
                    ordersInfo={{
                        setIsOrdersModalOpen: setIsOrdersModalOpen,
                        selectedOrdersData: selectedOrdersData
                    }}
                />
            </Modal>

        </>
    );

};

export default Orders;
