// schemas.js
import * as Yup from "yup";

export const appointmentSchema = Yup.object().shape({
  title: Yup.string().required("The Appointment title field is required"),
  participants: Yup.array()
    .test(
      "participants",
      "Please select date and time first",
      function (value) {
        return !!this.parent.date && !!this.parent.time;
      }
    )
    .required("The participants field is required"),
  description: Yup.string().required(
    "The Appointment description field is required"
  ),
  date: Yup.date().nullable().required("The date field is required"),
  time: Yup.string().required("The time field is required"),
  duration: Yup.string().required("The duration field is required"),
});

export const appointmentSchemaWithFrequency = Yup.object().shape({
  ...appointmentSchema.fields, // Use spread operator to include fields from appointmentSchema
  frequency: Yup.string().required("The frequency field is required"),
});

export const addnewTaskSchema = Yup.object().shape({
  discription: Yup.string().required("The task discription field is required."),
  performer: Yup.string()
    .required("Performer is required.")
    .notOneOf(["Select status"], "Please select a valid status."),
  created: Yup.date()
    .required("Date Created is required.")
    .typeError("Date Created field is required."),
  provider: Yup.string().required("Provider field is required."),
  dueDate: Yup.date()
    .required("Due Date field is required.")
    .typeError("Due Date field is required."),
});
