const blockTexts = [
  "General Information",
  "General Screen & Presenting Concerns",
  "Suicide Risk Assessment",
  "Mental Status Exam",
  "Treatment History",
  "Mental Health History & Trauma History",
  "Physical Health",
  "Family & Social Support, Religion and Culture",
  "Legal History & Financial Status",
  "Educational/Vocational Background & Housing",
  "Hobbies/Interests & Additional Treatment Considerations",
];

const GENDER_IDENTITIES = [
  "Male",
  "Female",
  "Transgender Male",
  "Transgender Female",
  "Non-binary",
  "Genderqueer",
  "Genderfluid",
  "Agender",
  "Bigender",
  "Two-Spirit",
  "Gender-nonconforming",
  "Gender questioning",
  "Other",
];

const SEXUAL_ORIENTATIONS = [
  "Heterosexual",
  "Homosexual",
  "Bisexual",
  "Pansexual",
  "Asexual",
  "Queer",
  "Other",
];

const RACES = [
  "White",
  "Black or African American",
  "Asian",
  "Native American or Alaska Native",
  "Native Hawaiian or Other Pacific Islander",
  "Mixed Race",
  "Other",
];

const GENERAL_SCREEN_OPTIONS = [
  { name: "risky_behaviour", label: "Risky behaviour", id: "risky-behaviour" },
  {
    name: "memory_impairment",
    label: "Memory Impairment",
    id: "memory-impairment",
  },
  {
    name: "difficulty_concentrating",
    label: "Difficulty Concentrating",
    id: "difficulty-concentrating",
  },
  { name: "panic_attacks", label: "Panic attacks", id: "panic-attacks" },
  { name: "dissociation", label: "Dissociation", id: "dissociation" },
  { name: "avoidance", label: "Avoidance", id: "avoidance" },
  { name: "fatigue", label: "Fatigue", id: "fatigue" },
  {
    name: "irritability_anger",
    label: "Irritability/Anger",
    id: "irritability-anger",
  },
  {
    name: "loss_interest_pleasurable_activities",
    label: "Loss of Interest in Pleasurable Activities",
    id: "loss-interest-pleasurable-activities",
  },
  {
    name: "social_isolation",
    label: "Social Isolation",
    id: "social-isolation",
  },
  {
    name: "negative_self_concept",
    label: "Negative Self-Concept",
    id: "negative-self-concept",
  },
];

const AVOIDANCE_QUESTIONS = [
  { name: "people", label: "People", id: "avoidance-people" },
  { name: "places", label: "Places", id: "avoidance-places" },
  { name: "situations", label: "Situations", id: "avoidance-situations" },
];

const ASSESSMENTS_TYPES = [
  { name: "1", label: "General assessments", id: "avoidance-people" },
  { name: "2", label: "Pre/post-session assessments", id: "avoidance-places" },
];

const PRESENTING_CONCERNS = [
  {
    name: "chief_complaint",
    label: "Chief Complaint (In patient's own words)",
  },
  { name: "current_symptoms", label: "Current Symptoms" },
  { name: "duration_of_symptoms", label: "Duration of Symptoms" },
  {
    name: "precipitating_events_stressors",
    label: "Precipitating Events/Current Stressors",
  },
  {
    name: "most_recent_diagnosis",
    label: "Most Recent Diagnosis (if applicable)",
  },
  { name: "goal_for_seeking_treatment", label: "Goal for Seeking Treatment" },
];

const SUICIDAL_BEHAVIOR = [
  { name: "actual_suicide_attempt", label: "Actual Suicide Attempt" },
  { name: "interrupted_attempt", label: "Interrupted Attempt" },
  { name: "aborted_attempt", label: "Aborted Attempt" },
  { name: "other_prepatory_acts", label: "Other prepatory acts to kill self" },
  {
    name: "self_injury_behavior",
    label: "Self-injury behavior w/o suicide intent",
  },
];

const SUICIDAL_IDEATION = [
  { name: "wish_to_be_dead", label: "Wish to be dead" },
  { name: "suicidal_thoughts", label: "Suicidal thoughts" },
  {
    name: "suicidal_thoughts_with_method",
    label:
      "Suicidal thoughts with method (but without specific plan or intent to act)",
  },
  { name: "suicidal_intent", label: "Suicidal intent (without specific plan)" },
  {
    name: "suicidal_intent_with_plan",
    label: "Suicidal intent with specific plan",
  },
];

const ACTIVATING_EVENTS = [
  {
    name: "recent_loss",
    label: "Recent loss or other significant negative event",
  },
  {
    name: "pending_incarceration_homelessness",
    label: "Pending incarceration or homelessness",
  },
  {
    name: "current_or_pending_isolation",
    label: "Current or pending isolation or feeling alone",
  },
];

const TREATMENT_HISTORY = [
  {
    name: "previous_psychiatric_diagnoses_treatments",
    label: "Previous psychiatric diagnoses and treatments",
  },
  {
    name: "hopeless_or_dissatisfied_with_treatment",
    label: "Hopeless or dissatisfied with treatment",
  },
  { name: "noncompliant_with_treatment", label: "Noncompliant with treatment" },
  { name: "not_receiving_treatment", label: "Not receiving treatment" },
];

const LEGAL_CHECKBOX_ITEMS = [
  { name: "plaintiff", label: "Plaintiff (is suing)" },
  { name: "defendant", label: "Defendant (is being sued)" },
  { name: "family_court", label: "Family court" },
  { name: "other", label: "Other" },
];
const FINANCE_CHECKBOX_ITEMS = [
  { name: "stable", label: "Stable" },
  { name: "struggling_to_pay_bills", label: "Struggling to pay bills" },
  {
    name: "need_assistance_with_basic_needs",
    label: "Need assistance with basic needs",
  },
];
const FINANCE_HELP_ITEMS = [
  { name: "nothing", label: "Nothing" },
  { name: "rent_mortgage", label: "Rent/Mortgage" },
  { name: "food", label: "Food" },
  { name: "healthcare", label: "Healthcare" },
  { name: "transportation", label: "Transportation" },
  { name: "utilities", label: "Utilities (electric, gas, water)" },
  { name: "other_financial_needs", label: "Other Financial Needs" },
];
const MONEY_MANAGEMENT_ITEMS = [
  { name: "able_to_budget", label: "Able to budget" },
  { name: "gambling_problems", label: "Gambling Problems" },
  { name: "compulsive_spending", label: "Compulsive Spending" },
  { name: "hoarding_money", label: "Hoarding Money" },
];

const ASSESSMENTS_FREQUENCY_TYPES = [
  { value: "", label: "" },
  { value: "1", label: "Weekly" },
  { value: "2", label: "Bi-weekly" },
  { value: "3", label: "Monthly" },
];

const ASSESSMENTS_ASSIGNED_STATUS = [
  { value: "0", label: "Not at all" },
  { value: "1", label: "A little bit" },
  { value: "2", label: "Moderately" },
  { value: "3", label: "Quite a bit" },
  { value: "4", label: "Extremely" },
];

export {
  blockTexts,
  ASSESSMENTS_ASSIGNED_STATUS,
  ASSESSMENTS_FREQUENCY_TYPES,
  ASSESSMENTS_TYPES,
  GENDER_IDENTITIES,
  SEXUAL_ORIENTATIONS,
  RACES,
  PRESENTING_CONCERNS,
  AVOIDANCE_QUESTIONS,
  GENERAL_SCREEN_OPTIONS,
  TREATMENT_HISTORY,
  ACTIVATING_EVENTS,
  SUICIDAL_IDEATION,
  SUICIDAL_BEHAVIOR,
  MONEY_MANAGEMENT_ITEMS,
  FINANCE_HELP_ITEMS,
  FINANCE_CHECKBOX_ITEMS,
  LEGAL_CHECKBOX_ITEMS,
};

export const MESSAGES_DATA = [
  {
    createdAt: "29 November 2022 9:45AM",
    assigner: "Dr. Jones",
    assignee: "Walter Pearlman",
    imageSrc: "/images/doctor-pic.png",
    notificationMessage:
      "Remember to practice self care for yourself too, also I need to meet with you before IOP rounds next week. ",
    type: "Assignment",
    read: false,
  },
  {
    createdAt: "23 November 2022 9:45AM",
    assigner: "Dr. Alverez",
    assignee: "Walter Pearlman",
    imageSrc: "/images/doctor-pic.png",
    notificationMessage:
      "However, progress has been slow, and I’m wondering if there are additional strategies we could explore.  ",
    type: "Request",
    read: false,
  },
  {
    createdAt: "19 November 2022 9:45AM",
    assigner: "Dr. Jones",
    assignee: "Walter Pearlman",
    imageSrc: "/images/doctor-pic.png",
    notificationMessage:
      "Charlotte L is raising Lithium dose to help with Bipolar mood fluctuations, is at risk for toxicity. Watch for nausea, aggression, confusion, and notify me if these occur.",
    type: "Appointment",
    read: false,
  },
  {
    createdAt: "21 November 2022 9:45AM",
    assigner: "Dr. Jameson",
    assignee: "Walter Pearlman",
    imageSrc: "/images/doctor-noti.png",
    notificationMessage: "Your CBT Thursday group is starting in 5 minutes",
    type: "Join Call",
    read: true,
  },
  {
    createdAt: "5 November 2022 9:45AM",
    assigner: "Dr. Smith",
    assignee: "Walter Pearlman",
    imageSrc: "/images/doctor-noti.png",
    notificationMessage: "added a new assignment in your assignments",
    type: "Assignment",
    read: true,
  },
  {
    createdAt: "5 November 2022 9:45AM",
    assigner: "Dr. Jones",
    assignee: "Walter Pearlman",
    imageSrc: "/images/doctor-noti.png",
    notificationMessage: "added a new assignment in your assignments",
    type: "Assignment",
    read: true,
  },
  {
    createdAt: "5 November 2022 9:45AM",
    assigner: "Dr. Smith",
    assignee: "Walter Pearlman",
    imageSrc: "/images/doctor-pic.png",
    notificationMessage: "added a 1x1 appointment for Sept 17, 2024",
    type: "Appointment",
    read: true,
  },
];

export const DASHBOARD_DATA = [
  {
    name: "Sara",
    message: "here is your schedule for today",
  },
];

export const APPOINTMENTS = [
  {
    time: "11:00AM",
    duration: "04:00PM-05:00PM",
    doctor: "Dr. Jones",
    type: "1x1",
    bgClass: "successBg",
  },
  {
    time: "12:00AM",
    duration: "04:00PM-05:00PM",
    doctor: "Dr. Jones",
    type: "1x1",
    bgClass: "alertBg",
  },
  {
    time: "01:00PM",
    duration: "04:00PM-05:00PM",
    doctor: "Dr. Jones",
    type: "1x1",
    bgClass: "primary text-white blueBg",
  },
  {
    time: "02:00PM",
    duration: null,
    doctor: null,
    type: null,
    bgClass: null,
  },
  {
    time: "03:00PM",
    duration: "04:00PM-05:00PM",
    doctor: "Dr. Jones",
    type: "1x1",
    bgClass: "successBg",
  },
  {
    time: "04:00PM",
    duration: "04:00PM-05:00PM",
    doctor: "Dr. Jones",
    type: "1x1",
    bgClass: "dangerBg",
  },
  {
    time: "05:00PM",
    duration: null,
    doctor: null,
    type: null,
    bgClass: null,
  },
  {
    time: "06:00PM",
    duration: "04:00PM-05:00PM",
    doctor: "Dr. Jones",
    type: "1x1",
    bgClass: "alertBg",
  },
];

export const DASHBOARD_TASK_DATA = [
  {
    name: "Jane Cooper",
    createdDate: "May 4, 2023",
    status: "Pending",
  },
  {
    name: "Mason Tyler",
    createdDate: "May 4, 2023",
    status: "Pending",
  },
  {
    name: "Isbella Hernandez",
    createdDate: "May 4, 2023",
    status: "Pending",
  },
  {
    name: "Carlotte Lee",
    createdDate: "May 4, 2023",
    status: "Pending",
  },
  {
    name: "Jane Cooper",
    createdDate: "May 4, 2023",
    status: "Pending",
  },
  {
    name: "Wade Warren",
    createdDate: "May 4, 2023",
    status: "Pending",
  },
];

export const ASSESSMENT_DASHBOARD_DATA = [
  {
    title: "EDE-Q",
    due: "Overdue: Dec 23, 2023",
    status: "Pendding,",
  },
  {
    title: "PHQ-9 (Depression)",
    due: "Overdue: Dec 23, 2023",
    status: "Pendding,",
  },

  {
    title: "PCL-5 (PTSD) ",
    due: "Due to: Dec 25, 2023",
    status: "Pendding,",
  },
  {
    title: "GAD-7 (Anxiety)",
    due: "Due to: Dec 23, 2023",
    status: "Pendding,",
  },
  {
    title: "Beck Anxiety Inventory",
    due: "Due to: Dec 23, 2023",
    status: "Pendding,",
  },
];
export const MESSAGES_USER_LIST = [
  {
    id: 1,
    image: "/images/Group.png",
    userName: "All Therapists",
    userKit: "./images/kit.png",
    userMessage: "Dr.Williams: We are moving IOP rounds this",
    userTime: "02:44PM",
    userPin: "./images/pin.png",
  },
  {
    id: 2,
    image: "/images/user_img.png",
    userName: "All Therapists",
    userKit: "./images/kit.png",
    userMessage: "Dr.Williams: We are moving IOP rounds this ",
    userTime: "02:44PM",
    userPin: null,
  },
  {
    id: 3,
    image: "/images/user_img.png",
    userName: "All Therapists",
    userKit: "./images/kit.png",
    userMessage: "Dr.Williams: We are moving IOP rounds this ",
    userTime: "02:44PM",
    userPin: null,
  },
  {
    id: 4,
    image: "/images/user_img.png",
    userName: "Dr. Williams",
    userKit: "./images/kit.png",
    userMessage: "How did the coping strategies we ... ",
    userTime: "18 Aug 2023",
    userPin: null,
  },
];

export const MESSAGES_PATIENT_LIST = [
  {
    id: 1,
    image: "/images/ellipse-6.png",
    userName: "Emily Johnson",
    userMessage: "Hi there! Just wanted to check in and ... ",
    userTime: "02:44PM",
  },
  {
    id: 2,
    image: "/images/ellipse-5.png",
    userName: "Ethan Thompson - LCDC",
    userMessage: "Dr.Williams: We are moving IOP rounds this ",
    userTime: "02:44PM",
  },
  {
    id: 3,
    image: "/images/ellipse-4.png",
    userName: "Jane Cooper",
    userMessage: "How did the coping strategies we ... ",
    userTime: "18 Aug 2023",
  },
  {
    id: 4,
    image: "/images/ellipse-3.png",
    userName: "Wade Warren",
    userMessage: "How did the coping strategies we ... ",
    userTime: "18 Aug 2023",
  },
  {
    id: 5,
    image: "/images/ellipse-2.png",
    userName: "Mason Taylor",
    userMessage: "How did the coping strategies we ... ",
    userTime: "18 Aug 2023",
  },
];

export const MODAL_USER_LIST = [
  {
    id: 1,
    image: "/images/ellipse-3.png",
    name: "Dr. Scott",
    kitImage: "../images/kit.png",
  },
  {
    id: 2,
    image: "/images/ellipse-2.png",
    name: "Dr. Cooper",
    kitImage: "../images/kit.png",
  },
  {
    id: 3,
    image: "/images/ellipse.png",
    name: "Dr. Bauer",
    kitImage: "../images/kit.png",
  },
  {
    id: 4,
    image: "/images/ellipse-4.png",
    name: "Dr. Heath",
    kitImage: "../images/kit.png",
  },
  {
    id: 5,
    image: "/images/ellipse-5.png",
    name: "Dr. Lewis",
    kitImage: "../images/kit.png",
  },
];

export const MODAL_PATIENTS_LIST = [
  {
    id: 1,
    image: "../images/ellipse-2.png",
    name: "Ethan Thompson",
  },
  {
    id: 1,
    image: "../images/ellipse-6.png",
    name: "Lori Briggs ",
  },
  {
    id: 3,
    image: "../images/ellipse-5.png",
    name: "Mason Taylor",
  },
  {
    id: 4,
    image: "../images/ellipse-3.png",
    name: "Sophia Garcia",
  },
  {
    id: 5,
    image: "../images/user_img.png",
    name: "Isabella Hernandez",
  },
];

export const APPOINTEMENT_TITLE = [
  {
    id: "1",
    title: "15 mins",
  },
  {
    id: "2",
    title: "30 mins",
  },
  {
    id: "3",
    title: "45 mins",
  },
  {
    id: "4",
    title: "60 mins",
  },
  {
    id: "5",
    title: "90 mins",
  },
];

export const FREQUAENCY = [
  {
    id: "1",
    title: "weekly",
  },
  {
    id: "2",
    title: "biweekly",
  },
  {
    id: "3",
    title: "monthly",
  },
  {
    id: "4",
    title: "bimonthly",
  },
];

export const GENDER_DATA = [
  {
    id: "1",
    name: "Male",
  },
  {
    id: "2",
    name: "Female",
  },
  {
    id: "3",
    name: "Neutral",
  },
];
export const CHIP_DATA = [
  {
    id: 1,
    name: "Walter Pearlman",
    image: "../images/user_img.png",
  },
  {
    id: 2,
    name: "Dr. Williams",
    image: "../images/user_img.png",
  },
];

export const THERAPIST_PATIENTS_CARD_DATA = [
  {
    name: "Bruce Truman",
    imageSrc: "images/ellipse.png",
    patientNumber: "#73435",
    LOC: "IOP",
    age: "33 | Male",
    DOB: "24 Aug 1990",
    children: [
      {
        title: "Psychiatrist",
        image: "/images/doctor-pic.png",
        name: "Dr. Alvares, PhD",
        date: "1/1/24",
        description:
          "Patient started 100mg 2010ft in addition to current treatment",
      },
      {
        title: "Primary therapist",
        image: "/images/doctor-pic.png",
        name: "Dr. Alvares, PhD",
        date: "1/1/24",
        description:
          "Increase in self harm behaviors, no suicidal intent, created safety plan to reduce acting on urges.",
      },
      {
        title: "Group therapist",
        image: "/images/doctor-pic.png",
        name: "Dr. Smith, MD",
        date: "1/3/24",
        description:
          "Patient participated in DBT group, patient showed distress during process when other patients talked about family physical abuse, had to leave room to regulate with other staff",
      },
      {
        title: "ICD-10 Codes",
        image: "/images/doctor-pic.png",
        name: "Dr. Jones, MD",
        date: "1/1/24",
        codes: [
          "F31.9 Bipolar Disorder, unspecified",
          "F41.1 Generalized Anxiety Disorder",
        ],
      },
    ],
    footer: [
      {
        title: "Patient intake",
        route: "/newPatient/page1",
      },
    ],
  },
  {
    name: "Daniel Spargo",
    imageSrc: "/images/ellipse-2.png",
    patientNumber: "#73435",
    LOC: "IOP",
    age: "33 | Male",
    DOB: "24 Aug 1990",
    children: [
      {
        title: "Psychiatrist",
        image: "/images/doctor-pic.png",
        name: "Dr. Jones, MD",
        date: "1/1/24",
        description:
          "Patient started 100mg 2010ft in addition to current treatment",
      },
      {
        title: "Primary therapist",
        image: "/images/doctor-pic.png",
        name: "Dr. Alvares, PhD",
        date: "1/1/24",
        description:
          "Increase in self harm behaviors, no suicidal intent, created safety plan to reduce acting on urges.",
      },
      {
        title: "Group therapist",
        image: "/images/doctor-pic.png",
        name: "Dr. Smith, MD",
        date: "1/3/24",
        description:
          "Patient participated in DBT group, patient showed distress during process when other patients talked about family physical abuse, had to leave room to regulate with other staff",
      },
      {
        title: "ICD-10 Codes",
        image: "/images/doctor-pic.png",
        name: "Dr. Jones, MD",
        date: "1/1/24",
        codes: [
          "F31.9 Bipolar Disorder, unspecified",
          "F41.1 Generalized Anxiety Disorder",
        ],
      },
    ],
    footer: [
      {
        title: "See more",
        route: "",
      },
    ],
  },
  {
    name: "Tayna Yancey",
    imageSrc: "/images/ellipse-3.png",
    patientNumber: "#73435",
    LOC: "IOP",
    age: "33 | Male",
    DOB: "24 Aug 1990",
    children: [
      {
        title: "Psychiatrist",
        image: "/images/doctor-pic.png",
        name: "Dr. Jones, MD",
        date: "1/1/24",
        description:
          "Patient started 100mg 2010ft in addition to current treatment",
      },
      {
        title: "Primary therapist",
        image: "/images/doctor-pic.png",
        name: "Dr. Alvares, PhD",
        date: "1/1/24",
        description:
          "Increase in self harm behaviors, no suicidal intent, created safety plan to reduce acting on urges.",
      },
      {
        title: "Group therapist",
        image: "/images/doctor-pic.png",
        name: "Dr. Smith, MD",
        date: "1/3/24",
        description:
          "Patient participated in DBT group, patient showed distress during process when other patients talked about family physical abuse, had to leave room to regulate with other staff",
      },
      {
        title: "ICD-10 Codes",
        image: "/images/doctor-pic.png",
        name: "Dr. Jones, MD",
        date: "1/1/24",
        codes: [
          "F31.9 Bipolar Disorder, unspecified",
          "F41.1 Generalized Anxiety Disorder",
        ],
      },
    ],
    footer: [
      {
        title: "See more",
        route: "",
      },
    ],
  },
];

export const DASHBOARD_USER_DATA = [
  {
    id: 1,
    imageSrc: "images/image_dashboard.png",
    name: "Sara",
    message: "here is your schedule for today Nov 28, 2023",
  },
];

export const PATIENTCHECKBOX_DATA = (stepPatient: number[]) => {
  return [
    {
      id: 1,
      status: stepPatient.includes(0),
      checkedStatus: stepPatient.includes(1),
      name: "Personal",
      label: "Personal Information",
      discription: "Fill in your personal information",
    },
    {
      id: 2,
      name: "Primary",
      status: stepPatient.includes(1),
      checkedStatus: stepPatient.includes(2),
      label: "Primary Concerns",
      discription: "Provide information about your primary concerns",
    },
    {
      id: 3,
      status: stepPatient.includes(2),
      checkedStatus: stepPatient.includes(3),
      name: "Providers",
      label: "Providers",
      discription: "Fill in your providers information",
    },
    {
      id: 4,
      status: stepPatient.includes(3),
      checkedStatus: stepPatient.includes(4),
      name: "Source",
      label: "Information Sources",
      discription: "Provide information of how did you hear about us",
    },
    {
      id: 5,
      status: stepPatient.includes(4),
      checkedStatus: stepPatient.includes(5),
      name: "Signup",
      label: "Sign up",
      discription: "Provide your email address and create a password",
    },
  ];
};

export const CONCERNS_DATA = [
  {
    id: "Anxiety",
    label: "Anxiety",
    discription:
      "Anxiety is an overwhelming sense of worry and unease, often accompanied by physical symptoms, that can hinder daily life.",
  },
  {
    id: "Depression",
    label: "Depression",
    discription:
      "Depression is a pervasive mental health disorder marked by a range of emotional and physical symptoms.",
  },
  {
    id: "PTSD",
    label: "PTSD",
    discription:
      "Condition resulting from experiencing a traumatic event, leading to symptoms like flashbacks, nightmares, and heightened anxiety.",
  },
  {
    id: "Eating",
    label: "Eating disorders",
    discription:
      "Range of unhealthy eating behaviors and attitudes towards food, and can have serious physical and psychological consequences.",
  },
  {
    id: "OCD",
    label: "OCD",
    discription:
      "Condition characterized by intrusive, distressing thoughts paired with repetitive behaviors or mental rituals aimed at reducing anxiety.",
  },
  {
    id: "Personality",
    label: "Personality disorders",
    discription:
      "Patterns of thoughts, behaviors, and emotions that lead to difficulties in relating to oneself and others, causing distress and impairment.",
  },
];

export const CHECKBOX_OUTPATIENT = [
  {
    id: "1",
    name: "Psychiatrist",
  },
  {
    id: "2",
    name: "Therapist",
  },
  {
    id: "3",
    name: "Dietitian",
  },
  {
    id: "4",
    name: "None",
  },
  {
    id: "5",
    name: "Other",
  },
];

export const RADIO_DATA = [
  {
    id: "1",
    name: "Advertisement in Google search",
  },
  {
    id: "2",
    name: "Referral from clinician",
  },
  {
    id: "3",
    name: "Referral from facility",
  },
];

export const Specialties = [
  {
    id: "Therapist",
    name: "Therapist",
    description:
      "A mental health professional who helps individuals navigate and improve their emotional well-being through therapy and support.",
  },
  {
    id: "Psychiatrist",
    name: "Psychiatrist",
    description:
      "Psychiatrist diagnoses, treats, and manages mental illnesses through a combination of therapy, medication, and other interventions.",
  },
  {
    id: "Dietitian",
    name: "Dietitian",
    description:
      "A dietitian is a nutrition expert who guides individuals in making healthy dietary choices for improved well-being.",
  },
  {
    id: "Nurse",
    name: "Nurse",
    description:
      "A nurse is a healthcare professional who cares for and supports patients in various medical settings.",
  },
  {
    id: "Other",
    name: "Other",
    description: null,
  },
];
