import React, { useState } from "react";
import Header from "../../components/Header/Header";
import "../../pages/Schedule/Schedule.css";
import Layout from "components/layout/layout";
import toast from "react-hot-toast";
import { preaAndPostSessionAssessment } from "lib/api/service";
import { useParams, useLocation, useNavigate } from "react-router-dom";
const ScheduleAssissment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const meetLink = location.state?.meetLink;
  let { id } = useParams();
  const [questionnaireData, setQuestionnaireData] = useState([
    {
      question:
        "1. Rate your current anxiety*  (10 - the worst anxiety possible; 0 - not anxious)",
      response: 0,
      patientResponse: "1",
    },
    {
      question:
        "2. Rate your current depression level?*  (10 - unbearable depression; 0 - no depression) ",
      response: 0,
      patientResponse: "2",
    },
    {
      question:
        "3.Have you tried to implement any therapy skills outside of treatment, if so on a scale of 1-10 how effective was the skill for helping you cope with the situation?* (10 - extremely effective; 0 - no effect) ",
      response: 0,
      patientResponse: "3",
    },
    {
      question:
        "4. How willing to engage in treatment are you today?* (10 - fully engaged; 0 - no engagement)",
      response: 0,
      patientResponse: "4",
    },
    {
      question: "5. What do you want to work on or discuss today?",
      response: 0,
      patientResponse: "5",
    },
  ]);

  const handleResponseChange = (index, event) => {
    const updatedQuestionnaireData = [...questionnaireData];
    updatedQuestionnaireData[index].response = parseInt(event.target.value);
    updatedQuestionnaireData[index].patientResponse = calculatePatientResponse(
      updatedQuestionnaireData[index].response
    );
    setQuestionnaireData(updatedQuestionnaireData);
  };

  const calculatePatientResponse = (value) => {
    if (value <= 20) {
      return "0";
    } else if (value <= 40) {
      return "7";
    } else if (value <= 60) {
      return "Yes, 2";
    } else if (value <= 80) {
      return "8";
    } else {
      return "I want to work on reducing my anxiety.";
    }
  };

  const handleSubmit = async () => {
    const payload = {
      scheduleId: id,
      pre_anxiety: questionnaireData[0].response,
      pre_depression: questionnaireData[1].response,
      pre_therapy: questionnaireData[2].response,
      pre_engage: questionnaireData[3].response,
      pre_discussion: questionnaireData[4].response,
    };
    try {
      const response = await preaAndPostSessionAssessment(payload);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/schedule");
        }, 2000);
      } else {
        toast.error("Failed to submit data");
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={""} />
        <div className="main-content px-3 schedule">
          <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4"></div>
          <div className="modal-body pt-0 pb-5 px-4">
            <div className="d-flex justify-content-between mt-2 mb-3">
              <div>
                <h3 className="mb-1 session-assessment">
                  Pre-session assessment
                </h3>
              </div>
              <button
                type="button"
                className="px-5 py-2 w-10 btn-assessment"
                onClick={handleSubmit}>
                Submit
              </button>
            </div>
            <div className="tableUI">
              <table className="table table-bordered">
                <tbody>
                  {questionnaireData.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <p className="mb-0">{data.question}</p>
                        <div className="range-slider px-4 mt-2">
                          <input
                            className="range-slider__range w-50"
                            type="range"
                            min="1"
                            max="100"
                            value={data.response}
                            onChange={(event) =>
                              handleResponseChange(index, event)
                            }
                          />
                          <div className="row mt-1 w-50">
                            <div className="col">
                              <p>1</p>
                            </div>
                            <div className="col text-center">
                              <p>2</p>
                            </div>
                            <div className="col text-center">
                              <p>3</p>
                            </div>
                            <div className="col text-center">
                              <p>4</p>
                            </div>
                            <div className="col text-end">
                              <p>5</p>
                            </div>
                            <div className="col text-end">
                              <p>6</p>
                            </div>
                            <div className="col text-end">
                              <p>7</p>
                            </div>
                            <div className="col text-end">
                              <p>8</p>
                            </div>
                            <div className="col text-end">
                              <p>9</p>
                            </div>
                            <div className="col text-end">
                              <p>10</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        Patient’s response:
                        <br /> <b>{calculatePatientResponse(data.response)}</b>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2 mb-3 modal-body pt-0 pb-5 px-4">
            <div className="headingSec">
              <h3 className="mb-1 session-assessment">Clinical Consultation</h3>
              <p>Thursday, 6 July 2023 02:30-03:30PM</p>
            </div>
            <div className="white-rounded-footer">
              <div className="bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end gap-4">
                <button type="button" onClick={()=>navigate("/schedule")}  className="px-5 py-2 w-10 cancelButton">
                  {" "}
                  cancel{" "}
                </button>
                {/* <button type="button" className="px-5 py-2 w-10 btn-assessment">
                  {" "}
                  Join Appointment{" "}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScheduleAssissment;
