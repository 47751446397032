// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-text {
    margin-left: 5px;
}

.dischargeForm td {
    color: #5B6680;
}

.dischargeForm h4 {
    padding: 1em 0 1em 0;    
}

.dischargeForm input {
    margin: 5px 0 5px 0;
    color: #5B6680;
    padding: 1em;
}

.dischargeForm label {
    padding: 5px 0 0 0
}

/* //having an issue with too much margin, this is here to negate it */
.nomargin {
    margin-top: -2px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Patients/Discharge/Discharge.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI;AACJ;;AAEA,sEAAsE;AACtE;IACI,gBAAgB;AACpB","sourcesContent":[".checkbox-text {\n    margin-left: 5px;\n}\n\n.dischargeForm td {\n    color: #5B6680;\n}\n\n.dischargeForm h4 {\n    padding: 1em 0 1em 0;    \n}\n\n.dischargeForm input {\n    margin: 5px 0 5px 0;\n    color: #5B6680;\n    padding: 1em;\n}\n\n.dischargeForm label {\n    padding: 5px 0 0 0\n}\n\n/* //having an issue with too much margin, this is here to negate it */\n.nomargin {\n    margin-top: -2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
