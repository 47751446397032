import { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  useParams,
} from "react-router-dom";
import Header from "../../../../components/Header/Header";
import NewPatientPage1 from "../NewPatientPages/NewPatientPage1";
import NewPatientPage2 from "../NewPatientPages/NewPatientPage2";
import NewPatientPage3 from "../NewPatientPages/NewPatientPage3";
import NewPatientPage4 from "../NewPatientPages/NewPatientPage4";
import NewPatientPage5 from "../NewPatientPages/NewPatientPage5";
import NewPatientPage6 from "../NewPatientPages/NewPatientPage6";
import NewPatientPage7 from "../NewPatientPages/NewPatientPage7";
import NewPatientPage8 from "../NewPatientPages/NewPatientPage8";
import NewPatientPage9 from "../NewPatientPages/NewPatientPage9";
import NewPatientPage10 from "../NewPatientPages/NewPatientPage10";
import NewPatientPage11 from "../NewPatientPages/NewPatientPage11";
import "../NewPatient.css";
import Layout from "components/layout/layout";
import ProfileCard from "components/Patient/Profile/ProfileCard";
import { FiDownload } from "react-icons/fi";
import { getAllIntakeFormData } from "lib/api/service";

const IntakePaperWork = () => {
  const { patientId } = useParams();
  const [formData, setFormData] = useState({});
  const componentRef = useRef();

  const getAllDischargeFormDataValue = async () => {
    try {
      const response = await getAllIntakeFormData(patientId);
      setFormData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (patientId) {
      getAllDischargeFormDataValue();
    }
  }, [patientId]);

  const handleChange = (e) => {
    console.warn(e);
  };
  // previous button
  const handleGoBack = () => {
    window.history.back();
  };
  const handleSubmit = async (e) => {
    console.warn("Submitted", e);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Layout>
      <div className="vh-100 mainBglight">
        <Header pageTitle="New Patient" />
        <div className="main-content px-3">
          <div
            className="bg-white rounded-top-3 p-4 cardPaitent"
            ref={componentRef}>
            <div className="row w-100">
              <ProfileCard patientId={patientId} />
              <div className="col-8">
                <NewPatientPage1
                  patientId={patientId}
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  setFormData={setFormData}
                  showFooter={false}
                />
                <NewPatientPage2
                  patientId={patientId}
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />
                <NewPatientPage3
                  patientId={patientId}
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />
                <NewPatientPage4
                  patientId={patientId}
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />
                <NewPatientPage5
                  patientId={patientId}
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  setFormData={setFormData}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />
                <NewPatientPage6
                  patientId={patientId}
                  formData={formData ? formData : {}}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />
                <NewPatientPage7
                  patientId={patientId}
                  formData={formData ? formData : {}}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />

                <NewPatientPage8
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />
                <NewPatientPage9
                  patientId={patientId}
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />
                <NewPatientPage10
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />
                <NewPatientPage11
                  patientId={patientId}
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  handleGoBack={handleGoBack}
                  showFooter={false}
                />
              </div>
            </div>
          </div>
          <div
            className="bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end mt-4"
            style={{ marginRight: "48px" }}>
            <button
              className="btn btn-outline-secondary px-5 btnSize rounded-3 mx-4"
              type="button"
              onClick={handleGoBack}>
              Cancel
            </button>
            <button
              className="btn btn-primary px-5 btnSize rounded-3"
              type="button"
              onClick={handlePrint}>
              <FiDownload /> Download Intake paperwork
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IntakePaperWork;
