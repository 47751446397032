import storage from 'pages/lib/storage';
import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

const LoginPrivateRoute = ({ children }) => {
    const location = useLocation();
    const { userId } = storage.getItem('sbCalls');


    if (userId && (location.pathname === '/login' || location.pathname == '/signup')) {
        return <Navigate to="/" replace />;
    } else {
        return children;
    }

};


export default LoginPrivateRoute;