import React, { useState, useRef } from "react";
import Modal from "react-modal";
import ScheduleModal from "modals/Schedule/ScheduleModal";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import EventCards from "components/atoms/cards/EventCards";

const ScheduleWeekly = ({ onClick, eventsdata, data, refetch }) => {
  const [openAddMessageModal, setOpenAddMessageModal] = useState(false);
  const [databyId, setDatbyId] = useState({ data: {}, originalData: {} });
  const today = new Date();
  const calendarRef = useRef(null);

  const openModal = (arg) => {
    const finddata = data?.find(
      (item) => item.id === Number(arg?.event?._def?.publicId)
    );
    setDatbyId({ data: finddata, originalData: arg });
    setOpenAddMessageModal(true);
  };

  const closeModal = () => {
    setOpenAddMessageModal(false);
  };
  const [calendarView, setCalendarView] = useState("dayGridMonth");
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    generateMonthYearOptions(0)[0].value
  );
  const monthYearOptions = generateMonthYearOptions();
  const handleViewChange = (event) => {
    setCalendarView(event.target.value);
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(event.target.value);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <EventCards data={eventInfo} openModal={() => openModal(eventInfo)} />
    );
  };

  const handleMonthYearChange = (event) => {
    const newMonthYear = event.target.value;
    setSelectedMonthYear(newMonthYear);
    const [year, month] = newMonthYear.split("-");
    const calendarApi = calendarRef.current.getApi();
    const newDate = new Date(calendarApi.getDate());
    newDate.setFullYear(year, month);
    calendarApi.gotoDate(newDate);
  };

  return (
    <div className="mb-12">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          {/* <div className="fc-center mx-3">
          <div className="fc-title page_title">{calendarRef.current ? calendarRef.current.getApi().view.title : selectedMonthYear}</div>
        </div> */}
          <div className="fc-right d-flex align-items-center gap-2">
            <label htmlFor="monthYearSelect">Select: </label>
            <select
              id="monthYearSelect"
              className="custom_dropdown form-select"
              onChange={handleMonthYearChange}
              value={selectedMonthYear}
            >
              {monthYearOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <label htmlFor="viewSelect">View: </label>
          <select
            id="viewSelect"
            className="custom_dropdown form-select"
            onChange={handleViewChange}
            value={calendarView}
          >
            <option value="dayGridMonth">Month</option>
            <option value="timeGridWeek">Week</option>
            <option value="timeGridDay">Day</option>
          </select>
        </div>
      </div>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={calendarView}
        locale="en"
        timeZone="UTC" // Set the time zone to UTC
        headerToolbar={{
          end: "prev today next",
          center: "title",
          start: "",
        }}
        events={eventsdata}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEventRows={2}
        select={onClick}
        eventContent={renderEventContent}
        eventResizableFromStart={true}
        droppable={true}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
        }}
        validRange={{
          start: today,
          FullCalendar,
        }}
      />
      <Modal
        isOpen={openAddMessageModal}
        onRequestClose={closeModal}
        className="modal-custom"
      >
        <ScheduleModal
          assessmentInfo={{
            setOpenAddMessageModal: setOpenAddMessageModal,
            carddata: databyId,
            refetch: refetch,
          }}
        />
      </Modal>
    </div>
  );
};

export default ScheduleWeekly;

const generateMonthYearOptions = (numYears = 5) => {
  const options = [];
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  for (
    let year = currentYear - numYears;
    year <= currentYear + numYears;
    year++
  ) {
    // Start from the current month of the current year
    let startMonth = 0; // January
    if (year === currentYear) {
      startMonth = currentMonth; // Start from current month
    }

    for (let month = startMonth; month < 12; month++) {
      const date = new Date(year, month);
      const monthYear = `${date.toLocaleString("default", {
        month: "long",
      })} ${year}`;
      options.push({ value: `${year}-${month}`, label: monthYear });
    }
  }

  return options;
};
