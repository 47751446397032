import { TextInput } from "components/FormComponents/FormComponents";
import { updateInsuranceCorrespondence } from "lib/api/service";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Modal from "react-modal";

const Correspondence = ({
  date,
  previousReview,
  upcomingReview,
  status,
  allDetails,
  itemId,
  levelCareApproved,
  unitsCareApprovedad,
  insuranceFirm,
  insuranceContactNumber,
  insuranceContactName,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  const handleClick = (detail) => {
    setSelectedDetail(detail);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedDetail(null);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [formValues, setFormValues] = useState({
    additional_comment: "",
    statusCode: "0",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        id: itemId,
        ...formValues,
      };
      const response = await updateInsuranceCorrespondence(data);
      if(response?.message){
        setShowModal(false);
        toast.success(response?.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="row justify-content-between align-items-center text-center"
      style={{
        background: "#F8F9FE",
        padding: "20px",
        marginBottom: "10px",
        borderRadius: "10px",
      }}>
      <div className="col-4">
        <h3 style={{ color: "#18357A", fontSize: "16px", fontWeight: 700 }}>
          Date of Contact: {date}
        </h3>
      </div>
      <div className="col-4">
        <p style={{ color: "#18357A", fontSize: "15px" }}>
          Previous Review: {previousReview}
          <br />
          Upcoming Review: {upcomingReview}
        </p>
      </div>
      <div className="col-4">
        <button
          type="button"
          onClick={() => handleClick(allDetails)}
          className={`${
            status == 1 ? " btn pendingBtn" : "btn closedBtn"
          }`}>
          {status == 1 ?  'Resolved' : "Pending"}
        </button>
      </div>

      {/* {selectedDetail && ( */}
      <Modal isOpen={showModal} onRequestClose={handleClose}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              <div className="row d-flex">
                <div className="col-2">
                  <div className="flex-shrink-0">
                    <img src="/images/patients.svg" alt="Patient" />
                  </div>
                </div>
                <div className="col-10">
                  <div className="row d-flex mb-2">
                    <h1 className="mb-2">{`${selectedDetail?.firstName} ${selectedDetail?.lastName}`}</h1>
                    <div className="col-4">
                      <label>
                        Patient No: <span>#{selectedDetail?.id}</span>
                      </label>
                    </div>
                    <div className="col-4">
                      <label>
                        Gender: <span>{selectedDetail?.gender}</span>
                      </label>
                    </div>
                    <div className="col-4">
                      <label>
                        LOC: <span>{selectedDetail?.level_care_approved}</span>
                      </label>
                    </div>
                  </div>
                  <div className="row d-flex">
                    <div className="col-4">
                      <label>
                        AGE: <span>{selectedDetail?.dob} years</span>
                      </label>
                    </div>
                    <div className="col-4">
                      <label>
                        DOB: <span>{selectedDetail?.dob}</span>
                      </label>
                    </div>
                    <div className="col-4">
                      <label>
                        Admission Date:{" "}
                        <span>{formatDate(selectedDetail?.createdAt)}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>
                    Date of Contact: <span>{date}</span>
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    Date of Review: <span>{previousReview}</span>
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    level of Care Approved: <span>{levelCareApproved}</span>
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    Units of Care Approved: <span>{unitsCareApprovedad}</span>
                  </label>
                </div>
                <div className="col-12 mb-4">
                  <label>
                    Date of Next Review: <span>{upcomingReview}</span>
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    Insurance firm: <span>{insuranceFirm}</span>
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    Insurance Contact Number:{" "}
                    <span>{insuranceContactNumber}</span>
                  </label>
                </div>
                <div className="col-12">
                  <label>
                    Insurance Contact Name: <span>{insuranceContactName}</span>
                  </label>
                </div>
                <div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <div className="col-6">
                        <TextInput
                          id="additional_comment"
                          name="additional_comment"
                          value={formValues.additional_comment}
                          onChange={handleChange}
                          label="Comment About Insurance Communication:"
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label">
                          Status:{" "}
                        </label>
                        <select
                          className="form-select"
                          aria-label="Select any one"
                          name="statusCode"
                          required
                          value={formValues.statusCode}
                          onChange={handleChange}>
                          <option value="0">No Action</option>
                          <option value="1">Resolved</option>
                          <option value="2">Pending</option>
                          <option value="3">Action Needed</option>
                        </select>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center white-rounded-footer mt-4">
                      <hr className="whitebox-footer-divider"></hr>
                      <button
                        type="button"
                        className="btn btn-outline-secondary px-5 btnSize rounded-3 mx-2"
                        onClick={handleClose}>
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary px-5 btnSize rounded-3">
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* )} */}
    </div>
  );
};

export default Correspondence;
