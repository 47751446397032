import { CheckboxInput, TextInput, RadioButtons } from "../../../../components/FormComponents/FormComponents";
import { FAMILY_STRUCTURE_QUESTIONS , FAMILY_UPBRINGING , CHILDREN_TABLE_HEADERS } from "../../../../const/PatientIntakeConst";
import { RelationshipTable , FamilyHistoryTable } from "../Components/NewPatientPage";


const NewPatientPage8 = ({ formData, handleChange, handleSubmit ,handleGoBack, showFooter = true }) => {
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="form-group row">  
                <h4>Family of origin </h4>
                    <span>{"what happend while growing up (select that all apply):"}</span>
                    {/* family section */}
                    {FAMILY_UPBRINGING.slice(0, 2).map((section, index) => {
                        return (
                            <div>
                                <div key={index}>
                                    <label>{section.label}:</label>
                                    <div className='d-flex flex-wrap align-items-center'>
                                    {section.options.map((option) => {
                                        return (
                                            <div className="pe-3">
                                                <CheckboxInput
                                                    id={option.name}
                                                    name={`${option.name}`}
                                                    label={option.label}
                                                    checked={formData[`${option.name}`]}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        )
                                    })}
                                    </div>
                                </div>
                                <TextInput
                                    id={`desc_${section.label}`}
                                    name={`desc_${section.label}`}
                                    value={formData[`desc_${section.label}`]}
                                    onChange={handleChange}
                                    placeholder={'Describe:'}
                                    label={''}
                                />
                            </div>
                        )
                    })}
                    <TextInput
                        id="upbringingClinicalImpression"
                        name="upbringingClinicalImpression"
                        value={formData.upbringingClinicalImpression}
                        onChange={handleChange}
                        label="Clinical Impressions: (Staff use only)"
                        placeholder={'Explanation:'}
                        required
                    />

                    {FAMILY_UPBRINGING.slice(2).map((section, index) => {
                        return (
                            <div key={index}>
                                <label>{section.label}:</label>
                                <div className='d-flex flex-wrap align-items-center'>
                                {section.options.map((option) => {
                                    return (
                                        <div className="pe-3">
                                            <CheckboxInput
                                                id={option.name}
                                                name={`${option.name}`}
                                                label={option.label}
                                                checked={formData[`${option.name}`]}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                        )}
                    )}

                    {/* children section */}
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Children
                        </label>                        
                        <div className="d-flex align-items-center">
                            <CheckboxInput
                                id='patientHasNoChildren'
                                name='patientHasNoChildren'
                                label='None'
                                checked={formData.patientHasNoChildren}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                {CHILDREN_TABLE_HEADERS.map(header => (
                                <th key={header.label}>
                                    <label>{header.label}</label>
                                </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(4)].map((_, rowIndex) => (
                                <tr key={rowIndex}>
                            
                                {CHILDREN_TABLE_HEADERS.map((header, index) => (
                                <td key={header}>
                                    {index === 2 ? (
                                        <div className="d-flex align-items-center">
                                            <input type="radio" id="M" name={`${header.name}[${rowIndex}]`} value="M" onChange={handleChange} checked={formData[`${header.name}[${rowIndex}] === 'M'`]} />
                                            <label htmlFor="M" className="px-1"><span>M</span></label>
                                            <input type="radio" id="F" name={`${header.name}[${rowIndex}]`} value="F" onChange={handleChange} checked={formData[`${header.name}[${rowIndex}] === 'F'`]} />
                                            <label htmlFor="F" className="px-1"><span>F</span></label>
                                        </div>

                                    ) : index === 3 ? (
                                        <RadioButtons name={`${header.name}[${rowIndex}]`} formData={formData} handleChange={handleChange}/>
                                    ) : (
                                        <TextInput 
                                            id={`${header.name}[${rowIndex}]`}
                                            name={`${header.name}[${rowIndex}]`}
                                            value={formData[`${header.name}[${rowIndex}]`]}
                                            onChange={handleChange}
                                        />
                                    )}
                                </td>
                                ))}
                            </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Has client ever had involvement with Child Protective Services?
                        </label>
                        {formData.CPSInvolvement === 'Yes' && (
                            <TextInput
                                id="CPSInvolvementDescription"
                                name="CPSInvolvementDescription"
                                value={formData.CPSInvolvementDescription}
                                onChange={handleChange}
                                placeholder={'Year:'}
                                required
                            />
                        )}
                        <div className="d-flex align-items-center">
                            <RadioButtons name='CPSInvolvement' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>

                    {/* additional family section */}

                    <RelationshipTable formData={formData} handleChange={handleChange} />

                    <div>
                        <label>Identify family that would be willing to participate in treatment to assist client in recovery:</label>
                        <TextInput
                            id='additionalFamilyMembersForTreatmentAssistance'
                            name='additionalFamilyMembersForTreatmentAssistance'
                            value={formData.additionalFamilyMembersForTreatmentAssistance}
                            onChange={handleChange}
                            placeholder={'Name:'}
                        />
                    </div>

                    <TextInput
                        id="familyClinicalImpressions"
                        name="familyClinicalImpressions"
                        value={formData.familyClinicalImpressions}
                        onChange={handleChange}
                        label="Clinical Impressions: (Staff use only)"
                        placeholder={'Describe:'}
                        required
                    />

                    <label>Additional family structure/upbringing information:</label>
                    {FAMILY_STRUCTURE_QUESTIONS.map((question, index) => {
                        return (
                            <div className="d-flex align-items-center justify-content-between">
                                <label className="grey-text">
                                    {question.label}
                                </label>
                                <div className="d-flex align-items-center">
                                    <RadioButtons name={`${question.name}`} formData={formData} handleChange={handleChange}/>
                            </div>
                        </div>
                        )
                    })}
                    {formData['Have any of your family members been incarcerated?'] === 'Yes' && (
                        <div>
                            <span>If yes, please explain:</span>
                            <TextInput
                                id='familyIncarcerationDescription'
                                name='familyIncarcerationDescription'
                                value={formData.familyIncarcerationDescription}
                                onChange={handleChange}
                                placeholder={'Describe:'}
                            />
                        </div>
                    )}

                    <div>
                        <FamilyHistoryTable formData={formData} type="substance" label="Family History of Mental Illnesses/Substance Use Disorder" handleChange={handleChange} />
                        <FamilyHistoryTable formData={formData} type="suicide" label="Family History of Suicide" handleChange={handleChange}/>
                    </div>

                    <TextInput
                        id="currentSocialSupport"
                        name="currentSocialSupport"
                        value={formData.currentSocialSupport}
                        onChange={handleChange}
                        label="Current Social Support"
                        required
                    />

                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Support Group Involvement?
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='supportGroupInvolvement' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.supportGroupInvolvement === 'Yes' && (
                        <div>
                            <span>If yes, please explain:</span>
                            <TextInput
                                id='supportGroupInvolvementDescription'
                                name='supportGroupInvolvementDescription'
                                value={formData.supportGroupInvolvementDescription}
                                onChange={handleChange}
                                placeholder={'Describe:'}
                            />
                        </div>
                    )}

                    <h3 className="mt-3">Religion and Culture</h3>
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Religious or Spiritual Affiliation
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='religiousAffiliation' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.religiousAffiliation === 'Yes' && (
                        <div>
                            <span>If yes, please explain:</span>
                            <TextInput
                                id='religiousAffiliationDescription'
                                name='religiousAffiliationDescription'
                                value={formData.religiousAffiliationDescription}
                                onChange={handleChange}
                                placeholder={'Describe:'}
                            />
                        </div>
                    )}

                    <TextInput
                        id="culturalBackground"
                        name="culturalBackground"
                        value={formData.culturalBackground}
                        onChange={handleChange}
                        label="Cultural Background"
                        required
                    />

                </div>
                <div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                    {showFooter && (<div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center'>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3 mx-4'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>)}
                </div>
            </form>
        </div>
    )
};

export default NewPatientPage8;