import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const PtsdResultModal = ({ ptsdResultInfo }) => {

  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const options = {
    animationEnabled: true,
    title: {
      // text: "Number of New Customers"
    },
    axisY: {
      // title: "Number of Customers",
      // interval: 10,
      // minimum: 0,
      // maximum: 40
    },
    toolTip: {
      shared: true,
      contentFormatter: function (e) {
        let content = "<strong>" + e.entries[0].dataPoint.label + "</strong><br/>";
        for (let i = 0; i < e.entries.length; i++) {
          content += "Score: <strong>" + e.entries[i].dataPoint.y + "/40</strong><br/>";
        }
        content += "PTSD Assessment";
        return content;
      }
    },
    data: [{
      type: "spline",
      name: "2016",
      showInLegend: false,
      dataPoints: [
        { y: 30, label: "Oct 23 2022" },
        { y: 33, label: "Mar 24 2023" },
        { y: 20, label: "Aug 25 2023" },
        { y: 30, label: "Nov 26 2023" },
        { y: 40, label: "Nov 27 2023" },
        { y: 30, label: "Nov 28 2023" },
        { y: 32, label: "Nov 29 2023" },
        { y: 23, label: "Nov 30 2023" },
        { y: 28, label: "Dec 1 2023" },
        { y: 10, label: "Dec 2 2023" },
        { y: 20, label: "Dec 3 2023" },
        { y: 30, label: "Dec 4 2023" }
      ]
    }],
    // containerProps: {
    //   style: { // Custom CSS styles
    //     width: "100%",
    //     height: "300px", // Set desired height
    //     border: "1px solid #ccc",
    //     borderRadius: "5px"
    //   },
    //   className: "custom-chart-container" // Custom class name
    // }
    // licenseKey: "YOUR_LICENSE_KEY_HERE",
  }
  const { selectedAssessmentResultData, setIsPtsdResultModalModalOpen } = ptsdResultInfo;

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setIsPtsdResultModalModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
          <div className='d-flex justify-content-between mt-2 mb-3'>
            <div className='headingSec'>
              <h2 className='mb-0'> {selectedAssessmentResultData.title}  assessment
                <button type="button" className="btn completeBtn ms-2">Complete</button></h2>
              <p className='mb-0'>Taken on: Nov 23, 2023</p>
            </div>
          </div>
          <div className='graph'>
            <CanvasJSChart options={options} />
          </div>
          <div className="gap-4 d-flex justify-content-center mt-4">
            <button type="button" onClick={() => setIsPtsdResultModalModalOpen(false)} className="btn btn-primary px-5 btnSize rounded-3">Ok</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PtsdResultModal;
