import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import Checkbox from "@mui/material/Checkbox";

import toast from "react-hot-toast";
import { ProfileContext } from "context/ProfileProvider";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { Row, Col, Form, Image, Button } from "react-bootstrap";
import UserModal from "modals/userModal/UserModal";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { getProfile } = useContext(ProfileContext);
  const [showModal, setShowModal] = useState(false);

  const toggleShowCurrentPassword = () => {
    setShowPassword(!showPassword);
  };

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(
      object().shape({
        email: string().email().required("The email field is required"),
        password: string().required("The password field is required."),
      })
    ),
  });
  const { errors } = formState;

  const handleKeyDown = (e) => {
    const { value, selectionStart } = e.target;
    if (e.key === " " && selectionStart === 0) {
      e.preventDefault();
    }
    const precedingChar = value[selectionStart - 1];
    const followingChar = value[selectionStart];
    if (e.key === " " && (precedingChar === " " || followingChar === " ")) {
      e.preventDefault();
    }
  };

  const onSubmit = async (formData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/users/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
          }),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        const { message, ...rest } = responseData;
        localStorage.setItem("userDetails", JSON.stringify(rest));
        const id = responseData?.id;
        const userRole = responseData?.userRole;
        const token = responseData?.token;
        const name = responseData?.rows?.Name;
        localStorage.setItem("sbCalls", JSON.stringify({ userId: id }));
        localStorage.setItem("userRole", userRole);
        localStorage.setItem("token", token);
        localStorage.setItem("name", name);
        getProfile();
        toast.success(responseData?.message);
        reset();
        if (userRole === "Patient") {
          navigate("/patients-dashboard");
        } else if (userRole === "Doctor") {
          navigate("/therapist-dashboard");
        } else if (userRole === "SuperAdmin") {
          navigate("/");
        }
      } else {
        toast.error(responseData?.message || "Something went wrong.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="vh-100 w-100 loginBglight px-3 overflow-hidden ">
      <Row className="h-100">
        <Col md={5} className="h-100">
          <div className="d-flex align-content-between flex-wrap h-100">
            <Image src="images/logo-spero.png" className="ps-4 pt-3" alt="" />
            <Image src="images/login-bg.svg" className="w-100" alt="" />
          </div>
        </Col>
        <Col md={7} className="h-100 bg-white startRounded">
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="maxWidth">
              <h1 className="text-center" style={{ fontSize: "36px" }}>
                Log in
              </h1>
              <p className="text-center mb-5">
                Please fill in your login and password to get started
              </p>

              <Form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    {...register("email")}
                    type="text"
                    id="email"
                    className="input_form_field"
                    placeholder="Enter Email"
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                  />
                  <div className="text-danger">
                    {errors.email?.message?.toString()}
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      {...register("password")}
                      type={showPassword ? "text" : "password"}
                      className="input_form_field"
                      id="password"
                      placeholder="Enter Password"
                      variant="outlined"
                      onKeyDown={handleKeyDown}
                    />
                    <div className="text-danger">
                      {errors.password?.message?.toString()}
                    </div>
                    <p
                      type="button"
                      className="show_forgot_pass"
                      onClick={toggleShowCurrentPassword}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </p>
                  </div>
                </Form.Group>
                <div className="d-flex justify-content-between">
                  <div className="form-check d-flex p-0">
                    <Checkbox sx={{ padding: 0 }} />
                    <label
                      className="form-check-label"
                      htmlFor="sendEmailCheckbox"
                    >
                      <span
                        className="px-1"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        Remember me
                      </span>
                    </label>
                  </div>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <div className="w-100">
                  <Button
                    type="submit"
                    className="btn btn-primary w-100 mt-4 py-3"
                  >
                    Log in
                  </Button>
                </div>
                <div className="mt-5 text-center d-flex gap-5 user_link">
                  <p className="mt-2">
                    Don’t have an account?{" "}
                    <a href="#" onClick={() => setShowModal(true)}>
                      Sign up
                    </a>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      {showModal && (
        <UserModal
          show={showModal}
          onHide={() => setShowModal(false)}
          title="Are you a Patient or Clinician?"
        />
      )}
    </div>
  );
};

export default Login;
