import React from 'react';

const PsychotherapyOtherModal = ({ psychotherapyDetailInfo }) => {

  const { selectedPsychotherapyData, setIsPsychotherapyModalOpen } = psychotherapyDetailInfo;

  if (!selectedPsychotherapyData) {
    return null;
  }

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setIsPsychotherapyModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
        <h2 className='mb-3'>{selectedPsychotherapyData.name}</h2>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Date created:</label>
              <p className="mb-0">{selectedPsychotherapyData.date} <span>{selectedPsychotherapyData.time}</span></p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Provider:</label>
              <p className="mb-0">{selectedPsychotherapyData.provider}</p>
            </div>
          </div>
           <div className="col-md-12 mb-2">
            <p className="mb-2 overflow-auto scrollMax">
                <p>Session Summary:</p>
                During today's therapy session with Lori, we explored various aspects of their emotional well-being and progress in therapy.Patient reported feeling -  anxious, depressed since our last session on  May 02, 2023. She mentioned specific challenges related to work stress and relationship issues.We discussed the following topics during today's session:
                1. [Topic 1]: [Brief description of the discussion or intervention related to the topic.]
                2. [Topic 2]: [Brief description of the discussion or intervention related to the topic.][Patient's Name] expressed [Patient's Emotions, e.g., frustration, sadness] regarding [Specific Issue Discussed]. We explored coping strategies, including [Interventions or Coping Techniques Discussed], to help them better manage these emotions.We also reviewed their progress on [Treatment Goals, e.g., improving self-esteem, reducing anxiety], and [Patient's Name] has shown improvement in [Specific Areas of Progress].Treatment Plan:In the upcoming sessions, we will continue to work on [Specific Goals or Areas of Focus] to help [Patient's Name] achieve their therapeutic objectives. Homework assignments, such as [Homework Assignments or Exercises], will be provided to support their progress between sessions.I recommend a follow-up session on [Next Session Date] to monitor [Patient's Name]'s progress and further address their therapeutic needs.If you have any questions or need additional information, please feel free to contact me at [Therapist's Phone Number] or [Therapist's Email Address].sing telemental health services. It increases accessibility to treatment, reduces travel time, and allows for participation in therapy from an environment of your choosing. Years of empirical research has established telemental healthcare as a useful and effective mode of healthcare delivery.
    When using technology, however, there is the risk of security and technical difficulty (e.g., disconnection of internet, computer or software not working, etc.). Please refer to the “Telemental Health Checklist” to reduce technical issues, maximize security, and make the most of your therapeutic experience.
    Additionally, technical issues can sometimes limit visual or auditory cues and contribute to miscommunication or misunderstanding. Please know that open, clear, and meaningful communication is one of our highest priorities. Please talk to us about any communication challenges or perceived misunderstandings during our sessions. If issues persist and impact your treatment, we will discuss alternatives and consider referrals for in-person sessions.
    Privacy & Confidentiality
    The laws that protect your privacy and the confidentiality of your health information also apply to tele-mental health services. For more information about exceptions to confidentiality, please refer to the HIPAA NOTICE OF PRIVACY PRACTICES & PRACTICE POLICIES.
    Video Recording
    No permanent video or voice recordings are kept from our tele-mental health sessions. To preserve your privacy and confidentiality, it is also required that you do not record or store videoconference or phone sessions. You must talk with your provider before recording any telemental health interaction.
    Location
    For safety reasons, you will be asked to disclose your physical address in your session. If you anticipate that you will be traveling or changing locations, please let me know in advance so that we can make the appropriate arrangements for privacy and format of our sessions.
    Bringing Someone to Sessions
    If you would like to have a family member or another person join you in your session, please first discuss this with me to make arrangements. If you do not make prior arrangements, sessions that include unapproved individuals will be terminated.
    Emergency Contact Person
    You are required to provide contact information to an emergency person of your choice. This person would be contacted only in cases of an emergency. This person must be over the age of 18 and willing and able to physically go to your location in the event of an emergency. Alternatively, and depending on the nature of the emergency, I might contact local authorities or mental health deputies.
    Technical Difficulties
    If reception is bad, or if our session gets disconnected, we will try to reconnect by restarting the video platform. If we still experience technical difficulties, we will use phone to continue with our session. You also have the option to cancel or reschedule your session.

    Legal Notice: This document contains general information and is not provided as advice, and should not be treated as such. If you have any specific questions about any legal matter you should consult your attorney or other professional legal services provider. For questions, contact info and communications please visit us at: Discovery Counseling Austin: www.discoverycounselingaustin.com

    Discovery Counseling Telemental Health Consent

    Telemental Health Checklist
    Choose a “meeting” space or environment that allows you to focus on the discussions you want to have with your counselor. If you have technology options (phone, laptop, tablet, etc.), choose the one that allows you to have the best telemental health experience. If your environment or your technology is creating distractions, you won’t get as much out of the sessions.
    Situate yourself in a private space
    Think about the best way to make your privacy a priority so you feel comfortable talking freely. For example:
    • Close the door
    • Put a Do Not Disturb sign out (if appropriate)
    • Go for a walk in a park or neighborhood with privacy
    • Inform people in your house or office that you have an important meeting and ask that they not interrupt you unless absolutely necessary.
    • Consider who might enter the room/space during your meeting time and prepare your response to get back to the session quickly.

    Use optimal technology
    • For video sessions: Your laptop with a microphone and speaker are ideal because it will feel most natural for talking to one another. Using headphones with your laptop might work even better for you. Confirm that your internet speed and therefore your video quality allow us to see and hear each other. Good lighting and a stabilized video camera (not walking with your phone or moving around often) will help both you and I get the most out of our video experience with each other.

    • For Phone/Voice sessions: Consider whether you need to be on wifi to get the best experience and choose your location accordingly. Using headphones when possible is the best way to have a clear and volume regulated conversation.

    With a little attention to a comfortable environment and technology, telemental health offers a wonderful alternative to in-person meetings and can have many benefits to your personal counseling goals.

    Signature Required
    By signing this document, I acknowledge that I have been informed about the policies of Discovery Counseling Austin as it relates to telemental health services. I affirm that I have been provided all necessary information about the policies of the practice in order to make a decision to engage in telemental health. My signature also shows my understanding of my rights related to confidentiality and that I know that I can ask questions at any time about the services I’m receiving and that I can terminate at any time.
            </p>
          </div> 

          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center mt-2">
              <label className="me-2 mt-2">Additionally selected:</label>
            </div>
            <p className="mt-2">1.Add new medication Propranolol XR, 40 mg, 1 x per day AM, 90 days supply, Anxiety</p>
            <p className="mt-2">
              2. Order Blood work 144 Lake Road, Camden, New Jersey, Medilab, +17705644561 <a href='#'>Blood work details</a>
            </p>
            <p className="mt-2">
              3.Order genetic testing
              144 Lake Road, Camden, New Jersey, Medilab, +17705644561
            </p>
          </div>
          <div className='row mb-3 mt-3'>
            <div className='col-md-12 mt-3'>
              <div class="gap-4 d-flex justify-content-center">
                <button type="button" class="btn btn-outline-secondary px-5 btnSize rounded-3" onClick={() => setIsPsychotherapyModalOpen(false)}>Cancel</button>
                <button type="button" class="btn btn-primary px-5 btnSize rounded-3" onClick={() => setIsPsychotherapyModalOpen(false)}>Download note</button></div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default PsychotherapyOtherModal;
