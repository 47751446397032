import React, {  useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSbCalls } from 'pages/lib/sendbird-calls';
import { SoundType } from 'sendbird-calls';
import storage from 'pages/lib/storage';


export const PrivateRoute = ({ children }) => {
    const location = useLocation();
    const { userId } = storage.getItem('sbCalls');


    const sbCalls = useSbCalls();
    const [appId] = useState(process.env.REACT_APP_APP_ID || '');
    const { isAuthenticated,  } = useSbCalls();

    useEffect(() => {
        let offlineCall = async () => {
            if (userId) {
                const option = { userId: userId };
                sbCalls.init(appId);
                sbCalls.addDirectCallSound(SoundType.DIALING, '/sounds/Dialing.mp3');
                sbCalls.addDirectCallSound(SoundType.RINGING, '/sounds/Ringing.mp3');
                sbCalls.addDirectCallSound(SoundType.RECONNECTING, '/sounds/Reconnecting.mp3');
                sbCalls.addDirectCallSound(SoundType.RECONNECTED, '/sounds/Reconnected.mp3');
                await sbCalls.auth(option);
                storage.setItem('sbCalls', { appId, userId: userId, role: '2' });
            }
        }
        offlineCall();
    }, [userId, isAuthenticated])


    if (userId) {
        return children;
    } else {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
};

