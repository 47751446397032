import { createContext, useState, useContext } from "react";
import {
  dischargePatients,
  intakePatients,
  singlePatientProfile,
  allPatients,
} from "./Api";
import { assignUserGetDetails } from "lib/api/service";

export const PatientIntakContext = createContext();


export const PatientIntakeProvider = (props) => {
  const [patients, setPatient] = useState([]);
  const [filterPatients, setSearchPatients] = useState([]);
  const setPatientIntake = async (updatedFormData, page_id, patient_id) => {
    const storedData = JSON.parse(localStorage.getItem(`intake_${patient_id}`)) || {};
    if (storedData[page_id]) {
      storedData[page_id] = updatedFormData;
    } else {
      storedData[page_id] = updatedFormData;
    }
    localStorage.setItem(`intake_${patient_id}`, JSON.stringify(storedData));
    return JSON.parse(localStorage.getItem(`intake_${patient_id}`)) || {};
  };
  const getPatientIntake = async (patient_id) => {
    return JSON.parse(localStorage.getItem(`intake_${patient_id}`)) || {};
  };

  const deletePatientIntake = async (patient_id) => {
    localStorage.removeItem(`intake_${patient_id}`);
  };

  const PatientIntakeApi = async (body, patientId) => {
    try {
      const response = await intakePatients(body, patientId);
      return response;
    } catch (error) {
      return null;
    }
  };

  const singlePatientProfileData = async (id) => {
    try {
      const data = JSON.stringify({
        id: id,
        task: "patientdetails",
      });
      const response = await assignUserGetDetails(data);
      return response?.data;
    } catch (error) {
      console.error(error);
    }
  };

  const AllPatients = async (userId) => {
    try {
      const response = await allPatients(userId);
      setPatient(response);
    } catch (error) {
      setPatient([]);
    }
  };

  const SearchPatients = async (userId, filter = '') => {
    try {
      setSearchPatients([]);
      const response = await allPatients(userId, filter);
      setSearchPatients(response);
    } catch (error) {
      setSearchPatients([]);
    }
  };

  const emptyPatients = async () => {
    setPatient([]);
  };
  const emptyPatientsSearchItme = async () => {
    setSearchPatients([]);
  };

  return (
    <PatientIntakContext.Provider
      value={{
        setPatientIntake,
        AllPatients,
        emptyPatients,
        patients,
        getPatientIntake,
        deletePatientIntake,
        PatientIntakeApi,
        singlePatientProfileData,
        SearchPatients,
        filterPatients,
        emptyPatientsSearchItme

      }}
    >
      {props.children}
    </PatientIntakContext.Provider>
  );
};
