import React, { useContext, useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import UploadDocModal from '../../modals/UploadDocModal';
import { ProfileContext } from '../../context/ProfileProvider';
import toast from 'react-hot-toast';
import { ErrorMessage, SuccessMessage } from '../../components/Toast/Toast';
import { fetchProfile, updateProfile, fileUpload } from '../../context/Api';

const PersonalDataTab = () => {
    const formRef = useRef(null);

    const { profile, updateProfileHandler } = useContext(ProfileContext)

    const [isUploadDocModalModalOpen, setUploadDocModalOpen] = useState(false);
    const [fileInputName, setInputFileName] = useState('');
    const [front_docs_object_name, setfront_docs_object_name] = useState("")
    const [front_docs_bucket_name, setfront_docs_bucket_name] = useState("")
    const [back_docs_object_name, setback_docs_object_name] = useState("")
    const [back_docs_bucket_name, setback_docs_bucket_name] = useState("")
    const [bucket_name, setbucket_name] = useState("")
    const [object_name, setobject_name] = useState("")
    const [profile_image, setPofileIimage] = useState("")
    const [front_docs, setfront_docs] = useState("")
    const [back_docs, setback_docs] = useState("")
    const [message, setMessage] = useState("");



    const setUploadDocCloseModal = () => {
        setUploadDocModalOpen(false);
    };
    const onChangeImageFiled = (event) => {

        const file = event.target.files[0];
        const file_input_name = event.target.name;

        if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
                try {


                    if (file_input_name == "profileImage") {
                        setPofileIimage(reader.result);
                    } else if (file_input_name == "front_docs") {
                        setfront_docs(reader.result);
                    } else if (file_input_name == "back_docs") {
                        setback_docs(reader.result);
                    }

                    const uploadResult = await fileUpload({
                        "task": "uploadImage",
                        "image": reader.result
                    });

                    if (uploadResult) {
                        toast.success("File upload successfully");
                        setUploadDocModalOpen(false)

                        if (file_input_name == "profileImage") {

                            setbucket_name(uploadResult.bucket.bucket_name);
                            setobject_name(uploadResult.bucket.object_key);
                        } else if (file_input_name == "front_docs") {
                            setfront_docs_object_name(uploadResult.bucket.object_key);
                            setfront_docs_bucket_name(uploadResult.bucket.bucket_name);
                        } else if (file_input_name == "back_docs") {
                            setback_docs_object_name(uploadResult.bucket.object_key);
                            setback_docs_bucket_name(uploadResult.bucket.bucket_name);
                        } else {
                            return false;
                        }
                    } else {
                        toast.error("Error uploading file");
                        return false

                    }

                } catch (error) {
                    toast.error("Error uploading file");
                    return false
                }
            };

            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };

            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };


    const handleSubmit = async (event) => {
        setMessage("");

        event.preventDefault();
        try {
            const formData = new FormData(formRef.current);
            const formDataObject = {};
            formData.forEach((value, key) => {
                formDataObject[key] = value;
            });

            const profileUpdate = await updateProfileHandler(formDataObject);
            if (!profileUpdate?.success) {
                toast.error("File upload successfully");


                return false;
            } else {
                toast.success(profileUpdate.message || 'Profile not update');

                return false;
            }
        } catch (err) {
            toast.error(<ErrorMessage message={'Something went wrong..'} />, { autoClose: 2000 });
            return false;
        }
    };
    return (
        <>
            <div className='cards-container'>
                <div className="blue-card my-4 w-50 col">
                    <div className='row'>
                        <div className='bold-blue-text'>Personal Data:</div>
                        <div className='col-4'>
                            <div className='profile'>
                                <img
                                    className='profile-img'
                                    src={profile_image || profile.image || 'images/logo.svg'}
                                    alt='profile-img'
                                />
                                <div>
                                    <button
                                        className='upload-link-button'
                                        onClick={() => { setUploadDocModalOpen(true); setInputFileName("profileImage"); }}

                                    >
                                        Edit Picture
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-8'>
                            <form method='post' ref={formRef} onSubmit={handleSubmit}>
                                <input type='hidden' name='front_docs_object_name' value={front_docs_object_name || profile.profile?.front_docs_object_name} />
                                <input type='hidden' name='front_docs_bucket_name' value={front_docs_bucket_name || profile.profile?.front_docs_bucket_name} />
                                <input type='hidden' name='back_docs_object_name' value={back_docs_object_name || profile.profile?.back_docs_object_name} />
                                <input type='hidden' name='back_docs_bucket_name' value={back_docs_bucket_name || profile.profile?.back_docs_bucket_name} />
                                <input type='hidden' name='bucket_name' value={bucket_name || profile.bucket_name} />
                                <input type='hidden' name='object_name' value={object_name || profile.object_name} />
                                <input type='hidden' name='task' value='updatePersoanlData' />
                                <input type='hidden' name='userid' value={profile.user_id} />
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        id="firstName"
                                        className="form-control"
                                        name="firstName"
                                        type="text"
                                        defaultValue={profile.firstName || ''}

                                    />
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        id="lastName"
                                        className="form-control"
                                        name="lastName"
                                        type="text"
                                        defaultValue={profile.lastName || ''}

                                    />
                                    <label htmlFor="email">Email</label>
                                    <input
                                        id="email"
                                        className="form-control"
                                        name="email"
                                        type="text"
                                        defaultValue={profile.email || ''}

                                    />
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input
                                        id="phoneNumber"
                                        className="form-control"
                                        name="phoneNumber"
                                        type="text"
                                        maxLength={10}
                                        pattern="[0-9]*"
                                        defaultValue={profile.phoneNumber || ''}

                                    />
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                {/* right card */}
                <div className="blue-card my-4 mx-3 w-50 col">
                    <div className='row'>
                        <div className='bold-blue-text'>ID Card:</div>
                        <div className='py-1'>
                            <span className="small-text">
                                Upload photos of the front and back of your most recent identification card.
                            </span>
                            <div className='id-card-container'>
                                <div className='id-card'>
                                    <img
                                        src={front_docs || profile.profile?.front_docs || 'images/ID-front-example.png'}
                                        alt='id-front'
                                        onClick={() => { setUploadDocModalOpen(true); setInputFileName("front_docs"); }}
                                    />
                                    {/* <span className="small-text">Uploaded: 21 Oct 2020</span> */}
                                </div>
                                <div className='id-card'>
                                    <img
                                        src={back_docs || profile.profile?.back_docs || 'images/ID-back-example.png'}
                                        alt='id-back'
                                        onClick={() => { setUploadDocModalOpen(true); setInputFileName("back_docs"); }}
                                    />
                                    {/* <span className="small-text">Uploaded: 21 Oct 2020</span> */}
                                </div>
                            </div>
                            {/* <div>
                            <button
                                onClick={() => {setUploadDocModalOpen(true)}}
                                className='upload-link-button'
                            >
                                Upload ID Card
                            </button>
                        </div> */}
                        </div>
                    </div>


                </div>


                <Modal
                    isOpen={isUploadDocModalModalOpen}
                    onRequestClose={() => setUploadDocModalOpen(false)}
                >
                    <UploadDocModal
                        uploadDocInfo={{
                            setUploadDocCloseModal: setUploadDocCloseModal,
                            setInputName: fileInputName,
                            handleFileChange: onChangeImageFiled
                        }}
                    />
                </Modal>
            </div>
            <div className='row'>
                <div className='col-md-12 col-12'>
                    <div className='white-rounded-footer'>
                        <hr className="whitebox-footer-divider"></hr>
                        <div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end'>
                            <button className='ftrBtn blueSubmitButton' onClick={handleSubmit} type="button">Update Profile</button>
                        </div>
                    </div>

                </div>
                {message && (
                    <h5 className='text text-warning'>{message}</h5>
                )}
            </div>
        </>

    )
};

export default PersonalDataTab;