// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardInside {
    height: auto !important;

}


.firstChild:first-child {
    height: auto !important;
    width: auto !important;

}`, "",{"version":3,"sources":["webpack://./src/styles/dialview.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;;AAE3B;;;AAGA;IACI,uBAAuB;IACvB,sBAAsB;;AAE1B","sourcesContent":[".cardInside {\n    height: auto !important;\n\n}\n\n\n.firstChild:first-child {\n    height: auto !important;\n    width: auto !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
