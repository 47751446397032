import React from "react";
import notifications from "../../../data/notifications.json";
import Navbar from "react-bootstrap/Navbar";
import { ListGroup, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DashboardMessagesComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white rounded-top-3 mt-4 p-3 box_radius">
      <div className="modal-content">
        <Navbar className="modal-header p-2 border-bottom-0">
          <Navbar.Brand>
            <h2 className="bold-text blue-text">Messages</h2>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="">
              <button
                className="text-button view_all_btn"
                onClick={() => navigate("/messages")}
              >
                View all
              </button>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>

        <ListGroup variant="flush" className="p-0 gap-2 d-grid ul_class">
          <ListGroup.Item className="d-flex flex-wrap align-items-center p-0">
            <Image src="images/tinder.png" className="mx-2" alt="" />
            <span>marked as urgent and require your attention</span>
          </ListGroup.Item>
        </ListGroup>

        <div className="modal-body">
          <ul className="px-2">
            {notifications.map((notification, index) => (
              <React.Fragment>
                <li
                  key={index}
                  className="d-flex justify-content-between align-items-center py-1"
                >
                  <div className="d-flex align-items-center">
                    <Image
                      width={35}
                      height={35}
                      src={notification.imageSrc}
                      alt="profile-icon"
                    />
                    <div className="px-2">
                      <span className="bold-text">{notification.assigner}</span>
                      <span>{notification.notificationMessage}</span>

                      <div className="smaller-text light-grey-text py-1">
                        {notification.message}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="smaller-text light-grey-text mb-0">
                      {notification.createdAt}
                    </p>
                    <img
                      src="images/tinder.png"
                      alt="img"
                      className="align-items-end"
                    />
                  </div>
                </li>
                <li className="horizontal-divider"></li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DashboardMessagesComponent;
