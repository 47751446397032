import React from 'react';

const MedicationsDataModal = ( { medicationsInfo } ) => {

  const { setIsMedicationModalOpen, selectedMedicationData } = medicationsInfo;
  
  const prescriptionInfo = [
    {
      label: "Prescription reason:",
      content: "Patient has been diagnosed with Bipolar Disorder. This diagnosis is based on a comprehensive assessment, including the patient's medical history, reported symptoms, and clinical evaluation. Bipolar Disorder is characterized by recurrent episodes of mania (elevated mood, increased energy, impulsivity) and depression (low mood, decreased energy, hopelessness)."
    },
    {
      label: "Dosage and Administration:",
      content: "Patient will be initiated on Abilify at a starting dose of 40 mg AM+PM, with subsequent adjustments based on therapeutic response and tolerability. The patient will be closely monitored for any side effects or changes in mood throughout treatment."
    },
    {
      label: "Discontinued reason:",
      content: "Patient has been on Abilify for the treatment of Bipolar Disorder, for a period from Apr 22, 2023 - May 22, 2023 (30 days). During this time, the patient's did not make clinical progress has been observed negative side effects of headaches and chronic exhaustion."
    }
  ];

  return (

    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
            <button type="button" className="btn-close" onClick={() => setIsMedicationModalOpen(false)} >
            </button>
          </div>
          <div className="modal-body pt-3 pb-5 px-5">
            <div className='headingSec d-flex justify-content-between mb-4'>
              <h2 className='mb-0'> { selectedMedicationData.name } { selectedMedicationData.dosage }</h2>
            </div>
            <div className="col-md-12 mb-2">
              <div className="d-flex align-items-center">
                <label className="me-2">Start date:</label>
                <p className="mb-0">{ selectedMedicationData.startDate }</p>
              </div>
            </div>
            <div className="col-md-12 mb-2">
              <div className="d-flex align-items-center">
                <label className="me-2">End date:</label>
                <p className="mb-0">{ selectedMedicationData.endDate }</p>
              </div>
            </div>

            {prescriptionInfo.map((info, index) => (
            <div key={index} className='col-md-12 mb-2'>
              <label className="me-2 my-2">{info.label}</label>
              <p className='mb-1'>{info.content}</p>
            </div>
            ))}

              <div className="gap-4 d-flex justify-content-center">
                <button type="button" className="btn btn-primary px-5 btnSize rounded-3" onClick={() => setIsMedicationModalOpen(false)}>Ok</button>
              </div>
          </div>
        </div>
    </div>

  );
};

export default MedicationsDataModal;
