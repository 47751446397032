// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.badge {
    visibility: hidden;
}

.badge-button {
    background-color: inherit;
    border: none; 
    background: none; 
    padding: 0; 
    cursor: pointer;
}

.badge-button:focus,
.badge-button:active {
  outline: none;
  border: none;
}

.visible {
    visibility: visible;
}

.close {
    position: absolute;
    top: 0px; 
    right: 5px; 
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }

.notifications-modal {
    font-size: 12px;
    position: absolute;
    top: calc(100% - 10px); /* Adjust as needed */
    right: calc(-350% - 8px); /* Adjust as needed */
    display: block;
    z-index: 100;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    width: 400px; /* Adjust the width as needed */
}

.modal-dialog {
    margin: 0;
}

.modal-content {
    border: none;
}

.modal-header {
    position: relative;
}

.modal-body {
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Modal.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,UAAU;IACV,eAAe;AACnB;;AAEA;;EAEE,aAAa;EACb,YAAY;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,eAAe;EACjB;;AAEF;IACI,eAAe;IACf,kBAAkB;IAClB,sBAAsB,EAAE,qBAAqB;IAC7C,wBAAwB,EAAE,qBAAqB;IAC/C,cAAc;IACd,YAAY;IACZ,uBAAuB;IACvB,oCAAoC;IACpC,mBAAmB;IACnB,yCAAyC;IACzC,YAAY,EAAE,+BAA+B;AACjD;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;EACE,YAAY;AACd","sourcesContent":["\n.badge {\n    visibility: hidden;\n}\n\n.badge-button {\n    background-color: inherit;\n    border: none; \n    background: none; \n    padding: 0; \n    cursor: pointer;\n}\n\n.badge-button:focus,\n.badge-button:active {\n  outline: none;\n  border: none;\n}\n\n.visible {\n    visibility: visible;\n}\n\n.close {\n    position: absolute;\n    top: 0px; \n    right: 5px; \n    padding: 10px;\n    background: none;\n    border: none;\n    cursor: pointer;\n  }\n\n.notifications-modal {\n    font-size: 12px;\n    position: absolute;\n    top: calc(100% - 10px); /* Adjust as needed */\n    right: calc(-350% - 8px); /* Adjust as needed */\n    display: block;\n    z-index: 100;\n    background-color: white;\n    border: 1px solid rgba(0, 0, 0, 0.2);\n    border-radius: 10px;\n    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);\n    width: 400px; /* Adjust the width as needed */\n}\n\n.modal-dialog {\n    margin: 0;\n}\n\n.modal-content {\n    border: none;\n}\n\n.modal-header {\n    position: relative;\n}\n\n.modal-body {\n  padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
