import { ASSESSMENTS_FREQUENCY_TYPES } from 'Data';
import { SelectDropdown } from 'components/FormComponents/FormComponents';
import Badge from 'components/views/Badge';
import React from 'react';





interface AssignedAssessmentPatientModalInterfece {
    setIsAssignedToPatientModalOpen: any,
    assessments: any[],
    currentAssessment: any,
    filterData: () => void,
    assignedAssessments: any,
    handleChange: any,
    formData: any,
    patientDetail: any
}

const AssignedAssessmentPatientModal: React.FC<AssignedAssessmentPatientModalInterfece> = ({
    setIsAssignedToPatientModalOpen,
    assessments,
    filterData,
    currentAssessment,
    assignedAssessments,
    handleChange,
    formData,
    patientDetail
}) => {

    return (
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
                    <button type="button" className="btn-close" onClick={() => setIsAssignedToPatientModalOpen(false)} />
                </div>
                <div className="modal-body pt-0 pb-5 px-4">
                    <form onSubmit={assignedAssessments}>
                        <input type='hidden' name="id" value={currentAssessment.id} />
                        <div className='headingSec'>
                            <h2 className='mb-0'>Assign assessment to patient</h2>
                        </div>

                        <div className="d-flex mt-3 align-items-center gap-3">
                            <label>Patient selected:</label>
                            <div className="form-check ps-0 d-flex align-items-center ">
                                <div className="ml-3"> <Badge userName={patientDetail?.patientName} /></div>
                                <label className="form-check-label mr-2" htmlFor="flexCheckDefault">{patientDetail?.patientName}</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Assessment(s):</label>
                            <div className="input-group custominp w-100">
                                <span className="input-group-text border-end-0" id="basic-addon1">
                                    <i className="far fa-search"></i>
                                </span>
                                <input type="text" onKeyUp={filterData} className="form-control ps-0 border-start-0" placeholder="Search assessment " aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                        <div className='patientList'>
                            <ul className='p-0 gap-3 d-grid'>
                                {assessments.length > 0 && assessments.map((row: any, index: number) => (
                                    <li key={row.id}>
                                        <div className='d-flex justify-content-between align-items-center '>
                                            <div className="form-check col-md-4">
                                                <input
                                                    className="form-check-input mt-2"
                                                    onChange={handleChange}
                                                    checked={formData[`assessment[${row.id}]`] == 'on' || formData[`assessment[${row.id}]`] == 1}
                                                    name={`assessment[${row.id}]`}
                                                    type="checkbox"
                                                    value={row.id}
                                                    id={`flexCheckDefault${row.id}`}
                                                />
                                                <label className="form-check-label" htmlFor={`flexCheckDefault${row.id}`} >
                                                    {row.title}
                                                </label>
                                            </div>

                                            <div className=" col-md-4">
                                                <SelectDropdown name={`frequency[${row.id}]`} value={formData[`frequency[${row.id}]`]} options={ASSESSMENTS_FREQUENCY_TYPES} labelKey="value" nameKey="label" onChange={handleChange} />
                                            </div>

                                            <div className="col-md-4">
                                                {formData[`assessment[${row.id}]`] == 'on' || formData[`assessment[${row.id}]`] == 1 ? (
                                                    <span className='slected text-success '><i className="far fa-check"></i> Selected</span>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>

                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="gap-4 d-flex justify-content-center">
                                <button type="button" className="btn btn-outline-secondary px-5 btnSize rounded-3" onClick={() => setIsAssignedToPatientModalOpen(false)}>Cancel</button>
                                <button type="submit" className="btn btn-primary px-5 btnSize rounded-3" >Assign assessment</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AssignedAssessmentPatientModal;
