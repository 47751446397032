import React, { useEffect, useState } from "react";
import {
  TextInput,
  DateInput,
  Dropdown,
  DOBDateInput,
  CountryDropdown,
  NumberInput,
} from "../../../components/FormComponents/FormComponents";
import { GENDER_IDENTITIES, SEXUAL_ORIENTATIONS, RACES } from "../../../Data";
import { toast } from "react-toastify";

const calculateDisableGuardianInputs = (dob) => {
  const dobDate = new Date(dob);
  const currentDate = new Date();
  const age = currentDate.getFullYear() - dobDate.getFullYear();
  return age >= 18;
};

const AddCorrespondenceForm = ({
  formData,
  handleChange,
  handleSubmit,
  setFormData,
  patientId,
  onCloseClick
}) => {
  const [showNewEmergencyContact, setShowNewEmergencyContact] = useState(false);
  const [numEmergencyContacts, setNumEmergencyContacts] = useState(0);
  const [addedEmergencyFormsIndices, setAddedEmergencyFormsIndices] = useState([
    0,
  ]);
  const [errors, setError] = useState(false);

  const handleAddEmergencyContact = () => {
    if (numEmergencyContacts === 0) {
      const newIndex = numEmergencyContacts;
      setAddedEmergencyFormsIndices((prevIndices) => [
        ...prevIndices,
        newIndex,
      ]);
      setFormData((prevData) => ({
        ...prevData,
        [`additonalComment[${newIndex}]`]: "",
      }));
      setNumEmergencyContacts((prevNum) => prevNum + 1);
      setShowNewEmergencyContact(true);
    }
  };

  const handleRemoveEmergencyContact = () => {
    const lastAddedIndex = addedEmergencyFormsIndices.pop();
    setFormData((prevData) => {
      const updatedFormData = { ...prevData };
      delete updatedFormData[`additonalComment[${lastAddedIndex}]`];
      return updatedFormData;
    });
    setNumEmergencyContacts((prevNum) => Math.max(0, prevNum - 1));
    setAddedEmergencyFormsIndices((prevIndices) =>
      prevIndices.filter((index) => index !== lastAddedIndex)
    );
    if (addedEmergencyFormsIndices.length === 0) {
      setNumEmergencyContacts(0);
      setShowNewEmergencyContact(false);
    }
  };

  const formBeforeSubmit = (e) => {
    setError(false);
    e.preventDefault();
    const formData = new FormData(e.target);
    for (const [key, value] of formData.entries()) {
      if (
        (key === "guardianNumber" ||
          key === "patientPhoneNumber" ||
          key.startsWith("emergencyPhoneNumber[")) &&
        (value.length < 10 || value.length > 12)
      ) {
        const errorMessage =
          key === "guardianNumber"
            ? "Incorrect guardian phone number format"
            : key === "patientPhoneNumber"
            ? "Incorrect patient phone number format"
            : "Incorrect emergency phone number format";
        toast.error(errorMessage);
        setError(errorMessage);
        return;
      }
    }
    handleSubmit(e);
  };

  return (
    <div>
      <form onSubmit={formBeforeSubmit}>
        <div className="form-group row">
          <div className="col-6">
            <DateInput
              id="Date_Of_contact"
              name="Date_Of_contact"
              value={formData.Date_Of_contact}
              onChange={handleChange}
              label="Date of Contact:"
              required
            />
          </div>
          <div className="col-6">
            <DateInput
              id="Date_of_review"
              name="Date_of_review"
              value={formData.Date_of_review}
              onChange={handleChange}
              label="Date of Review:"
              required
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-6">
            <TextInput
              id="level_care_approved"
              name="level_care_approved"
              value={formData.level_care_approved}
              onChange={handleChange}
              label="Level of Care Approved:"
            />
          </div>
          <div className="col-6">
            <TextInput
              id="Units_care_approvedad"
              name="Units_care_approvedad"
              value={formData.Units_care_approvedad}
              onChange={handleChange}
              label="Units of Care Approved:"
            />
          </div>

          <div className="col-6">
            <DateInput
              id="date_of_next_review"
              name="date_of_next_review"
              value={formData.date_of_next_review}
              onChange={handleChange}
              label="Date of Next Review:"
              required
            />
          </div>
        </div>

        <div className="col-12">
          <div className="row form-group">
            <div className="col-md-6 col-12">
              <TextInput
                id="insurance_firm"
                name="insurance_firm"
                value={formData.insurance_firm}
                onChange={handleChange}
                label="Insurance Firm:"
              />
            </div>
            <div className="col-md-6 col-12">
              <NumberInput
                id={`insurance_contact_number`}
                name={`insurance_contact_number`}
                value={formData.insurance_contact_number}
                onChange={handleChange}
                label={"Insurance Contact number:"}
                required
              />
            </div>

            <div className="col-6">
              <TextInput
                id="insurance_contact_name"
                name="insurance_contact_name"
                value={formData.insurance_contact_name}
                onChange={handleChange}
                label="Insurance Contact Name:"
                required
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-6">
              <TextInput
                id="comments_about_insurance_communication"
                name="comments_about_insurance_communication"
                value={formData.comments_about_insurance_communication}
                onChange={handleChange}
                label="Comments about Insurance Communication:"
                required
              />
            </div>
            <div className="col-6">
              <TextInput
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                label="Status:"
                required
              />
            </div>
          </div>

          {addedEmergencyFormsIndices.length> 0 && addedEmergencyFormsIndices.map((index) => (
            <div key={`additonalComment${index}`} className="row form-group">
              <div className="col-md-12 col-12">
                <TextInput
                  id={`additonalComment[${index}]`}
                  name={`additonalComment[${index}]`}
                  value={formData[`additonalComment[${index}]`]}
                  onChange={handleChange}
                  label={`Additional Comment`}
                />
              </div>
            </div>
          ))}
          <div className="row d-flex justify-content-between">
            {!showNewEmergencyContact && (
              <div>
                <button
                  type="button"
                  onClick={handleAddEmergencyContact}
                  className="text-button">
                  + Add Additional Comment
                </button>
              </div>
            )}
            {showNewEmergencyContact && (
              <div>
                <button
                  type="button"
                  onClick={handleRemoveEmergencyContact}
                  className="text-button"
                  style={{ color: "#FF6760" }}>
                  - Remove Additional Comment
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="white-rounded-footer mt-2">
          <hr className="whitebox-footer-divider"></hr>
          <div className="bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn btn-outline-secondary px-5 btnSize rounded-3 mx-2"
            >
            Cancel
          </button>
            <button className="btn btn-primary px-5 btnSize rounded-3" type="submit">
              Submit
            </button>
          </div>
          {errors && <div className="text-danger">{errors}</div>}
        </div>
      </form>
    </div>
  );
};

export default AddCorrespondenceForm;
