import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import AssessmentDocModal from '../../../../modals/AssessmentDocModal';
import UploadDocModal from '../../../../modals/UploadDocModal';
import TelementalHealthModal from '../../../../modals/TelementalHealthModal';

const Notes = () => {

  const [isUploadDocModalModalOpen, setUploadDocModalOpen] = useState(false);
  const [selectedAssignedStatus, setSelectedAssignedStatus] = useState('Select status');
  const [selectedAllAssignedStatus, setSelectedAllAssignedStatus] = useState('Select status');
  const [isAssignedToPatientModalOpen, setIsAssignedToPatientModalOpen] = useState(false);

  const assignedData = [
    {
      name: "LoBri_Jun10.doc",
      providerImage: "images/doctor-pic.png",
      provider: "Dr. Jones",
      createdDate: "Jun 10, 2023",
      status: "Signature required"
    },
    {
      name: "LoBri_May25.doc",
      providerImage: "images/doctor-pic.png",
      provider: "Dr. Jones",
      createdDate: "Jun 10, 2023",
      status: "Signature required"
    },
    {
      name: "LoBri_May02.doc",
      providerImage: "images/doctor-pic.png",
      provider: "Dr. Jones",
      createdDate: "Jun 10, 2023",
      status: "Signed"
    },
    {
      name: "LoBri_Apr22.doc",
      providerImage: "images/doctor-pic.png",
      provider: "Dr. Jones",
      createdDate: "Jun 10, 2023",
      status: "Signed"
    },
  ]

  const allAssignedData = [
    {
      name: "Telemental Health Consent",
      providerImage: "images/doctor-pic.png",
      provider: "Dr. Jones",
      createdDate: "Jun 10, 2023",
    },
    {
      name: "HIPAA NOTICE OF PRIVACY PRACTICES.pdf",
      providerImage: "images/doctor-pic.png",
      provider: "Dr. Jones",
      createdDate: "Jun 10, 2023",
    },
    {
      name: "Outpatient Psych Consent.pdf",
      providerImage: "images/doctor-pic.png",
      provider: "Dr. Jones",
      createdDate: "Jun 10, 2023",
    },
    {
      name: "Telemental Health Consent",
      providerImage: "images/doctor-pic.png",
      provider: "Dr. Jones",
      createdDate: "Jun 10, 2023",
    },
  ]

  const assignedDatafilteredTasks = selectedAssignedStatus === 'Select status' ? assignedData : assignedData.filter(assigned => assigned.status === selectedAssignedStatus);

  const allAssignedDatafilteredTasks = selectedAllAssignedStatus === 'Select status' ? allAssignedData : allAssignedData.filter(allAssignedData => allAssignedData.status === selectedAllAssignedStatus);

  const handleAssignedChange = (e) => {
    setSelectedAssignedStatus(e.target.value);
  };

  const handleallAssignedDataChange = (e) => {
    setSelectedAssignedStatus(e.target.value);
  };

  const [isAssignedModalOpen, setIsAssignedModalOpen] = useState(false);
  const [selectedAssignedData, setSelectedAssignedData] = useState(false);

  const setUploadDocCloseModal = () => {
    setUploadDocModalOpen(false);
  };

  return (
    <>
      <div className="tab-pane fade" id="medical-notes" role="tabpanel" aria-labelledby="medical-notes-tab">
        {/* //////////////////// */}
        <div className="headingTable mb-3">
          <div className="row align-items-center">
            <div className="col-auto">
              <h1 className="mb-0">Medical notes  </h1>
            </div>
            <div className='col'>
              <div className='row justify-content-end'>

                <div className='col-md-3'>
                  <button type="button" className="btn btn-success border-0"
                  //  onClick={() => {
                  //   // setSelectedUploadDocData("");
                  //   setUploadDocModalOpen(true);
                  // }}
                  >
                    <i className="fas fa-list-alt"></i> Add new note</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //////////////////// */}

        <div className='tableList'>
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col">Note name <img src='images/short-icon.svg' alt='' /></th>
                <th scope="col">Provider <img src='images/short-icon.svg' alt='' /></th>
                <th scope="col">Date Send <img src='images/short-icon.svg' alt='' /></th>
                {/* <th scope="col">Status</th> */}
              </tr>
            </thead>
            <tbody>

              {assignedDatafilteredTasks.map(document => (
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img src="images/doc-icon.png" alt="..." />
                      </div>


                      <div className="flex-grow-1 ms-3">
                        <span className='curser-pointer'
                          onClick={() => {
                            setSelectedAssignedData(document);
                            setIsAssignedModalOpen(true);
                          }}
                        >
                          {document.name}
                        </span>
                      </div>


                    </div>
                  </td>
                  <td>
                    <h6 className='mb-0'><img src={document.providerImage} alt='' />{document.provider}</h6>
                  </td>
                  <td>{document.createdDate}</td>
                  <td>
                    <button type="button" className='btn completeBtn'>
                      <img src="images/tabler_download.svg" alt="..." />
                    </button>

                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>

      <Modal isOpen={isAssignedToPatientModalOpen} onRequestClose={() => setIsAssignedToPatientModalOpen(false)} >
        <AssessmentDocModal
          assessmentInfo={{
            setIsAssignedToPatientModalOpen: setIsAssignedToPatientModalOpen
          }}
        />
      </Modal>

      <Modal isOpen={isUploadDocModalModalOpen} onRequestClose={() => setUploadDocModalOpen(false)} >
        <UploadDocModal
          uploadDocInfo={{
            setUploadDocCloseModal: setUploadDocCloseModal,
            // selectedUploadDocData:selectedUploadDocData
          }}
        />
      </Modal>

      <Modal isOpen={isAssignedModalOpen} onRequestClose={() => setIsAssignedModalOpen(false)} >
        <TelementalHealthModal
          assessmentInfo={{
            setIsAssignedModalOpen: setIsAssignedModalOpen,
            selectedAssignedData: selectedAssignedData
          }}
        />
      </Modal>

    </>
  );

};

export default Notes;
