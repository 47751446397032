import React from "react";
const PatientProfile = ({ listingallPatients }) => {
  const patient = listingallPatients[0];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const calculateAge = (patientDob) => {
    const today = new Date();
    const birthDate = new Date(patientDob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  return (
    <div id="treatmentPlan">
      <div className="headingTable cardPaitent">
        <div className="row d-flex align-items-center mt-2">
          <div className="col-2">
            <div class="flex-shrink-0">
              <img src="/images/patients.svg" alt="" />
            </div>
          </div>
          <div className="col-10 pt-2">
            <div className="row d-flex mb-2">
              <h1 className="mb-2 text-capitalize">
                {patient.firstName} {patient.lastName}
              </h1>
              <div className="col-3">
                <label>
                  Patient No: <span>#{patient.id}</span>
                </label>
              </div>
              <div className="col-3">
                <label>
                  Gender: <span>{patient.gender}</span>
                </label>
              </div>
              <div className="col-3">
                <label>
                  LOC: <span>IOP</span>
                </label>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-3">
                <label>
                  AGE: <span>{calculateAge(patient.dob)} years</span>
                </label>
              </div>
              <div className="col-3">
                <label>
                  DOB: <span>{patient.dob}</span>
                </label>
              </div>
              <div className="col-6">
                <label>
                  Admission Date:{" "}
                  <span>{patient ? formatDate(patient.createdAt) : ""}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>
              Sex: <span> {patient.gender}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Gender identity:
              <span> {patient?.gender}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Preffered pronouns: <span> {patient.source}</span>
            </label>
          </div>
          <div className="col-12 mb-1">
            <label>
              Address:{" "}
              <span>
                {" "}
                {patient.street} {patient.city} {patient.state}{" "}
                {patient.country} {patient.zcode}
              </span>
            </label>
          </div>
          <div className="px-3">
            <hr style={{ color: "royalBlue" }} />
          </div>
          <div className="col-12">
            <label>
              Contact phone number: <span> {patient.phoneNumber}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Emergency contact name:
              <span> {patient.Emergency_contact_name}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Emergency contact phone number:
              <span> {patient.Emergency_contact_no}</span>
            </label>
          </div>
          <div className="col-12 mb-1">
            <label>
              Emergency contact relationship:
              <span> {patient.Emergency_contact_relation}</span>
            </label>
          </div>
          <div>
            <hr style={{ color: "royalBlue" }} />
          </div>
          <div className="col-12">
            <label>
              Insurance firm: <span> {patient.Inr_com_name}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Insurance ID: <span> {patient.Policy_holder_ID}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Policy holder’s name:<span> {patient.Policy_holder_name}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Policy holder’s DOB:<span> {patient.dob}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Group number: <span> {patient.Inr_grp_no}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Relationship: <span> {patient.Inr_relation}</span>
            </label>
          </div>
          <div className="col-12">
            <label>
              Phone number: <span> {patient.Inr_phone_no}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientProfile;
