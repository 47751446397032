import React, {  useMemo } from 'react';
import { TextAreaInput } from '../../../../components/FormComponents/FormComponents';
import { MSE_OPTIONS } from '../../../../const/PatientIntakeConst';
import { MSECheckboxComponent } from '../Components/NewPatientPage';


const NewPatientPage4 = ({ formData, handleChange, handleSubmit, handleGoBack, showFooter = true  }) => {


    const memoizedMSECheckboxComponent = useMemo(() => (
        <div>
            {MSE_OPTIONS.map((examChoice, index) => (
                <div key={index}>
                    <MSECheckboxComponent
                        options={examChoice.examOptions}
                        selectedOptions={formData || []}
                        handleChange={handleChange}
                        label={examChoice.label}
                    />
                    <TextAreaInput
                        id={`input_${examChoice.name}`}
                        name={`input_${examChoice.name}`}
                        value={formData[`input_${examChoice.name}`]}
                        onChange={handleChange}
                        placeholder={'Describe:'}
                    />
                </div>
            ))}
        </div>
    ), [formData,  handleChange]);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                {memoizedMSECheckboxComponent}
                <div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                    {showFooter && (<div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center'>
                        <button 
                            className='btn btn-primary px-5 btnSize rounded-3 mx-4'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button 
                            className='btn btn-primary px-5 btnSize rounded-3 mx-4'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>)}
                </div> 
            </form>
        </div>
    );
};

export default NewPatientPage4;
