import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const   ScheduleAppointmentModal = ( { scheduleAppointmenInfo , createOrUpdateAppoitment , handleChange  }  ) => {

        const { selectedScheduleAppointmentData, setIsScheduleAppointmentModalOpen, updateAppointment, patientId,clinicianId, currentAppointment } = scheduleAppointmenInfo;
        const currentDateTime = new Date();



        const [errors, setErrors] = useState({});

  




  return (
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
          <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setIsScheduleAppointmentModalOpen(false)}>
              </button>
            </div>
            <div className="modal-body pt-0 pb-5 px-4">
              <h2 className='mb-3'>Schedule appointment</h2>
              <form onSubmit={createOrUpdateAppoitment}>
                <input type="hidden" name="patientId" value={patientId} />
                <input type="hidden" name="clinicianId" value={clinicianId} />
                <input type="hidden" name="appointmentId" value={currentAppointment?.appointmentId || 0} />
                <input type='hidden' name='uploaded_datetime' value={currentAppointment?.appointmentId ? currentAppointment?.uploaded_datetime : currentDateTime.toISOString() }  />
                <input type="hidden" name="typeOfRequest" value={currentAppointment?.appointmentId ? "UPDATE" : 'INSERT'} />
                
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
                  <div className="mb-3">
                    <label className="form-label">Appointment title : </label>
                   
                    <input 
                    type="text" 
                    className="form-control" 
                    id="exampleFormControlInput1" 
                    placeholder="Lori Briggs 1x1"
                    name="appointmentTitle"
                    value={currentAppointment?.appointmentTitle}
                    onChange={handleChange}
                    pattern="^[a-zA-Z0-9\s]*$"
                    required
                    />


                  </div>
               
                  <div className="mb-3">
                    <label className="form-label">Participant(s) : </label>
                    <div className="input-group custominp w-100">
                  
                      <input type="text" 
                      className="form-control ps-2 border-start-2 rounded-end-2" 
                      placeholder=""
                      name="participants"
                      value={currentAppointment?.participants}
                      onChange={handleChange}
                      pattern="^[a-zA-Z0-9\s]*$"
                      required
                      />

                    </div>
                  </div>
            
                  <div className='row mb-3'>
                    <div className='col-md-6'>
                      <label htmlFor="exampleFormControlInput1" className="form-label">Date :</label>
                      <input 
                      type="date" 
                      className="form-control" 
                      id="exampleFormControlInput1"
                      name="dateValue"
                      value={currentAppointment?.dateValue}
                      onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor="timeValue" className="form-label">Time :</label>
                      <input 
                      type="time" 
                      className="form-control" 
                      id="timeValue"
                      name="timeValue"
                      value={currentAppointment?.timeValue}
                      onChange={handleChange}
                      />
               

                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Duration : </label>
                    <div className='d-flex flex-wrap gap-3'>
                      <div className="form-check">
                        {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"  /> */}

                        <input className="form-check-input" 
                        type="radio" 
                        id="flexRadioDefault1" 
                        name="duration"
                        value="15 min"
                        checked={currentAppointment?.duration === '15 min'}
                        onChange={handleChange} 
                        />

                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                          15min
                        </label>
                      </div>
                      <div className="form-check">
                        
                      <input className="form-check-input" 
                        type="radio" 
                        id="flexRadioDefault1" 
                        name="duration"
                        value="30 min"
                        checked={currentAppointment?.duration === '30 min'}
                        onChange={handleChange} 
                        />

                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                          30min
                        </label>
                      </div>
                      <div className="form-check">
                      <input className="form-check-input" 
                        type="radio" 
                        id="flexRadioDefault1" 
                        name="duration"
                        value="45 min"
                        checked={currentAppointment?.duration === '45 min'}
                        onChange={handleChange} 
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                          45min
                        </label>
                      </div>
                      <div className="form-check">
                      <input className="form-check-input" 
                        type="radio" 
                        id="flexRadioDefault1" 
                        name="duration"
                        value="60 min"
                        checked={currentAppointment?.duration === '60 min'}
                        onChange={handleChange} 
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                          60min
                        </label>
                      </div>
                    </div>
                  </div>
               
                    <div className='row mb-3'>
                    <div className='col-md-6'>
                      <label htmlFor="exampleFormControlInput1" className="form-label">Notes :</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        id="exampleFormControlInput1"
                        name="appointmentDescription"
                        value={currentAppointment?.appointmentDescription}
                        onChange={handleChange}
                        />
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor="timeValue" className="form-label">Status :</label>
                      <select className="form-select" 
                      aria-label="Default select example" 
                      defaultValue="07:00PM"
                      name="Status"
                      value={currentAppointment?.Status}
                      onChange={handleChange}
                      >
                        <option selected value="">Select Status</option>
                        <option value="Inprogress">In Progress</option>
                        <option value="Accepted">Accepted</option>
                        <option value="Rejected">Rejected</option>
                      </select>
                    </div>
                  </div>

            

                  <div className="mb-3">
                    <div className="form-check">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            id="sendEmailCheckbox" 
                            name="sendEmail"
                            checked={currentAppointment?.sendEmail === 'on' ? true : false} 
                            onChange={handleChange} 
                        />

                        <label className="form-check-label" htmlFor="sendEmailCheckbox">
                            Notify me via different from main account email 
                            <Link className='ms-2 text-decoration-none'>Enter email address</Link>
                        </label>
                    </div>
                  </div>

                  <div className='gap-4 d-flex justify-content-center'>
                    <button type="button" className="btn btn-outline-secondary px-5 btnSize rounded-3" onClick={() => setIsScheduleAppointmentModalOpen(false)}>Cancel</button>
                    <button type="submit" className="btn btn-primary px-5 btnSize rounded-3" 
                 //   onClick={() => currentAppointment ? handleSaveAppointment('UPDATE') : handleSaveAppointment('INSERT')}
                    >Send an invite</button>
                  </div>
                </div>
             
              </div>
              </form>
            </div>
          </div> 
        </div>
  );
};

export default ScheduleAppointmentModal;
