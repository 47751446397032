import React from 'react';
import { Dropdown, TextInput, RadioButtons, CheckboxInput } from '../../../../components/FormComponents/FormComponents';
import { HEIGHTS , CURRENT_SLEEP } from '../../../../const/PatientIntakeConst';
import { MedicationTable } from '../Components/NewPatientPage';



const NewPatientPage7 = ({ formData, setFormData, handleChange, handleSubmit  , handleGoBack, showFooter = true}) => {

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="form-group row">
                    <div className="col-6">
                        <TextInput
                            id="pcpName"
                            name="pcpName"
                            value={formData.pcpName}
                            onChange={handleChange}
                            label="PCP Name:"
                            required
                        />
                        <div className='intake-dropdown'>
                            <Dropdown
                                label="Height:"
                                name="patientHeight"
                                value={formData.patientHeight}
                                options={HEIGHTS}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <label htmlFor="pcpPhoneNumber">
                            PCP Phone Number:
                        </label>
                        <input 
                            type="number"
                            id="pcpPhoneNumber"
                            name="pcpPhoneNumber"
                            value={formData.pcpPhoneNumber}
                            onChange={handleChange}
                            className="form-control"
                            required 
                        />
                        <label htmlFor="patientWeight">
                            Weight
                        </label>
                        <input 
                            type="number"
                            id="patientWeight"
                            name="patientWeight"
                            value={formData.patientWeight}
                            onChange={handleChange}
                            className="form-control"
                            required 
                        />                
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Physical Health Diagnoses/Concerns
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='physicalHealthDiagnoses' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.physicalHealthDiagnoses === 'Yes' && (
                        <div>
                            <span>If yes, please explain:</span>
                            <TextInput
                                id="physicalHealthDiagnosesDescription"
                                name="physicalHealthDiagnosesDescription"
                                value={formData.physicalHealthDiagnosesDescription}
                                onChange={handleChange}
                                placeholder='Describe:'
                                required
                            />
                        </div>
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Current Medication for Physical Health Diagnoses/Concerns
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='currentMedicationsYesOrNo' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.currentMedicationsYesOrNo === 'Yes' && (
                        <MedicationTable formData={formData} setFormData={setFormData} handleChange={handleChange} />
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Current Dietary Requirements
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='dietaryRequirements' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.dietaryRequirements === 'Yes' && (
                        <div>
                            <span>If yes, please explain:</span>
                            <TextInput
                                id="dietaryRequirementsDescription"
                                name="dietaryRequirementsDescription"
                                value={formData.dietaryRequirementsDescription}
                                onChange={handleChange}
                                placeholder='Describe:'
                                required
                            />
                        </div>
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            History of Surgery
                        </label>
                        <div className="d-flex align-items-center">                                     
                            <RadioButtons name='surgicalHistory' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.surgicalHistory === 'Yes' && (
                        <div>
                            <span>If yes, please explain:</span>
                            <TextInput
                                id="surgicalHistoryDescription"
                                name="surgicalHistoryDescription"
                                value={formData.surgicalHistoryDescription}
                                onChange={handleChange}
                                placeholder='Describe:'
                                required
                            />
                        </div>
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Allergies
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='patientAllergies' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.patientAllergies === 'Yes' && (
                        <div>
                            <span>If yes, please explain:</span>
                            <TextInput
                                id="patientAllergiesDescription"
                                name="patientAllergiesDescription"
                                value={formData.patientAllergiesDescription}
                                onChange={handleChange}
                                placeholder='Describe:'
                                required
                            />
                        </div>
                    )}

                    <div>
                        <label>Current Sleep:</label>
                        <div className='d-flex align-items-center'>
                        {CURRENT_SLEEP.slice(0, 3).map((sleepStatus, index) => {
                            return (
                                <div key={index} className='pe-3'>
                                    <CheckboxInput
                                        id={sleepStatus.name}
                                        name={sleepStatus.name}
                                        label={sleepStatus.label}
                                        checked={formData[`${sleepStatus.name}`]}
                                        onChange={handleChange}
                                    />
                                </div>
                            )
                        })}
                        </div>
                        <div className='d-flex align-items-center'>
                        {CURRENT_SLEEP.slice(3).map((sleepStatus, index) => {
                            return (
                                <div key={index} className='pe-3'>
                                    <CheckboxInput
                                      id={sleepStatus.name}
                                      name={sleepStatus.name}
                                      label={sleepStatus.label}
                                       checked={formData[`${sleepStatus.name}`]}
                                      onChange={handleChange}
                                    />
                                </div>
                            )
                        })}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="currentExercise">
                            Current Exercise:
                        </label>
                        <div className='d-flex align-items-center'>
                            <input
                                type='radio'
                                id='noExercise'                                        
                                name='currentExercise'
                                value='None'
                                onChange={handleChange}
                                checked={formData.currentExercise === 'None'}
                            />
                            <span className="mx-2">None</span>
                            <input
                                type='radio'
                                id='oneToThreeTimesPerMonth'                                        
                                name='currentExercise'
                                value='oneToThreeTimesPerMonth'
                                onChange={handleChange}
                                checked={formData.currentExercise === 'oneToThreeTimesPerMonth'}
                            />
                            <span className="mx-2">Exercise 1-3x/Month</span>
                            <input
                                type='radio'
                                id='oneToThreeTimesPerWeek'                                        
                                name='currentExercise'
                                value='oneToThreeTimesPerWeek'
                                onChange={handleChange}
                                checked={formData.currentExercise === 'oneToThreeTimesPerWeek'}
                            />
                            <span className="mx-2">Exercise 1-3x/Week</span>
                            <input
                                type='radio'
                                id='daily'                                        
                                name='currentExercise'
                                value='daily'
                                onChange={handleChange}
                                checked={formData.currentExercise === 'daily'}
                            />
                            <span className="mx-2">Exercise Daily</span>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="currentAppetite">
                            Current Appetite:
                        </label>
                        <div className='d-flex align-items-center'>
                            <input
                                type='radio'
                                id='appetiteGood'                                        
                                name='currentAppetite'
                                value='Good'
                                onChange={handleChange}
                                checked={formData.currentAppetite === 'Good'}
                            />
                            <span className="mx-2">Good</span>
                            <input
                                type='radio'
                                id='appetiteFair'                                        
                                name='currentAppetite'
                                value='Fair'
                                onChange={handleChange}
                                checked={formData.currentAppetite === 'Fair'}
                            />
                            <span className="mx-2">Fair</span>
                            <input
                                type='radio'
                                id='appetitePoor'                                        
                                name='currentAppetite'
                                value='Poor'
                                onChange={handleChange}
                                checked={formData.currentAppetite === 'Poor'}
                            />
                            <span className="mx-2">Poor</span>
                        </div>
                    </div>  

                    <label>Does the patient experience the following:</label>
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            Chest Pain (if yes, please explain)
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='patientComplaintOfChestPain' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.patientComplaintOfChestPain === 'Yes' && (
                        <TextInput
                            id="patientComplaintOfChestPainDescription"
                            name="patientComplaintOfChestPainDescription"
                            value={formData.patientComplaintOfChestPainDescription}
                            onChange={handleChange}
                            placeholder='Describe:'
                            required
                        />
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            Rapid Heart Beat/Palpitations (If yes, please explain):
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='patientComplaintOfPalpitations' formData={formData}handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.patientComplaintOfPalpitations === 'Yes' && (
                        <TextInput
                            id="patientComplaintOfPalpitationsDescription"
                            name="patientComplaintOfPalpitationsDescription"
                            value={formData.patientComplaintOfPalpitationsDescription}
                            onChange={handleChange}
                            placeholder='Describe:'
                            required
                        />
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            GI Issues /Stomach Pain (If yes, please explain):
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='patientComplaintOfGIIssues' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.patientComplaintOfGIIssues === 'Yes' && (
                        <TextInput
                            id="patientComplaintOfGIIssuesDescription"
                            name="patientComplaintOfGIIssuesDescription"
                            value={formData.patientComplaintOfGIIssuesDescription}
                            onChange={handleChange}
                            placeholder='Describe:'
                            required
                        />
                    )}                
                </div>
                <div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                    {showFooter && (<div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center'>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3 mx-4'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>)}
                </div>
            </form>
        </div>
    );
};

export default NewPatientPage7;