import "./Header.css";
import NotificationsModal from "./NotificationsModal";
import avatar from "../../assets/images/avatar.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Image } from "react-bootstrap";

function Header({ pageTitle }) {
  const navigate = useNavigate();
  const location = useLocation();
  const name = localStorage.getItem("name");

  const handleGoBack = () => {
    const pathname = window.location.pathname;
    const pathParts = pathname.split("/");
    const patId = pathParts[pathParts.length - 2];
    pageTitle === "Discharge"
      ? navigate("/profile/" + patId)
      : window.history.back();
  };

  return (
    <>
      <header className="App-header">
        <div className="headerSec p-4">
          <Row className="row align-items-center">
            <Col>
              {pageTitle !== "Patients" &&
              pageTitle !== "Messages" &&
              pageTitle !== "Requests" &&
              pageTitle !== "Assessments" &&
              pageTitle !== "Documents" &&
              pageTitle !== "DBT tracker" &&
              pageTitle !== "Settings" &&
              pageTitle !== "adminBilling" &&
              pageTitle !== "Billing" &&
              pageTitle !== "My dashboard" &&
              pageTitle !== "Schedule" &&
              pageTitle !== "User Management" &&
              pageTitle !== "My notifications" ? (
                <button
                  type="button"
                  className="btn btn-link px-0 text-decoration-none profile-text"
                  onClick={handleGoBack}
                >
                  <i className="fas fa-long-arrow-left me-1"></i> Back
                </button>
              ) : (
                <h1 className="mb-0 header-text">{pageTitle}</h1>
              )}
              {location.pathname === "/patients" && (
                <div className="d-flex align-items-center">
                  <i className="fas fa-plus-circle me-1 text-green"></i>
                  <label className="me-2 ">
                    <Link
                      to={`/newPatient/page1`}
                      className="text-decoration-none"
                    >
                      Intake patient
                    </Link>
                  </label>
                </div>
              )}
            </Col>
            <div className="col-auto">
              <div className="d-flex gap-4 align-items-center">
                <div
                  className="input-group custominp"
                  style={pageTitle ? { display: "none" } : <></>}
                >
                  <span
                    className="input-group-text border-end-0"
                    id="basic-addon1"
                  >
                    <i className="far fa-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control ps-0 border-start-0"
                    placeholder="Search..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div>
                  <NotificationsModal />
                </div>
                <div className="d-flex align-items-center profileMenu col-auto">
                  <div className="flex-shrink-0">
                    <Image
                      src={avatar}
                      style={{ height: "50px", width: "50px" }}
                      alt="user_img"
                    />
                  </div>
                  <div className="flex-grow-1 ms-2 profile-name text-capitalize">
                    {name}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </header>
    </>
  );
}

export default Header;
