import React, { useEffect, useState } from 'react';
import { CheckboxInput, TextInput, RadioButtons } from '../../../../components/FormComponents/FormComponents';
import {  SUBSTANCE_USE_CHECKBOX, DISORDERED_EATING_QUESTIONS } from '../../../../const/PatientIntakeConst';
import { DisorderedEatingRadioQuestions , SubstanceCheckboxComponent , SubstanceUseTable , TraumaTable } from '../Components/NewPatientPage6Tables';




const NewPatientPage6 = ({ formData, setFormData, handleChange, handleSubmit , handleGoBack, showFooter = true }) => {
    const [showNewSelfInjuryMethod, setShowNewSelfInjuryMethod] = useState(false);
    const [numSelfInjuryMethods, setNumSelfInjuryMethods] = useState(0);
    const [addedSelfInjuryMethodFormsIndices, setAddedSelfInjuryMethodFormsIndices] = useState([]);

    const [showNewSuicideAttemptMethod, setShowNewSuicideAttemptMethod] = useState(false);
    const [numSuicideAttemptMethods, setNumSuicideAttemptMethods] = useState(0);
    const [addedSuicideAttemptMethodFormsIndices, setAddedSuicideAttemptMethodFormsIndices] = useState([]);

    const [showNewOtherInjury, setShowNewOtherInjury] = useState(false);
    const [numOtherInjuries, setNumOtherInjuries] = useState(0);
    const [addedOtherInjuryFormsIndices, setAddedOtherInjuryFormsIndices] = useState([]);

    useEffect(() => {


        const selfInjuryMethodIndices = Object.keys(formData)
        .filter(key => key.startsWith('selfInjuryMethod[') && !key.endsWith('[0]'))
        .map(key => parseInt(key.match(/\d+/)[0]));
      
        if (selfInjuryMethodIndices.length > 0) {
            setShowNewSelfInjuryMethod(true);
            setNumSelfInjuryMethods(selfInjuryMethodIndices.length);
            setAddedSelfInjuryMethodFormsIndices(selfInjuryMethodIndices);
      }


      const suicideAttemptMethodIndices = Object.keys(formData)
      .filter(key => key.startsWith('suicideAttemptMethod[') && !key.endsWith('[0]'))
      .map(key => parseInt(key.match(/\d+/)[0]));
    
      if (suicideAttemptMethodIndices.length > 0) {
        setShowNewOtherInjury(true);
        setNumOtherInjuries(suicideAttemptMethodIndices.length);
        setAddedSuicideAttemptMethodFormsIndices(suicideAttemptMethodIndices);
      }



      const otherInjuryMethodIndices = Object.keys(formData)
      .filter(key => key.startsWith('otherInjuryMethod[') && !key.endsWith('[0]'))
      .map(key => parseInt(key.match(/\d+/)[0]));
    
      if (otherInjuryMethodIndices.length > 0) {
        setShowNewSuicideAttemptMethod(true);
        setNumSuicideAttemptMethods(otherInjuryMethodIndices.length);
        setAddedOtherInjuryFormsIndices(otherInjuryMethodIndices);
      }
    
    }, [formData]);


    //self injury management section
    const handleAddSelfInjury = () => {
        const newIndex = numSelfInjuryMethods + 1;
        setNumSelfInjuryMethods(prevNum => prevNum + 1);
        setFormData(prevData => ({
            ...prevData,
            [`selfInjuryMethod[${newIndex}]`]: '',
            [`estimationOfLastSelfInjury[${newIndex}]`]: '',
            [`estimationOfDurationOfSIB[${newIndex}]`]: '',
        }));
        setAddedSelfInjuryMethodFormsIndices(prevIndices => [...prevIndices, newIndex]);
        setShowNewSelfInjuryMethod(true);
    }

    const handleRemoveSelfInjury = () => {
        const lastAddedIndex = addedSelfInjuryMethodFormsIndices.pop();
        setFormData(prevData => {
            const updatedFormData = { ...prevData };
            delete updatedFormData[`selfInjuryMethod[${lastAddedIndex}]`];
            delete updatedFormData[`estimationOfLastSelfInjury[${lastAddedIndex}]`];
            delete updatedFormData[`estimationOfDurationOfSIB[${lastAddedIndex}]`];
            return updatedFormData;
        });
        setNumSelfInjuryMethods(prevNum => Math.max(0, prevNum - 1));
    
        if (addedSelfInjuryMethodFormsIndices.length === 0) {
            setShowNewSelfInjuryMethod(false);
        }
    };

    const handleHistoryOfSelfInjuryChange = (e) => {
        // If the user selects "No" for historyOfSelfInjury, reset related form data and hide forms
        const { value } = e.target;
        if (value === "No") {
            setFormData(prevData => {
                const updatedFormData = { ...prevData };
                // Filter out the keys related to self-injury data
                Object.keys(updatedFormData).forEach(key => {
                if (key.includes('selfInjuryMethod') || 
                    key.includes('estimationOfLastSelfInjury') || 
                    key.includes('estimationOfDurationOfSIB')) {
                    delete updatedFormData[key];
                }
                return updatedFormData;
            });
            setNumSelfInjuryMethods(0)
            setShowNewSelfInjuryMethod(false);
            })
        }
        handleChange(e)
    };

    //suicide attempt management section
    const handleAddSuicideAttempt = () => {
        const newIndex = numSuicideAttemptMethods + 1;
        setNumSuicideAttemptMethods(prevNum => prevNum + 1);
        setFormData(prevData => ({
            ...prevData,
            [`suicideAttemptMethod[${newIndex}]`]: '',
            [`estimationOfSuicideAttempt[${newIndex}]`]: '',
            [`suicideAttemptHospitalization[${newIndex}]`]: '',
        }));
        setAddedSuicideAttemptMethodFormsIndices(prevIndices => [...prevIndices, newIndex]);
        setShowNewSuicideAttemptMethod(true);
    }

    const handleRemoveSuicideAttempt= () => {
        const lastAddedIndex = addedSuicideAttemptMethodFormsIndices.pop();
        setFormData(prevData => {
            const updatedFormData = { ...prevData };
            delete updatedFormData[`suicideAttemptMethod[${lastAddedIndex}]`];
            delete updatedFormData[`estimationOfSuicideAttempt[${lastAddedIndex}]`];
            delete updatedFormData[`suicideAttemptHospitalization[${lastAddedIndex}]`];
            return updatedFormData;
        });
        setNumSuicideAttemptMethods(prevNum => Math.max(0, prevNum - 1));
    
        if (addedSuicideAttemptMethodFormsIndices.length === 0) {
            setShowNewSuicideAttemptMethod(false);
        }
    };

    const handleHistoryOfSuicideAttemptChange = (e) => {
        // If the user selects "No" for historyOfSuicideAttempt, reset related form data and hide forms
        //Reset is necessary if user clicks 'yes', fills out form data, then later changes it to 'no' without erasing the form
        const { value } = e.target;
        if (value === "No") {
            setFormData(prevData => {
                const updatedFormData = { ...prevData };
                // Filter out the keys related to self-injury data
                Object.keys(updatedFormData).forEach(key => {
                if (key.includes('suicideAttemptMethod') || 
                    key.includes('estimationOfSuicideAttempt') || 
                    key.includes('suicideAttemptHospitalization')) {
                    delete updatedFormData[key];
                }
                return updatedFormData;
            });
            setNumSuicideAttemptMethods(0)
            setShowNewSuicideAttemptMethod(false);
            })
        }
        handleChange(e)
    };

    //other injury management section
    const handleAddOtherInjury = () => {
        const newIndex = numOtherInjuries + 1;
        setNumOtherInjuries(prevNum => prevNum + 1);
        setFormData(prevData => ({
            ...prevData,
            [`otherInjuryMethod[${newIndex}]`]: '',
            [`estimationOfOtherInjury[${newIndex}]`]: '',
            [`otherInjuryHospitalization[${newIndex}]`]: '',
        }));
        setAddedOtherInjuryFormsIndices(prevIndices => [...prevIndices, newIndex]);
        setShowNewOtherInjury(true);
    }

    const handleRemoveOtherInjury= () => {
        const lastAddedIndex = addedOtherInjuryFormsIndices.pop();
        setFormData(prevData => {
            const updatedFormData = { ...prevData };
            delete updatedFormData[`otherInjuryMethod[${lastAddedIndex}]`];
            delete updatedFormData[`estimationOfOtherInjury[${lastAddedIndex}]`];
            delete updatedFormData[`otherInjuryHospitalization[${lastAddedIndex}]`];
            return updatedFormData;
        });
        setNumOtherInjuries(prevNum => Math.max(0, prevNum - 1));
    
        if (addedOtherInjuryFormsIndices.length === 0) {
            setShowNewOtherInjury(false);
        }
    };

    const handleHistoryOfOtherInjuryChange = (e) => {
        // If the user selects "No" for historyOfOtherInjury, reset related form data and hide forms
        //Reset is necessary if user clicks 'yes', fills out form data, then later changes it to 'no' without erasing the form
        const { value } = e.target;
        if (value === "No") {
            setFormData(prevData => {
                const updatedFormData = { ...prevData };
                // Filter out the keys related to self-injury data
                Object.keys(updatedFormData).forEach(key => {
                if (key.includes('otherInjuryMethod') || 
                    key.includes('estimationOfOtherInjury') || 
                    key.includes('otherInjuryHospitalization')) {
                    delete updatedFormData[key];
                }
                return updatedFormData;
            });
            setNumOtherInjuries(0)
            setShowNewOtherInjury(false);
            })
        }
        handleChange(e)
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <TextInput
                        id="previousDiagnoses"
                        name="previousDiagnoses"
                        value={formData.previousDiagnoses}
                        onChange={handleChange}
                        label="Previous Diagnoses:"             
                    />
                    <TextInput
                        id="copingMechanisms"
                        name="copingMechanisms"
                        value={formData.copingMechanisms}
                        onChange={handleChange}
                        label="Coping Mechanisms:"          
                    />

                    {/* self injury section */}
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            History of Self Injury (if yes, elaborate below)
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='historyOfSelfInjury' formData={formData} handleChange={handleHistoryOfSelfInjuryChange} />
                        </div>
                    </div>
                    {formData.historyOfSelfInjury === 'Yes' && (
                    <div className="form-group row">
                        <div className="col-4">
                            <TextInput
                                id="selfInjuryMethod"
                                name="selfInjuryMethod[0]"
                                value={formData[`selfInjuryMethod[0]`]}
                                onChange={handleChange}
                                label="Method"
                            />
                        </div>
                        <div className='col-4'>
                            <TextInput
                                id="estimationOfLastSelfInjury[0]"
                                name="estimationOfLastSelfInjury[0]"
                                value={formData[`estimationOfLastSelfInjury[0]`]}
                                onChange={handleChange}
                                label="Estimation of Last Self Injury"
                            />
                        </div>
                        <div className='col-4'>
                            <TextInput
                                id="estimationOfDurationOfSIB[0]"
                                name="estimationOfDurationOfSIB[0]"
                                value={formData[`estimationOfDurationOfSIB[0]`]}
                                onChange={handleChange}
                                label="Estimation of Duration of SIB"
                            />
                        </div>
                    </div>
                    )}
                    {showNewSelfInjuryMethod && (
                    [...Array(numSelfInjuryMethods)].map((_, index) => (
                        <div key={`selfInjuryMethod${index}`} className='row form-group'>
                            <div className='col-4'>
                                <TextInput
                                    id={`selfInjuryMethod[${index + 1}]`}
                                    name={`selfInjuryMethod[${index + 1}]`}
                                    value={formData[`selfInjuryMethod[${index + 1}]`]}
                                    onChange={handleChange}
                                    label='Method'
                                />
                            </div>
                            <div className='col-4'>
                                <TextInput
                                    id={`estimationOfLastSelfInjury${index + 1}`}
                                    name={`estimationOfLastSelfInjury[${index + 1}]`}
                                    value={formData[`estimationOfLastSelfInjury[${index + 1}]`]}
                                    onChange={handleChange}
                                    label="Estimation of Last Self Injury"
                                />
                            </div>
                            <div className='col-4'>
                                <TextInput
                                    id={`estimationOfDurationOfSIB[${index + 1}]`}
                                    name={`estimationOfDurationOfSIB[${index + 1}]`}
                                    value={formData[`estimationOfDurationOfSIB[${index + 1}]`]}
                                    onChange={handleChange}
                                    label="Estimation of Duration of SIB"
                                />
                            </div>
                        </div>
                    ))
                    )}
                    {formData.historyOfSelfInjury === 'Yes' && showFooter && (
                    <div className='row d-flex justify-content-between'>
                        <div>
                            <button onClick={handleAddSelfInjury} type="button" className='text-button'>
                                + Add New Method
                            </button>
                            </div>
                        {showNewSelfInjuryMethod && (
                            <div>
                                <button onClick={handleRemoveSelfInjury} type="button" className='text-button' style={{color: '#FF6760'}}>
                                    - Remove Method
                                </button>
                            </div>
                            )}
                    </div>
                    )}

                    {/* suicide attempt section */}
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            History of Suicide Attempt (if yes, elaborate below)
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='historyOfSuicideAttempt' formData={formData} handleChange={handleHistoryOfSuicideAttemptChange} />
                        </div>
                    </div>
                    {formData.historyOfSuicideAttempt === 'Yes' && (
                    <div className="form-group row">
                        <div className="col-4">
                            <TextInput
                                id="suicideAttemptMethod[0]"
                                name="suicideAttemptMethod[0]"
                                value={formData[`suicideAttemptMethod[0]`]}
                                onChange={handleChange}
                                label="Method"
                            />
                        </div>
                        <div className='col-4'>
                            <TextInput
                                id="estimationOfSuicideAttempt[0]"
                                name="estimationOfSuicideAttempt[0]"
                                value={formData[`estimationOfSuicideAttemp[0]`]}
                                onChange={handleChange}
                                label="Estimation of Date"
                            />
                        </div>
                        <div className='col-4'>
                            <label>
                                Was patient hospitalized?
                            </label>
                            <div className="d-flex align-items-center">
                                <RadioButtons name='suicideAttemptHospitalization[0]' formData={formData} handleChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    )}
                    {showNewSuicideAttemptMethod && (
                    [...Array(numSuicideAttemptMethods)].map((_, index) => (
                        <div key={`suicideAttemptMethod${index}`} className='row form-group'>
                            <div className='col-4'>
                                <TextInput
                                    id={`suicideAttemptMethod[${index + 1}]`}
                                    name={`suicideAttemptMethod[${index + 1}]`}
                                    value={formData[`suicideAttemptMethod[${index + 1}]`]}
                                    onChange={handleChange}
                                    label='Method'
                                />
                            </div>
                            <div className='col-4'>
                                <TextInput
                                    id={`estimationOfSuicideAttempt[${index + 1}]`}
                                    name={`estimationOfSuicideAttempt[${index + 1}]`}
                                    value={formData[`estimationOfSuicideAttempt[${index + 1}]`]}
                                    onChange={handleChange}
                                    label="Estimation of Date"
                                />
                            </div>
                            <div className='col-4'>
                                <label>
                                    Was patient hospitalized?
                                </label>
                                <div className="d-flex align-items-center">
                                    <RadioButtons name={`suicideAttemptHospitalization[${index + 1}]`} formData={formData} handleChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    ))
                    )}
                    {formData.historyOfSuicideAttempt === 'Yes' && showFooter &&  (
                    <div className='row d-flex justify-content-between'>
                        <div>
                            <button onClick={handleAddSuicideAttempt} type="button" className='text-button'>
                                + Add New Method
                            </button>
                            </div>
                        {showNewSuicideAttemptMethod && (
                            <div>
                                <button onClick={handleRemoveSuicideAttempt} type="button" className='text-button' style={{color: '#FF6760'}}>
                                    - Remove Method
                                </button>
                            </div>
                            )}
                    </div>
                    )}

                    {/* history of others inflicting injury section */}
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            History of Others (if yes, elaborate below)
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='historyOfOtherInjury' formData={formData} handleChange={handleHistoryOfOtherInjuryChange} />
                        </div>
                    </div>
                    {formData.historyOfOtherInjury === 'Yes' && (
                    <div className="form-group row">
                        <div className="col-4">
                            <TextInput
                                id="otherInjuryMethod"
                                name="otherInjuryMethod[0]"
                                value={formData[`otherInjuryMethod[0]`]}
                                onChange={handleChange}
                                label="Method"
                            />
                        </div>
                        <div className='col-4'>
                            <TextInput
                                id="estimationOfOtherInjury"
                                name="estimationOfOtherInjury"
                                value={formData.estimationOfOtherInjury}
                                onChange={handleChange}
                                label="Estimation of Date"
                            />
                        </div>
                        <div className='col-4'>
                            <label>
                                Was patient hospitalized?
                            </label>
                            <div className="d-flex align-items-center">
                                <RadioButtons name='otherInjuryHospitalization' formData={formData} handleChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    )}
                    {showNewOtherInjury && (
                    [...Array(numOtherInjuries)].map((_, index) => (
                        <div key={`otherInjuryMethod${index}`} className='row form-group'>
                            <div className='col-4'>
                                <TextInput
                                    id={`otherInjuryMethod[${index + 1}]`}
                                    name={`otherInjuryMethod[${index + 1}]`}
                                    value={formData[`otherInjuryMethod[${index + 1}]`]}
                                    onChange={handleChange}
                                    label='Method'
                                />
                            </div>
                            <div className='col-4'>
                                <TextInput
                                    id={`estimationOfOtherInjury[${index + 1}]`}
                                    name={`estimationOfOtherInjury[${index + 1}]`}
                                    value={formData[`estimationOfOtherInjury[${index + 1}]`]}
                                    onChange={handleChange}
                                    label="Estimation of Date"
                                />
                            </div>
                            <div className='col-4'>
                                <label>
                                    Was patient hospitalized?
                                </label>
                                <div className="d-flex align-items-center">
                                    <RadioButtons name={`otherInjuryHospitalization[${index + 1}]`} formData={formData} handleChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    ))
                    )}
                    {formData.historyOfOtherInjury === 'Yes' && showFooter &&  (
                    <div className='row d-flex justify-content-between'>
                        <div>
                            <button onClick={handleAddOtherInjury} type="button" className='text-button'>
                                + Add New Method
                            </button>
                            </div>
                        {showNewOtherInjury && (
                            <div>
                                <button onClick={handleRemoveOtherInjury} type="button" className='text-button' style={{color: '#FF6760'}}>
                                    - Remove Method
                                </button>
                            </div>
                            )}
                    </div>
                    )}

                    {/* substance use section */}
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            Substance Use (past or current) (if yes, elaborate below)
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='pastOrCurrentSubstanceUse' formData={formData} handleChange={handleChange} />
                        </div>
                    </div>

                    {formData.pastOrCurrentSubstanceUse === 'Yes' && (
                        <div>
                            <SubstanceUseTable formData={formData} handleChange={handleChange} />
                            <SubstanceCheckboxComponent 
                                formData={formData}
                                handleChange={handleChange}
                                options={SUBSTANCE_USE_CHECKBOX[0]} 
                            />
                            <SubstanceCheckboxComponent 
                                formData={formData}
                                handleChange={handleChange}
                                options={SUBSTANCE_USE_CHECKBOX[2]} 
                            />
                        </div>
                    )}
                    <SubstanceCheckboxComponent 
                        formData={formData}
                        handleChange={handleChange}
                        options={SUBSTANCE_USE_CHECKBOX[1]} 
                    />
                    
                    {/* detox history section */}
                    <h3 className='mt-3'>Detoxification History</h3>
                    <div className="d-flex justify-content-between">
                        <div className='w-75'>
                            <TextInput
                                id='substancesDetoxedFrom'
                                name='substancesDetoxedFrom'
                                value={formData.substancesDetoxedFrom}
                                onChange={handleChange}
                                placeholder={'Substance(s):'}
                            />
                        </div>
                        <div className='align-self-end text-overflow-none'>
                            <CheckboxInput
                                id='patientNeverDetoxed'
                                name='patientNeverDetoxed'
                                label='Never detoxed'
                                checked={formData.patientNeverDetoxed}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {formData.patientNeverDetoxed !== true && (
                        <SubstanceCheckboxComponent 
                            formData={formData}
                            handleChange={handleChange}
                            options={SUBSTANCE_USE_CHECKBOX[3]} 
                        />
                    )}

                    {/* disordered eating section */}
                    <h3 className='mt-3'>Disordered Eating</h3>
                    <DisorderedEatingRadioQuestions 
                        formData={formData}
                        handleChange={handleChange} 
                        question={DISORDERED_EATING_QUESTIONS[0]} 
                    />
                    <DisorderedEatingRadioQuestions
                        formData={formData}
                        handleChange={handleChange} 
                        question={DISORDERED_EATING_QUESTIONS[1]} 
                    />
                    <DisorderedEatingRadioQuestions
                        formData={formData}
                        handleChange={handleChange} 
                        question={DISORDERED_EATING_QUESTIONS[2]} 
                    />
                    <DisorderedEatingRadioQuestions 
                        formData={formData} 
                        handleChange={handleChange} 
                        question={DISORDERED_EATING_QUESTIONS[3]} 
                    />
                    
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Do you currently use weight-loss pills?
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='useOfWeightLossPills' formData={formData} handleChange={handleChange} />
                        </div>
                    </div>
                    {formData.useOfWeightLossPills === 'Yes' && (
                        <div>
                            <span>If yes, list type, quantity, and frequency</span>
                            <TextInput
                                id="useOfWeightLossPillsDescription"
                                name="useOfWeightLossPillsDescription"
                                value={formData.useOfWeightLossPillsDescription}
                                onChange={handleChange}
                                placeholder="Describe:"          
                            />
                        </div>
                    )}

                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Do you drink an excessive amount of water?
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='excessiveWaterDrinking' formData={formData} handleChange={handleChange} />
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Do you have any food rituals that concern you?
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='concerningFoodRituals' formData={formData} handleChange={handleChange} />
                        </div>
                    </div>
                    {formData.concerningFoodRituals === 'Yes' && (
                        <TextInput
                            id="concerningFoodRitualsDescription"
                            name="concerningFoodRitualsDescription"
                            value={formData.concerningFoodRitualsDescription}
                            onChange={handleChange}
                            placeholder="Describe:"          
                        />
                    )}

                    <div>
                        <label htmlFor="unhealthyRelationshipWithExercise">
                            Do you have an unhealthy relationship with exercise?
                        </label>
                        <div className=''>
                            <div className='d-flex align-items-center'>
                            <input
                                type='radio'
                                id='axiousOrIrritable'                                        
                                name='unhealthyRelationshipWithExercise'
                                value={'Yes, I get anxious or irritable if I can\'t engage in my exercise routine'}
                                onChange={handleChange}
                                checked={formData.unhealthyRelationshipWithExercise === 'Yes, I get anxious or irritable if I can\'t engage in my exercise routine'}
                            />
                            <span className="mx-2">Yes, I get anxious or irritable if I can't engage in my exercise routine</span>
                            </div>
                            
                            <div className='d-flex align-items-center'>
                                <input
                                    type='radio'
                                    id='overExercise'                                        
                                    name='unhealthyRelationshipWithExercise'
                                    value='Yes, I over-exercise'
                                    onChange={handleChange}
                                    checked={formData.unhealthyRelationshipWithExercise === 'Yes, I over-exercise'}
                                />
                                <span className="mx-2">Yes, I over-exercise</span>
                                <div className='w-50'>
                                    <TextInput
                                        id="unhealthyRelationshipWithExerciseOverExerciseDaysPerWeek"
                                        name="unhealthyRelationshipWithExerciseOverExerciseDaysPerWeek"
                                        value={formData.unhealthyRelationshipWithExerciseOverExerciseDaysPerWeek}
                                        onChange={handleChange}
                                        placeholder="How many hours/days per week:"          
                                    />
                                </div>

                            </div>

                            <div className='d-flex align-items-center'>
                                <input
                                    type='radio'
                                    id='exerciseAgainstHealthcareAdvice'                                        
                                    name='unhealthyRelationshipWithExercise'
                                    value='Yes, I exercise against the advice of a health care provider and/ or despite pain'
                                    onChange={handleChange}
                                    checked={formData.unhealthyRelationshipWithExercise === 'Yes, I exercise against the advice of a health care provider and/ or despite pain'}
                                />
                                <span className="mx-2">Yes, I exercise against the advice of a health care provider and/ or despite pain</span>
                            </div>

                            <div className='d-flex align-items-center'>
                                <input
                                    type='radio'
                                    id='underExercise'                                        
                                    name='unhealthyRelationshipWithExercise'
                                    value='Yes, I under-exercise'
                                    onChange={handleChange}
                                    checked={formData.unhealthyRelationshipWithExercise === 'Yes, I under-exercise'}
                                />
                                <span className="mx-2">Yes, I under-exercise</span>
                            </div>

                            <div className='d-flex align-items-center'>
                                <input
                                    type='radio'
                                    id='normalExercise'                                        
                                    name='unhealthyRelationshipWithExercise'
                                    value='No'
                                    onChange={handleChange}
                                    checked={formData.unhealthyRelationshipWithExercise === 'No'}
                                />
                                <span className="mx-2">No</span>
                            </div>
                        </div>
                    </div>  

                    {/* trauma history section */}
                    <h3 className='mt-3'>Trauma History</h3>
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Has patient experienced any physical, sexual, verbal, or emotional abuse?
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='historyOfAbuse' formData={formData} handleChange={handleChange} />
                        </div>
                    </div>
                    {formData.historyOfAbuse === 'Yes' && (
                        <TraumaTable formData={formData} setFormData={setFormData} handleChange={handleChange} />
                    )}

                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Does patient have current or past military experience?
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='historyOfMilitaryExperience' formData={formData} handleChange={handleChange} />
                        </div>
                    </div>
                    {formData.historyOfMilitaryExperience === 'Yes' && (
                        <TextInput
                            id="historyOfMilitaryExperienceDescription"
                            name="historyOfMilitaryExperienceDescription"
                            value={formData.historyOfMilitaryExperienceDescription}
                            onChange={handleChange}
                            placeholder="Describe:"          
                        />
                    )}
                </div>

                <div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                   {showFooter &&(<div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center'>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3 mx-4'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>)}
                </div>
            </form>
        </div>
    )
};

export default NewPatientPage6;