import { useState, useEffect } from "react";
import AssessmentResultModal from "../../../modals/AssessmentResultModal";
import Modal from "react-modal";
import { ProgressBar, Row, Col, Image } from "react-bootstrap";
import Header from "components/Header/Header";
import Layout from "components/layout/layout";
import axios from "axios";

const PatientAssessment = () => {
  const [isAssessmentResultModalOpen, setIsAssessmentResultModalOpen] =
    useState(false);
  const [selectedAssessmentResultData, setSelectedAssessmentResultData] =
    useState("");
  const [patientAssessments, setPatientAssessments] = useState([]);
  const token = localStorage.getItem("token");
  const patientId = localStorage.getItem("userDetails");
  const name = localStorage.getItem("name");

  const getAllAssessmentsList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_API_URL
        }/assessments/getAssessmentAssigned/${JSON.parse(patientId)?.id}`,
        {
          headers: headers,
        }
      );
      setPatientAssessments(response.data?.assessment);
    } catch (error) {
      console.error("Error fetching all assessments:", error);
      return [];
    }
  };
  useEffect(() => {
    getAllAssessmentsList();
  }, [token, patientId]);

  const getButtonDetails = (status) => {
    let className, buttonText;
    if (status === 0) {
      className = "Denied";
      buttonText = "Pending";
    } else if (status === 1) {
      className = "completeBtn";
      buttonText = "Completed";
    }
    return { className, buttonText };
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"Assessments"} />
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-3">
            <section>
              <div id="assessment">
                <div className="headingTable cardPaitent">
                  <Row>
                    <Col md={12}>
                      <div className="bg-white rounded-3 py-4 cardPaitent">
                        <div className="rounded-3  topBar mb-4">
                          <div className="d-flex justify-content-between">
                            <div>
                              <div className="d-flex align-items-center w-full">
                                <div className="flex-shrink-0">
                                  <Image
                                    src="../../images/avatar.png"
                                    alt=""
                                    width={75}
                                    height={75}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-4">
                                  <div className="d-flex justify-content-between">
                                    <h4 className="text-capitalize">{name}</h4>
                                  </div>
                                  <Col md={12}>
                                    <label className="fw-normal">
                                      {patientAssessments[0]?.assessment_id}{" "}
                                      Assessments
                                    </label>
                                  </Col>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <Image
                                src="../../images/timeschdule.png"
                                alt=""
                                width={16}
                                height={16}
                              />
                              <span className="ms-2 span_label">
                                You have {patientAssessments[0]?.assessment_id}{" "}
                                pending assessments for today
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={12} className="assignmentList ">
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-single"
                          role="tabpanel"
                          aria-labelledby="pills-single-tab"
                        >
                          <ul className="p-0 assignmentList d-grid gap-3">
                            {patientAssessments?.map((item, index) => (
                              <li
                                key={index}
                                className="border rounded-3 py-2 px-3"
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{ width: "100%" }}
                                >
                                  <div className="flex-shrink-0">
                                    <img
                                      src="../../images/book-icon.svg"
                                      alt="..."
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-3 gap-2">
                                    <div className="flex-grow-1 ms-3 gap-2">
                                      <h3
                                        onClick={() => {
                                          setSelectedAssessmentResultData(item);
                                          setIsAssessmentResultModalOpen(true);
                                        }}
                                        className="mb-2 fs-6"
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "16px",
                                          color: "#18357A",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item?.assessmentName}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-auto"
                                    style={{
                                      width: "100px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <ProgressBar
                                      now={item.status === 1 ? 100 : ""}
                                    />
                                    <span>
                                      {item.status === 1 ? 100 : ""}% complete
                                    </span>
                                  </div>
                                  <div
                                    className="d-flex gap-1 justify-content-center col-md-auto"
                                    style={{
                                      width: "20%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <p>
                                      {item.status === 0
                                        ? "Due to:"
                                        : "Taken on:"}
                                    </p>{" "}
                                    <span> {formatDate(item.created_at)}</span>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className={`btn ${
                                        getButtonDetails(item.status).className
                                      }`}
                                    >
                                      {getButtonDetails(item.status).buttonText}
                                    </button>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal
                  isOpen={isAssessmentResultModalOpen}
                  onRequestClose={() => setIsAssessmentResultModalOpen(false)}
                >
                  <AssessmentResultModal
                    assessmentInfo={{
                      selectedAssessmentResultData:
                        selectedAssessmentResultData,
                      setIsAssessmentResultModalOpen:
                        setIsAssessmentResultModalOpen,
                    }}
                  />
                </Modal>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PatientAssessment;
