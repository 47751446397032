import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import { CHIP_DATA } from 'Data';

export default function CustomChips() {
    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    return (
        <Stack direction="row" spacing={1}>
            {CHIP_DATA.map((item) => (
                <Chip
                    key={item.id}
                    avatar={<Avatar alt="Natacha" src={item.image} />}
                    label={item.name}
                    onClick={handleClick}
                    onDelete={handleDelete}
                    deleteIcon={<CloseIcon />}
                    className={item.id === 1 ? 'active_chip' : ''}
                    variant="outlined"
                />
            ))}

        </Stack>
    );
}
