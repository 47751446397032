import React, { createContext, useState, useEffect } from "react";
import {
    store_update_Assessments,
    getAssessments,
    deleteAssessments,
    assignedAssessments,
} from "./Api";
import storage from "pages/lib/storage";


interface TreatmentPlanContextType {
    assessments: any
}

export const AssessmentsContext = createContext<TreatmentPlanContextType | any>(undefined);

export const AssessmentsProvider: React.FC = (props) => {

    let { userId } = storage.getItem("sbCalls")

    const [assessments, setAssessments] = useState<any>([])

    const [assessmentsPagination, setAssessmentsPagination] = useState({})
    const [assessmentsError, setAssessmentsError] = useState('')

    const getAllAssessments = async () => {

        try {
            setAssessmentsError("")
            const response: any = await getAssessments();
            setAssessments(response);
            setAssessmentsPagination(response.pagination);
        } catch (error) {
            setAssessmentsError("Something went wrong..")
        }
    };
    useEffect(() => {
        getAssessments().then((response) => {
            setAssessments(response);
            setAssessmentsPagination(response.pagination);
        }).catch((err) => {
            setAssessmentsError("Something went wrong..")
        });
    }, [userId])
    const AddUpdateAssessments = async (data: any) => {
        await store_update_Assessments(data);
        getAllAssessments()
    };

    const emptyPatients = () => {
        setAssessments([]);
    };

    const deleteAssessmentsRecords = async (id: any, dr_id: any) => {
        try {
            await deleteAssessments(id, dr_id);
            setAssessments((prevAssessments: any) => prevAssessments.filter((assessment: any) => assessment.id != id));


        } catch (err) {
            setAssessmentsError("Something went wrong..")
        }
    }

    const assignedDocumentsForm = (data: any) => {
        assignedAssessments(data)
            .then((res) => {
                setAssessmentsError('');
                getAllAssessments()
            })
            .catch((error: any) => {
                console.error(error, 'error')
                setAssessmentsError(error.message || "Something went wrong..");
            });
    }


    return (
        <AssessmentsContext.Provider
            value={{
                assessments,
                assessmentsError,
                emptyPatients,
                getAllAssessments,
                AddUpdateAssessments,
                deleteAssessmentsRecords,
                assignedDocumentsForm,
                assessmentsPagination


            }}
        >
            {props.children}
        </AssessmentsContext.Provider>
    );
};




