const Button = ({ className, type, onClick, variant, children, ...props }) => {
  return (
    <button
      variant={variant}
      className={className}
      type={type}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
