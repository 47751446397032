import React, { useEffect, useState, useRef } from "react";
import "./DBTracker.css";
import EditDairyCardModal from "../../../modals/EditDairyCardModal";
import Modal from "react-modal";
import EditDairySkillsCardModal from "../../../modals/EditDairySkillsCardModal";
import { useLocation } from "react-router-dom";
import { LineChart } from "@mui/x-charts/LineChart";
import { DBGraphIcon, DBTableIcon } from "utils/svg";
import {
  getAssignedPatientGraph,
  getAssignedTherapistGraph,
} from "lib/api/service";
import { useParams } from "react-router-dom";

import "bootstrap-daterangepicker/daterangepicker.css";

const DBTracker = () => {
  const { patientId: patientIdData } = useParams();
  const [activeTab, setActiveTab] = useState("tab1");
  const [nestedTab, setNestedTab] = useState("nestedTab1");
  const [title, setTitle] = useState("");
  const [DBTData, setDBTData] = useState([]);
  const loginDetails = localStorage.getItem("userDetails");
  const userRole = localStorage.getItem("userRole");

  const keyRef = useRef();
  const [open, setOpen] = React.useState(false);

  const headers = {
    "Content-Type": "application/json",
  };
  const apiEndpoint =
    "https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query";

  // const [ editData, setEditData ] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDBTSkillsModalOpen, setIsDBTSkillsEditModalOpen] = useState(false);

  const [skillsStats, setSkillsStats] = useState([]);
  const [dBTSkillData, setDBTSkillData] = useState([]);

  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const patientId = pathParts[pathParts.length - 1];

  const getPatient = async () => {
    try {
      const response = await getAssignedPatientGraph(activeTab);
      setDBTData(response);
    } catch (error) {
      console.error("Error fetching all assessments:", error);
      return [];
    }
  };

  const getTherapist = async () => {
    try {
      // const response = await getAssignedTherapistGraph(patientIdData)
      const response = await getAssignedTherapistGraph(
        patientIdData,
        activeTab
      );

      setDBTData(response);
    } catch (error) {
      console.error("Error fetching all assessments:", error);
      return [];
    }
  };

  useEffect(() => {
    const stats = calculateSkillsStats();
    const statsArray = Object.keys(stats).map((skill) => ({
      skill,
      count: stats[skill].count,
      averageScore: stats[skill].averageScore,
    }));
    setSkillsStats(statsArray);
  }, [dBTSkillData]);

  useEffect(() => {
    if (JSON.parse(loginDetails)?.userRole == "Doctor") {
      getTherapist();
    }
    if (JSON.parse(loginDetails)?.userRole == "Patient") {
      getPatient();
    }
  }, [activeTab, loginDetails, patientIdData]);

  useEffect(() => {
    if (JSON.parse(loginDetails)?.userRole == "Doctor") {
      getTherapist();
    }
    if (JSON.parse(loginDetails)?.userRole == "Patient") {
      getPatient();
    }
  }, []);

  const calculateSkillsStats = () => {
    const stats = {};

    dBTSkillData.forEach((entry) => {
      Object.keys(entry).forEach((category) => {
        if (
          [
            "mindfulness",
            "interpersonal_effectiveness",
            "emotion_regulation",
            "distress_tolerance",
          ].includes(category)
        ) {
          Object.keys(entry[category]).forEach((skill) => {
            const skillData = entry[category][skill];
            if (skillData[skill] === "True") {
              if (!stats[skill]) {
                stats[skill] = { count: 0, totalScore: 0, averageScore: 0 };
              }
              stats[skill].count += 1;
              stats[skill].totalScore += skillData.score;
            }
          });
        }
      });
    });

    // Calculate the average score for each skill
    Object.keys(stats).forEach((skill) => {
      if (stats[skill].count > 0) {
        stats[skill].averageScore =
          stats[skill].totalScore / stats[skill].count;
      }
    });

    // Convert stats object to an array if needed or use directly as is
    return stats;
  };

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);

    if (tabId === "tab1") {
      setNestedTab("nestedTab1");
    } else if (tabId === "tab2") {
      setNestedTab("nestedTab3");
    }
  };

  const handleNestedTabChange = (tabId) => {
    setNestedTab(tabId);
  };

  return (
    <div id="tracker">
      <div className="headingTable mb-4">
        <div className="row">
          <div className="col"></div>

          <div class="d-flex col-md-auto">
            <label for="staticEmail" class="me-2 col-form-label mb-0">
              Timeframe:
            </label>
            <div class="col-md">
              <select class="form-select">
                <option>July 1 - 7, 2023</option>
                <option>July 1 - 7, 2023</option>
                <option>July 1 - 7, 2023</option>
              </select>
            </div>
          </div>

          <div className="col-auto">
            <div
              className="btn-group btn-group-lg ms-2 gridList nav nav-pills"
              id="pills-tab"
              role="group">
              <button
                type="button"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                className="active btn btn-primary nav-link py-0 px-2 py-2 rounded-start-2 bg-light border-0"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={() => handleTabChange("tab1")}>
                <DBTableIcon />
              </button>
              <button
                type="button"
                className="btn btn-primary nav-link py-0 px-2 py-2 rounded-end-2 bg-light border-0"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                onClick={() => handleTabChange("tab2")}>
                <DBGraphIcon />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="position-relative topRelative text-right w-auto float-end">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-auto"></div>
          <div className="col-md-auto">
            <div className="d-flex">
              {nestedTab === "nestedTab1" && userRole === "Doctor" && (
                <button
                  type="button"
                  class="btn btnEdit ms-3"
                  onClick={() => {
                    setTitle("Edit diary card");
                    setIsEditModalOpen(true);
                  }}>
                  <i class="far fa-edit me-1"></i>
                  Edit diary card
                </button>
              )}

              {nestedTab === "nestedTab2" && (
                <button
                  type="button"
                  class="btn btnEdit ms-3"
                  onClick={() => {
                    setTitle("Edit Skills");
                    setIsDBTSkillsEditModalOpen(true);
                  }}>
                  <i class="far fa-edit me-1"></i>
                  Edit Skills
                </button>
              )}

              {nestedTab === "nestedTab3" && (
                <>
                  <div class="d-flex col-md-auto">
                    <label for="staticEmail" class="me-2 col-form-label mb-0">
                      Select:
                    </label>
                    <div class="col-md">
                      <select class="form-select">
                        <option>All emotions</option>
                        <option>Anger</option>
                      </select>
                    </div>
                  </div>

                  {userRole === "Doctor" && (
                    <button
                      type="button"
                      class="btn btnEdit ms-3"
                      onClick={() => {
                        setTitle("Edit diary card");
                        setIsEditModalOpen(true);
                      }}>
                      <i class="far fa-edit me-1"></i>
                      Edit diary card
                    </button>
                  )}
                </>
              )}

              {nestedTab === "nestedTab4" && (
                <>
                  <button
                    type="button"
                    class="btn btnEdit ms-3"
                    onClick={() => {
                      setTitle("Edit Skills");
                      setIsEditModalOpen(true);
                    }}>
                    <i class="far fa-edit me-1"></i>
                    Edit Skills
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Tab content */}
      {activeTab === "tab1" && (
        <div>
          {/* Nested Tab */}
          <button
            className="border-0 bg-transparent ps-0"
            onClick={() => handleNestedTabChange("nestedTab1")}>
            <div className="col-md">
              <ul
                class="nav nav-pills mb-0 dbSkillTab gap-2"
                id="pills-tab"
                role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      nestedTab === "nestedTab1" ? "active" : ""
                    }`}
                    id="pills-diryCard-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-diryCard"
                    type="button"
                    role="tab"
                    aria-controls="pills-diryCard"
                    aria-selected={nestedTab === "nestedTab1"}>
                    DIARY CARD
                  </button>
                </li>
              </ul>
            </div>
          </button>

          {/* <button
            className="border-0 bg-transparent"
            onClick={() => handleNestedTabChange("nestedTab2")}
          >
            <div className="col-md">
              <ul
                class="nav nav-pills mb-0 dbSkillTab gap-2"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    className={`nav-link ${nestedTab === "nestedTab2" ? "active" : ""
                      }`}
                    id="pills-skill-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-skill"
                    type="button"
                    role="tab"
                    aria-controls="pills-skill"
                    aria-selected={nestedTab === "nestedTab2"}
                  >
                    SKILLS & COMMENTS
                  </button>
                </li>
              </ul>
            </div>
          </button> */}

          {nestedTab === "nestedTab1" && (
            <div class="tab-content">
              <div
                class="tab-pane fade show active"
                aria-labelledby="pills-diryCard-tab">
                <div class="table-responsive">
                  {DBTData?.length > 0 && (
                    <table class="table border tableCustmization">
                      <tbody>
                        <tr>
                          <th>Date</th>
                          {DBTData?.map((dayData, index) => (
                            <td>{dayData?.date_value}</td>
                          ))}
                        </tr>

                        {["behaviors", "urges", "emotions"].flatMap(
                          (category, catIndex) =>
                            DBTData[0]?.dbtFields
                              ?.find((field) => field.type === category)
                              ?.fields?.map((field, itemIndex) => (
                                <tr
                                  key={`${category}-${field.key}-${catIndex}-${itemIndex}`}>
                                  <td>{field.key}</td>
                                  {DBTData.map((dayData, dayIndex) => {
                                    const categoryFields =
                                      dayData.dbtFields.find(
                                        (f) => f.type === category
                                      )?.fields || [];
                                    const fieldData = categoryFields.find(
                                      (f) => f.key === field.key
                                    );

                                    return (
                                      <td
                                        className="text-green"
                                        key={`${dayData.date_value}-${field.key}-${dayIndex}`}>
                                        {fieldData
                                          ? Array.isArray(fieldData.value)
                                            ? fieldData.value.join(", ")
                                            : fieldData.value
                                          : "N/A"}
                                      </td>
                                    );
                                  })}
                                </tr>
                              )) || []
                        )}
                      </tbody>
                      <tbody></tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          )}
          {nestedTab === "nestedTab2" && (
            <div>
              <div class="tab-content">
                <div
                  class="tab-pane fade show active"
                  role="tabpanel"
                  aria-labelledby="pills-diryCard-tab">
                  <div class="table-responsive">
                    <table class="table border tableCustmization">
                      {dBTSkillData?.length > 0 && (
                        <tbody>
                          <tr>
                            <th>Skill / Uploaded DateTime</th>
                            {dBTSkillData?.map((dayData) => (
                              <>
                                <th> {dayData.uploaded_datetime} </th>
                              </>
                            ))}
                          </tr>

                          {[
                            "distress_tolerance",
                            "emotion_regulation",
                            "interpersonal_effectiveness",
                            "mindfulness",
                          ]?.flatMap((category, catIndex) =>
                            Object.keys(dBTSkillData[0][category])?.map(
                              (key, itemIndex) => (
                                <tr
                                  key={`${category}-${key}-${catIndex}-${itemIndex}`}>
                                  <td> {key}</td>

                                  {dBTSkillData?.map((dayData, dayIndex) => (
                                    <td
                                      key={`${dayData.date_value}-${key}-${dayIndex}`}>
                                      {" "}
                                      {dayData[category][key].score} (
                                      {dayData[category][key][key] == "True"
                                        ? "Yes"
                                        : "No"}
                                      )
                                    </td>
                                  ))}
                                </tr>
                              )
                            )
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {activeTab === "tab2" && (
        <div>
          {/* Nested Tab for Tab 2 */}
          <div>
            <button
              className="border-0 bg-transparent ps-0"
              onClick={() => handleNestedTabChange("nestedTab3")}>
              <div className="col-md">
                <ul
                  class="nav nav-pills mb-0 dbSkillTab gap-2"
                  id="pills-tab"
                  role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        nestedTab === "nestedTab3" ? "active" : ""
                      }`}
                      id="pills-diryCard-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-diryCard"
                      type="button"
                      role="tab"
                      aria-controls="pills-diryCard"
                      aria-selected={nestedTab === "nestedTab3"}>
                      DIARY CARD
                    </button>
                  </li>
                </ul>
              </div>
            </button>

            {/* <button
              className="border-0 bg-transparent"
              onClick={() => handleNestedTabChange("nestedTab4")}
            >
              <div className="col-md">
                <ul
                  class="nav nav-pills mb-0 dbSkillTab gap-2"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      className={`nav-link ${nestedTab === "nestedTab4" ? "active" : ""
                        }`}
                      id="pills-skill-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-skill"
                      type="button"
                      role="tab"
                      aria-controls="pills-skill"
                      aria-selected={nestedTab === "nestedTab4"}
                    >
                      SKILLS & COMMENTS
                    </button>
                  </li>
                </ul>
              </div>
            </button> */}

            {/* Nested Tab content for Tab 2 */}

            {nestedTab === "nestedTab3" && (
              <div>
                {DBTData?.length > 0 &&
                  (() => {
                    // Ensure DBTData has valid data
                    if (!DBTData || DBTData.length === 0) return null;

                    // Convert date_value to Date objects for sorting
                    const sortedData = [...DBTData].sort(
                      (a, b) => new Date(a.date_value) - new Date(b.date_value)
                    );

                    // Get unique dates
                    const uniqueDates = Array.from(
                      new Set(sortedData.map((entry) => entry.date_value))
                    );

                    // Ensure there's at least one entry to get emotions from
                    const sampleEntry = DBTData[0];
                    if (!sampleEntry || !sampleEntry.emotions) return null;

                    // Get unique data for each date
                    const uniqueData = uniqueDates.map((date) => {
                      return sortedData.find(
                        (entry) => entry.date_value === date
                      );
                    });

                    // Prepare series data for each emotion
                    const seriesData = Object.keys(sampleEntry.emotions).map(
                      (emotion) => {
                        return {
                          label: emotion,
                          data: uniqueData.map((dayData) => {
                            if (
                              !dayData ||
                              !dayData.emotions ||
                              !dayData.emotions[emotion]
                            ) {
                              return null; // Ensure that the emotion exists before accessing
                            }
                            // Replace 0 with null to skip rendering the data point but keep the line intact
                            const score = dayData.emotions[emotion].score;
                            return score === 0 ? null : score;
                          }),
                        };
                      }
                    );

                    return (
                      <LineChart
                        width={800}
                        height={500}
                        series={seriesData}
                        xAxis={[
                          {
                            scaleType: "point",
                            data: uniqueDates,
                          },
                        ]}
                      />
                    );
                  })()}
              </div>
            )}

            {nestedTab === "nestedTab4" && (
              <div>
                <p className="my-2 darkBlue">
                  <b>Top 10 most effective skills</b>
                </p>
                <div class="tab-content">
                  <div
                    class="tab-pane fade show active"
                    aria-labelledby="pills-diryCard-tab">
                    <div class="table-responsive">
                      <table class="table border-0 table-borderless tableCustmization">
                        <thead>
                          <tr>
                            <th>Skill name</th>
                            <th>
                              Avg. score{" "}
                              <img src="images/short-icon.svg" alt="" />
                            </th>
                            <th>
                              Frequency{" "}
                              <img src="images/short-icon.svg" alt="" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {skillsStats.map(({ skill, count, averageScore }) => (
                            <tr>
                              <td>{skill}</td>
                              <td>{averageScore.toFixed(2)}</td>
                              <td className="text-green">
                                <div className="horizontal-bar-container">
                                  {[...Array(5)].map((_, index) =>
                                    index < count ? (
                                      <div
                                        key={index}
                                        className={`horizontal-bar filled ${
                                          count === 1
                                            ? "count-1"
                                            : count === 2
                                            ? "count-2"
                                            : count === 3
                                            ? "count-3"
                                            : count === 4
                                            ? "count-4"
                                            : count === 5
                                            ? "count-5"
                                            : count > 5
                                            ? "count-more-than-5"
                                            : ""
                                        }`}></div>
                                    ) : null
                                  )}

                                  <div className="text bar-text">
                                    {count <= 3
                                      ? "low"
                                      : count === 4
                                      ? "medium"
                                      : "high"}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}>
        <EditDairyCardModal
          editInfo={{
            title: title,
            patientId: patientId,
            setIsEditModalOpen: setIsEditModalOpen,
          }}
        />
      </Modal>

      <Modal
        isOpen={isDBTSkillsModalOpen}
        onRequestClose={() => setIsDBTSkillsEditModalOpen(false)}>
        <EditDairySkillsCardModal
          editInfo={{
            title: title,
            patientId: patientId,
            setIsDBTSkillsEditModalOpen: setIsDBTSkillsEditModalOpen,
          }}
        />
      </Modal>
    </div>
  );
};

export default DBTracker;
