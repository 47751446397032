import React, { useState } from "react";
import RecieptsTabTable from "./RecieptsTabTable";

const RecieptsTab = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const items = [
    {
      name: "Emily Johnson",
      imgSrc: "https://bootdey.com/img/Content/avatar/avatar2.png",
    },
    {
      name: "Emily Johnson",
      imgSrc: "https://bootdey.com/img/Content/avatar/avatar2.png",
    },
  ];

  return (
    <>
      <div className="col-3 mt-4">
        <div className="my-4">
          <h2>Patients</h2>
        </div>
        <div className="input-group">
          <span className="input-group-text border-end-0" id="basic-addon1">
            <i className="far fa-search"></i>
          </span>
          <input
            type="text"
            className="form-control ps-0 border-start-0"
            placeholder="Search..."
            aria-label="Username"
            aria-describedby="basic-addon1"
            style={{ backgroundColor: "#e9ecef" }}
          />
        </div>
        <div className="articles card mt-4">
          {items.map((item, index) => (
            <div
              key={index}
              className={`${
                activeIndex === index ? "active" : ""
              } card-body no-padding`}
              style={{
                borderBottom: index === 0 ? "1px solid #E7EBF2" : "none",
              }}>
              <li>
                <div
                  className={`item d-flex align-items-center`}
                  onClick={() => handleClick(index)}>
                  <div className="image">
                    <img
                      src={item.imgSrc}
                      alt="..."
                      className="img-fluid rounded-circle"
                    />
                  </div>
                  <div className="text">
                    <h2
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        cursor: "pointer",
                        lineHeight: "21px",
                      }}>
                      {item.name}
                    </h2>
                  </div>
                </div>
              </li>
            </div>
          ))}
        </div>
      </div>
      <RecieptsTabTable />
    </>
  );
};

export default RecieptsTab;
