import { Link } from "react-router-dom";

const PatientProfileSidebar = ({ location, blockTexts, patientId, formName }) => {

    const PAGES = blockTexts.map(({ page, text }) => ({
        page: page,
        label: text,
        icon: '',
        completed: false,
    }));

    let currentPageIndex = -1;
    for (let i = 0; i < PAGES.length; i++) {
        if (location.pathname === `/profile/${formName}/${patientId}/page${i + 1}`) {
            currentPageIndex = i;
            break;
        }
    }

    for (let i = 0; i <= currentPageIndex; i++) {
        PAGES[i].completed = true;
    }

    return (
        <div className="sideBar">
            <ul>
                {PAGES.map((page, index) => (
                    <li 
                        key={index}
                        className={`page-item ${page.completed ? 'completed' : ''} ${index === currentPageIndex ? 'current' : ''}`}
                    >
                        <div>
                            <span>
                                {index === currentPageIndex ? (
                                    <i className="far fa-circle pe-1"></i>
                                ) : page.completed ? (
                                    <i className="fa fa-check-circle pe-1"></i>
                                ) : null}
                            </span>
                            <span className='bold-text'>
                                {/* <Link to={`/profile/${formName}/${patientId}/page${page.page}`} className='text-decoration-none'> */}
                                    {page.label}
                                {/* </Link> */}
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PatientProfileSidebar;