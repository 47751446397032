

const ACTIVATING_EVENTS = [
    { name: 'recent_loss_or_other_significant_negative_event', label: 'Recent loss or other significant negative event' },
    { name: 'pending_incarceration_or_homelessness', label: 'Pending incarceration or homelessness' },
    { name: 'current_or_pending_isolation_or_feeling_alone', label: 'Current or pending isolation or feeling alone' }
];

const TREATMENT_HISTORY = [
    { name: 'previous_psychiatric_diagnoses_and_treatments', label: 'Previous psychiatric diagnoses and treatments' },
    { name: 'hopeless_or_dissatisfied_with_treatment', label: 'Hopeless or dissatisfied with treatment' },
    { name: 'noncompliant_with_treatment', label: 'Noncompliant with treatment' },
    { name: 'not_receiving_treatment', label: 'Not receiving treatment' }
];

const CLINICAL_STATUS = [
    { name: 'hopelessness', label: 'Hopelessness' },
    { name: 'helplessness', label: 'Helplessness' },
    { name: 'feeling_trapped', label: 'Feeling Trapped' },
    { name: 'major_depressive_episode', label: 'Major depressive episode' },
    { name: 'mixed_affective_episode', label: 'Mixed affective episode' },
    { name: 'command_hallucinations_to_hurt_self', label: 'Command hallucinations to hurt self' },
    { name: 'highly_impulsive_behavior', label: 'Highly impulsive behavior' },
    { name: 'substance_abuse_or_dependence', label: 'Substance abuse or dependence' },
    { name: 'agitation_or_severe_anxiety', label: 'Agitation or severe anxiety' },
    { name: 'perceived_burden_on_family_or_others', label: 'Perceived burden on family or others' },
    { name: 'chronic_physical_pain_or_other_acute', label: 'Chronic physical pain or other acute medical problem (AIDS, COPD, cancer, etc)' },
    { name: 'homicidal_ideation', label: 'Homicidal ideation' },
    { name: 'method_or_suicide_available', label: 'Method or suicide available (gun, pills, etc)' },
    { name: 'refuses_or_feels_unable_to_agree_to_safety_plan', label: 'Refuses or feels unable to agree to safety plan' },
    { name: 'sexual_abuse_(lifetime)', label: 'Sexual abuse (lifetime)' },
    { name: 'family_history_of_suicide_(lifetime)', label: 'Family history of suicide (lifetime)' }
];
const PROTECTIVE_FACTORS = [
    { name: 'identifies_reasons_for_living', label: 'Identifies reasons for living' },
    { name: 'responsibility_to_family_or_others_living_with_family', label: 'Responsibility to family or others; living with family' },
    { name: 'supportive_social_network_or_family', label: 'Supportive social network or family' },
    { name: 'fear_of_death_or_dying_due_to_pain_or_suffering', label: 'Fear of death or dying due to pain and suffering' },
    { name: 'belief_that_suicide_is_immoral_high_spirituality', label: 'Belief that suicide is immoral, high spirituality' },
    { name: 'engaged_in_work_or_school', label: 'Engaged in work or school' },
    { name: 'engaged_with_phone_worker', label: 'Engaged with Phone Worker' }
];
const SUICIDAL_IDEATION_TABLE = [
    { name: 'wish_to_be_dead', label: 'Wish to be Dead', text: 'Subject endorses thoughts about a wish to be dead or not alive anymore, or wish to fall asleep and not wake up.', boldText: 'Have you wished you were dead or wished you could go to sleep and not wake up?' },
    { name: 'non_specific_active_suicidal_thoughts', label: 'Non-Specific Active Suicidal Thoughts', text: 'General non-specific thoughts of wanting to end one’s life/commit suicide (e.g., “I’ve thought about killing myself”) without thoughts of ways to kill oneself/associated methods, intent, or plan during the assessment period.', boldText: 'Have you actually had any thoughts of killing yourself?' },
    { name: 'active_suicidal_ideation_with_any_methods', label: 'Active Suicidal Ideation with Any Methods (Not Plan) without Intent to Act', text: 'Subject endorses thoughts of suicide and has thought of at least one method during the assessment period. This is different than a specific plan with time, place or method details worked out (e.g., thought of method to kill self but not a specific plan). Includes person who would say, “I thought about taking an overdose but I never made a specific plan as to when, where or how I would actually do it...and I would never go through with it.', boldText: 'Have you been thinking about how you might do this?' },
    { name: 'active_suicidal_ideation_with_some_intent_to_act', label: 'Active Suicidal Ideation with Some Intent to Act, without Specific Plan', text: 'Active suicidal thoughts of killing oneself and subject reports having some intent to act on such thoughts, as opposed to “I have the thoughts but I definitely will not do anything about them.', boldText: 'Have you had these thoughts and had some intention of acting on them?' },
    { name: 'active_suicidal_ideation_with_specific_plan_and_intent', label: 'Active Suicidal Ideation with Specific Plan and Intent', text: 'Thoughts of killing oneself with details of plan fully or partially worked out and subject has some intent to carry it out', boldText: 'Have you started to work out or worked out the details of how to kill yourself? Do you intend to carry out this plan?' }
];
const SUICIDAL_IDEATION_HEADERS = [
    { name: 'ask_questions_1_and_2', label: 'Ask questions 1 and 2. If both are negative, proceed to “Suicidal Behavior” section. If the answer to question 2 is “yes”, ask questions 3, 4 and 5. If the answer to question 1 and/or 2 is “yes”, complete “Intensity of Ideation” section below.' },
    { name: 'lifetime', label: 'Lifetime' },
    { name: 'past_1_month', label: 'Past 1 Month' }
];
const SUICIDE_INTENSITY_TABLE = [
    {name: 'frequency', label: 'Frequency', text: 'How many times have you had these thoughts?', boldText: '(1) Less than once a week\n (2) Once a week\n (3) 2-5 times in week\n (4) Daily or almost daily\n (5) Many times each day'}, 
    {name: 'duration', label: 'Duration', text: 'When you have the thoughts how long do they last?', boldText: '(1) Fleeting - few seconds or minutes\n (2) Less than 1 hr/some of the time\n (3) 1-4 hours/a lot of time\n (4) 4-8 hours/most of day\n (5) More than 8 hours/persistent or continuous'},
    {name: 'controllability', label: 'Controllability', text: 'Could/can you stop thinking about killing yourself or wanting to die if you want to?', boldText: '(1) Easily able to control thoughts\n (2) Can control thoughts with little difficulty\n (3) Can control thoughts with some difficulty\n (4) Can control thoughts with a lot of difficulty\n (5) Unable to control thoughts\n (0) Does not attempt to control thoughts'},
    {name: 'deterrents', label: 'Deterrents', text: 'Are there things - anyone or anything (e.g., family, religion, pain of death) - that stopped you from wanting to die or acting on thoughts of committing suicide?', boldText: '(1) Deterrents definitely stopped you from attempting suicide\n (2) Deterrents probably stopped you\n (3) Uncertain that deterrents stopped you\n (4) Deterrents most likely did not stop you\n (5) Deterrents definitely did not stop you\n (0) Does not apply'},
    {name: 'reasons_for_ideation', label: 'Reasons for Ideation', text: 'What sort of reasons did you have for thinking about wanting to die or killing yourself? Was it to end the pain or stop the way you were feeling (in other words you couldn’t go on living with this pain or how you were feeling) or was it to get attention, revenge or a reaction from others? Or both?', boldText: '(1) Completely to get attention, revenge or a reaction from others\n (2) Mostly to get attention, revenge or a reaction from others\n (3) Equally to get attention, revenge or a reaction from others and to end/stop the pain\n (4) Mostly to end or stop the pain (you couldn’t go on living with the pain or how you were feeling)\n (5) Completely to end or stop the pain (you couldn’t go on living with the pain or how you were feeling)\n (0) Does not apply'}
];

const SUICIDAL_BEHAVIOR_HEADERS = ['(Check all that apply, so long as these are separate events; must ask about all types)', 'Lifetime', 'Past 3 Months'];

const SUICIDAL_BEHAVIOR_TABLE = [
    { name: 'interrupted_attempt', label: 'Interrupted attempt', text: 'When the person is interrupted (by an outside circumstance) from starting the potentially self-injurious act (if not for that, actual attempt would have occurred). Overdose: Person has pills in hand but is stopped from ingesting. Once they ingest any pills, this becomes an attempt rather than an interrupted attempt. Shooting: Person has gun pointed toward self, gun is taken away by someone else, or is somehow prevented from pulling trigger. Once they pull the trigger, even if the gun fails to fire, it is an attempt. Jumping: Person is poised to jump, is grabbed and taken down from ledge. Hanging: Person has noose around neck but has not yet started to hang - is stopped from doing so.', boldText: 'Has there been a time when you started to do something to end your life but someone or something stopped you before you actually did anything?' },
    { name: 'aborted_or_self_interrupted_attempt', label: 'Aborted or Self-Interrupted Attempt:', text: 'When person begins to take steps toward making a suicide attempt, but stops themselves before they actually have engaged in any self-destructive behavior. Examples are similar to interrupted attempts, except that the individual stops him/herself, instead of being stopped by something else.', boldText: 'Has there been a time when you started to do something to try to end your life but you stopped yourself before you actually did anything?' },
    { name: 'preparatory_acts_or_behavior', label: 'Preparatory Acts or Behavior:', text: 'Acts or preparation towards imminently making a suicide attempt. This can include anything beyond a verbalization or thought, such as assembling a specific method (e.g., buying pills, purchasing a gun) or preparing for one’s death by suicide (e.g., giving things away, writing a suicide note).', boldText: 'Have you taken any steps towards making a suicide attempt or preparing to kill yourself (such as collecting pills, getting a gun, giving valuables away or writing a suicide note)?' }
];


const ACTUAL_SUICIDE_ATTEMPTS_HEADERS = [
    { name: 'answer_for_actual_attempts_only', label: 'Answer for Actual Attempts Only' },
    { name: 'most_recent_attempt', label: 'Most Recent Attempt' },
    { name: 'most_lethal_attempt', label: 'Most Lethal Attempt' },
    { name: 'initial_first_attempt', label: 'Initial/First Attempt' }
];
const ACTUAL_SUICIDE_ATTEMPTS_TABLE = [
    { name: 'actual_attempt', label: 'Actual attempt', boldText: `0. No physical damage or very minor physical damage (e.g., surface scratches).\n 1. Minor physical damage (e.g., lethargic speech; first-degree burns; mild bleeding; sprains).\n 2. Moderate physical damage; medical attention needed (e.g., conscious but sleepy, somewhat responsive; second-degree burns; bleeding of major vessel). 3. Moderately severe physical damage; medical hospitalization and likely intensive care required (e.g., comatose with reflexes intact; third-degree burns less than 20% of body; extensive blood loss but can recover; major fractures).\n 4. Severe physical damage; medical hospitalization with intensive care required (e.g., comatose without reflexes; third-degree burns over 20% of body; extensive blood loss with unstable vital signs; major damage to a vital area).\n 5. Death` },
    { name: 'potential_lethality', label: 'Potential Lethality:\n Only Answer if Actual Lethality=0', text: 'Likely lethality of actual attempt if no medical damage (the following examples, while having no actual medical damage, had potential for very serious lethality: put gun in mouth and pulled the trigger but gun fails to fire so no medical damage; laying on train tracks with oncoming train but pulled away before run over).', boldText: '0 = Behavior not likely to result in injury\n, 0.1 = Behavior likely to result in injury but not likely to cause death, \n0.2 = Behavior likely to result in death despite available medical care' }
];

//Patient Intake Page 4  MSE
const MSE_OPTIONS = [
    {
        name: 'appearance',
        label : 'Appearance',
        examOptions: [
            { name: 'appearance_neat', label: 'Neat' },
            { name: 'appearance_disheveled', label: 'Disheveled' },
            { name: 'appearance_inappropriate', label: 'Inappropriate' },
            { name: 'appearance_bizarre', label: 'Bizarre' },
            { name: 'appearance_other', label: 'Other' }
        ]
    },
    {
        name: 'speech',
        label: 'Speech',
        examOptions: [
            { name: 'speech_clear_&_coherent', label: 'Clear & Coherent' },
            { name: 'speech_tangential', label: 'Tangential' },
            { name: 'speech_pressured', label: 'Pressured' },
            { name: 'speech_rapid', label: 'Rapid' },
            { name: 'speech_slow', label: 'Slow' },
            { name: 'speech_loud', label: 'Loud' },
            { name: 'speech_quiet', label: 'Quiet' },
            { name: 'speech_talkative', label: 'Talkative' },
            { name: 'speech_impoverished', label: 'Impoverished' },
            { name: 'speech_other', label: 'Other' }
        ]
    },
    {
        name: 'eye_contact',
        label: 'Eye Contact',
        examOptions: [
            { name: 'eye_contact_within_normal_limits', label: 'Within Normal Limits' },
            { name: 'eye_contact_intense', label: 'Intense' },
            { name: 'eye_contact_avoidant', label: 'Avoidant' },
            { name: 'eye_contact_other', label: 'Other' }
        ]
    },
    {
        name: 'motor_activity',
        label: 'Motor Activity',
        examOptions: [
            { name: 'motor_activity_within_normal_limits', label: 'Within Normal Limits' },
            { name: 'motor_activity_restless', label: 'Restless' },
            { name: 'motor_activity_tics', label: 'Tics' },
            { name: 'motor_activity_slowed', label: 'Slowed' },
            { name: 'motor_activity_other', label: 'Other' }
        ]
    },
    {
        name: 'affect',
        label: 'Affect',
        examOptions: [
            { name: 'affect_full_range', label: 'Full Range' },
            { name: 'affect_congruent', label: 'Congruent' },
            { name: 'affect_flat', label: 'Flat' },
            { name: 'affect_labile', label: 'Labile' },
            { name: 'affect_blunted', label: 'Blunted' },
            { name: 'affect_restricted', label: 'Restricted' },
            { name: 'affect_incongruent', label: 'Incongruent' },
            { name: 'affect_other', label: 'Other' }
        ]
    },
    {
        name: 'mood',
        label: 'Mood',
        examOptions: [
            { name: 'mood_euthymic', label: 'Euthymic' },
            { name: 'mood_anxious', label: 'Anxious' },
            { name: 'mood_angry', label: 'Angry' },
            { name: 'mood_depressed', label: 'Depressed' },
            { name: 'mood_euphoric', label: 'Euphoric' },
            { name: 'mood_irritable', label: 'Irritable' },
            { name: 'mood_other', label: 'Other' }
        ]
    },
    {
        name: 'orientation',
        label: 'Orientation',
        examOptions: [
            { name: 'orientation_person', label: 'Person' },
            { name: 'orientation_place', label: 'Place' },
            { name: 'orientation_time', label: 'Time' },
            { name: 'orientation_impaired', label: 'Impaired' }
        ]
    },
    {
        name: 'memory',
        label: 'Memory',
        examOptions: [
            { name: 'memory_intact', label: 'Intact' },
            { name: 'memory_short_term_impairment', label: 'Short Term Impairment' },
            { name: 'memory_long_term_impairment', label: 'Long Term Impairment' }
        ]
    },
    {
        name: 'attention',
        label: 'Attention',
        examOptions: [
            { name: 'attention_intact', label: 'Intact' },
            { name: 'attention_distracted', label: 'Distracted' },
            { name: 'attention_other', label: 'Other' }
        ]
    },
    {
        name: 'perception',
        label: 'Perception',
        examOptions: [
            { name: 'perception_within_normal_limits', label: 'Within Normal Limits' },
            { name: 'perception_hallucinations_(a)', label: 'Hallucinations (A)' },
            { name: 'perception_hallucinations_(v)', label: 'Hallucinations (V)' },
            { name: 'perception_derealization', label: 'Derealization' },
            { name: 'perception_depersonalization', label: 'Depersonalization' },
            { name: 'perception_other', label: 'Other' }
        ]
    },
    {
        label: 'Thought Process',
        name: 'thought_process',
        examOptions: [
            { name: 'thought_process_goal_directed', label: 'Goal Directed' },
            { name: 'thought_process_disorganized', label: 'Disorganized' },
            { name: 'thought_process_perseveration', label: 'Perseveration' },
            { name: 'thought_process_loose_association', label: 'Loose Association' },
            { name: 'thought_process_circumstantial', label: 'Circumstantial' },
            { name: 'thought_process_logical', label: 'Logical' },
            { name: 'thought_process_thought_blocking', label: 'Thought Blocking' },
            { name: 'thought_process_flight_of_ideas', label: 'Flight of Ideas' },
            { name: 'thought_process_magical_thinking', label: 'Magical Thinking' },
            { name: 'thought_process_other', label: 'Other' }
        ]
    },
    {
        label: 'Thought Content',
        name: 'thought_content',
        examOptions: [
            { name: 'thought_content_within_normal_limits', label: 'Within Normal Limits' },
            { name: 'thought_content_si', label: 'SI' },
            { name: 'thought_content_hi', label: 'HI' },
            { name: 'thought_content_delusions', label: 'Delusions' },
            { name: 'thought_content_obsessions', label: 'Obsessions' },
            { name: 'thought_content_phobias', label: 'Phobias' },
            { name: 'thought_content_paranoia', label: 'Paranoia' },
            { name: 'thought_content_ideas_of_reference', label: 'Ideas of Reference' },
            { name: 'thought_content_ruminations', label: 'Ruminations' },
            { name: 'thought_content_other', label: 'Other' }
        ]
    },
    {
        name: 'behavior',
        label: 'Behavior',
        examOptions: [
            { name: 'behavior_cooperative', label: 'Cooperative' },
            { name: 'behavior_guarded', label: 'Guarded' },
            { name: 'behavior_hyperactive', label: 'Hyperactive' },
            { name: 'behavior_agitated', label: 'Agitated' },
            { name: 'behavior_paranoid', label: 'Paranoid' },
            { name: 'behavior_aggressive', label: 'Agressive' },
            { name: 'behavior_bizarre', label: 'Bizarre' },
            { name: 'behavior_withdrawn', label: 'Withdrawn' },
            { name: 'behavior_other', label: 'Other' }
        ]
    },
    {
        name: 'insight',
        label: 'Insight',
        examOptions: [
            { name: 'insight_good', label: 'Good' },
            { name: 'insight_fair', label: 'Fair' },
            { name: 'insight_poor', label: 'Poor' }
        ]
    },
    {
        name: 'Judgment',
        label: 'Judgment',
        examOptions: [
            { name: 'judgment_good', label: 'Good' },
            { name: 'judgment_fair', label: 'Fair' },
            { name: 'judgment_poor', label: 'Poor' }
        ]
    }
];



// Patient Intake Page 6 Mental Health and Trauma History
const SUBSTANCES_HEADERS = ['Substance', 'Past Use', 'Current Use', 'Frequency of Use', 'Age of First Use'];
const SUBSTANCES = ['Alcohol', 'Nicotine', 'Marijuana', 'Cocaine', 'Opiates', 'Sedatives', 'Hallucinogens', 'Stimulants', 'Methamphetamines'];
const SUBSTANCE_USE_CHECKBOX = [
    { 
        name: 'consequences_checkbox',
        label: 'Consequences as a result of Drug/Alcohol Use (past or current):',
        text: '(select all that apply)',
        checkboxOptions: [
            { name: 'consequences_hangovers', label: 'Hangovers' },
            { name: 'consequences_left_school', label: 'Left School' },
            { name: 'consequences_jail', label: 'Jail' },
            { name: 'consequences_increased_tolerance', label: 'Increased tolerance (need more to get high)' },
            { name: 'consequences_overdoses', label: 'Overdoses' },
            { name: 'consequences_relationship_losses', label: 'Relationship Losses' },
            { name: 'consequences_seizures', label: 'Seizures' },
            { name: 'consequences_binges', label: 'Binges' },
            { name: 'consequences_traded_sex', label: 'Traded Sex for drugs' },
            { name: 'consequences_liver_disease', label: 'Liver Disease' },
            { name: 'consequences_blackouts', label: 'Blackouts' },
            { name: 'consequences_drinking_driving', label: 'Drinking and driving' },
            { name: 'consequences_gi_bleeding', label: 'GI Bleeding' },
            { name: 'consequences_dts_shakes', label: 'DTs/Shakes' },
            { name: 'consequences_stealing_for_drugs', label: 'Stealing for drugs' },
            { name: 'consequences_sleep_problems', label: 'Sleep problems' },
            { name: 'consequences_arrest', label: 'Arrest' },
            { name: 'consequences_lost_job', label: 'Lost Job' },
            { name: 'consequences_other', label: 'Other' },
            { name: 'consequences_none', label: 'None' }
        ]
    },
    {
        name: 'risk_behaviors_checkbox',
        label: 'Risk Taking/Impulsive Behaviors (past or current):',
        text: '(select all that apply)',
        checkboxOptions: [
            { name: 'risk_behaviors_gambling', label: 'Gambling' },
            { name: 'risk_behaviors_selling_drugs', label: 'Selling drugs' },
            { name: 'risk_behaviors_unprotected_sex', label: 'Unprotected Sex' },
            { name: 'risk_behaviors_reckless_driving', label: 'Reckless driving' },
            { name: 'risk_behaviors_gang_involvement', label: 'Gang involvement' },
            { name: 'risk_behaviors_carry_weapons', label: 'Carry/using weapons' },
            { name: 'risk_behaviors_shoplifting', label: 'Shoplifting' },
            { name: 'risk_behaviors_other', label: 'Other' },
            { name: 'risk_behaviors_none', label: 'None' }
        ]
    },
    {
        name: 'client_thoughts_checkbox',
        label: 'Client\'s thoughts about making changes to substance use:',
        checkboxOptions: [
            { name: 'client_thoughts_not_ready_to_quit', label: 'Not ready to quit' },
            { name: 'client_thoughts_thinking_about_quitting', label: 'Thinking about quitting' },
            { name: 'client_thoughts_making_plans_to_quit', label: 'Making plans to quit' },
            { name: 'client_thoughts_quit_and_need_help', label: 'Quit and need help to prevent a relapse' },
            { name: 'client_thoughts_already_started_making_changes', label: 'Already started making changes' }
        ]
    },
    {
        name: 'detox_symptoms_checkbox',
        label: 'Detox Symptoms:',
        checkboxOptions: [
            { name: 'detox_symptoms_dts_shakes', label: 'DTs/Shakes' },
            { name: 'detox_symptoms_seizures', label: 'Seizures' },
            { name: 'detox_symptoms_vomiting', label: 'Vomiting' },
            { name: 'detox_symptoms_achy', label: 'Achy' },
            { name: 'detox_symptoms_no_appetite', label: 'No appetite' },
            { name: 'detox_symptoms_sleeplessness', label: 'Sleeplessness' },
            { name: 'detox_symptoms_diarrhea', label: 'Diarrhea' },
            { name: 'detox_symptoms_other', label: 'Other' }
        ]
    }
];

const DISORDERED_EATING_QUESTIONS = [
    {
        name: 'foodIntakeRestriction',
        label: 'Do you restrict your food intake?',
        answers: ['Yes, I currently restrict', 'Yes, but only in the past', 'No'],
        span: ['If yes, how do you/did you restrict (e.g., calories, fasting, specific food avoidance)?'],
        ids: ['yesCurrentFoodRestriction', 'yesPastFoodRestriction', 'noFoodRestriction']
    },
    {
        name: 'bingeEating',
        label: 'Do you binge eat?',
        descriptionUnderLabel: 'Binge eating is defined as eating an amount of food that is larger than the average person would consume, accompanied by a sense of being out of control.)',
        answers: ['Yes, I currently binge', 'Yes, but only in the past', 'No'],
        span: ['If yes, how do you/did you purge per day/week on average?', 'Do you have other over-eating patterns such as “grazing” on food all day?', 'What are your preferred bingeing and/ or grazing foods?'],
        ids: ['yesCurrentBingeing', 'yesPastBingeing', 'noBingeing']
    },
    {
        name: 'purgingOfFood',
        label: 'Do you purge?',
        answers: ['Yes, I currently purge', 'Yes, but only in the past', 'No'],
        span: ['If yes, how do you/did you purge per day/week on average?'],
        ids: ['yesCurrentPurging', 'yesPastPurging', 'noPurging']
    },
    {
        name: 'useOfLaxatives',
        label: 'Do you abuse laxatives?',
        answers: ['Yes, I currently abuse', 'Yes, but only in the past', 'No'],
        span: ['If yes, what kind of laxatives do you/did you use, and what amount per day/week on average?'],
        ids: ['yesCurrentUseOfLaxatives', 'yesPastUseOfLaxatives', 'noUseOfLaxatives']
    }
];
const dropdownNumOptions = [['', 0, 1, 2, 3, 4, 5], ['', 0, 0.1, 0.2]];
const HOUSING_OPTIONS = [
    { name: 'housing_type', label: 'House', value: 'house' },
    { name: 'housing_type', label: 'Apartment', value: 'apartment' },
    { name: 'housing_type', label: 'Sober House/Halfway House', value: 'sober_house_halfway_house' },
    { name: 'housing_type', label: 'Homeless', value: 'homeless' },
    { name: 'housing_type', label: 'Other', value: 'other' }
];

const LIVING_ARRANGEMENT_OPTIONS = [
    { name: 'living_arrangement', label: 'Alone', value: 'alone' },
    { name: 'living_arrangement', label: 'With Roommate(s)', value: 'with_roommates' },
    { name: 'living_arrangement', label: 'With Partner', value: 'with_partner' },
    { name: 'living_arrangement', label: 'With Family', value: 'with_family' },
    { name: 'living_arrangement', label: 'Other', value: 'other' }
];


const EDUCATION_COMPLETION_ITEMS = [
    [{ name: 'high_school_graduate', label: 'High school graduate or GED' }],
    [{ name: 'less_than_12_years_school' , label : "Less than 12 years school" }, { name: 'last_grade_completed', label: 'Last grade completed' }],
    [{ name: 'college', label: 'College' }, { name: 'number_of_years_completed', label: 'Number of years completed' }],
    [{ name: 'vocational_schooling', label: 'Vocational Schooling' }, { name: 'number_of_years_completed', label: 'Number of years completed' }]
];
const EDUCATION_RADIO_QUESTIONS = [
    { name: 'patient_currently_in_school', label: 'Is the patient currently in school?' },
    { name: 'need_help_with_reading_writing', label: 'Do you need help with reading and/or writing?' },
    { name: 'learning_disabilities_or_problems', label: 'Any learning disabilities or other educational or learning problems?' }
];

const STAGES_OF_CHANGE_RADIO = [
    { name: 'stage_of_change', label: 'Pre-Contemplation', value: 'Pre-Contemplation' },
    { name: 'stage_of_change', label: 'Contemplation', value: 'Contemplation' },
    { name: 'stage_of_change', label: 'Preparation', value: 'Preparation' },
    { name: 'stage_of_change', label: 'Action', value: 'Action' },
    { name: 'stage_of_change', label: 'Maintenance', value: 'Maintenance' }
];



const EDUCATION_LEARNING_QUESTIONS = [
    { name: 'reading', label: 'Reading' },
    { name: 'writing', label: 'Writing' },
    { name: 'listening_to_information', label: 'Listening to information' },
    { name: 'practicing', label: 'Practicing' }
];
const EMPLOYMENT_TYPE = [
    { name: 'full_time', label: 'Full-time' },
    { name: 'part_time', label: 'Part-time' },
    { name: 'contractual_side_jobs', label: 'Contractual/Side Jobs' }
];const EMPLOYMENT_SATISFACTION = [
    { name: 'satisfied', label: 'Satisfied' },
    { name: 'not_satisfied', label: 'Not satisfied' },
    { name: 'conflict_with_supervisor', label: 'Conflict with supervisor' },
    { name: 'conflict_with_coworker', label: 'Conflict with coworker' },
    { name: 'used_substances_at_work', label: 'I have used substances at work' },
    { name: 'others_use_substances_at_work', label: 'Others use substances at work' },
    { name: 'employment_helps_recovery', label: 'Employment will help with recovery' },
    { name: 'employment_hurts_recovery', label: 'Employment could hurt recovery' }
];
const UNEMPLOYMENT_DESCRIPTION = [
    { name: 'currently_looking_for_work', label: 'Currently looking for work' },
    { name: 'disabled', label: 'Disabled' },
    { name: 'need_job_skills_training', label: 'Need job skills training' },
    { name: 'currently_in_school', label: 'Currently in school' },
    { name: 'never_been_employed', label: 'Never been employed' },
    { name: 'homemaker', label: 'Homemaker' },
    { name: 'unstable_work_history', label: 'Unstable work history' },
    { name: 'history_of_military_service', label: 'History of military service' },
    { name: 'not_looking_for_work', label: 'Not looking for work' }
];


const FAMILY_UPBRINGING = [
    {
        label: 'Who raised client',
        name: 'who_raised_client',
        options: [
            { name: 'mother', label: 'Mother' },
            { name: 'father', label: 'Father' },
            { name: 'grandparent', label: 'Grandparent' },
            { name: 'other', label: 'Other' }
        ]
    },
    {
        label: 'Impression of upbringing',
        name: 'impression_of_upbringing',
        options: [
            { name: 'healthy', label: 'Healthy' },
            { name: 'fair', label: 'Fair' },
            { name: 'dysfunctional', label: 'Dysfunctional' }
        ]
    },
    {
        label: 'Marital Status',
        name: 'marital_status',
        options: [
            { name: 'never_married', label: 'Never married' },
            { name: 'married', label: 'Married' },
            { name: 'separated', label: 'Separated' },
            { name: 'divorced', label: 'Divorced' },
            { name: 'widowed', label: 'Widowed' },
            { name: 'living_with_partner', label: 'Living with partner' },
            { name: 'in_relationship', label: 'In relationship' }
        ]
    }
];

const FAMILY_STRUCTURE_QUESTIONS = [
    { name: 'parents_together', label: 'Are your parents together?' },
    { name: 'parents_alive', label: 'Are your parents alive?' },
    { name: 'violence_in_house', label: 'Was there ever violence in your house as a child?' },
    { name: 'learning_difficulties', label: 'Did you have any learning difficulties?' },
    { name: 'born_prematurely', label: 'Were you born prematurely?' },
    { name: 'chronic_illnesses', label: 'Did you have any chronic illnesses under the age of 15?' },
    { name: 'family_incarcerated', label: 'Have any of your family members been incarcerated?' }
];
const CHILDREN_TABLE_HEADERS = [
    { name: 'name', label: 'Name' },
    { name: 'age', label: 'Age' },
    { name: 'gender', label: 'Gender' },
    { name: 'client_has_custody', label: 'Client has custody?' },
    { name: 'child_lives_with', label: 'Child lives with?' },
    { name: 'additional_information', label: 'Additional information' }
];

const HEIGHTS =  [
    "", "4'8\"", "4'9\"", "4'10\"", "4'11\"",
    "5'0\"", "5'1\"", "5'2\"", "5'3\"", "5'4\"", "5'5\"", "5'6\"", "5'7\"", "5'8\"", "5'9\"", "5'10\"", "5'11\"",
    "6'0\"", "6'1\"", "6'2\"", "6'3\"", "6'4\"", "6'5\"", "6'6\""
];

const CURRENT_SLEEP = [
    { name: 'no_sleep_problem', label: 'No sleep problem' },
    { name: 'cant_fall_asleep', label: 'Can\'t fall asleep' },
    { name: 'waking_often_in_night', label: 'Waking often in the night' },
    { name: 'sleep_more_than_9_hours', label: 'Sleep more than 9 hours per night' },
    { name: 'sleep_less_than_6_hours', label: 'Sleep less than 6 hours per night' }
];

export { FAMILY_STRUCTURE_QUESTIONS ,CURRENT_SLEEP,HEIGHTS,CHILDREN_TABLE_HEADERS,  FAMILY_UPBRINGING ,  STAGES_OF_CHANGE_RADIO, dropdownNumOptions ,HOUSING_OPTIONS,LIVING_ARRANGEMENT_OPTIONS, ACTIVATING_EVENTS, TREATMENT_HISTORY, CLINICAL_STATUS, PROTECTIVE_FACTORS, SUICIDAL_IDEATION_TABLE, SUICIDAL_IDEATION_HEADERS, SUICIDE_INTENSITY_TABLE, SUICIDAL_BEHAVIOR_HEADERS, SUICIDAL_BEHAVIOR_TABLE, ACTUAL_SUICIDE_ATTEMPTS_HEADERS, ACTUAL_SUICIDE_ATTEMPTS_TABLE, MSE_OPTIONS, EDUCATION_COMPLETION_ITEMS, EDUCATION_RADIO_QUESTIONS, EDUCATION_LEARNING_QUESTIONS, EMPLOYMENT_TYPE, EMPLOYMENT_SATISFACTION, UNEMPLOYMENT_DESCRIPTION, SUBSTANCES_HEADERS, SUBSTANCES, SUBSTANCE_USE_CHECKBOX, DISORDERED_EATING_QUESTIONS };