import {
  border,
  borderRadius,
  color,
  height,
  margin,
  padding,
  width,
} from "@mui/system";
import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";

const InsuranceBenefits = ({
  formData,
  handleChange,
  benefitsData,
  setBenefitsData,
  handleSubmit,
  setFormData,
  listingallPatients,
}) => {
  // const [benefitsData, setBenefitsData] = useState({
  //   familyOpp: { firstValue: "", secondValue: "", percentageResult: "0" },
  //   individualOpp: { firstValue: "", secondValue: "", percentageResult: "0" },
  //   familyDeductible: {
  //     firstValue: "",
  //     secondValue: "",
  //     percentageResult: "0",
  //   },
  //   individualDeductible: {
  //     firstValue: "",
  //     secondValue: "",
  //     percentageResult: "0",
  //   },
  // });

  const handleBenefitsData = (event) => {
    const { name, value } = event.target;

    setBenefitsData((prevValue) => {
      const [key, field] = name.split(".");

      const newValue = {
        ...prevValue,
        [key]: {
          ...prevValue[key],
          [field]: value,
        },
      };

      const firstValue = parseFloat(newValue[key].firstValue);
      const secondValue = parseFloat(newValue[key].secondValue);

      newValue[key].percentageResult = "0";

      if (!isNaN(firstValue) && !isNaN(secondValue)) {
        if (secondValue > 0 && secondValue >= firstValue) {
          const percentage = (firstValue / secondValue) * 100;
          newValue[key].percentageResult = percentage.toFixed(2);
        } else if (secondValue <= 0) {
          console.warn("secondValue must be greater than zero.");
        } else {
          console.warn("secondValue must be greater than firstValue.");
        }
      } else {
        console.warn("Please ensure both values are valid numbers.");
      }

      return newValue;
    });
  };

  // console.log(listingallPatients);
  console.log(benefitsData);
  // console.log("adgasfgasdf", listingallPatients[0].benefitsDataArray[0].percentage);
  

  return (
    <div id="treatmentPlan">
      <div className="headingTable cardPaitent">
        <div className="row d-flex mt-4">
          <div className="col-4">
            <div className="row d-flex mb-2">
              <h1 className="mb-2">Outpatient Mental Health Services</h1>
            </div>
            <br />
            <span>Individual therapy</span>
            <br />
            <br />
            <br />
            <span>Group therapy</span>
            <br />
            <br />
            <br />
            <span>Family therapy</span>
            <br />
            <br />
            <br />
            <span>Psychiatric consultations</span>
            <br />
            <br />
            <br />
            <span>Medication management</span>
            <div className="row d-flex mt-4">
              <h1 className="mb-2 mt-4">Inpatient Mental Health Services</h1>
            </div>
            <span>Inpatient psychiatric hospitalization</span>
            <div className="row d-flex mt-5">
              <h1 className="mb-1">Network Providers</h1>
            </div>
            <span>Preferred network</span>
          </div>
          <div className="col-4">
            <div className="row d-flex mb-4">
              <h1 className="mb-2">Insurance coverage</h1>
            </div>
            <span>
              Covered at
              <input
                placeholder="%"
                type="text"
                name="coverage1"
                value={formData.coverage1}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="$0"
                type="text"
                name="copayment1"
                value={formData.copayment1}
                onChange={handleChange}
                style={style}
              />
              copayment per session.
            </span>
            <br />
            <br />
            <span>
              Covered at
              <input
                placeholder="$0"
                type="text"
                name="coverage2"
                value={formData.coverage2}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="$0"
                type="text"
                name="copayment2"
                value={formData.copayment2}
                onChange={handleChange}
                style={style}
              />
              copayment per session.
            </span>
            <br />
            <br />
            <span>
              Covered at
              <input
                placeholder="$0"
                type="text"
                name="coverage3"
                value={formData.coverage3}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="$0"
                type="text"
                name="copayment3"
                value={formData.copayment3}
                onChange={handleChange}
                style={style}
              />
              copayment per session.
            </span>
            <br />
            <br />
            <span>
              Covered at{" "}
              <input
                placeholder="$0"
                type="text"
                name="coverage4"
                value={formData.coverage4}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="$0"
                type="text"
                name="copayment4"
                value={formData.copayment4}
                onChange={handleChange}
                style={style}
              />
              copayment per visit.
            </span>
            <br />
            <br />
            <span>
              Covered at
              <input
                placeholder="$0"
                type="text"
                name="coverage5"
                value={formData.coverage5}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="$0"
                type="text"
                name="copayment5"
                value={formData.copayment5}
                onChange={handleChange}
                style={style}
              />
              copayment per visit.
            </span>
            <br />
            <br />
            <br />
            <br />
            <span>
              Covered at
              <input
                placeholder="$0"
                type="text"
                name="coverage6"
                value={formData.coverage6}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="$0"
                type="text"
                name="deductible"
                value={formData.deductible}
                onChange={handleChange}
                style={style}
              />
              deductible per admission. Requires preauthorization.
            </span>
            <br />
            <br />
            <br />
            <span>
              Trustory Insurance has a network of mental health providers. Using
              in-network providers will result in lower out-of-pocket costs.
            </span>
          </div>
          <div className="col-4" style={{ background: "#F8F9FE" }}>
            <div className="row d-flex mt-4">
              <div className="col-2">
                <div className="flex-shrink-0">
                  <img
                    src="/images/Salary_Date.png"
                    alt=""
                    width={50}
                    height={50}
                  />
                </div>
              </div>
              <div className="col-10">
                <h2
                  className="mb-2"
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#5B6680",
                    lineHeight: "21px",
                  }}
                >
                  Benefits plan
                </h2>
                <h1 className="text-center">
                  {/* {listingallPatients[0]?.Inr_com_name} */}
                  Insurance Company
                </h1>
              </div>
            </div>
            {/* <h1 className="text-left">48%</h1> */}
            <h1 className="text-left">{`${listingallPatients[0].insurancebenefit.benefitsDataArray[0].percentage }%`}</h1>
            {/* <ProgressBar now={48} /> */}
            <ProgressBar now={listingallPatients[0].insurancebenefit.benefitsDataArray[0].percentage } />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder="$0"
                  type="text"
                  // name="familyOOP1"
                  name="familyOpp.firstValue"
                  // value={formData.familyOOP1}
                  value={benefitsData.familyOpp.firstValue}
                  // onChange={handleChange}
                  onChange={handleBenefitsData}
                  style={style2}
                />
                of your
                <input
                  placeholder="$0"
                  type="text"
                  // name="familyOOP2"
                  name="familyOpp.secondValue"
                  // value={formData.familyOOP2}
                  value={benefitsData.familyOpp.secondValue}
                  // onChange={handleChange}
                  onChange={handleBenefitsData}
                  style={style3}
                />
                Family Out-of-Pocket has been met for 2023
              </span>
            </div>
            {/* <h1 className="text-left">40%</h1> */}
            <h1 className="text-left">{`${listingallPatients[0].insurancebenefit.benefitsDataArray[1].percentage}%`}</h1>
            {/* <ProgressBar now={40} /> */}
            <ProgressBar now={listingallPatients[0].insurancebenefit.benefitsDataArray[1].percentage} />
            <div className="row">
              <span className="mt-4">
                <input
                  // placeholder="$0"
                  type="text"
                  // name="individualOOP1"
                  name="individualOpp.firstValue"
                  // value={formData.individualOOP1}
                  value={benefitsData.individualOpp.firstValue}
                  placeholder={listingallPatients[0].insurancebenefit.benefitsDataArray[1].firstValue}
                  // onChange={handleChange}
                  onChange={handleBenefitsData}
                  // style={style}
                  style={style2}
                />
                of your
                <input
                  placeholder="$0"
                  type="text"
                  // name="individualOOP2"
                  name="individualOpp.secondValue"
                  // value={formData.individualOOP2}
                  value={benefitsData.individualOpp.secondValue}
                  // onChange={handleChange}
                  onChange={handleBenefitsData}
                  // style={style}
                  style={style3}
                />
                Individual Out-of-Pocket Max has been met for 2023
              </span>
            </div>
            {/* <h1 className="text-left">73%</h1> */}
            <h1 className="text-left">{`${listingallPatients[0].insurancebenefit.benefitsDataArray[2].percentage}%`}</h1>
            {/* <ProgressBar now={73} /> */}
            <ProgressBar now={listingallPatients[0].insurancebenefit.benefitsDataArray[2].percentage} />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder="$0"
                  type="text"
                  // name="familyDeductible1"
                  name="familyDeductible.firstValue"
                  // value={formData.familyDeductible1}
                  value={benefitsData.familyDeductible.firstValue}
                  // onChange={handleChange}
                  onChange={handleBenefitsData}
                  // style={style}
                  style={style2}
                />
                of your
                <input
                  placeholder="$0"
                  type="text"
                  // name="familyDeductible2"
                  name="familyDeductible.secondValue"
                  // value={formData.familyDeductible2}
                  value={benefitsData.familyDeductible.secondValue}
                  // onChange={handleChange}
                  onChange={handleBenefitsData}
                  // style={style}
                  style={style3}
                />
                Family Deductible has been met for 2023
              </span>
            </div>
            {/* <h1 className="text-left">100%</h1> */}
            <h1 className="text-left">{`${listingallPatients[0].insurancebenefit.benefitsDataArray[3].percentage}%`}</h1>
            {/* <ProgressBar now={100} /> */}
            <ProgressBar
              now={listingallPatients[0].insurancebenefit.benefitsDataArray[3].percentage}
            />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder="$0"
                  type="text"
                  // name="individualDeductible1"
                  name="individualDeductible.firstValue"
                  // value={formData.individualDeductible1}
                  value={benefitsData.individualDeductible.firstValue}
                  // onChange={handleChange}
                  onChange={handleBenefitsData}
                  // style={style}
                  style={style2}
                />
                of your
                <input
                  placeholder="$0"
                  type="text"
                  // name="individualDeductible2"
                  name="individualDeductible.secondValue"
                  // value={formData.individualDeductible2}
                  value={benefitsData.individualDeductible.secondValue}
                  // onChange={handleChange}
                  onChange={handleBenefitsData}
                  // style={style}
                  style={style3}
                />
                Individual Deductible has been met for 2023
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const style = {
  border: "1px solid #ECECEC",
  padding: "2px",
  width: "27px",
  height: "27px",
  textAlign: "center",
  marginLeft: "5px",
  marginRight: "5px",
};

const style2 = {
  border: "1px solid #ccc",
  borderRadius: "5px",
  padding: "0 4px",
  marginRight: "7px",
  width: "60px",
  height: "24px",
  color: "grey",
};

const style3 = {
  border: "1px solid #ccc",
  borderRadius: "5px",
  padding: "0 4px",
  margin: "0 7px",
  width: "60px",
  height: "24px",
  color: "grey",
};

export default InsuranceBenefits;
