import {  createContext, useEffect , useState } from "react";
import { fetchDrMedications, createDrMedications , updateDrMedications} from "./Api";

export const MedicationContext  = createContext();


export const MedicationProvider = ({ children}) => {
  const [medicationdData, setMedicationdData] = useState([]);
  const [error, setError] = useState(null);


  function getMedications(){
    fetchDrMedications()
    .then((profileData) => {
      setMedicationdData(profileData.data);
      setError(null);
    })
    .catch((error) => {
      setMedicationdData([]);
      setError( error.message || "Something went wrong..");
    });

  }

  function addNewMedication(data){
    createDrMedications(data)
    .then((profileData) => {
      getMedications()
    })
    .catch((error) => {
      setError( error.message || "Something went wrong..");
    });

  }

  function updateMedication(data){
    updateDrMedications(data)
    .then((profileData) => {
      getMedications()
    })
    .catch((error) => {
      setError( error.message || "Something went wrong..");
    });

  }

 useEffect(() => {
    getMedications()
}, []);

  return (
    <MedicationContext.Provider
      value={{ getMedications , medicationdData ,  error , addNewMedication , updateMedication}}
    >
      {children}
    </MedicationContext.Provider>
  );
};
