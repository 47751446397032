import React, { useState } from "react";
import Modal from "react-modal";

const RecieptsTabTable = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <>
      <div className="col-9 mt-4">
        <div className="title-dbttracker d-flex gap-1rem justify-content-between pt-4">
          <h2>Receipts</h2>
        </div>
        <div className="rounded-3 px-3 pb-5">
          <div className="tab-content mt-4" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab">
              <div className="tableList tableList receipt-list-tbl">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">
                        Date sent
                        <img src="../../images/short-icon.svg" alt="" />{" "}
                      </th>
                      <th scope="col">
                        Reference №
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Amount requested
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Amount paid
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Due date
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Status
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {clinicalData.map((clinicalData) => (
                      <tr key={clinicalData.dateSent}>
                        <td>{clinicalData.dateSent}</td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.referenceNo}</h6>
                        </td>
                        <td>
                          <h6 className="mb-0">
                            {" "}
                            {clinicalData.amountRequested}
                          </h6>
                        </td>
                        <td>{clinicalData.amountPaid}</td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.dueDate}</h6>
                        </td>
                        <td>
                          <button
                            type="button"
                            className={`btn ${
                              clinicalData.status === "Pending"
                                ? "pendingBtn"
                                : clinicalData.status === "Paid"
                                ? "completeBtn"
                                : "Denied"
                            }`}>
                            {clinicalData.status}
                          </button>
                        </td>
                        <td>
                          <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalOpen(false)}></button>
            </div>
            <div className="modal-body pt-0 px-4">
              <div className="headingSec mb-2">
                <h1
                  className="mb-0"
                  style={{
                    color: "#18357A",
                    fontSize: "28px",
                    fontWeight: 700,
                  }}>
                  Initiate payment
                </h1>
              </div>
              <p
                className="mb-4"
                style={{
                  color: "#5B6680",
                }}>
                Select receipt(s) that require payment by patient:
              </p>
              <h1
                className="mb-2"
                style={{
                  color: "#18357A",
                  fontSize: "16px",
                  fontWeight: 700,
                }}>
                Receipt(s):
              </h1>
              <div className="input-group custominp">
                <span
                  className="input-group-text border-end-0"
                  id="basic-addon1">
                  <i className="far fa-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control ps-0 border-start-0"
                  placeholder="Search..."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div className="rounded-3 px-4 pt-2">
              <div className="tab-content mt-4" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab">
                  <div className="tableList">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th></th>
                          <th scope="col">Dates of service</th>
                          <th scope="col">Amount billed</th>
                          <th scope="col">RMK Code</th>
                          <th scope="col">Procedure code</th>
                          <th scope="col">Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <div className="d-flex col-md-auto mt-2">
                            <div className="check-box align-items-center p-0">
                              <input
                                type="checkbox"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked
                              />
                            </div>
                          </div>
                          <td>Mar 27 2023</td>
                          <td>$175.00</td>
                          <td>94</td>
                          <td>90847-95</td>
                          <td>Aug 27, 2023</td>
                        </tr>
                        <tr>
                          <div className="d-flex col-md-auto mt-2">
                            <div className="check-box align-items-center p-0">
                              <input
                                type="checkbox"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked
                              />
                            </div>
                          </div>
                          <td>Mar 27 2023</td>
                          <td>$175.00</td>
                          <td>94</td>
                          <td>90847-95</td>
                          <td>Aug 27, 2023</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-between py-2 px-4 rounded-3 mx-4"
              style={{ backgroundColor: "#F8F9FE" }}>
              {" "}
              <h1
                className="mb-2"
                style={{
                  color: "#18357A",
                  fontSize: "16px",
                  fontWeight: 700,
                }}>
                Amount total:
              </h1>
              <h1
                className="mb-2"
                style={{
                  color: "#18357A",
                  fontSize: "16px",
                  fontWeight: 700,
                }}>
                $1,100.00 (2 receipts selected)
              </h1>
            </div>
            <div className="py-2 px-4 mt-4 mx-4 add-message">
              <h1
                className="mb-2"
                style={{
                  color: "#18357A",
                  fontSize: "16px",
                  fontWeight: 700,
                }}>
                Add message:
              </h1>
              <textarea
                id="w3review"
                name="w3review"
                rows="4"
                cols="50"
                style={{
                  color: "#8F99B0",
                  fontSize: "12px",
                }}
                placeholder="Hi Charlotte,
                Attached is your invoice for the recent services provided. The total amount due is $2,300.00.
                Kindly process the payment by Feb 22, 2024. Feel free to reach out if you have any questions.
                Thanks,
                Spero Institute"></textarea>
            </div>
            <div className="col-12">
              <div className="row my-4">
                <div className="col-md-12">
                  <div className="gap-4 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-outline-secondary px-5 btnSize rounded-3"
                      onClick={() => {
                        setModalOpen(false);
                      }}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary px-5 btnSize rounded-3"
                      onClick={() => {
                        setModalOpen(false);
                      }}>
                      Initiate payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const clinicalData = [
  {
    dateSent: "Jul 27, 2023",
    referenceNo: "4875983",
    amountRequested: "$2,300.00",
    amountPaid: "$2,300.00",
    dueDate: "Aug 27, 2023",
    status: "Pending",
  },
  {
    dateSent: "Jul 27, 2023",
    referenceNo: "4875983",
    amountRequested: "$2,300.00",
    amountPaid: "$2,300.00",
    dueDate: "Aug 27, 2023",
    status: "Paid",
  },
  {
    dateSent: "Jul 27, 2023",
    referenceNo: "4875983",
    amountRequested: "$2,300.00",
    amountPaid: "$2,300.00",
    dueDate: "Aug 27, 2023",
    status: "Overdue",
  },
];

export default RecieptsTabTable;
