import React from 'react';

const OrdersDataModal = ( { ordersInfo  } ) => {
  const { selectedOrdersData, setIsOrdersModalOpen } = ordersInfo;

  if (!selectedOrdersData) {
    return null;
  }

  return (
       <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
              <button type="button" className="btn-close" onClick={() => setIsOrdersModalOpen(false)} >
              </button>
            </div>
            <div className="modal-body pt-3 pb-5 px-5">
              
              <div className='row'>

              <div className="col-md-6 mb-2">
              <div className="col-md-12 mb-2">
                <div className="d-flex align-items-center">
                  <label className="me-2">{ selectedOrdersData.name } order</label>
                </div>
              </div>
              <div className="col-md-12 mb-2">
                <div className="d-flex align-items-center">
                  <label className="me-2">Date sent:</label>
                  <p className="mb-0">{ selectedOrdersData.sendDate }</p>
                </div>
              </div>
              <div className="col-md-12 mb-2">
                <div className="d-flex align-items-center">
                  <label className="me-2">Provider:</label>
                  <p className="mb-0"><img src="images/doctor-pic.png" alt="" /> { selectedOrdersData.provider }</p>
                </div>
              </div>

              <div className="col-md-12 mb-2">
                <img src="images/order-demo.png" className='image-fluid' style={{width:"250px"}} alt="" />
              </div>
             
              </div>

              <div className="col-md-6 mb-2">
              <div className="col-md-12 mb-2">
                <div className="d-flex align-items-center">
                  <label className="me-2">{ selectedOrdersData.name } order</label>
                </div>
              </div>
              <div className="col-md-12 mb-2">
                <div className="d-flex align-items-center">
                  <label className="me-2">Date received::</label>
                  <p className="mb-0">{ selectedOrdersData.sendDate }</p>
                </div>
              </div>
              <div className="col-md-12 mb-2">
                <div className="d-flex align-items-center">
                  <label className="me-2">Recipient:</label>
                  <p className="mb-0"><img src="images/doctor-pic.png" alt="" /> { selectedOrdersData.recipient }</p>
                </div>
              </div>

              <div className="col-md-12 mb-2">
                <img src="images/result-demo.png" style={{width:"250px"}} className='image-fluid' alt="" />
              </div>
             
              </div>

              </div>

              <div className='gap-4 d-flex justify-content-center p-4'>
                <button type="button" className="btn btn-primary px-5 btnSize rounded-3" onClick={() => setIsOrdersModalOpen(false)}>Ok</button>
              </div>

            </div>
          </div>
        </div>
  );
};

export default OrdersDataModal;
