const times = [];
for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
        let formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
        const ampm = hour < 12 ? 'AM' : 'PM';
        const formattedMinute = minute.toString().padStart(2, '0');
        const formattedTime = `${formattedHour}:${formattedMinute} ${ampm}`;
        times.push(formattedTime);
    }
}

export const TimeSelectBox = ( {defaultVal , onChangeHangler , name} ) => {
    return (
        <select name={name} onChange={(e) => onChangeHangler(e)} value={defaultVal}>
            {times.map((time, index) => (
                <option key={index} value={time}>{time}</option>
            ))}
        </select>
    );
}

export const convertTo12HourFormat = (timeString) => {
    const timeRegex = /^(\d{2}):(\d{2}):(\d{2})$/;

    const match = timeString.match(timeRegex);
    if (match) {
        let [hours, minutes] = match.slice(1, 3);
        hours = parseInt(hours);
        const meridiem = hours >= 12 ? 'PM' : 'AM';
        if (hours > 12) hours -= 12;
        return `${hours}:${minutes} ${meridiem}`;
    }
    return ''; 
};