import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const RootModal = ({
  show,
  handleClose,
  title,
  children,
  isHeader = false,
  showHeaderCloseButton = true,
  footerButtons = [],
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal"
    >
      <div className="p-3">
        {isHeader && (
          <Modal.Header
            className="border-0"
            closeButton={showHeaderCloseButton}
          >
            {title && <Modal.Title>{title}</Modal.Title>}
          </Modal.Header>
        )}
        <Modal.Body className="p-4">{children}</Modal.Body>
        <Modal.Footer className="border-0 justify-content-center gap-3">
          {footerButtons.map((button, index) => (
            <Button
            className={button.classname}
              key={index}
              variant={button.variant || "secondary"}
              onClick={button.onClick || handleClose}
            >
              {button.label}
            </Button>
          ))}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default RootModal;
