import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Modal.css';
import notificationsData from '../../data/notifications.json';

// TODO: when backend is created, need to merge the notifications to be taking in the same notifications data, so when "Mark all as read" is clicked, it updates on both
const NotificationsModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [notifications, setNotifications] = useState(notificationsData);
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //TODO: needs to be get request from backend
        setNotifications(notificationsData);
      } catch (error) {
        console.error('Error setting notifications:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const updatedUnreadNotifications = notifications.filter((notification) => {
      if (notification.read === true) {
        return false;
      };
      return true;
    })
    setUnreadNotifications(updatedUnreadNotifications)
  }, [notifications]);


  //TODO: will need to send patch request to update data as read, then rerender td item to no longer be bold when item is marked as read
  const handleMarkAsRead = () => {
    //will need to change this to be a patch request to update the notifications array, rather than directly mutating it as below. currently directing mutating to show how it will rerender on frontend ui, but this will not work once a backend api is being fetched to/from
    const updatedNotifications = notificationsData.map(notification => ({
        ...notification,
        read: true
    }));
    setNotifications(updatedNotifications);
  }

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <div className='position-relative'>
      <button className="badge-button" onClick={toggleModal}>
         <img src='/images/notification.svg' alt='' />
         <span className={`position-relative translate-middle badge rounded-pill ${unreadNotifications.length !== 0 ? 'visible' : ''}`}>
            {unreadNotifications.length}
        </span>
      </button>

      {modalVisible && (
        <div className="notifications-modal translate-middle-x">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close p-0" onClick={toggleModal}>
                <span>&times;</span>
              </button>
              <div className='p-3 d-flex align-items-center'>
                <div className='p-2 pb-0'>
                  <h2 className='bold-text blue-text'>Notifications</h2>
                </div>
              </div>
              <div className='p-2 pb-0'>
                  <button className='text-button' onClick={handleMarkAsRead}>
                    Mark all as read
                  </button>
                </div>
            </div>
            <div className="modal-body">
              <ul className='px-2'>
                  {/* only show first 5 notifications, can adjust based on design needs */}
                  {notifications.slice(0, 5).map((notification, index) => (
                      <>
                      <li key={index} className='d-flex justify-content-between align-items-center py-1'>
                      <div className="d-flex align-items-center">
                          <img className='small-list-img' src={notification.imageSrc} alt='profile-icon' />
                          <div>
                              {notification.type !== 'Join Call' && (
                                  <span className={notification.read ? 'assigner-text' : 'bold-text'}>{notification.assigner}</span>
                              )}
                              <span className={`pe-1 ${notification.read ? '' : 'bold-text'}`}> {notification.notificationMessage}
                              </span>
                              <br />
                              <span className='smaller-text light-grey-text'>{notification.createdAt}</span>
                          </div>
                      </div>
                          <div>
                              {notification.type !== 'Join Call' ? (
                              //TODO: add link to details here
                              <a href='#' className='pe-1'>See details</a>
                              ) : (
                              //TODO: add link to meeting here
                              <button className="blueSubmitButton">
                                  Join now
                              </button>
                              )}
                          </div> 
                      </li>
                      <li className='horizontal-divider'></li>
                      </>
                  ))}
                </ul>
              </div>
              <div className="modal-footer">
                <Link to="/notifications" type="button" className="btn btn-secondary border-0 w-100 viewNoti" onClick={toggleModal}>View all notifications</Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsModal;