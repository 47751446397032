import React, { useState, useEffect } from "react";
import Header from "components/Header/Header";
import Modal from "react-modal";
import PatientBillingProfile from "./PatientBillingProfile";
import Correspondence from "./Correspondence";
import PaymentTab from "./PaymentTab";
import RecieptsTab from "./RecieptsTab";
import Layout from "components/layout/layout";
import AddCorrespondenceForm from "./AddCorrespondenceForm";
import {
  createInsuranceCorrespondence,
  getInsuranceCorrespondenceBypatientId,
  getAllInsuranceCorrespondence,
  assignUserGetDetails,
  usersAllPatients,
  getAllAssingPatients
} from "lib/api/service";
import toast from "react-hot-toast";

const Billing = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDeniedOpen, setModalDeniedOpen] = useState(false);
  const [activeDiv, setActiveDiv] = useState("Patient profile");
  const [isModalICOpen, setModalICOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [allInsuCorresListing, setAllInsuCorresListing] = useState([]);
  const [allInsuCorresListingById, setAllInsuCorresListingById] = useState([]);
  const [listingallPatients, setListingallPatients] = useState([]);
  const [listingallAssignPatients, setListingallAssignPatients] = useState([]);
  const [listingProfile, setlistingProfile] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userData, setUserData] = useState(null);

  const userRole = localStorage.getItem("userRole");

  const listingToUse =
    userRole === "SuperAdmin" ? allInsuCorresListing : allInsuCorresListingById;

  const listingToUseOfPatient =
    userRole === "SuperAdmin" ? listingallPatients : listingallAssignPatients;


    useEffect(() => {
      const storedData = JSON.parse(localStorage.getItem("userDetails"));
      if (storedData) {
        setUserData(storedData.rows);
      }
    }, []);
  const showDiv = (divId) => {
    setActiveDiv(divId);
  };

  // updating formData with form inputs
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked === true ? "on" : "off") : value,
    }));
  };

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const fetchData = async () => {
    try {
      const response = await usersAllPatients();
      setListingallPatients(response?.patients);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedRow]);


  const fetchDataAllAssingPatients = async () => {
    try {
      const data = {
        doctorEmail: userData?.email,
      };
      const response = await getAllAssingPatients(data);
      setListingallAssignPatients(response?.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userData?.email) {
      fetchDataAllAssingPatients();
    }
  }, [userData?.email]);

  const patient = listingProfile[0];

  // handleSubmitFromData
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = {};
      formData.forEach((value, key) => (data[key] = value));
      const patient = listingProfile[0];
      data.patientId = patient?.id;
      const response = await createInsuranceCorrespondence(data);
      setModalICOpen(false);
      toast.success(response?.message);
      setFormData({});
      await getAllInsuranceCorrespondenceListing();
      await getInsuranceBypatientId();
    } catch (error) {
      console.error(error);
    }
  };

  const getSingleViewProfile = async (id) => {
    try {
      const data = JSON.stringify({
        id: id,
        task: "patientdetails",
      });
      const response = await assignUserGetDetails(data);
      setlistingProfile(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllInsuranceCorrespondenceListing = async () => {
    try {
      const response = await getAllInsuranceCorrespondence();
      setAllInsuCorresListing(response?.insuranceCorrespondences);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllInsuranceCorrespondenceListing();
  }, []);

  const getInsuranceBypatientId = async () => {
    try {
      const data = patient?.id;
      const response = await getInsuranceCorrespondenceBypatientId(data);
      setAllInsuCorresListingById(response?.insuranceCorrespondence);
      setModalICOpen(false);
      setFormData({});
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (patient?.id) {
      getInsuranceBypatientId();
    }
  }, [patient?.id]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "-webkit-fill-available",
    },
  };

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"Billing"} />
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-3">
            <div id="clinical">
              <div className="main-content h-100">
                <div className="headingTable cardPaitent h-100">
                  <div className="headingTable mb-4 px-3">
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <h1
                          className="mb-2"
                          style={{
                            color: "#18357A",
                            fontSize: "28px",
                            fontWeight: 700,
                          }}>
                          Patients
                        </h1>
                        <div className="input-group custominp">
                          <span
                            className="input-group-text border-end-0"
                            id="basic-addon1">
                            <i className="far fa-search"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control ps-0 border-start-0"
                            placeholder="Search..."
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>

                        <div className="col-md-12">
                          <div className="rounded-3 pt-4 pb-5">
                            <div
                              className="tab-content mt-4"
                              id="pills-tabContent">
                              <div
                                className="tab-pane fade show active"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab">
                                <div className="tableList-billing">
                                  <table className="table table-borderless">
                                    <thead>
                                      <tr>
                                        <th scope="col">
                                          Patient name{" "}
                                          <img
                                            src="../../images/short-icon.svg"
                                            alt="filter"
                                          />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {listingToUseOfPatient?.map((item, index) => {
                                        return (
                                          <tr
                                            onClick={() => {
                                              handleRowClick(index);
                                              getSingleViewProfile(item.id);
                                            }}
                                            key={item.id}
                                            className={
                                              selectedRow === index
                                                ? "activeRow rounded-3"
                                                : ""
                                            }
                                            style={{
                                              border: "1px solid #E7EBF2",
                                              cursor: "pointer",
                                            }}>
                                            <td
                                              className={`text-capitalize ${
                                                selectedRow === index
                                                  ? "text-white"
                                                  : ""
                                              }`}>{`${item.firstName} ${item.lastName}`}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`col-md-9`}>
                        <div className="bg-white">
                          <div className="main-content">
                            <div className="px-3">
                              <section>
                                <div className="row">
                                  <div className="col-md-12">
                                    <ul
                                      className="nav nav-pills my-3 navTabUI"
                                      id="pills-tab"
                                      role="tablist">
                                      <li
                                        className="nav-item flex-fill rounded-start-3"
                                        role="presentation">
                                        <button
                                          className="nav-link active py-2"
                                          id="pills-single-tab"
                                          data-bs-toggle="pill"
                                          onClick={() =>
                                            showDiv("Patient profile")
                                          }
                                          data-bs-target="#pills-single"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-single"
                                          aria-selected="true">
                                          Patient profile
                                        </button>
                                      </li>
                                      <li
                                        className="nav-item flex-fill rounded-end-3"
                                        role="presentation">
                                        <button
                                          className="nav-link py-2"
                                          id="pills-reoccurring-tab"
                                          data-bs-toggle="pill"
                                          onClick={() =>
                                            showDiv("Insurance Correspondence")
                                          }
                                          data-bs-target="#pills-reoccurring"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-reoccurring"
                                          aria-selected="false">
                                          Insurance Correspondence
                                        </button>
                                      </li>
                                      <li
                                        className="nav-item flex-fill rounded-end-3"
                                        role="presentation">
                                        <button
                                          className="nav-link py-2"
                                          id="pills-reoccurring-tab"
                                          data-bs-toggle="pill"
                                          onClick={() => showDiv("Payments")}
                                          data-bs-target="#pills-reoccurring"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-reoccurring"
                                          aria-selected="false"
                                          disabled>
                                          Payments
                                        </button>
                                      </li>
                                      <li
                                        className="nav-item flex-fill rounded-end-3"
                                        role="presentation">
                                        <button
                                          className="nav-link py-2"
                                          id="pills-reoccurring-tab"
                                          data-bs-toggle="pill"
                                          onClick={() => showDiv("Reciepts")}
                                          data-bs-target="#pills-reoccurring"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-reoccurring"
                                          aria-selected="false"
                                          disabled>
                                          Reciepts
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                  {activeDiv === "Patient profile" &&
                                    listingProfile?.length > 0 && (
                                      <PatientBillingProfile listingProfile={patient} />
                                    )}
                                  {activeDiv === "Insurance Correspondence" && listingProfile?.length > 0  && (
                                      <>
                                        <div className="title-dbttracker d-flex gap-1rem justify-content-between py-4">
                                          <div>
                                            <h2>Insurance Correspondence: </h2>
                                          </div>
                                          <div
                                            className="d-flex  align-items-center"
                                            style={{
                                              padding: "0px 10px",
                                              backgroundColor: "#F8F9FE",
                                              marginRight: "10px",
                                            }}>
                                            <img
                                              src="/images/add.svg"
                                              width={16}
                                              height={16}
                                            />
                                            <h1
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: 600,
                                                color: "#4264D0",
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                              }}
                                              onClick={() =>
                                                setModalICOpen(true)
                                              }>
                                              Add Correspondence
                                            </h1>
                                          </div>
                                        </div>
                                        {
                                          listingToUse?.map(
                                            (contact, index) => {
                                              return(
                                                  <Correspondence
                                                    key={index}
                                                    date={contact.Date_Of_contact}
                                                    previousReview={
                                                      contact.Date_of_review
                                                    }
                                                    upcomingReview={
                                                      contact.date_of_next_review
                                                    }
                                                    status={contact.status}
                                                    allDetails={patient}
                                                    itemId={contact.id}
                                                    levelCareApproved={
                                                      contact.level_care_approved
                                                    }
                                                    unitsCareApprovedad={
                                                      contact.Units_care_approvedad
                                                    }
                                                    insuranceFirm={
                                                      contact.insurance_firm
                                                    }
                                                    insuranceContactNumber={
                                                      contact.insurance_contact_number
                                                    }
                                                    insuranceContactName={
                                                      contact.insurance_contact_name
                                                    }
                                                  />
                                                )
                                            }
                                          )}
                                      </>
                                    )}
                                  {activeDiv === "Payments" && <PaymentTab />}
                                  {activeDiv === "Reciepts" && <RecieptsTab />}
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          style={customStyles}
          isOpen={isModalOpen}
          onRequestClose={() => setModalOpen(false)}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalOpen(false)}></button>
              </div>
              <div
                className="modal-body pt-0 pb-5 px-4"
                style={{ width: "50%", margin: "auto" }}>
                <div className="headingSec mb-4">
                  <h1
                    className="mb-0 text-center"
                    style={{
                      color: "#18357A",
                      fontSize: "28px",
                      fontWeight: 700,
                    }}>
                    Accept patient request
                  </h1>
                </div>
                <p
                  className="mb-2"
                  style={{
                    color: "#5B6680",
                    textAlign: "center",
                  }}>
                  Do you want to accept this request? Patient will be notified
                  about request acceptance through email.
                </p>
              </div>
              <div className="col-12">
                <div className="row my-4">
                  <div className="col-md-12">
                    <div className="gap-4 d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-outline-secondary px-5 btnSize rounded-3"
                        onClick={() => {
                          setModalOpen(false);
                        }}>
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary px-5 btnSize rounded-3"
                        onClick={() => {
                          setModalOpen(false);
                        }}>
                        Confirm accept
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          style={customStyles}
          isOpen={isModalDeniedOpen}
          onRequestClose={() => setModalDeniedOpen(false)}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalDeniedOpen(false)}></button>
              </div>
              <div
                className="modal-body pt-0 pb-5 px-4"
                style={{ width: "60%", margin: "auto" }}>
                <div className="headingSec mb-4">
                  <h1
                    className="mb-0"
                    style={{
                      color: "#18357A",
                      fontSize: "28px",
                      fontWeight: 700,
                    }}>
                    Deny patient request
                  </h1>
                </div>
                <p
                  className="mb-2"
                  style={{
                    color: "#5B6680",
                  }}>
                  Patient will be notified about denial reason through email
                </p>
                <div className="headingSec mb-4">
                  <h3
                    className="mb-0 text-left"
                    style={{
                      color: "#18357A",
                      fontSize: "28px",
                      fontWeight: 700,
                    }}>
                    Denial reason:
                  </h3>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="pills-single"
                  role="tabpanel"
                  aria-labelledby="pills-single-tab">
                  <ul className="p-0 assignmentList d-grid gap-3">
                    <li className="border rounded-3 py-2 px-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-3 gap-2">
                          <p className="mb-0 pe-3">
                            {
                              "After careful consideration, it has been determined that we cannot provide treatment for individuals with OCD. We recommend guiding the patient towards specialized professionals and resources for optimal care."
                            }
                          </p>
                        </div>
                        <div className="col-md-auto"></div>
                        <div className="col-md-auto"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12">
                <div className="row my-4">
                  <div className="col-md-12">
                    <div className="gap-4 d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-outline-secondary px-5 btnSize rounded-3"
                        onClick={() => {
                          setModalDeniedOpen(false);
                        }}>
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary px-5 btnSize rounded-3"
                        onClick={() => {
                          setModalDeniedOpen(false);
                        }}>
                        Confirm Denied
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          style={customStyles}
          isOpen={isModalICOpen}
          onRequestClose={() => setModalICOpen(false)}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalICOpen(false)}></button>
              </div>
              <div className="modal-body pt-0">
                <div className="headingSec mb-2">
                  <h1
                    className="mb-0"
                    style={{
                      color: "#18357A",
                      fontSize: "28px",
                      fontWeight: 700,
                    }}>
                    Patient Profile
                  </h1>
                </div>
              </div>
              <AddCorrespondenceForm
                listingallPatients={patient}
                patientId={"patientId"}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                setFormData={setFormData}
                onCloseClick={() => setModalICOpen(false)}
              />
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

const contacts = [
  {
    date: "5/24/2024",
    previousReview: "Nov 23 2023",
    upcomingReview: "Nov 23 2023",
    status: "Pending",
  },
  {
    date: "6/10/2024",
    previousReview: "Dec 15 2023",
    upcomingReview: "Dec 15 2023",
    status: "Closed",
  },
  {
    date: "7/01/2024",
    previousReview: "Jan 10 2024",
    upcomingReview: "Jan 10 2024",
    status: "Closed",
  },
  {
    date: "8/05/2024",
    previousReview: "Feb 20 2024",
    upcomingReview: "Feb 20 2024",
    status: "Pending",
  },
];
export default Billing;
