import { useContext, useEffect, useState, useRef } from "react";
import SwitchComponent from "./SwitchComponent";
import { TimeSelectBox } from "./SelectTimeComponenets";
import toast from 'react-hot-toast';


import { ProfileContext } from '../../context/ProfileProvider';

const ProfileSettingsTab = () => {
    const { profile, getProfile, updateProfileHandler } = useContext(ProfileContext);
    const [profileData, setProfileData] = useState({});
    const [message, setMessage] = useState("");

    const inputChangeHandle = async (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? (checked ? 'on' : 'off') : value;
        setProfileData(prevData => ({
            ...prevData,
            [name]: newValue
        }));
    }

    useEffect(() => {
        setProfileData(profile?.profile || {});
    }, [profile]);

    const handleSubmit = async (event) => {
        event.preventDefault();




        try {
            profileData.task = "updateUserProfileData";
            profileData.userid = profile?.user_id;
            const newObject = convertBooleanToString(profileData);
            const profileUpdate = await updateProfileHandler(newObject);

            if (!profileUpdate?.success) {
                toast.success(profileUpdate.message || 'Profile not updated')
                await getProfile();
            } else {
                toast.success(profileUpdate.message || 'Profile not updated')

            }

        } catch (err) {
            toast.error('Profile not updated')

        }
    };

    const convertBooleanToString = (obj) => {
        for (let key in obj) {
            if (typeof obj[key] === 'boolean') {
                obj[key] = obj[key] ? "1" : "0";
            }
        }
        return obj;
    }

    return (
        <>

            {!profile?.user_id ? (
                <div>Loading...</div>
            ) : (
                <form method="POST" onSubmit={handleSubmit}>

                    <div className='cards-container'>
                        {/* left card */}
                        <div className="blue-card my-4 w-50 col">
                            <div>
                                <div className='bold-blue-text'>Profile Settings: </div>
                                <table>
                                    {/* Messages */}
                                    <tr>
                                        <th>Messages</th>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <SwitchComponent isChecked={profileData.message_is_app == 'on' ? true : false} onChangedHandeler={inputChangeHandle} name="message_is_app" />
                                            <span className='ps-1'>In-app</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Get alerts about patients and treatment team messages</td>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <SwitchComponent isChecked={profileData.message_is_email == 'on' ? true : false} onChangedHandeler={inputChangeHandle} name="message_is_email" />
                                            <span className='ps-1'>Email</span>
                                        </td>
                                    </tr>
                                    {/* Tags */}
                                    <tr className="divider"></tr>
                                    <tr>
                                        <th>Tags</th>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <SwitchComponent isChecked={profileData.tags_is_app == 'on' ? true : false} onChangedHandeler={inputChangeHandle} name="tags_is_app" />
                                            <span className='ps-1'>In-app</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Get alerts about patients and treatment team messages</td>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <SwitchComponent isChecked={profileData.tags_is_email == 'on' ? true : false} onChangedHandeler={inputChangeHandle} name="tags_is_email" />
                                            <span className='ps-1'>Email</span>
                                        </td>
                                    </tr>
                                    {/* Task Reminders */}
                                    <tr className="divider"></tr>
                                    <tr>
                                        <th>Task Reminders</th>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <SwitchComponent isChecked={profileData.task_reminders_is_app == 'on' ? true : false} onChangedHandeler={inputChangeHandle} name="task_reminders_is_app" />
                                            <span className='ps-1'>In-app</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Get alerts about new tasks assigned to you, unfinished tasks within one day of due date, and tasks that have become urgent</td>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <SwitchComponent isChecked={profileData.task_reminders_is_email == 'on' ? true : false} onChangedHandeler={inputChangeHandle} name="task_reminders_is_email" />
                                            <span className='ps-1'>Email</span>
                                        </td>
                                    </tr>
                                    {/* Upcoming Appointments */}
                                    <tr className="divider"></tr>
                                    <tr>
                                        <th>Upcoming Appointments</th>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <SwitchComponent isChecked={profileData.upcoming_appointments_is_app == 'on' ? true : false} onChangedHandeler={inputChangeHandle} name="upcoming_appointments_is_app" />
                                            <span style={{ whiteSpace: 'nowrap' }} className='ps-1'>In-app</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Get alerts about upcoming appointments 5 minutes before the start time</td>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <SwitchComponent isChecked={profileData.upcoming_appointments_is_email == 'on' ? true : false} onChangedHandeler={inputChangeHandle} name="upcoming_appointments_is_email" />
                                            <span style={{ whiteSpace: 'nowrap' }} className='ps-1'>Email</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        {/* right card */}
                        <div className="blue-card my-4 mx-3 col d-block">
                            <div className='bold-blue-text one-col-display'>
                                Working Hours:
                            </div>
                            <div className='py-1'>
                                <span>Enter information about your working hours</span>
                            </div>
                            <div className='py-2'>
                                <span className='bold-text'>Days of week:</span>
                            </div>

                            <table className='w-75'>
                                <tr>
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="is_monday"
                                            onChange={inputChangeHandle}
                                            checked={profileData?.is_monday == 'on' ? true : false}
                                            id="inlineFormCheck"

                                        />
                                        <span className='ps-1'>Monday</span>
                                    </td>
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="is_saturday"
                                            onChange={inputChangeHandle}
                                            checked={profileData?.is_saturday == 'on' ? true : false}
                                            id="inlineFormCheck"
                                        />
                                        <span className='ps-1'>Saturday</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="inlineFormCheck"
                                            name="is_tuesday"
                                            onChange={inputChangeHandle}
                                            checked={profileData?.is_tuesday == 'on' ? true : false}
                                        />
                                        <span className='ps-1'>Tuesday</span>
                                    </td>
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="inlineFormCheck"
                                            name="is_sunday"
                                            onChange={inputChangeHandle}
                                            checked={profileData?.is_sunday == 'on' ? true : false}
                                        />
                                        <span className='ps-1'>Sunday</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="inlineFormCheck"
                                            name="is_wednesday"
                                            onChange={inputChangeHandle}
                                            checked={profileData?.is_wednesday == 'on' ? true : false}
                                        />
                                        <span className='ps-1'>Wednesday</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="inlineFormCheck"
                                            name="is_thursday"
                                            onChange={inputChangeHandle}
                                            checked={profileData?.is_thursday == 'on' ? true : false}
                                        />
                                        <span className='ps-1'>Thursday</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            className="form-check-input" type="checkbox"
                                            id="inlineFormCheck"
                                            name="is_friday"
                                            onChange={inputChangeHandle}

                                            checked={profileData?.is_friday == 'on' ? true : false}
                                        />
                                        <span className='ps-1'>Friday</span>
                                    </td>
                                </tr>
                            </table>
                            <div className='py-2'>
                                <span className='bold-text'>Working hours:</span>
                            </div>
                            <div className='py-2'>
                                <span className='bold-blue-text pe-2'>From:</span>
                                <TimeSelectBox name='working_start_time' onChangeHangler={inputChangeHandle} defaultVal={profileData?.working_start_time} />
                                <span className='bold-blue-text px-2'>To:</span>
                                <TimeSelectBox name='working_end_time' onChangeHangler={inputChangeHandle} defaultVal={profileData?.working_end_time} />
                            </div>
                            <div className='py-2'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineFormCheck"
                                    name="is_nation_day_of"
                                    onChange={inputChangeHandle}
                                    checked={profileData?.is_nation_day_of == 'on' ? true : false}
                                />
                                <span className='px-1'>National holidays are off</span>
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <div className='white-rounded-footer'>
                                <hr className="whitebox-footer-divider"></hr>
                                <div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end'>
                                    <button className='ftrBtn blueSubmitButton' type="submit">Update Settings</button>
                                </div>
                            </div>

                        </div>
                        {message && (
                            <h5 className='text text-warning'>{message}</h5>
                        )}
                    </div>
                </form>

            )}




        </>
    )
}

export default ProfileSettingsTab;