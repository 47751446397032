import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import ClinicalTaskModal from '../../../../modals/Document/Indivisual/All/ClinicalTaskModal';
import { patientDocumentations } from "lib/api/service";

const ClinicalTask = () => {
  const { patientId } = useParams();
  const [clinicalData, setclinicalData] = useState([]);
  const [isClinicalTaskModalOpen, setIsClinicalTaskModalOpen] = useState(false);
  const [selectedClinicalData, setSelectedClinicalData] = useState('');

  useEffect(() => {
    if (patientId) {
      const numericPatientId = Number(patientId);
      fetchNotesById(numericPatientId);
    }
  }, [patientId]);

  const fetchNotesById = async (patientId) => {
    try {
      if (patientId) {
        const response = await patientDocumentations(patientId);
        setclinicalData(response?.documentation);
      } else {
        console.error("Patient email is not available");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  return (
    <>
      <div id="clinical">

        <div className="tab-content mt-4" id="pills-tabContent">

          <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className='tableList'>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Document Name <img src='../../images/short-icon.svg' alt='' /> </th>
                    <th scope="col">Provider <img src='../../images/short-icon.svg' alt='' /></th>
                    <th scope="col">Created <img src='../../images/short-icon.svg' alt='' /></th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>

                  {clinicalData.map(clinicalData => (
                    <tr key={clinicalData.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img src="../../images/doc-icon.png" alt="..." />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <span className='curser-pointer'
                              onClick={() => {
                                setSelectedClinicalData(clinicalData);
                                setIsClinicalTaskModalOpen(true);
                              }}
                            >
                              {clinicalData.name}
                            </span>
                          </div>
                        </div>
                      </td>
                     
                      <td><h6 className='mb-0'><img src={clinicalData.providerImage} alt='' /> {clinicalData.name}</h6></td>
                      <td>
                        <h6 className='mb-0'> {clinicalData.createdate} <br /><span>{clinicalData.createtime}</span></h6>
                      </td>

                      <td><button type="button" className={`btn ${clinicalData.status === 'Pending' ? 'pendingBtn' : clinicalData.status === 'Completed' ? 'completeBtn' : 'inProgressBtn'}`}>{clinicalData.status}</button></td>

                      <td>
                        <span>
                          <img src='../../images/edit.svg' alt='' />
                          <img src='../../images/download.svg' alt='' />
                          <img src='../../images/delete.svg' alt='' />
                        </span>
                      </td>

                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isClinicalTaskModalOpen} onRequestClose={() => setIsClinicalTaskModalOpen(false)} >

        <ClinicalTaskModal

          clinicalInfo={{
            selectedClinicalData: selectedClinicalData,
            setIsClinicalTaskModalOpen: setIsClinicalTaskModalOpen
          }}

        />

      </Modal>

    </>
  );

};

export default ClinicalTask;
