import React from 'react';

const AssessmentPatientModal = ({ closeModal }) => {
  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}>
          </button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
          <div className='headingSec'>
            <h2 className='mb-0'>Assign assessment to patient</h2>
          </div>
          <div className='d-flex mt-3 align-items-center gap-3'>
            <label>Assessment selected:</label>
            <div className="form-check ps-0">
              <img src='./images/ellipse-3.png' className='me-2' width={30} alt='' />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Isabella Hernandez
              </label>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">Patient(s):</label>
            <div className="input-group custominp w-100">
              <span className="input-group-text border-end-0" id="basic-addon1">
                <i className="far fa-search"></i>
              </span>
              <input type="text" className="form-control ps-0 border-start-0" placeholder="Search patients..." aria-label="Username" aria-describedby="basic-addon1" />
            </div>
          </div>
          <div className='patientList'>
            <ul className='p-0 gap-3 d-grid'>
              <li>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className="form-check">
                    <input className="form-check-input mt-2" type="checkbox" value="" id="flexCheckDefault" checked />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Lori Briggs
                    </label>
                  </div>
                  <span className='slected text-success'><i className="far fa-check"></i> Selected</span>
                </div>
              </li>
              <li>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className="form-check">
                    <input className="form-check-input mt-2" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Harper Adams
                    </label>
                  </div>
                </div>
              </li>
              <li>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className="form-check">
                    <input className="form-check-input mt-2" type="checkbox" value="" id="flexCheckDefault" checked />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Isabella Hernandez
                    </label>
                  </div>
                  <span className='slected text-success'><i className="far fa-check"></i> Selected</span>
                </div>
              </li>
              <li>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className="form-check">
                    <input className="form-check-input mt-2" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Mason Taylor
                    </label>
                  </div>
                </div>
              </li>
              <li>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className="form-check">
                    <input className="form-check-input mt-2" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Sophia Garcia
                    </label>
                  </div>
                </div>
              </li>
            </ul>
            <div className="gap-4 d-flex justify-content-center">
              <button type="button" className="btn btn-outline-secondary px-5 btnSize rounded-3">Cancel</button>
              <button type="button" className="btn btn-primary px-5 btnSize rounded-3">Assign assessment</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentPatientModal;
