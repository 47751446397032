import { ASSESSMENTS_TYPES } from 'Data';
import React from 'react';


interface AccessmentAddEditModalInterfece {
    setIsAddEditAssessmentModalOpen: any,
    currentAssessment: any,
    handleChange: any,
    dr_id: any,
    updateAssessments: any
}
const AccessmentAddEditModal: React.FC<AccessmentAddEditModalInterfece> = ({
    setIsAddEditAssessmentModalOpen,
    currentAssessment,
    dr_id,
    handleChange,
    updateAssessments
}) => {

    return (
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsAddEditAssessmentModalOpen(false)}>
                    </button>
                </div>
                <form className='addEditModalAssesment' onSubmit={updateAssessments}>
                    <div className="modal-body pt-0 pb-5 px-4 cardPaitent">

                        <div className='headingSec mb-3'>
                            {currentAssessment && currentAssessment.id ? (
                                <>
                                    <h2 className='mb-0'>Update assessments</h2>
                                    <input type='hidden' name="assessment_id" value={currentAssessment.id} />
                                    <input type='hidden' name="dr_id" value={currentAssessment.dr_id || dr_id} />
                                </>
                            ) : (<>
                                <h2 className='mb-0'>Add a new assessments</h2>
                                <input type='hidden' name="dr_id" value={dr_id} />


                            </>

                            )}
                        </div>


                        <div className='row mb-3 mt-3'>
                            <div className='col-md-12 mt-3'>
                                <label htmlFor="exampleFormControlInput1" className="form-label">Title: </label>
                                <input type="text" className="form-control" id="exampleFormControlInput1"
                                    name="title"
                                    required
                                    value={currentAssessment?.title}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className='row mb-3 mt-3'>
                            <div className='col-md-12'>
                                <label htmlFor="exampleFormControlInput1" className="form-label">Description: </label>
                                <input type="text" className="form-control" id="exampleFormControlInput1"
                                    name="description"
                                    required
                                    value={currentAssessment?.description}
                                    onChange={handleChange}
                                />
                            </div>



                            <div className='col-md-12  mt-3'>
                                <label htmlFor="exampleFormControlInput" className="form-label">Select type</label>
                                <select className="form-select"
                                    aria-label="Select any one"
                                    name="type"
                                    required
                                    value={currentAssessment?.type}
                                    onChange={handleChange}
                                >
                                    {ASSESSMENTS_TYPES && ASSESSMENTS_TYPES.map((item, index) => <option key={index} value={item.name}>{item.label}</option>)}
                                </select>
                            </div>



                            <div className='col-md-6  mt-3'>
                                <label htmlFor="exampleFormControlInput1" className="form-label">Status: </label>
                                <select className="form-select"
                                    aria-label="Select any one"
                                    name="statusCode"
                                    required
                                    value={currentAssessment?.statusCode}
                                    onChange={handleChange}
                                >
                                    <option value="0">Pending</option>
                                    <option value="1">In Progress</option>
                                    <option value="2">Completed</option>
                                </select>
                            </div>

                            <div className='col-md-6 mt-3'>
                                <label htmlFor="dueDate" className="form-label">Due Date: </label>
                                <input type="date" className="form-control" id="dueDate"
                                    name="dueDate"
                                    required
                                    value={currentAssessment?.dueDate}
                                    onChange={handleChange}
                                />
                            </div>



                            <div className='col-md-12 mt-5'>
                                <div className="gap-4 d-flex justify-content-center">
                                    <button type="button" className="btn btn-outline-secondary px-5 btnSize rounded-3" onClick={() => setIsAddEditAssessmentModalOpen(false)} >Cancel</button>
                                    <button type="submit" className="btn btn-primary px-5 btnSize rounded-3" >

                                        {currentAssessment && currentAssessment.id ? (
                                            <>Update assessments</>
                                        ) : (
                                            <>Add a new assessments</>
                                        )}

                                    </button></div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    );
};

export default AccessmentAddEditModal;
