import React from "react";
import Form from "react-bootstrap/Form";

interface RadioButtonProps {
  id: string;
  label: string;
  name: string;
  checked: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  props?:any
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  label,
  name,
  checked,
  value,
  onChange,
  className,
  ...props
}) => {
  return (
    <Form>
      <div className="mb-3">
        <Form.Check
          type="radio"
          {...props}
          id={id}
          className={className}
          label={label}
          checked={checked}
          value={value}
          name={name}
          onChange={onChange}
        />
      </div>
    </Form>
  );
};

export default RadioButton;
