import React from 'react';
import PasswordChecker from './PasswordChecker';

const PasswordAndSupportTab = () => {
    
    return (
        <div className='cards-container'>
            {/* left card */}
            <div className='blue-card my-4 col-7 bg-white'>
                <div className='w-75'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span className='bold-blue-text'>Password:</span>
                    </div>
                    <PasswordChecker />
                </div>
            </div>
                    
            {/* right card */}
            <div className="blue-card my-4 col-5">
                <div className='row'>
                    <img className='logo-img' src='images/support-logo.png' alt='customer-support' />
                    <div className='bold-blue-text'>Contact customer support:</div>
                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</span>
                    <ul>
                        <li>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                        </li>
                        <li>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                        </li>
                    </ul>
                
                    <a class="button" href="mailto:team@speroinstitute.com">
                        <button className='w-50 ftrBtn blueSubmitButton'>Contact Support</button>
                    </a>
                    <div className='py-2'>
                        <input 
                            className="form-check-input"
                            type="checkbox"
                            id="inlineFormCheck"
                        />
                        {/* TODO: make this checkmark functional */}
                        <span className='px-1'>Notifications will be received on main account email</span>
                        <a href='./Settings'>Change email address</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordAndSupportTab;