import Sidebar from "./Sidebar/Sidebar";

type ChildrenContainer = Pick<JSX.IntrinsicElements["div"], "children">;

function Layout({ children }: ChildrenContainer) {
  return (
    <div className="layout_container">
      <Sidebar />
      <div className="main-wrapper">
        <main>{children}</main>
      </div>
    </div>
  );
}

export default Layout;
