import React from "react";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

const BillingTabContent = () => {
  return (
    <div className="row d-flex mt-4 px-4">
      <div className="col-9">
        <div className="row justify-content-between">
          <div
            className="col-6 p-3"
            style={{
              backgroundColor: "#F5F5F7",
              borderRadius: "15px",
              width: "49%",
            }}>
            <div className="d-flex align-items-center">
              <div>
                <img src="/images/wallet.svg" alt="" />
              </div>
              <div className="mx-4">
                <p style={{ color: "#5B6680", fontSize: "16px" }}>
                  Max responsibility
                </p>
                <h2>$2,500.00</h2>
              </div>
            </div>
          </div>
          <div
            className="col-6 p-3"
            style={{
              backgroundColor: "#F5F5F7",
              borderRadius: "15px",
              width: "49%",
            }}>
            <div className="d-flex align-items-center">
              <div>
                <img src="/images/addtowallet.svg" alt="" />
              </div>
              <div className="mx-4">
                <p style={{ color: "#5B6680", fontSize: "16px" }}>
                  Max responsibility
                </p>
                <h2>$2,500.00</h2>
                <p style={{ color: "#FF6760", fontSize: "16px" }}>
                  -$1,200.00 scholarship
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between my-4">
            <div className="d-flex align-items-center justify-content-between">
              <h2>12 months</h2>
              <p className="mx-3" style={{ color: "#5B6680" }}>
                Trustory Insurance
              </p>
              <p style={{ color: "#00BB61", fontSize: "16px" }}>In network</p>
            </div>
            <div
              className="d-flex align-items-center"
              style={{
                padding: "0px 10px",
                backgroundColor: "#F8F9FE",
                marginRight: "10px",
              }}>
              <img src="/images/tabler_download.svg" width={16} height={16} />
              <h1
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#4264D0",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}>
                Download receipt
              </h1>
            </div>
          </div>
          <div className="rounded-3 px-3 pb-5">
            <div className="tab-content mt-4" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab">
                <div className="tableList">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">
                          Date
                          <img src="../../images/short-icon.svg" alt="" />{" "}
                        </th>
                        <th scope="col">
                          Amount
                          <img src="../../images/short-icon.svg" alt="" />
                        </th>
                        <th scope="col">
                          Payment date
                          <img src="../../images/short-icon.svg" alt="" />
                        </th>
                        <th scope="col">
                          Payment method
                          <img src="../../images/short-icon.svg" alt="" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clinicalData.map((clinicalData) => (
                        <tr key={clinicalData.dateSent}>
                          <td>{clinicalData.dateSent}</td>
                          <td>
                            <h6 className="mb-0"> {clinicalData.amount}</h6>
                          </td>
                          <td>
                            <h6 className="mb-0">
                              {" "}
                              {clinicalData.paymentDate}
                            </h6>
                          </td>
                          <td>{clinicalData.paymentMethod}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-3">
        <div
          className="p-3"
          style={{
            backgroundColor: "#F5F5F7",
            borderRadius: "15px",
          }}>
          <div className="d-flex align-items-center">
            <div>
              <img src="/images/Salary_Date.svg" alt="" />
            </div>
            <div className="mx-4">
              <p style={{ color: "#5B6680", fontSize: "14px" }}>Payment plan</p>
              <h2>12 months</h2>
              <p style={{ color: "#5B6680", fontSize: "12px" }}>
                24 Apr 2023 - 24 Apr 2024
              </p>
            </div>
          </div>
          <div className="row py-4 align-items-center">
            <div className="col-4">
              <p style={{ color: "#5B6680", fontSize: "12px" }}>April 2023</p>
            </div>
            <div className="col-4">
              <h4>$250.00</h4>
            </div>
            <div className="col-4">
              <p style={{ color: "#00BB61", fontSize: "12px" }}>
                <FaCheck /> Paid
              </p>
            </div>
            <div className="col-4">
              <p style={{ color: "#5B6680", fontSize: "12px" }}>October 2023</p>
            </div>
            <div className="col-4">
              <h4>$250.00</h4>
            </div>
            <div className="col-4">
              <p style={{ color: "#FF6760", fontSize: "12px" }}>
                <RxCross2 /> Unpaid
              </p>
            </div>
          </div>
          <p
            className="text-center"
            style={{ color: "#5B6680", fontSize: "12px" }}>
            Modify current plan to suite your needs.
          </p>
          <div className="text-center">
            <a
              href="#"
              className="text-center"
              style={{
                color: "#4264D0",
                textDecoration: "none",
                fontWeight: 600,
              }}>
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const clinicalData = [
  {
    dateSent: "Jul 27, 2023",
    amount: "$2,300.00",
    amountPaid: "$2,300.00",
    paymentDate: "Aug 27, 2023",
    paymentMethod: "Visa****4675",
  },
  {
    dateSent: "Jul 27, 2023",
    amount: "$2,300.00",
    amountPaid: "$2,300.00",
    paymentDate: "Aug 27, 2023",
    paymentMethod: "Master****5342",
  },
  {
    dateSent: "Jul 27, 2023",
    amount: "$2,300.00",
    amountPaid: "$2,300.00",
    paymentDate: "Aug 27, 2023",
    paymentMethod: "Visa****2957",
  },
];
export default BillingTabContent;
