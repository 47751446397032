import { Link, useNavigate, useLocation } from "react-router-dom";
import "./login.css";
import { useState } from "react";
import { FaEye, FaEyeSlash, FaCheck, FaArrowLeft } from "react-icons/fa";
import { Row, Col, Image, Form, Button } from "react-bootstrap";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isMatching, setIsMatching] = useState(true);
  const [isValid, setIsValid] = useState(false);
  let location = useLocation();
  let queryString = location.search;
  if (queryString.startsWith("?")) {
    queryString = queryString.substring(1);
  }
  const navigate = useNavigate();

  const validationRules = [
    { regex: /.{8,}/, name: "8 characters minimum" },
    { regex: /[A-Z]/, name: "Uppercase character" },
    {
      regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      name: "One special symbol",
    },
    { regex: /[0-9]/, name: "One number" },
    { regex: /[a-z]/, name: "Lowercase character" },
  ];

  const handleKeyDown = (e) => {
    const { value, selectionStart } = e.target;
    if (e.key === " " && selectionStart === 0) {
      e.preventDefault();
    }
    const precedingChar = value[selectionStart - 1];
    const followingChar = value[selectionStart];
    if (e.key === " " && (precedingChar === " " || followingChar === " ")) {
      e.preventDefault();
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChangePassword = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePasswords(newPassword, confirmPassword);
  };

  const handleChangeConfirmPassword = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePasswords(password, newConfirmPassword);
  };

  const validatePasswords = (newPassword, newConfirmPassword) => {
    let isValidPassword = true;
    for (const rule of validationRules) {
      isValidPassword = isValidPassword && rule.regex.test(newPassword);
    }
    setIsValid(isValidPassword);
    setIsMatching(newPassword === newConfirmPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/changePassword/change`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            new_password: password,
            confirm_password: confirmPassword,
            token: queryString,
          }),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        toast.success("Password change successful");
        navigate("/login");
      } else {
        toast.error(responseData.message || "Password change failed");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="vh-100 w-100 loginBglight px-3 overflow-hidden">
      <Row className="h-100">
        <Col md={5} className="h-100">
          <div className="d-flex align-content-between flex-wrap h-100">
            <Image
              onClick={() => navigate("/login")}
              src="images/logo-spero.png"
              className="ps-4 pt-3"
              alt=""
            />
            <Image src="images/login-bg.svg" className="w-100" alt="" />
          </div>
        </Col>
        <Col md={7} className="h-100 bg-white">
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="maxWidth">
              <h1 className="text-center">Reset Password</h1>
              <p className="text-center mb-5">
                Please fill in your password and confirm password.
              </p>

              <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      placeholder="Enter new password"
                      className="input_form_field"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handleChangePassword}
                      onKeyDown={handleKeyDown}
                    />
                    <p
                      type="button"
                      className="show_pass"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </p>
                  </div>
                </Form.Group>

                <div className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      placeholder="Enter confirm password"
                      type={showConfirmPassword ? "text" : "password"}
                      className="input_form_field"
                      value={confirmPassword}
                      onChange={handleChangeConfirmPassword}
                      onKeyDown={handleKeyDown}
                    />
                    <p
                      type="button"
                      className="show_pass"
                      onClick={toggleShowConfirmPassword}
                    >
                      {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div style={{ marginRight: "20px" }}>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {validationRules.slice(0, 3).map((rule, index) => (
                        <li key={index} style={{ fontSize: "12px" }}>
                          {rule.regex.test(password) ? (
                            <span style={{ color: "#00BB61" }}>
                              <FaCheck style={{ marginRight: "5px" }} />
                              {rule.name}
                            </span>
                          ) : (
                            <span style={{ color: "#8F99B0" }}>
                              • {rule.name}
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {validationRules.slice(3).map((rule, index) => (
                        <li key={index} style={{ fontSize: "12px" }}>
                          {rule.regex.test(password) ? (
                            <span style={{ color: "#00BB61" }}>
                              <FaCheck style={{ marginRight: "5px" }} />
                              {rule.name}
                            </span>
                          ) : (
                            <span style={{ color: "#8F99B0" }}>
                              • {rule.name}
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="w-100">
                  <Button
                    type="submit"
                    className="btn btn-primary w-100 mt-4 py-3"
                    disabled={!isValid || !isMatching}
                  >
                    Reset Password
                  </Button>
                </div>
                <div className="mt-5 text-center">
                  <p>
                    <span className="px-2">
                      <FaArrowLeft />
                    </span>
                    Back to <Link to="/login">Log in</Link>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
