import React from 'react';
import { CheckboxInput, TextInput, TextAreaInput, Dropdown, DateInput, RadioButtons } from '../../../../components/FormComponents/FormComponents';
import {  ACTIVATING_EVENTS, TREATMENT_HISTORY, CLINICAL_STATUS, PROTECTIVE_FACTORS, SUICIDAL_IDEATION_TABLE, SUICIDAL_IDEATION_HEADERS, SUICIDE_INTENSITY_TABLE, SUICIDAL_BEHAVIOR_HEADERS, SUICIDAL_BEHAVIOR_TABLE, ACTUAL_SUICIDE_ATTEMPTS_HEADERS, ACTUAL_SUICIDE_ATTEMPTS_TABLE, dropdownNumOptions } from '../../../../const/PatientIntakeConst';


import { PatientIntakeCustomTable } from '../PatientIntakeCustomTable';
import { SUICIDAL_BEHAVIOR , SUICIDAL_IDEATION } from '../../../../Data';



const NewPatientPage3 = ({ formData, handleChange, handleSubmit , handleGoBack, showFooter = true  }) => {

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Instructions:</label>
                    <span> Check all risk and protective factors that apply. To be completed following the patient interview, review of medical record(s) and/or consultation with family members and/or other professionals.</span>
                </div>
                <div className='row form-group'>
                <div className='horizontal-divider'></div>
                    <div className='col-6 vertical-divider'>
                        <div className='checkbox-container'>
                            <label>Suicidal and Self-Injury Behavior (Past week)</label>
                        </div>
                        <div className='horizontal-divider row'></div>
                        {SUICIDAL_BEHAVIOR.map((behavior, index) => {
                            return (
                                <div className='row d-flex justify-content-between'>
                                    <div key={index} className='col-6 checkbox-container'>
                                        <CheckboxInput
                                            id={`pastWeek${behavior.label}`}
                                            name={`pastWeek${behavior.name}`}
                                            label={behavior.label}
                                            checked={formData[`pastWeek${behavior.name}`]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='col-6 checkbox-container'>
                                        <CheckboxInput
                                            id={`lifetime${behavior.label}`}
                                            name={`lifetime${behavior.name}`}
                                            label='Lifetime'
                                            checked={formData[`lifetime${behavior.name}`]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        <div className='horizontal-divider row'></div>
                        <div className='checkbox-container'>
                            <label>Suicidal Ideation (Most Severe in Past week)</label>
                        </div>
                        <div className='horizontal-divider row'></div>
                        {SUICIDAL_IDEATION.map((behavior, index) => {
                            return (
                                    <div key={index} className='row checkbox-container'>
                                        <CheckboxInput
                                            id={`pastWeek${behavior.label}`}
                                            name={`pastWeek${behavior.name}`}
                                            label={behavior.label}
                                            checked={formData[`pastWeek${behavior.name}`]}
                                            onChange={handleChange}
                                        />
                                    </div>
                            )
                        })}
                        <div className='horizontal-divider row'></div>
                        <div className='checkbox-container'>
                            <label>Activating Events (Recent)</label>
                        </div>
                        <div className='horizontal-divider row'></div>
                        {ACTIVATING_EVENTS.map((event, index) => {
                            return (
                                <div>
                                    <div key={index} className='row checkbox-container'>
                                        <CheckboxInput
                                            id={event.name}
                                            name={event.name}
                                            label={event.label}
                                            checked={formData[`${event.name}`]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {formData['Recent loss or other significant negative event'] === true && index === 0 && (
                                        <div className='row checkbox-container' style={{marginBottom: '2em'}}>
                                        <TextInput
                                            id="recentNegativeEventDetails"
                                            name="recentNegativeEventDetails"
                                            value={formData.recentNegativeEventDetails}
                                            onChange={handleChange}
                                            placeholder="Describe:"
                                            />
                                    </div>
                                    )}
                                </div>
                            )
                        })}
                        <div className='horizontal-divider row'></div>
                        <div className='checkbox-container'>
                            <label>Treatment History</label>
                        </div>
                        <div className='horizontal-divider row'></div>
                            {TREATMENT_HISTORY.map((treatment, index) => {
                                return (
                                    <div key={index} className='row checkbox-container'>
                                        <CheckboxInput
                                            id={treatment.name}
                                            name={treatment.name}
                                            label={treatment.label}
                                            checked={formData[`${treatment.name}`]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )
                            })}
                        <div className='horizontal-divider row'></div>                  
                        <div className='checkbox-container'>
                            <label>Other Risk Factors</label>
                        </div>
                        <div className='horizontal-divider row'></div>
                        <div className='row checkbox-container'>
                            <CheckboxInput
                                id='otherRiskFactors'
                                name='otherRiskFactors'
                                label='Other'
                                checked={formData.otherRiskFactors}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='row checkbox-container'>
                            <TextAreaInput
                                id="otherRiskFactorsDetails"
                                name="otherRiskFactorsDetails"
                                value={formData.otherRiskFactorsDetails}
                                onChange={handleChange}
                                placeholder="Describe:"
                            />
                        </div>
                    </div>
                    
                    <div className='col-6 vertical-divider end-divider'>
                        <div className='checkbox-container'>
                            <label>Clinical Status (Recent)</label>
                        </div>
                        <div className='horizontal-divider row'></div>
                        {CLINICAL_STATUS.map((clinicalStatus, index) => {
                            return (
                                <div key={index} className='row checkbox-container'>
                                    <CheckboxInput
                                        id={clinicalStatus.name}
                                        name={clinicalStatus.name}
                                        label={clinicalStatus.label}
                                        checked={formData[`${clinicalStatus.name}`]}
                                        onChange={handleChange}
                                    />
                                </div>
                            )
                        })}
                        <div className='horizontal-divider row'></div>
                        <div className='checkbox-container'>
                            <label>Protective Factors (Recent)</label>
                        </div>
                        <div className='horizontal-divider row'></div>
                        {PROTECTIVE_FACTORS.map((protectiveFactor, index) => {
                            return (
                                <div key={index} className='row checkbox-container'>
                                    <CheckboxInput
                                        id={protectiveFactor.name}
                                        name={protectiveFactor.name}
                                        label={protectiveFactor.label}
                                        checked={formData[`${protectiveFactor.name}`]}
                                        onChange={handleChange}
                                    />
                                </div> 
                            )
                        })}

                        <div className='checkbox-container'>
                            <label>Other Protective Factors</label>
                        </div>
                        <div className='horizontal-divider row'></div>
                        <div className='row checkbox-container'>
                            <CheckboxInput
                                id='otherProtectiveFactors'
                                name='otherProtectiveFactorsDetails'
                                label='Other'
                                checked={formData.otherProtectiveFactorsDetails}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='row checkbox-container' style={{marginBottom: '2em'}}>
                            <TextInput
                                id="suicidal_aggressive_behavior_details"
                                name="suicidal_aggressive_behavior_details"
                                value={formData.suicidal_aggressive_behavior_details}
                                onChange={handleChange}
                                placeholder="Describe:"
                            />
                        </div>
                    </div>

                    <div className='horizontal-divider'></div>

                    <div className='vertical-divider end-divider'>
                        <label>Describe any suicidal, self-injury or aggressive behavior (include dates):</label>
                        <TextInput
                            id="other_protectivefactors_details"
                            name="other_protectivefactors_details"
                            value={formData.other_protectivefactors_details}
                            onChange={handleChange}
                            placeholder="Describe:"
                        />
                        
                        <div>
                            <h3 className='pt-2'>Suicidal Ideation</h3>
                            <PatientIntakeCustomTable 
                                headers={SUICIDAL_IDEATION_HEADERS}
                                rowData={SUICIDAL_IDEATION_TABLE}
                                formData={formData}
                                handleChange={handleChange}
                                secondColumn='radio'
                                thirdColumn='radio'
                                tableName='suicideIdeationTable'
                            />
                            <h3 className='pt-2'>Intensity of Ideation</h3>
                            <table className='custom-table'>
                                <tbody>
                                    <tr>
                                        <td className='custom-table w-75' >
                                            <div>
                                                <span>The following features should be rated with respect to the most severe type of ideation (i.e., 1-5 from above, with 1 being the least severe and 5 being the most severe). Ask about time he/she was feeling the most suicidal.
                                                </span>
                                            </div>
                                            <div>
                                                <label>Lifetime</label>
                                                <div className='intake-dropdown'>
                                                    <Dropdown 
                                                        name='lifetimeSevereIdeationScore'
                                                        options={dropdownNumOptions[0]}
                                                        value={formData.lifetimeSevereIdeationScore}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <TextInput
                                                    id='lifetimeSevereIdeationDescription'
                                                    name='lifetimeSevereIdeationDescription'
                                                    value={formData.lifetimeSevereIdeationDescription}
                                                    onChange={handleChange}
                                                    placeholder={'Description of ideation'}
                                                />
                                                <label>Recent</label>
                                                <div className='intake-dropdown'>
                                                    <Dropdown 
                                                        name='recentSevereIdeationScore'
                                                        options={dropdownNumOptions[0]}
                                                        value={formData.recentSevereIdeationScore}
                                                        onChange={handleChange}
                                                        className='intake-dropdown'
                                                    />
                                                </div>
                                                <TextInput
                                                    id='recentSevereIdeationDescription'
                                                    name='recentSevereIdeationDescription'
                                                    value={formData.recentSevereIdeationDescription}
                                                    onChange={handleChange}
                                                    placeholder={'Description of ideation'}
                                                />
                                            </div>
                                        </td>
                                        <td className='custom-table' >
                                            <label>Most Severe</label>
                                        </td>
                                        <td className='custom-table'>
                                            <label>Most Severe</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <PatientIntakeCustomTable rowData={SUICIDE_INTENSITY_TABLE} handleChange={handleChange} formData={formData} secondColumn='dropdown' thirdColumn='dropdown' />
                            <h3 className='pt-2'>Suicidal Behavior</h3>
                            <table className='custom-table'>
                                <thead>
                                    <tr>
                                    {SUICIDAL_BEHAVIOR_HEADERS.map((header, index) => (
                                        <th key={index}>
                                            {index !== 0 ? (
                                                <label>{header}</label>
                                                ) : (
                                                <span>{header}</span>
                                                )
                                            }
                                        </th>
                                    ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='custom-table-border w-75'>
                                            <label>Actual Attempt</label>
                                            <div><span>A potentially self-injurious act committed with at least some wish to die, as a result of act. Behavior was in part thought of as method to kill oneself. Intent does not have to be 100%. If there is any intent/desire to die associated with the act, then it can be considered an actual suicide attempt. There does not have to be any injury or harm, just the potential for injury or harm. If person pulls trigger while gun is in mouth but gun is broken so no injury results, this is considered an attempt. Inferring Intent: Even if an individual denies intent/wish to die, it may be inferred clinically from the behavior or circumstances. For example, a highly lethal act that is clearly not an accident so no other intent but suicide can be inferred (e.g., gunshot to head, jumping from window of a high floor/story). Also, if someone denies intent to die, but they thought that what they did could be lethal, intent may be inferred.</span></div>
                                            <div><span><strong>
                                                Have you made a suicide attempt?
                                                </strong></span>
                                            </div>
                                        </td>
                                        <td className='custom-table-border align-bottom' >
                                                <div>
                                                <RadioButtons name='lifetimeActualAttempt' formData={formData} handleChange={handleChange} />
                                                </div>
                                        </td>
                                        <td className='custom-table-border align-bottom'>
                                                <div>
                                                <RadioButtons name='past3MonthsActualAttempt' formData={formData} handleChange={handleChange} />
                                                </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='custom-table-border  w-75'>
                                            <span><strong>
                                                Have you done anything to harm yourself?
                                            </strong></span>
                                        </td>
                                        <td className='custom-table-border align-bottom' >
                                            <div>
                                                <RadioButtons name='lifetimeSelfHarm' formData={formData} handleChange={handleChange} />
                                            </div>
                                        </td>
                                        <td className='custom-table-border align-bottom'>
                                            <div>
                                                <RadioButtons name='past3MonthsSelfHarm' formData={formData} handleChange={handleChange} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='custom-table-border w-75'>
                                            <span><strong>
                                            Have you done anything dangerous where you could have died?
                                            </strong></span>
                                        </td>
                                        <td className='custom-table-border align-bottom'>
                                            <div>
                                                <RadioButtons name='lifetimeDangerousActivity' formData={formData} handleChange={handleChange} />
                                            </div>
                                        </td>
                                        <td className='custom-table-border align-bottom'>
                                            <div>
                                                <RadioButtons name='past3MonthsDangerousActivity' formData={formData} handleChange={handleChange} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='custom-table-border w-75'>
                                            <div className='ps-2'><span><strong>
                                                What did you do?
                                                </strong></span>
                                            </div>
                                            <div className='ps-2'><span><strong>
                                                Did you... as a way to end your life?
                                                </strong></span>
                                            </div>
                                            <div className='ps-2'><span><strong>
                                                Did you want to die (even a little) when you
                                            </strong></span></div>
                                            <div className='ps-2'><span><strong>
                                                Were you trying to end your life when you
                                            </strong></span></div>
                                            <div className='ps-2'><span><strong>
                                                Or did you think it was possible you could have died from
                                            </strong></span></div>
                                            <div><span><strong>
                                                Or did you do it purely for other reasons / without ANY intention of killing yourself (like to relieve stress, feel better, get sympathy, or get something else to happen)?</strong></span>
                                            </div>
                                            <div><span>(Self-Injurious Behavior without suicidal intent)</span></div>
                                            <div>
                                                <div><p>If yes, describe:</p></div>
                                                <div>
                                                    <TextInput
                                                        id='actualSuicideAttemptDescription'
                                                        name='actualSuicideAttemptDescription'
                                                        value={formData.actualSuicideAttemptDescription}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>    
                                        </td>
                                        <td className='custom-table-border align-bottom' >
                                            <div>  
                                                <RadioButtons name='lifetimeOtherSuicideReasonWithoutIntentionOfSuicide'  formData={formData} handleChange={handleChange} />
                                                <TextInput
                                                    id='lifetimeTotalSuicideAttempts' 
                                                    name='lifetimeTotalSuicideAttempts'
                                                    value={formData.lifetimeTotalSuicideAttempts}
                                                    onChange={handleChange}
                                                    label='Total Number of Attempts'
                                                />
                                            </div>
                                        </td>
                                        <td className='custom-table-border align-bottom'>
                                            <div>
                                                <RadioButtons name='past3MonthsOtherSuicideReasonWithoutIntentionOfSuicide' formData={formData} handleChange={handleChange} />
                                                <TextInput
                                                    id='past3MonthsTotalSuicideAttempts' 
                                                    name='past3MonthsTotalSuicideAttempts' 
                                                    value={formData.past3MonthsTotalSuicideAttempts}
                                                    onChange={handleChange}
                                                    label='Total Number of Attempts'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='custom-table-border w-75'>
                                            <span><strong>
                                            Has subject engaged in Non-Suicidal Self-Injurious Behaviour?
                                            </strong></span>
                                        </td>
                                        <td className='custom-table-border align-bottom' >
                                            <div>
                                                <RadioButtons name='lifetimeNonSuicidalSelfInjury' formData={formData} handleChange={handleChange} />
                                            </div>
                                        </td>
                                        <td className='custom-table-border custom-table align-bottom'>
                                            <div>
                                                <RadioButtons name='past3MonthsNonSuicidalSelfInjury' formData={formData} handleChange={handleChange} />
                                            </div>
                                        </td>
                                    </tr>
                                    {SUICIDAL_BEHAVIOR_TABLE.map((row, index) => (
                                        <tr key={index}>
                                            <td className='w-75' >
                                                <label>{row.label}</label>
                                                <div><span>{row.text}</span></div>
                                                {typeof row.boldText === 'string' ? (
                                                    <div><span><strong>{row.boldText}</strong></span></div>
                                                ) : typeof row.boldText === 'object' ? (
                                                    row.boldText.map((text) => {
                                                        return (
                                                            <div><span><strong>{text}</strong></span></div>
                                                        ) 
                                                    })
                                                ) : (
                                                    <></>
                                                )
                                                }
                                                <div>
                                                    <div><p>If yes, describe:</p></div>
                                                    <div>
                                                        <TextInput
                                                            id={row.label}
                                                            name={row.name}
                                                            value={formData[row.name]}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div> 
                                                        
                                            </td>
                                            
                                            <td className='align-bottom' >
                                                <div>
                                                <RadioButtons name={`lifetimes_${row.name}`} formData={formData} handleChange={handleChange} />
                                                <TextInput
                                                    id={`lifetime_${row.label}`}
                                                    name={`lifetime_${row.name}`}
                                                    value={formData[`lifetime_${row.name}`]}
                                                    onChange={handleChange}
                                                    label='Total Number of Attempts'
                                                />
                                                </div>
                                            </td>
                                            
                                            <td className='align-bottom'>
                                                <div>
                                                    <RadioButtons name={`past_month_${row.name}`} formData={formData} handleChange={handleChange} />
                                                    <TextInput
                                                        id={`past_months_${row.name}`}
                                                        name={`past_months_${row.name}`}
                                                        value={formData[`past_months_${row.name}`]}
                                                        onChange={handleChange}
                                                        label='Total Number of Attempts'
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className='custom-table-border w-75'>
                                            <label>Suicidal Behavior:</label>
                                            <div>
                                                <span>
                                                Suicidal Behavior was present during the assessment period?
                                                </span>
                                            </div>
                                        </td>
                                        <td className='custom-table-border align-bottom' >
                                            <div>
                                                <RadioButtons name='lifetimeCurrentSuicidalBehavior' formData={formData} handleChange={handleChange} />
                                            </div>
                                        </td>
                                        <td className='custom-table-border align-bottom'>
                                            <div>
                                                <RadioButtons name='past3MonthsCurrentSuicidalBehavior' formData={formData} handleChange={handleChange} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='custom-table wider-first-column'>
                                <thead>
                                    <tr>
                                        {ACTUAL_SUICIDE_ATTEMPTS_HEADERS.map((header, index) => (
                                            <th key={index}>
                                                <label>{header.label}</label>
                                                {index !== 0 && (
                                                    <DateInput name={`date_${header.name}`} value={formData[`date_${header.name}`]} onChange={handleChange} />
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {ACTUAL_SUICIDE_ATTEMPTS_TABLE.map((row, index) => (
                                        <tr key={index}>
                                            <td>
                                                {index !== 0 && (
                                                    <label>{row.label}</label>
                                                )}
                                                <div><span>{row.text}</span></div>
                                                {typeof row.boldText === 'string' ? (
                                                    <div><span><strong>{row.boldText}</strong></span></div>
                                                ) : typeof row.boldText === 'object' ? (
                                                    row.boldText.map((text) => {
                                                        return (
                                                            <div><span><strong>{text}</strong></span></div>
                                                        )
                                                        
                                                    })
                                                ) : null
                                                }
                                            </td>
                                            <td>
                                                <div className='intake-dropdown'>
                                                    <Dropdown 
                                                        name={`most_recent_attempt_${row.name}`}
                                                        options={index === 0 ? (
                                                            dropdownNumOptions[0]
                                                        ) : (
                                                            dropdownNumOptions[1]
                                                        )}
                                                        value={formData[`most_recent_attempt_${row.name}`]}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='intake-dropdown'>
                                                    <Dropdown 
                                                        name={`most_lethal_attempt_${row.name}`}
                                                        options={index === 0 ? (
                                                            dropdownNumOptions[0]
                                                        ) : (
                                                            dropdownNumOptions[1]
                                                        )}
                                                        value={formData[`most_lethal_attempt_${row.name}`]}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                            <div className='intake-dropdown'>
                                                    <Dropdown 
                                                        name={`initial_first_attempt_${row.name}`}
                                                        options={index === 0 ? (
                                                            dropdownNumOptions[0]
                                                        ) : (
                                                            dropdownNumOptions[1]
                                                        )}
                                                        value={formData[`initial_first_attempt_${row.name}`]}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <span>* The following questions are not part of the assessment, for clinician’s reference only.</span>
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            What is the current Level of Care for this patient?
                        </label>
                        <div className="d-flex align-items-center">
                            <TextInput
                                id='currentLevelOfCare'
                                name='currentLevelOfCare'
                                value={formData.currentLevelOfCare}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Is the patient improving at this current Level of Care?
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='patientImprovingAtLevelOfCare' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Are we seeing this patient immediately following an attempt?
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='seeingPatientImmediatelyFollowingSuicideAttempt' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                </div>

                <div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                   {showFooter &&(<div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center'>
                        <button 
                            className='btn btn-primary px-5 btnSize rounded-3 mx-4'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button 
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>)}
                </div> 
            </form>
        </div>
    );
};

export default NewPatientPage3;
