import { Routes, Route, useMatch } from 'react-router-dom';
import PrivateRoute from 'components/atoms/PrivateRoute';
import DirectCallMain from "../DirectCallMain";

const DirectCallFullScreen = () => {
  const match = useMatch("");

  return (
    <Routes>
      <PrivateRoute path={match} element={<DirectCallMain />} />
    </Routes>
  );
};

export default DirectCallFullScreen;
