import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import PsychotherapyModal from '../../../../../modals/Document/Group/Active/All/PsychotherapyModal';
const Psychotherapy = () => {

  const psychotherapyData = [
    {
      name: "LoBri_Nov28psych",
      date: "Jul 20, 2023",
      time: "2:02PM",
      duration: "1h 45min",
      provider:"Dr. Alvares",
      providerImage:"../images/doctor-pic.png",
      notes: "Patient participated in DBT group, patient showed distress during process when other..."
    },
    {
      name: "LoBri_Nov28psych",
      date: "Mar 2, 2023",
      time: "3:02PM",
      duration: "3h 45min",
      provider:"Dr. Johnes",
      providerImage:"../images/doctor-pic.png",
      notes: "Participated in DBT group, patient showed distress during process when other..."
    },
  ];  

  const [ isPsychotherapyModalOpen, setIsPsychotherapyModalOpen ] = useState(false);
  const [selectedPsychotherapyData, setSelectedPsychotherapyData ] = useState('');

                return (
                  <>
                    <div id="psychotherapy">
                      <div className="tab-content mt-4" id="pills-tabContent">

                        <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                          <div className='tableList'>
                            <table className="table table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col">Note name</th>
                                  <th scope="col"></th>
                                  <th scope="col">Provider <img src='images/short-icon.svg' alt='' /></th>
                                  <th scope="col">Created <img src='images/short-icon.svg' alt='' /></th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                               
                              {psychotherapyData.map( psychotherapy => ( 
                                <tr>
                                   <td>
                                    <div className='d-flex'>
                                     <img src='images/note.svg' alt='' />
                                     <div className='ms-3'>
                                     <p className='mb-0'>
                                     {psychotherapy.name }<br/> <br/>
                                    {psychotherapy.notes}</p>
                                    <Link className="text-decoration-none moreDetails" 
                                    onClick={() => {
                                      setSelectedPsychotherapyData( psychotherapy );
                                      setIsPsychotherapyModalOpen(true);
                                    }}
                                    >See more</Link>
                                     </div>
                                    </div>
                                  </td>
                                  <td></td>
                                  <td>
                                    <h6 className='mb-0'><img src={psychotherapy.providerImage} alt='' />{psychotherapy.provider}</h6>
                                  </td>

                                  <td>
                                    {psychotherapy.date} <br /><span>{psychotherapy.time}</span>
                                  </td>

                                  <td>
                                    <img src='../images/edit.svg' alt='' />
                                    <img src='../images/delete.svg' alt='' />
                                  </td>
                                  
                                 
                                </tr>
                              ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Modal isOpen={isPsychotherapyModalOpen} onRequestClose={() => setIsPsychotherapyModalOpen(false)} >
                      <PsychotherapyModal                      psychotherapyDetailInfo={{
                        selectedPsychotherapyData: selectedPsychotherapyData,
                        setIsPsychotherapyModalOpen: setIsPsychotherapyModalOpen
                      }}
                      />
                    </Modal>

</>
  );

};

export default Psychotherapy;

