// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_therapist {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #18357a !important;
}

.text_data {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Patients/Requests/request.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;AAC9B","sourcesContent":[".title_therapist {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 16px;\n  color: #18357a !important;\n}\n\n.text_data {\n  font-size: 14px !important;\n  font-weight: 400 !important;\n  line-height: 21px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
