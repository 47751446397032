// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/bg-folder.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgFolder {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 48px 20px 20px 20px;
    height: 232px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Document/Document.css"],"names":[],"mappings":"AAAA;IACI,yDAA2D;IAC3D,4BAA4B;IAC5B,qBAAqB;IACrB,4BAA4B;IAC5B,aAAa;AACjB","sourcesContent":[".bgFolder {\n    background-image: url(../../../public/images/bg-folder.svg);\n    background-repeat: no-repeat;\n    background-size: 100%;\n    padding: 48px 20px 20px 20px;\n    height: 232px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
