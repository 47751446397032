import { Routes, Route, Navigate } from "react-router-dom";
import DirectCallFullScreen from "./components/pages/DirectCallFullScreen";

const DirectCallApp = () => {
    return (
        <Routes>
            <Route path="/full-screen" element={<DirectCallFullScreen />} />
            <Route path="*" element={<Navigate to="/full-screen" />} />
        </Routes>
    );
}

export default DirectCallApp;
