import React, { useState, useEffect, useContext, useMemo, useLayoutEffect } from 'react';
import './Patients.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { ProfileContext } from '../../context/ProfileProvider';
import { useSbCalls } from 'pages/lib/sendbird-calls';

import * as mixins from '../../styles/mixins'
import styled from "styled-components";
import { media } from "utils";
import Screen from "components/templates/Screen";
import CallView from "components/views/CallView";
import Overlay from "components/atoms/Overlay";
import { PatientIntakContext } from "context/PatientIntakeProvider";
import { getUserDetail } from "utils/helpers";
import Layout from "components/layout/layout";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding-bottom: 55px; // TabToolbar height
  ${media.main} {
    padding-bottom: 0;
  }
`;

const Contents = styled(Screen)`
  ${mixins.flexCenter};
  flex-direction: column;
  height: calc(100% - 80px - 57px);
  ${media.main} {
    height: calc(100% - 48px - 56px);
  }
`;

const Patients = () => {
  const { profile } = useContext(ProfileContext);
  const { patients, AllPatients } = useContext(PatientIntakContext);
  const sbCall = useSbCalls();
  const { isAuthenticated, calls } = useSbCalls();

  const onCall = useMemo(() => {
    return calls.find((call) => call.isOngoing);
  }, [calls]);

  const getCallOption = (callOption) => {
    return {
      localMediaView: undefined,
      remoteMediaView: undefined,
      videoEnabled: true,
      audioEnabled: true,
      ...callOption,
    };
  };

  const videoCallView = async (userId, name) => {
    let checkUser = await getUserDetail(userId);
    // if (!checkUser) {
    //   toast.error("User account not found in sendbird please make first account this user than connect");
    //   await createSendBirdUser(userId, name);

    //   return false;
    // } else if (!checkUser.is_online) {
    //   toast.error("User is offline");
    //   return false;
    // } else {

    // }

    // Open the popup window with specified URL and window features
    // let checkUser;
    // try {
    //   checkUser = await getUserDetail(userId);
    //   if (!checkUser) {
    //     await createSendBirdUser(userId, name);
    //   }
    // } catch (error) {
    //   await createSendBirdUser(userId, name);
    // }
    sbCall.dial({ userId, isVideoCall: true, callOption: getCallOption({}) });
  };

  useEffect(() => {
    if (profile?.user_id) {
      AllPatients(profile?.user_id);
    }
  }, [profile?.user_id]);

  return (
    <>
      {onCall ? (
        <Wrapper>
          <Contents className="cardInside">
            <Overlay>
              <CallView call={onCall} />
            </Overlay>
          </Contents>
        </Wrapper>
      ) : (
        <Layout>
          <div className="vh-100 w-100 mainBglight">
            <Header pageTitle={"Patients"} />
            <div className="main-content">
              <div className="px-3">
                <div className="row row-gap-4">
                  {patients &&
                    patients.map((patient) => (
                      <div className="col-lg-4 mt-4" key={patient.id}>
                        <div className="bg-white rounded-3 p-3 cardPaitent">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <Link
                                to={`/profile/${patient.id}`}
                                className="text-decoration-none"
                              >
                                {patient.imageSrc ? (
                                  <img
                                    src={patient.imageSrc}
                                    alt=""
                                    className="rounded-circle"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                ) : (
                                  <div
                                    className="rounded-circle text-white d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      fontSize: "24px",
                                      backgroundColor: "#3F4A9C",
                                    }}
                                  >
                                    {patient.patientName[0]}
                                  </div>
                                )}
                              </Link>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="d-flex justify-content-between">
                                <Link
                                  to={`/profile/${patient.id}`}
                                  className="text-decoration-none"
                                >
                                  <h4>{patient.patientName}</h4>
                                </Link>
                                <i className="fas fa-ellipsis-h"></i>
                              </div>
                              <div className="row row-cols-2">
                                <div className="col">
                                  <label>
                                    Patient №: <span>PAT00{patient.id}</span>
                                  </label>
                                </div>
                                <div className="col">
                                  <label>
                                    Gender: <span>{patient.gender}</span>
                                  </label>
                                </div>
                                <div className="col">
                                  <label>
                                    Age: <span>{patient.age}</span>
                                  </label>
                                </div>
                                <div className="col">
                                  <label>
                                    DOB: <span>{patient.DOB}</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <div className="d-flex align-items-center">
                              <label className="me-2">Psychiatrist: </label>{" "}
                              <h6 className="mb-0">
                                <img src="images/doctor-pic.png" alt="" />{" "}
                                {patient.psychiatrist}
                              </h6>{" "}
                              <span className="ms-2 date">
                                {" "}
                                | {patient.psychiatristDate}
                              </span>
                            </div>
                            <p className="mb-0">{patient.psychiatristNote}</p>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="d-flex align-items-center">
                              <label className="me-2">
                                Primary therapist:{" "}
                              </label>
                              <h6 className="mb-0">
                                <img src="images/doctor-pic.png" alt="" />{" "}
                                {patient.primaryTherapist}
                              </h6>{" "}
                              <span className="ms-2 date">
                                {" "}
                                | {patient.primaryTherapistDate}
                              </span>
                            </div>
                            <p className="mb-0">
                              {patient.primaryTherapistNote}
                            </p>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="d-flex align-items-center">
                              <label className="me-2">Group therapist: </label>
                              <h6 className="mb-0">
                                <img src="images/doctor-pic.png" alt="" />{" "}
                                {patient.groupTherapist}
                              </h6>{" "}
                              <span className="ms-2 date">
                                {" "}
                                | {patient.groupTherapistDate}
                              </span>
                            </div>
                            <p className="mb-0">{patient.groupTherapistNote}</p>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="d-flex align-items-center">
                              <label className="me-2">ICD-10 Codes: </label>
                            </div>
                            <p className="mb-0">{patient.icdCodes} </p>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="d-flex align-items-center">
                              <label className="me-2">Last updated: </label>
                            </div>
                            <p className="mb-0">{patient.uploaded_datetime} </p>
                          </div>
                          <div className="col-md-12 mt-2 d-flex justify-content-between">
                            <Link
                              to={`/profile/${patient.id}`}
                              className="text-decoration-none moreDetails"
                            >
                              See more
                            </Link>
                            <div
                              onClick={() =>
                                videoCallView("PAT001", patient.name)
                              }
                              className="text-decoration-none moreDetails"
                            >
                              <img
                                src="/images/video-call.png"
                                style={{ width: "40px", height: "40px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Patients;
