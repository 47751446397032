import React, { useState , useContext } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import MedicationsDataModal from '../../../../modals/Medical/Medications/MedicationsDataModal';
import AddMedicationsModal from '../../../../modals/Medical/Medications/AddMedicationsModal';
import MedicationsOperationModal from '../../../../modals/Medical/Medications/MedicationsOperationModal';
import { MedicationContext } from '../../../../context/MedicationProvider';
import { ProfileContext } from '../../../../context/ProfileProvider';


const Medications = () => {

    const [isAddMedicationModalOpen, setAddMedicationModalOpen] = useState(false);
    const [isMedicationsOperationModalOpen, setIsMedicationsOperationModalOpen] = useState(false);
    const [isMedicationModalOpen, setIsMedicationModalOpen] = useState(false);
    const [selectedMedicationData, setSelectedMedicationData] = useState(false);

    const [selectedMedicationTitle, setMedicationTitle] = useState(false);

    const {medicationdData , addNewMedication , updateMedication} = useContext(MedicationContext)
    const {profile} = useContext(ProfileContext)
    const addEditMedication = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {};
        formData.forEach((value, key) => data[key] = value);
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
        const day = String(currentDate.getDate()).padStart(2, '0');
        data["startDate"] = `${year}-${month}-${day}`;
        data["endDate"] = "active";
        data["alert"] = "";
        data["task"] = "addMedications";
        data["dr_id"] = profile?.user_id;
        addNewMedication(data)
        setAddMedicationModalOpen(false)
    }
    const updateData = (e) =>{
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {};
        formData.forEach((value, key) => data[key] = value);
        data["task"] = "updateMedications";
        updateMedication(data)
        setIsMedicationsOperationModalOpen(false)
    }

    const changeHandler = (e) =>{
        const { name, value} = e.target;
        setSelectedMedicationData((prevData) => ({
            ...prevData,
            [name]:  value
        }));
      }
    return (
        <>
            <div className="tab-pane fade show active" id="medical-medications" role="tabpanel" aria-labelledby="medical-medications-tab">
                {/* //////////////////// */}
                <div className="headingTable mb-3">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <h1 className="mb-0">Medications</h1>
                        </div>
                        <div className='col'>
                            <div className='row justify-content-end'>

                                <div className='col-md-3'>
                                    <button type="button" className="btn btn-success border-0"
                                     onClick={() => {
                                      // setSelectedUploadDocData("");
                                      setAddMedicationModalOpen(true);
                                    }}
                                    >
                                        <i className="fas fa-list-alt"></i> Add new medications</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* //////////////////// */}

                <div className='tableList'>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                            <th scope="col">Auto refill name <img src='images/short-icon.svg' alt='' /></th>
                            <th scope="col">Dosage <img src='images/short-icon.svg' alt='' /></th>
                            <th scope="col">Start date <img src='images/short-icon.svg' alt='' /></th>
                            <th scope="col">End date <img src='images/short-icon.svg' alt='' /></th>
                            <th scope="col">Alerts <img src='images/short-icon.svg' alt='' /></th>
                            <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>

                            {medicationdData.length > 0 && medicationdData.map( data => (
                                <tr key={`aiigned${data.id}`}>
                                    <td>
                                    
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                            <input type='checkbox' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <span className='curser-pointer'
                                                    onClick={() => {
                                                        setSelectedMedicationData( data );
                                                        setIsMedicationModalOpen(true);
                                                    }}
                                                >
                                                    { data.name}
                                                </span>
                                            </div>
                                        </div>
                                    </td>

                                    <td><h6 className='mb-0'> { data.dosage}</h6></td>
                                    <td> { data.startDate} </td>
                                    <td> { data.endDate} </td>
                                    <td> <img src={ data.alert } alt='' title={ data.message } /></td>

                                    <td>
                                        <div className="btn-group dropMenu">
                                            <button type="button" className="btn bg-transparent border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true">
                                                <i className="fas fa-ellipsis-h"></i>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end border-0" data-popper-placement="bottom-end">
                                                <li>
                                                    <Link className="dropdown-item" to="#"
                                                        onClick={() => {
                                                            setMedicationTitle("Edit start date")
                                                            setSelectedMedicationData( data );
                                                            setIsMedicationsOperationModalOpen(true);
                                                        }}
                                                    >Edit start date</Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="#"
                                                        onClick={() => {
                                                            setMedicationTitle("Change dosage")
                                                            setSelectedMedicationData( data );
                                                            setIsMedicationsOperationModalOpen(true);
                                                        }}
                                                    >Change dosage</Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="#"
                                                        onClick={() => {
                                                            setMedicationTitle("Discontinue medication")
                                                            setSelectedMedicationData( data );
                                                            setIsMedicationsOperationModalOpen(true);
                                                        }}
                                                    >Discontinue medication</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal isOpen={ isMedicationModalOpen } onRequestClose={() => setIsMedicationModalOpen(false)} >
                <MedicationsDataModal
                    medicationsInfo={{
                        selectedMedicationData: selectedMedicationData,
                        setIsMedicationModalOpen: setIsMedicationModalOpen
                    }}
                />
            </Modal>
            
            <Modal isOpen={isAddMedicationModalOpen} onRequestClose={() => setAddMedicationModalOpen(false)} >
                <AddMedicationsModal
                    isMedicationInfo={{
                        setAddMedicationModalOpen: setAddMedicationModalOpen,
                        addEditMedication : addEditMedication
                        // selectedUploadDocData:selectedUploadDocData
                    }}
                />
            </Modal>

             <Modal isOpen={ isMedicationsOperationModalOpen } onRequestClose={() => setIsMedicationsOperationModalOpen(false)} >
                <MedicationsOperationModal
                    medicationsOperaitonInfo={{
                        selectedMedicationTitle: selectedMedicationTitle,
                        selectedMedicationData: selectedMedicationData,
                        setIsMedicationsOperationModalOpen: setIsMedicationsOperationModalOpen,
                        updateData : updateData,
                        changeHandler:changeHandler
                    }}
                />
            </Modal>

        </>
    );

};

export default Medications;
