import React from 'react';
import { Link } from 'react-router-dom';

const AddOrdresModal = ({ OrderInfo }) => {

  const { setAddOrderModalOpen } = OrderInfo;

  return (

    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setAddOrderModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-0 px-4">
          <div className='headingSec mb-4'>
            <h2 className='mb-0'>Add a new order</h2>
          </div>

          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">

              <div className='row mb-3'>
                <div className='col-md-6'>
                  {/* <label htmlFor="exampleFormControlInput1" className="form-label">Select type:</label>
                  <input type="text" placeholder='Propranolol XR' className="form-control" id="exampleFormControlInput1" /> */}
                  <label htmlFor="exampleFormControlInput1" className="form-label">Select type: </label>
                  <select className="form-select" aria-label="Default select example" defaultValue="07:00PM">
                                      <option value="Select status">Select</option>
                                      <option value="Blood Work">Blood Work</option>
                  </select>
                </div>
                <div className='col-md-6'>
                  <label htmlFor="exampleFormControlInput1" className="form-label">Recipient Name:</label>
                  <input type="text" placeholder='Primary Care Physician' className="form-control" id="exampleFormControlInput1" />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-md-6'>
                  <label htmlFor="exampleFormControlInput1" className="form-label">Recipient Address:</label>
                  <input type="text" placeholder='144 Lake Road, Camden, New Jers...' className="form-control" id="exampleFormControlInput1" />
                </div>
                <div className='col-md-6'>
                  <label htmlFor="exampleFormControlInput1" className="form-label">Recipient Fax:</label>
                  <input type="text" placeholder='+17705644561' className="form-control" id="exampleFormControlInput1" />
                </div>
              </div>

              <div className='gap-4 d-flex justify-content-center p-4'>
                <button type="button" className="btn btn-outline-secondary px-5 btnSize rounded-3" onClick={() => setAddOrderModalOpen(false)}>Cancel</button>
                <button type="button" className="btn btn-primary px-5 btnSize rounded-3" onClick={() => setAddOrderModalOpen(false)}>Add Order</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
};

export default AddOrdresModal;
