import React from 'react';
import Notes from './Notes/Notes';
import Medications from './Medications/Medications';
import Orders from './Orders/Orders';
import Vitals from './Vitals/Vitals';

const Medical = () => {

  return (
    <div id="medical">
     
      <div className='headingTable cardPaitent'>
        <div className='row'>
          <div className='col-md-12'>
            <ul className="nav nav-pills mb-3 navTabUI" id="pills-tab" role="tablist">
              {/* <li className="nav-item flex-fill bg-light rounded-start-3" role="presentation">
                <button className="nav-link px-5 py-2 w-100" id="medical-notes-tab" data-bs-toggle="pill" data-bs-target="#medical-notes" type="button" role="tab" aria-controls="medical-notes" aria-selected="true">Medical notes</button>
              </li> */}
              <li className="nav-item flex-fill bg-light rounded-end-3" role="presentation">
                <button className="nav-link active px-5 py-2 w-100" id="medical-medications-tab" data-bs-toggle="pill" data-bs-target="#medical-medications" type="button" role="tab" aria-controls="medical-medications" aria-selected="false">Medications</button>
              </li>
              <li className="nav-item flex-fill bg-light rounded-end-3" role="presentation">
                <button className="nav-link px-5 py-2 w-100" id="orders-tab" data-bs-toggle="pill" data-bs-target="#orders" type="button" role="tab" aria-controls="orders" aria-selected="false">Orders </button>
              </li>
              {/* <li className="nav-item flex-fill bg-light rounded-end-3" role="presentation">
                <button className="nav-link px-5 py-2 w-100" id="pills-reoccurring-tab" data-bs-toggle="pill" data-bs-target="#vitals" type="button" role="tab" aria-controls="pills-reoccurring" aria-selected="false">Vitals</button>
              </li> */}
            </ul>
          </div>

          <div className='col-md-12'>
            <div className="tab-content" id="pills-tabContent">
              
              <Medications />
              {/* <Notes /> */}
              <Orders />
              {/* <Vitals /> */}

            </div>
          </div>
        </div>
      </div>

    </div>
  );

};

export default Medical;
