import React from 'react';
import { Link } from 'react-router-dom';

const AddMedicationsModal = ({ isMedicationInfo }) => {

  const { setAddMedicationModalOpen , addEditMedication } = isMedicationInfo;

  return (

    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setAddMedicationModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-0 px-4">
          <div className='headingSec mb-4'>
            <h2 className='mb-0'>Add a new medication</h2>
          </div>

          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
             <form className='medicationForm' onSubmit={addEditMedication}>
              <div className='row mb-3'>
                <div className='col-md-6'>
                  <label htmlFor="exampleFormControlInput1" className="form-label">Medication name:</label>
                  <input type="text" name="name" placeholder='Propranolol XR' className="form-control" id="exampleFormControlInput1" />
                </div>
                <div className='col-md-6'>
                  <label htmlFor="exampleFormControlInput1" className="form-label">Fill:</label>
                  <input type="text" name="fill" placeholder='90 days supply' className="form-control" id="exampleFormControlInput1" />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-md-6'>
                  <label htmlFor="exampleFormControlInput1" className="form-label">Dose:</label>
                  <input type="text" name="dosage" placeholder='40 mg' className="form-control" id="exampleFormControlInput1" />
                </div>
                <div className='col-md-6'>
                  <label htmlFor="exampleFormControlInput1" className="form-label">Frequency:</label>
                  <input type="text" name="frequency" placeholder='1x per day AM' className="form-control" id="exampleFormControlInput1" />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Reason: </label>
                <input type="text" name="message" placeholder='Anxiety' className="form-control" id="exampleFormControlInput1" />
              </div>

              <div className='gap-4 d-flex justify-content-center p-4'>
                <button type="button" className="btn btn-outline-secondary px-5 btnSize rounded-3" onClick={() => setAddMedicationModalOpen(false)}>Cancel</button>
                <button type="submit" className="btn btn-primary px-5 btnSize rounded-3" >Add medication</button>
              </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
};

export default AddMedicationsModal;
