import React from 'react';
import { TextInput, RadioButtons,RadioButton, CheckboxInput , NumberInput } from '../../../../components/FormComponents/FormComponents';
import { EDUCATION_COMPLETION_ITEMS, EDUCATION_RADIO_QUESTIONS,HOUSING_OPTIONS,LIVING_ARRANGEMENT_OPTIONS, EDUCATION_LEARNING_QUESTIONS, EMPLOYMENT_TYPE, EMPLOYMENT_SATISFACTION, UNEMPLOYMENT_DESCRIPTION } from '../../../../const/PatientIntakeConst';

const NewPatientPage10 = ({ formData, handleChange, handleSubmit , handleGoBack, showFooter = true }) => {

  

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="form-group row">
                    <div className='mt-4 d-flex'>
                        <h3>Educational Background</h3>
                        <span className='ps-1'>(select all that apply)</span>
                    </div>

                    <div className='d-flex'>
                        <span>Education:</span>
                        <div>
                        {EDUCATION_COMPLETION_ITEMS.map((educationInfo, index) => {
                            const numberInputValue = index === 0 ? formData[`text_${educationInfo[0].name}`] : null;
                            const isAnyNumberInputFilled = index === 0
                                ? Object.keys(formData).some(key => key.startsWith('text_') && formData[key])
                                : null;
                            
                            return (
                                <div key={index} className='d-flex justify-content-between ps-3 space-items'>
                                    <CheckboxInput
                                        id={educationInfo[0].label}
                                        name={educationInfo[0].name}
                                        label={educationInfo[0].label}
                                        checked={formData[`${educationInfo[0].name}`]}
                                        onChange={handleChange}
                                        required={index === 0 ? (!isAnyNumberInputFilled || !!numberInputValue) : undefined}
                                    />
                                    {index !== 0 && (
                                        <div className='ms-5 w-auto'>
                                            <NumberInput
                                                id={`text_${educationInfo[0].label}`}
                                                name={`text_${educationInfo[0].name}`}
                                                value={formData[`text_${educationInfo[0].name}`]}
                                                onChange={handleChange}
                                                placeholder={educationInfo[1].label}
                                                required={!!numberInputValue}  // Adjust the condition based on your needs
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        </div>
                    </div>

                    {EDUCATION_RADIO_QUESTIONS.map((educationQuestion) => {
                        return (
                            <div className="d-flex align-items-center justify-content-between">
                                <span>
                                    {educationQuestion.label}
                                </span>
                                <div className="d-flex align-items-center">
                                    <RadioButtons name={educationQuestion.name} formData={formData}handleChange={handleChange}/>
                                </div>
                            </div>
                        )
                    })}

                    <div>
                        <span>How do you learn the best?</span>
                        <div className='d-flex pe-3'>
                        {EDUCATION_LEARNING_QUESTIONS.map((learningPreference, index) => {
                            return (
                                <div key={index} className='pe-3'>
                                    <CheckboxInput
                                        id={learningPreference.label}
                                        name={`${learningPreference.name}`}
                                        label={learningPreference.label}
                                        checked={formData[`${learningPreference.name}`]}
                                        onChange={handleChange}
                                    />
                                </div>
                            )
                        })}
                        </div>
                    </div>

                    <h3 className='mt-4'>Employment/Vocational</h3>

                    <div className='d-flex align-items-center'>
                        <input
                            type='radio'
                            id='employed'                                        
                            name='employmentStatus'
                            value='Employed'
                            onChange={handleChange}
                            checked={formData.employmentStatus === 'Employed'}
                        />
                        <h4 className='mt-2 mx-2'>Employed</h4>
                    </div>

                    {formData.employmentStatus === 'Employed' && (
                        <div>
                            <span>Select employment type:</span>
                            <div className='d-flex flex-wrap align-items-center pe-3'>
                                {EMPLOYMENT_TYPE.map((employmentType, index) => {
                                    return (
                                        <div key={index} className='pe-3'>
                                            <CheckboxInput
                                                id={employmentType}
                                                name={`${employmentType.name}`}
                                                label={`${employmentType.label}`}
                                                checked={formData[`${employmentType.name}`]}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="form-group row">
                                <div className="col-6">
                                    <TextInput
                                        id="employerName"
                                        name="employerName"
                                        value={formData.employerName}
                                        onChange={handleChange}
                                        label="Employer:"
                                        required
                                    />
                                </div>
                                <div className='col-6'>
                                    <TextInput
                                        id="lengthOfEmployment"
                                        name="lengthOfEmployment"
                                        value={formData.lengthOfEmployment}
                                        onChange={handleChange}
                                        label="Length of Employment:"
                                        required
                                    />
                                </div>
                                <TextInput
                                    id="jobDescription"
                                    name="jobDescription"
                                    value={formData.jobDescription}
                                    onChange={handleChange}
                                    label="Job Description:"
                                    required
                                />
                            </div>

                            <span>Select all that apply:</span>
                            <div className='d-flex flex-wrap align-items-center pe-3'>
                                {EMPLOYMENT_SATISFACTION.map((employmentSatisfaction, index) => {
                                    return (
                                        <div key={index} className='pe-3'>
                                            <CheckboxInput
                                                id={`${employmentSatisfaction.label}`}
                                                name={`${employmentSatisfaction.name}`}
                                                label={`${employmentSatisfaction.label}`}
                                                checked={formData[`${employmentSatisfaction.name}`]}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <TextInput
                                id="employmentSatisfactionExplanation"
                                name="employmentSatisfactionExplanation"
                                value={formData.employmentSatisfactionExplanation}
                                onChange={handleChange}
                                placeholder={'Explanation:'}
                                required
                            />
                        </div>

                        
                    )}

                    <div className='d-flex align-items-center'>
                        <input
                            type='radio'
                            id='unemployed'                                        
                            name='employmentStatus'
                            value='Unemployed'
                            onChange={handleChange}
                            checked={formData.employmentStatus === 'Unemployed'}
                        />
                        <h4 className='mt-2 mx-2'>Unemployed</h4>
                    </div>
                {formData.employmentStatus === 'Unemployed' && (
                <div>
                    <div className="form-group row">
                        <div className="col-6">
                            <TextInput
                                id="lastEmployerName"
                                name="lastEmployerName"
                                value={formData.lastEmployerName}
                                onChange={handleChange}
                                label="Last Employer:"
                                required
                            />
                        </div>
                        <div className='col-6'>
                            <TextInput
                                id="reasonForLeavingEmployment"
                                name="reasonForLeavingEmployment"
                                value={formData.reasonForLeavingEmployment}
                                onChange={handleChange}
                                label="Reason for Leaving:"
                                required
                            />
                        </div>
                    </div>

                    <span>Select all that apply:</span>
                    <div className='d-flex flex-wrap align-items-center pe-3'>
                        {UNEMPLOYMENT_DESCRIPTION.map((unemploymentDescription, index) => {
                            return (
                                <div key={index} className='pe-3'>
                                    <CheckboxInput
                                        id={`${unemploymentDescription.name}`}
                                        name={`${unemploymentDescription.name}`}
                                        label={`${unemploymentDescription.label}`}
                                        checked={formData[`${unemploymentDescription.name}`]}
                                        onChange={handleChange}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <TextInput
                        id="unemploymentDescriptionExplanation"
                        name="unemploymentDescriptionExplanation"
                        value={formData.unemploymentDescriptionExplanation}
                        onChange={handleChange}
                        placeholder={'Explanation:'}
                        required
                    />
                </div>
                )}
                

                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            Are you planning to retire or change careers soon?
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='planToRetireOrChangeCareers' formData={formData}handleChange={handleChange}/>
                        </div>
                    </div>
           

                    <TextInput
                        id="employmentClinicalImpression"
                        name="employmentClinicalImpression"
                        value={formData.employmentClinicalImpression}
                        onChange={handleChange}
                        label="Clinical Impression (Staff use only):"
                        placeholder={'Explanation:'}
                        required
                    />
                        

                    <h3 className='mt-4'>Housing</h3>

                    <label>Current Living Situation:</label>
                    <div className='d-flex flex-wrap'>
                    {HOUSING_OPTIONS.map((option, index) => {
                        return (
                            <div key={index} className='pe-3 d-flex align-items-center'>
                                <RadioButton
                                    id={option.label}
                                    name={option.name}
                                    value={option.value}
                                    label={option.label}
                                    checked={formData[`${option.name}`] == option.value}
                                    handleChange={handleChange}
                                />
                            </div>
                        )
                        })}
                       
                    </div>
                    {formData['housing_type'] == 'other' && (
                        <TextInput
                            id='patientOtherLivingSituationDescription'
                            name='patientOtherLivingSituationDescription'
                            value={formData.patientOtherLivingSituationDescription}
                            onChange={handleChange}
                            placeholder='If other, explain:'
                            required
                        />
                    )}
                    
                    <label>Who does the patient live with?</label>
                    <div className='d-flex flex-wrap'>

                    {LIVING_ARRANGEMENT_OPTIONS.map((option, index) => {
                        return (
                            <div key={index} className='pe-3 d-flex align-items-center'>
                                <RadioButton
                                    id={option.label}
                                    name={option.name}
                                    label={option.label}
                                    value={option.value}
                                    checked={formData[`${option.name}`] == option.value}
                                    handleChange={handleChange}
                                />
                            </div>
                        )
                        })}
                   
                    </div>
                    {formData['living_arrangement'] === 'other' && (
                        <TextInput
                            id='patientLivesWithOtherDescription'
                            name='patientLivesWithOtherDescription'
                            value={formData.patientLivesWithOtherDescription}
                            onChange={handleChange}
                            placeholder='If other, explain:'
                            required
                        />
                    )}

                    <label>On a scale of 1-5 how satisfied is the patient with their current living situation?</label>
                    <div className='d-flex'>
                        {[1, 2, 3, 4, 5].map((num, index) => {
                            return (
                                <div className='d-flex align-items-center'>
                                    <input
                                        type='radio'
                                        id={num}                                        
                                        name='patientLivingSituationSatisfaction'
                                        value={num}
                                        onChange={handleChange}
                                        checked={formData[`patientLivingSituationSatisfaction`] == num}
                                    />
                                    <span className="me-3 ms-1">{num}</span>
                                </div>
                            )
                        })}
                    </div> 
                </div>
                <div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                    {showFooter && (<div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center'>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3 mx-4'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>)}
                </div>
            </form>
        </div>
    );
};

export default NewPatientPage10;