export function formatDate(date: {
  getDate: () => any;
  getMonth: () => number;
  getFullYear: () => number;
}) {
  // Extract day, month, and year from the date
  const day = date.getDate();
  const month = date.getMonth() + 1;
  // Months are zero based
  const year = date.getFullYear() % 100;
  // Get last two digits of the year// Pad day and month with leading zeros if needed
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;
  return `${formattedDay}/${formattedMonth}/${year}`;
}
