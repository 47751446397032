import React from 'react';
import { TextInput, RadioButtons, CheckboxInput } from '../../../../components/FormComponents/FormComponents';
import { LEGAL_CHECKBOX_ITEMS ,FINANCE_CHECKBOX_ITEMS , FINANCE_HELP_ITEMS , MONEY_MANAGEMENT_ITEMS } from '../../../../Data';
import { LegalTable } from '../Components/NewPatientPage';


const NewPatientPage9 = ({ formData, setFormData, handleChange, handleSubmit  , handleGoBack, showFooter = true }) => {

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="form-group row">
                    <div>
                        <span>Current Legal Status:</span>
                        <div className='d-flex'>
                            <div className='d-flex align-items-center'>
                                <input
                                    type='radio'
                                    id='none'                                        
                                    name='currentLegalStatus'
                                    value='None'
                                    onChange={handleChange}
                                    checked={formData.currentLegalStatus === 'None'}
                                />
                                <span className="mx-2">None</span>
                            </div>
                            
                            <div className='d-flex align-items-center'>
                                <input
                                    type='radio'
                                    id='awaitingSentence'                                        
                                    name='currentLegalStatus'
                                    value='awaitingSentence'
                                    onChange={handleChange}
                                    checked={formData.currentLegalStatus === 'awaitingSentence'}
                                />
                                <span className="mx-2">Awaiting Sentence</span>
                            </div>
                            
                            <div className='d-flex align-items-center'>
                                <input
                                    type='radio'
                                    id='parole'                                        
                                    name='currentLegalStatus'
                                    value='parole'
                                    onChange={handleChange}
                                    checked={formData.currentLegalStatus === 'parole'}
                                />
                                <span className="mx-2">Parole</span>
                            </div>
                            
                            <div className='d-flex align-items-center'>
                                <input
                                    type='radio'
                                    id='awaitingTrial'                                        
                                    name='currentLegalStatus'
                                    value='awaitingTrial'
                                    onChange={handleChange}
                                    checked={formData.currentLegalStatus === 'awaitingTrial'}
                                />
                                <span className="mx-2">Awaiting Trial</span>
                            </div>
                        </div>
                    </div>

                    <LegalTable formData={formData} setFormData={setFormData} handleChange={handleChange} />

                    <div className="d-flex align-items-center justify-content-between">
                        <label>
                            Is the patient currently involved in any civil lawsuit?
                        </label>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='currentPatientLawsuit' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    {formData.currentPatientLawsuit === 'Yes' && (
                        <div>
                            <span>If yes, check boxes that apply to the patient:</span>
                            <div className='d-flex align-items-center'>
                            {LEGAL_CHECKBOX_ITEMS.map((lawsuitInfo, index) => {

                                return (
                                    <div key={index} className='pe-3'>
                                        <CheckboxInput
                                            id={lawsuitInfo.label}
                                            name={lawsuitInfo.name}
                                            label={lawsuitInfo.label}
                                            checked={formData[`${lawsuitInfo.name}`]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    )}

                    <div className='mt-3 d-flex'>
                        <h3>Financial Status</h3>
                        <span className='ps-1'>(select all that apply)</span>
                    </div>

                    <div>
                        <label>Finances are:</label>
                        <div className='d-flex align-items-center'>
                            {FINANCE_CHECKBOX_ITEMS.map((financeInfo, index) => {
                                return (
                                    <div key={index} className='pe-3'>
                                        <CheckboxInput
                                            id={financeInfo.label}
                                            name={`${financeInfo.name}`}
                                            label={financeInfo.label}
                                            checked={formData[`${financeInfo.name}`]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <label>Need help with:</label>
                        <div className='d-flex align-items-center'>
                        {FINANCE_HELP_ITEMS.slice(0, 4).map((financeInfo, index) => {
                            return (
                                <div key={index} className='pe-3'>
                                    <CheckboxInput
                                    id={financeInfo.label}
                                    name={`${financeInfo.name}`}
                                    label={financeInfo.label}
                                    checked={formData[`${financeInfo.name}`]}
                                    onChange={handleChange}
                                    />
                                </div>
                            )
                        })}
                        </div>
                        <div className='d-flex align-items-center'>
                        {FINANCE_HELP_ITEMS.slice(4).map((financeInfo, index) => {
                            return (
                                <div key={index} className='pe-3'>
                                    <CheckboxInput
                                          id={financeInfo.label}
                                          name={`${financeInfo.name}`}
                                          label={financeInfo.label}
                                          checked={formData[`${financeInfo.name}`]}
                                          onChange={handleChange}
                                    />
                                </div>
                            )
                        })}
                        </div>
                        {formData['needFinancialHelpWith_Other Financial Needs'] === true && (
                            <TextInput
                                id="otherFinancialNeedsDescription"
                                name="otherFinancialNeedsDescription"
                                value={formData.otherFinancialNeedsDescription}
                                onChange={handleChange}
                                placeholder={'Describe:'}
                                required
                            />
                        )}
                        <label>Money Management:</label>
                        <div className='d-flex align-items-center'>
                            {MONEY_MANAGEMENT_ITEMS.map((moneyInfo, index) => {
                                return (
                                    <div key={index} className='pe-3'>
                                        <CheckboxInput
                                            id={moneyInfo}
                                            name={`moneyManagement_${moneyInfo.name}`}
                                            label={moneyInfo.label}
                                            checked={formData[`moneyManagement_${moneyInfo.name}`]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                 {showFooter && ( <div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center'>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3 mx-4'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>)}
                </div>
            </form>
        </div>
    );
};

export default NewPatientPage9;