import { Avatar, Box, IconButton, Typography } from "@mui/material";
import toast from "react-hot-toast";
import CommonTable from "components/table/CommonTable";
import { createDocument, deleteDocuments } from "lib/api/service";
import PsychotherapyModal from "modals/PsychotherapyModal";
import RootModal from "modals/RootModal";
import { useState } from "react";
import { formatDateWithMonth } from "utils";

const Psychotherapy = ({ data }) => {
  const [isAddNewTaskModalModalOpen, setIsPsychotherapyModalOpen] =
    useState(false);
  const [columndata, setColumnData] = useState();
  const handleOpen = (data) => {
    setColumnData(data);
    setIsPsychotherapyModalOpen(true);
  };
  const handleClose = async () => {
    try {
      const response = await createDocument({ id: columndata.id });
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setIsPsychotherapyModalOpen(false);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const footerButtons = [
    {
      label: "Cancel",
      variant: "secondary",
      onClick: handleClose,
      classname: "notes_close_btn notes_modal",
    },
    {
      label: "Convert to documentation",
      variant: "primary",
      onClick: handleClose,
      classname: "notes_create_btn notes_modal",
    },
  ];

  const columns = [
    {
      field: "noteName",
      headerName: "Note name",
      flex: 1,
      renderCell: (params) => (
        <Box height="100%" display="flex" alignItems="center" gap="10px">
          <img
            src="/images/doc-icon.png"
            style={{ marginTop: "-20px" }}
            alt="..."
            width={35}
            height={40}
          />
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography
              variant="body2"
              sx={{
                ...styles.title,
                mb: "6px !important",
                color: "#18357A !important",
              }}
            >{`${params.row.noteName}_${formatDateWithMonth(
              params.row.created
            )}psych`}</Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={styles.description}
            >
              {params.row.description}
            </Typography>
            <Typography
              onClick={() => handleOpen(params?.row)}
              sx={{
                ...styles.provider,
                color: "#4264D0 !important",
                mt: "6px !important",
                cursor: "pointer",
              }}
            >
              See more
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "providerfooterButtons",
      headerName: "Provider",
      flex: 0.5,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" height="100%">
          <Avatar
            src={params.row.providerAvatar}
            alt={params.value}
            sx={{ width: "24px", height: "24px", fontSize: "14px" }}
          />
          <Typography
            variant="body2"
            sx={styles.provider}
            style={{ marginLeft: 8 }}
          >
            {params?.row?.provider}
          </Typography>
        </Box>
      ),
    },
    {
      field: "created",
      headerName: "Created",
      flex: 0.5,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          height="100%"
        >
          <Typography
            variant="body2"
            sx={styles.provider}
            style={{ marginLeft: 8 }}
          >
            {params.value}
          </Typography>
          <Typography
            variant="body2"
            sx={styles.provider}
            style={{ marginLeft: 15, marginTop: "3px" }}
          >
            {params.row.time}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      renderCell: () => (
        <Box
          display="flex"
          justifyContent="center"
          gap="5px"
          width="100%"
          height="100%"
        >
          <IconButton>
            <img src="/images/edit.svg" alt="" />
          </IconButton>
          <IconButton onClick={handleDocumentDelete}>
            <img src="/images/delete.svg" alt="" />
          </IconButton>
        </Box>
      ),
    },
  ];
  const { documentation, ...rest } = data;
  const rowData = documentation?.map((item) => ({
    id: item?.id,
    noteName: item?.provider_name,
    description: item?.psychotherapy_note,
    provider: item?.provider_name,
    providerAvatar: "/path/to/avatar1.jpg",
    created: item?.createdate,
    time: item?.createtime || "00:00",
  }));

  const handleDocumentDelete = async () => {
    try {
      const response = await deleteDocuments({ id: columndata.id });
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setIsPsychotherapyModalOpen(false);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <CommonTable rows={rowData} columns={columns} />
      <RootModal
        show={isAddNewTaskModalModalOpen}
        handleClose={() => setIsPsychotherapyModalOpen(false)}
        isHeader
        footerButtons={footerButtons}
      >
        <PsychotherapyModal
          psychotherapyDetailInfo={{
            selectedPsychotherapyData: columndata,
            patient_data: rest,
            setIsPsychotherapyModalOpen: setIsPsychotherapyModalOpen,
          }}
        />
      </RootModal>
    </div>
  );
};

export default Psychotherapy;

const styles = {
  provider: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    color: "#5B6680 !important",
  },
  description: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#18357A !important",
  },
  title: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "16px",
    color: "#18357A",
  },
};
