import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "components/Header/Header";
import "./Schedule.css";
import "../Patients/Profile/Profile.css";
import Modal from "react-modal";
import RequestAppoinmentModal from "modals/Schedule/RequestAppoinmentModal";
import ScheduleWeekly from "./ScheduleWeekly";
import Layout from "components/layout/layout";
import { getScheduleData } from "lib/api/service";
import { useLocalStorageGet } from "utils/hooks/useLoacalStorage";
import { mapSchedulesToCalendarEvents } from "utils";
const Schedule = ({ showSection = true }) => {
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [eventsdata, setEventsData] = useState({ data: [], eventsdata: [] });
  const today = new Date().toString().split(" ").splice(1, 3).join(" ");
  const {
    rows: { email },
  } = useLocalStorageGet("userDetails");
  const userRole = localStorage.getItem("userRole");

  const fetchData = async () => {
    try {
      const response = await getScheduleData({ email: email });
      if (response && response.schedules) {
        const res = mapSchedulesToCalendarEvents(response.schedules);
        setEventsData({ data: response.schedules, eventsdata: res });
      } else {
        console.error("No schedules found in the response", response);
      }
    } catch (error) {
      console.error("Error fetching schedule data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [email]);

  const appointmentColors = {
    "Clinical Consultations": "#D1C4E9",
    "Individual therapy": "#C8E6C9",
    "Insurance meeting": "#BBDEFB",
    "Clinical Team meeting": "#FF6760",
    "Group Therapy": "#5955B3",
  };

  const [visibleCount, setVisibleCount] = useState(4); // Initial count of items to display

  const showUpcommingEvents = eventsdata?.data?.map(
    (item) => item.appointmenttype
  );

  const handleShowMore = () => {
    setVisibleCount(showUpcommingEvents.length); // Show all items on button click
  };

  const handleShowLess = () => {
    setVisibleCount(4); // Reset to show only the initial items
  };

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        {showSection && <Header pageTitle={"Schedule"} />}
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-3 ">
            <Row className="d-flex align-items-center p-3">
              <Col className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <h3 className="page_title">Appointment calendar</h3>
                  <div className="appointments-container">
                    Your upcoming appointments
                    {showUpcommingEvents
                      ?.slice(0, visibleCount)
                      .map((type, index) => (
                        <span
                          key={index}
                          className="appointment"
                          style={{
                            backgroundColor:
                              appointmentColors[type] || "#E0E0E0",
                          }}
                        >
                          {type}
                        </span>
                      ))}
                    {visibleCount < showUpcommingEvents?.length && (
                      <div className="show_more_text">
                        <h6
                          className="card_footer_text appointment"
                          onClick={handleShowMore}
                        >
                          Show More...
                        </h6>
                      </div>
                    )}
                    {visibleCount === showUpcommingEvents.length &&
                      visibleCount > 2 && (
                        <div className="show_less_text">
                          <h6
                            className="card_footer_text appointment"
                            onClick={handleShowLess}
                          >
                            Show Less...
                          </h6>
                        </div>
                      )}
                  </div>
                  {/* <p>{`You have ${showUpcommingEvents.join(', ')} upcoming appointments in ${today}`}</p> */}
                </div>
                {/* <span className="span_text">
                    {`You have ${showUpcommingEvents} upcoming appointments in ${today}`}
                  </span> */}
                {/* </div> */}
                <div>
                  <button
                    type="button"
                    className="btn btn-success border-0"
                    onClick={() => {
                      setAppointmentModalOpen(true);
                    }}
                  >
                    <i className="fas fa-calendar-alt"></i>{" "}
                    {userRole === "Doctor" || userRole === "SuperAdmin"
                      ? "Schedule appointment"
                      : "Request Appointment"}
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="p-3">
              <Col>
                <div className="tab-content mb-5" id="pills-tabContent">
                  <ScheduleWeekly
                    onClick={() => setAppointmentModalOpen(true)}
                    eventsdata={eventsdata?.eventsdata}
                    data={eventsdata?.data}
                    refetch={fetchData}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          isOpen={appointmentModalOpen}
          size="lg"
          style={{
            content: {
              borderRadius: "10px",
            },
          }}
          onRequestClose={() => setAppointmentModalOpen(false)}
        >
          <RequestAppoinmentModal
            title={
              userRole === "Doctor" || userRole === "SuperAdmin"
                ? "Schedule Appointment"
                : "Request Appointment"
            }
            onModalhandle={setAppointmentModalOpen}
            refetch={fetchData}
          />
        </Modal>
      </div>
    </Layout>
  );
};
export default Schedule;
