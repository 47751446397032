import React, { useState } from "react";
import "./Group.css";
import { Link } from "react-router-dom";
import Psychotherapy from "../../../../components/Document/Group/Active/Psychotherapy/Psychotherapy";
import ClinicalTask from "../../../../components/Document/Group/Active/Clinical/ClinicalTask";
import Header from "../../../../components/Header/Header";
import Layout from "components/layout/layout";

const Group = () => {
  const [activeDiv, setActiveDiv] = useState("psychotherapy");

  const showDiv = (divId) => {
    setActiveDiv(divId);
  };

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"Patient Profile"} />

        <div className="main-content">
          <div className="px-3">
            <section>
              <div className="row">
                <div className="col-md-12">
                  {/* <div className='bg-white rounded-3 p-3 cardPaitent'> */}
                  <div className="bg-white rounded-3 p-3">
                    <div className="rounded-3 py-2 px-3 topBar mb-4">
                      <div className="row align-items-end">
                        <div className="col-md-6 px-1">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <img
                                src="../images/iop.svg"
                                alt=""
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                            <div className="flex-grow-1 ms-4">
                              <div className="d-flex justify-content-between">
                                <h4>IOP Tuesday DBT Group</h4>
                              </div>
                              <div className="row">
                                {/* <div className='col-12'>
                                <label className='fw-normal'>12 Notes | <span>4 Documents</span></label>
                              </div> */}
                                <div className="col-12">
                                  <label className="fw-normal text-secondary">
                                    Last added: <span>21 Oct 2023</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="row">
                            <ul
                              class="nav nav-pills mb-3 navTabUI"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li class="nav-item flex-fill bg-light rounded-start-3">
                                <Link
                                  className={`text-decoration-none ${
                                    activeDiv === "psychotherapy"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => showDiv("psychotherapy")}
                                >
                                  <button
                                    className={`nav-link px-5 py-2 w-100 ${
                                      activeDiv === "psychotherapy"
                                        ? "active"
                                        : ""
                                    }`}
                                    id="pills-single-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-single"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-single"
                                    aria-selected="true"
                                  >
                                    Psychotherapy Notes
                                  </button>
                                </Link>
                              </li>
                              <li className="nav-item flex-fill bg-light rounded-end-3">
                                <Link
                                  className={`text-decoration-none ${
                                    activeDiv === "clinical" ? "active" : ""
                                  }`}
                                  onClick={() => showDiv("clinical")}
                                >
                                  <button
                                    className={`nav-link px-5 py-2 w-100 ${
                                      activeDiv === "clinical" ? "active" : ""
                                    }`}
                                    id="pills-single-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-single"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-single"
                                    aria-selected="true"
                                  >
                                    My clinical Documentation
                                  </button>
                                </Link>
                              </li>
                              {/* <li className='nav-item flex-fill bg-light rounded-end-3'>
                              <Link className={`text-decoration-none ${activeDiv === 'other' ? 'active' : ''}`} onClick={() => showDiv('other')}>
                                <button className={`nav-link px-5 py-2 w-100 ${activeDiv === 'other' ? 'active' : ''}`} id="pills-single-tab" data-bs-toggle="pill" data-bs-target="#pills-single" type="button" role="tab" aria-controls="pills-single" aria-selected="true">other clinical's Documentation</button>
                              </Link>
                            </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {activeDiv === "psychotherapy" && <Psychotherapy />}
                    {activeDiv === "clinical" && <ClinicalTask />}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Group;
