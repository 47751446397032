// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dbSkillTab .nav-item .nav-link {
    background-color: #F8F9FE;
    color: #5B6680;
    font-size: 12.9px;
    border-radius: 10px 10px 0 0;
    padding: 15px 25px;
}
.dbSkillTab .nav-item .nav-link.active {
    background-color: #4264D0;
    color: #fff;
}
.tableCustmization th {
    color: #18357A !important;
    font-size: 14px;
    font-weight: 500;
    border-bottom-color: #E7EBF2 !important;
}
.tableCustmization.table  td {
    font-size: 14px;
    color: #5B6680;
}
.tableCustmization {
    border-color: #E7EBF2 !important;
}
.tableCustmization th.worldBreak {
    width: 10px;
    word-break: break-all;
    font-size: 24px !important;
    padding: 0 20px !important;
    border-right: 1px #dee2e6 solid;
    color: #5B6680 !important;
    font-weight: 400;
    vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/components/Patient/DBTracker/DBTracker.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,4BAA4B;IAC5B,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,uCAAuC;AAC3C;AACA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,gCAAgC;AACpC;AACA;IACI,WAAW;IACX,qBAAqB;IACrB,0BAA0B;IAC1B,0BAA0B;IAC1B,+BAA+B;IAC/B,yBAAyB;IACzB,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".dbSkillTab .nav-item .nav-link {\n    background-color: #F8F9FE;\n    color: #5B6680;\n    font-size: 12.9px;\n    border-radius: 10px 10px 0 0;\n    padding: 15px 25px;\n}\n.dbSkillTab .nav-item .nav-link.active {\n    background-color: #4264D0;\n    color: #fff;\n}\n.tableCustmization th {\n    color: #18357A !important;\n    font-size: 14px;\n    font-weight: 500;\n    border-bottom-color: #E7EBF2 !important;\n}\n.tableCustmization.table  td {\n    font-size: 14px;\n    color: #5B6680;\n}\n.tableCustmization {\n    border-color: #E7EBF2 !important;\n}\n.tableCustmization th.worldBreak {\n    width: 10px;\n    word-break: break-all;\n    font-size: 24px !important;\n    padding: 0 20px !important;\n    border-right: 1px #dee2e6 solid;\n    color: #5B6680 !important;\n    font-weight: 400;\n    vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
