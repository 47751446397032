import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../../components/Header/Header";
import { Page1, Page2, Page3, Page4 } from "../DischargePages";
import "../Discharge.css";
import Layout from "components/layout/layout";
import ProfileCard from "components/Patient/Profile/ProfileCard";
import { getAllDischargeFormData } from "lib/api/service";
import { FiDownload } from "react-icons/fi";
import { useReactToPrint } from "react-to-print";

const PaperWork = () => {
  const { patientId } = useParams();

  const componentRef = useRef();

  const [formData, setFormData] = useState();

  const handleChange = (e) => {
    console.error(e);
  };

  const handleSubmit = async (e) => {
    console.error("Submitted", e);
  };

  const getAllDischargeFormDataValue = async () => {
    try {
      const response = await getAllDischargeFormData(patientId);
      setFormData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (patientId) {
      getAllDischargeFormDataValue();
    }
  }, [patientId]);

  // previous button
  const handleGoBack = () => {
    window.history.back();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Layout>
      <div className="vh-100 mainBglight">
        <Header pageTitle="Discharge paper work" />
        <div className="main-content px-3">
          <div
            className="bg-white rounded-top-3 p-4 cardPaitent"
            ref={componentRef}>
            <div className="row w-100">
              <ProfileCard patientId={patientId} />
              <div className="col-8">
                <Page1
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  showFooter={false}
                />
                <Page2
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  showFooter={false}
                />
                <Page3
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  showFooter={false}
                />
                <Page4
                  formData={formData ? formData : {}}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  showFooter={false}
                />
              </div>
            </div>
          </div>
          <div
            className="bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end mt-4"
            style={{ marginRight: "48px" }}>
            <button
              className="btn btn-outline-secondary px-5 btnSize rounded-3 mx-4"
              type="button"
              onClick={handleGoBack}>
              Cancel
            </button>
            <button
              className="btn btn-primary px-5 btnSize rounded-3"
              type="button"
              onClick={handlePrint}>
              <FiDownload /> Download discharge paperwork
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PaperWork;
