import React, { useEffect, useState } from 'react';
import { CheckboxInput, TextInput, RadioButtons } from '../../../../components/FormComponents/FormComponents';
import { SUBSTANCES_HEADERS, SUBSTANCES} from '../../../../const/PatientIntakeConst';


export const SubstanceUseTable = ({ formData, handleChange }) => {
    const [numAdditionalRows, setNumAdditionalRows] = useState(0);


    const handleAddRow = () => {
      setNumAdditionalRows(prevNum => prevNum + 1);
    };

    useEffect(() => {
        const otherSubstanceUseDescriptionIndices = Object.keys(formData)
        .filter(key => key.startsWith('otherSubstanceUseDescription['))
        .map(key => parseInt(key.match(/\d+/)[0]));
      
        if (otherSubstanceUseDescriptionIndices.length > 0) {
            setNumAdditionalRows(otherSubstanceUseDescriptionIndices.length);
         }
    }, [formData]);

    return (
        <div>
            <table className='custom-table w-100'>
                <thead>
                    <tr>
                        {SUBSTANCES_HEADERS.map((header) => (
                            <th><label>{header}</label></th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {SUBSTANCES.map((substance, index) => (
                    <tr key={index}>
                        <td><span>{substance}</span></td>
                        <td>
                            <RadioButtons
                                name={`pastuse[${index}]`}
                                formData={formData}
                                handleChange={handleChange}
                            />
                        </td>
                        <td>
                            <RadioButtons
                                name={`current_use[${index}]`}
                                formData={formData}
                                handleChange={handleChange}
                            />
                        </td>
                        <td>
                            <TextInput
                                id={`frequency_of_use_${index}`}
                                name={`frequency_of_use_[${index}]`}
                                value={formData[`frequency_of_use_[${index}]`]}
                                onChange={handleChange}
                            />
                        </td>
                        <td>
                            <TextInput
                                id={`age_first_use_${index}`}
                                name={`age_first_use_[${index}]`}
                                value={formData[`age_first_use_[${index}]`]}
                                onChange={handleChange}
                            />
                        </td>
                    </tr>
                    ))}
                    {[...Array(numAdditionalRows)].map((_, index) => (
                    <tr key={index + SUBSTANCES.length}>
                        <td>
                            <TextInput
                                id={`otherSubstanceUseDescription[${index}]`}
                                name={`otherSubstanceUseDescription[${index}]`}
                                value={formData[`otherSubstanceUseDescription[${index}]`]}
                                onChange={handleChange}
                                placeholder="Other"
                            />
                        </td>
                        <td>
                            <RadioButtons
                                name={`pastSubstanceUseOther[${index}]`}
                                formData={formData}
                                handleChange={handleChange}
                            />
                        </td>
                        <td>
                            <RadioButtons
                                name={`currentSubstanceUseOther[${index}]`}
                                formData={formData}
                                handleChange={handleChange}
                            />
                        </td>
                        <td>
                            <TextInput
                                id={`frequencyOfSubstanceUseOther[${index}]`}
                                name={`frequencyOfSubstanceUseOther[${index}]`}
                                value={formData[`frequencyOfSubstanceUseOther[${index}]`]}
                                onChange={handleChange}
                            />
                        </td>
                        <td>
                            <TextInput
                                id={`ageOfFirstUseOther[${index}]`}
                                name={`ageOfFirstUseOther[${index}]`}
                                value={formData[`ageOfFirstUseOther[${index}]`]}
                                onChange={handleChange}
                            />
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={handleAddRow} type="button" className='text-button'>+ Add New Substance</button>
        </div>
    )
}

export const SubstanceCheckboxComponent = ({ options, handleChange, formData }) => {
 

    return (
        <div className='mt-2'>
            <div>
                <label>{options.label}</label>
                <span className='d-block'>{options.text}</span>
            </div>
            <div className='d-flex flex-wrap'>
            {options.checkboxOptions.map((option, index) => (
                <div key={index} className='d-flex align-items-center pe-3'>
                    <input
                        type="checkbox"
                        id={`${option.name}`}
                        name={`${option.name}`}
                        checked={formData[`${option.name}`] == "on" ||formData[`${option.name}`] == true }
                        onChange={handleChange}
                    />
                    <label htmlFor={`${option.name}`} className='ps-1'>
                        <span>{option.label}</span>
                    </label>
                </div>
            ))}
            </div>
            <TextInput 
                id={`${options.label} Description`}
                name={`${options.label} Description`}
                value={formData[`${options.label} Description`]}
                onChange={handleChange}
                placeholder={'Describe:'}
            />
        </div>
    );
};

export const DisorderedEatingRadioQuestions = ({ question, formData, handleChange }) => {
    return (
        <div>
            <div className="d-flex align-items-center">
                <label>
                    {question.label}
                </label>
                <div className="d-flex flex-grow-1 justify-content-end">
                    <div className='d-flex align-items-center'>
                        <input
                            type='radio'
                            id={question.ids[0]}                                    
                            name={question.name}
                            value={question.answers[0]}
                            onChange={handleChange}
                            checked={formData[`${question.name}`] === `${question.answers[0]}`}
                        />
                        <span className="mx-2">{question.answers[0]}</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <input
                            type='radio'
                            id={question.ids[1]}                                       
                            name={question.name}
                            value={question.answers[1]}
                            onChange={handleChange}
                            checked={formData[`${question.name}`] === `${question.answers[1]}`}
                        />
                        <span className="mx-2">{question.answers[1]}</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <input
                            type='radio'
                            id={question.ids[2]}                                       
                            name={question.name}
                            value={question.answers[2]}
                            onChange={handleChange}
                            checked={formData[`${question.name}`] === `${question.answers[2]}`}
                        />
                        <span className="mx-2">{question.answers[2]}</span>
                    </div>            
                </div>
            </div>
            {(formData[`${question.name}`] === `${question.answers[0]}` || formData[`${question.name}`] === `${question.answers[1]}`) && (
                <div>
                    <p>{question.descriptionUnderLabel}</p>
                    {question.span.map((textboxSpan, index) => {
                        return (
                            <div key={index}>
                                <span>{textboxSpan}</span>
                                <TextInput
                                    id={`${question.name} Description ${textboxSpan}`}
                                    name={`${question.name} ${textboxSpan} Description`}
                                    value={formData[`${question.name} ${textboxSpan} Description`]}
                                    onChange={handleChange}
                                    placeholder="Describe:"          
                                />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>          
    )
};

export const TraumaTable = ({formData, handleChange}) => {
    const [traumaHistory, setTraumaHistory] = useState([
        { type: '', perpetrator: '', ageAbuseBegan: '', duration: '' },
        { type: '', perpetrator: '', ageAbuseBegan: '', duration: '' },
    ]);

    const handleAddTraumaHistory = () => {
        setTraumaHistory([...traumaHistory,  { type: '', perpetrator: '', ageAbuseBegan: '', duration: '' }]);
    };


    useEffect(() => {
        const otherTraumaHistoryIndices = Object.keys(formData)
            .filter(key => key.startsWith('traumaHistoryType['))
            .map(key => parseInt(key.match(/\d+/)[0]));

        if (otherTraumaHistoryIndices.length > 0) {
            const updatedTraumaHistory = [...traumaHistory];
            otherTraumaHistoryIndices.forEach(index => {
                updatedTraumaHistory[index] = {
                    type: formData[`traumaHistoryType[${index}]`] || '',
                    perpetrator: formData[`traumaHistoryPerpetrator[${index}]`] || '',
                    ageAbuseBegan: formData[`traumaHistoryAgeAbuseBegan[${index}]`] || '',
                    duration: formData[`traumaHistoryDuration[${index}]`] || ''
                };
            });
            setTraumaHistory(updatedTraumaHistory);
        }
    }, [formData]);

    return (
        <div>
            <table className='custom-table w-100'>
                <thead>
                    <tr>
                        <th><label>Type</label></th>
                        <th><label>Perpetrator</label></th>
                        <th><label>Age Abuse Began</label></th>
                        <th><label>Duration</label></th>
                    </tr>
                </thead>
                <tbody>
                    {traumaHistory.map((legalCharge, index) => (
                        <tr key={index}>
                            <td>
                                <TextInput
                                    id={`traumaHistoryType[${index}]`}
                                    name={`traumaHistoryType[${index}]`}
                                    value={formData[`traumaHistoryType[${index}]`]}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <TextInput
                                    id={`traumaHistoryPerpetrator[${index}]`}
                                    name={`traumaHistoryPerpetrator[${index}]`}
                                    value={formData[`traumaHistoryPerpetrator[${index}]`]}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <TextInput
                                    id={`traumaHistoryAgeAbuseBegan[${index}]`}
                                    name={`traumaHistoryAgeAbuseBegan[${index}]`}
                                    value={formData[`traumaHistoryAgeAbuseBegan[${index}]`]}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <TextInput
                                    id={`traumaHistoryDuration[${index}]`}
                                    name={`traumaHistoryDuration[${index}]`}
                                    value={formData[`traumaHistoryDuration[${index}]`]}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" onClick={handleAddTraumaHistory} className='text-button'>+ Add New Occurence</button>
        </div>
    );
}