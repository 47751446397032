import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import { FaArrowLeft } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { Row, Col, Image, Form, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import Loader from "components/loader/Loader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(
      object().shape({
        email: string().email().required("The email field is required"),
      })
    ),
  });
  const { errors } = formState;

  const handleKeyDown = (e) => {
    const { value, selectionStart } = e.target;
    if (e.key === " " && selectionStart === 0) {
      e.preventDefault();
    }
    const precedingChar = value[selectionStart - 1];
    const followingChar = value[selectionStart];
    if (e.key === " " && (precedingChar === " " || followingChar === " ")) {
      e.preventDefault();
    }
  };

  const onSubmit = async (formData) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/changePassword/sendChangePasswordMail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
          }),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        setLoader(false);
        toast.success(responseData.message);
      } else {
        setLoader(false);
        toast.error(responseData.message || "Password change failed");
      }
    } catch (error) {
      setLoader(false);
      console.error("error", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="vh-100 w-100 loginBglight px-3 overflow-hidden">
      <Row className="h-100">
        <Col md={5} className="h-100">
          <div className="d-flex align-content-between flex-wrap h-100">
            <Image
              onClick={() => navigate("/login")}
              src="images/logo-spero.png"
              className="ps-4 pt-3"
              alt=""
            />
            <Image src="images/login-bg.svg" className="w-100" alt="" />
          </div>
        </Col>
        <Col md={7} className="h-100 bg-white">
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="maxWidth">
              <h1 className="text-center">Forgot Password</h1>
              <p className="text-center mb-5">
                Please fill in your email to get your email link.
              </p>

              <Form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    {...register("email")}
                    type="email"
                    id="email"
                    className="input_form_field"
                    placeholder="Enter email"
                    name="email"
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                  />
                  <div className="text-danger">
                    {errors.email?.message?.toString()}
                  </div>
                </Form.Group>

                <div className="w-100">
                  <Button
                    type="submit"
                    className="btn btn-primary w-100 mt-4 py-3"
                  >
                    Forgot Password
                  </Button>
                </div>
                <div className="mt-5 text-center">
                  <p>
                    <span className="px-2">
                      <FaArrowLeft />
                    </span>
                    Back to <Link to="/login">Log in</Link>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      {loader && <Loader />}
    </div>
  );
};

export default ForgotPassword;
