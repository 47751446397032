import React, { useEffect, useState } from "react";
import "./DBTracker.css";

const WeeklyDatePicker = () => {
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek());
  const [weekRange, setWeekRange] = useState("");

  //   const [maxWeek, setMaxWeek] = useState(getCurrentWeek());

  //   //   my addition
  //   const [myWeekStartDate, setMyWeekStartDate] = useState("");
  //   const [myWeekEndDate, setMyWeekEndDate] = useState("");

  useEffect(() => {
    const startDate = getStartDateOfWeek(selectedWeek);
    const endDate = getEndDateOfWeek(selectedWeek);
    setWeekRange(`${formatDateMonth(startDate)} - ${formatDateOnly(endDate)}`);
  }, []);

  const handleWeekChange = (event) => {
    const weekValue = event.target.value;
    console.log(weekValue);
    setSelectedWeek(weekValue);
    const startDate = getStartDateOfWeek(weekValue);
    const endDate = getEndDateOfWeek(weekValue);
    setWeekRange(`${formatDateMonth(startDate)} - ${formatDateOnly(endDate)}`);
  };

  const getStartDateOfWeek = (weekString) => {
    const [year, week] = weekString.split("-W");
    const firstDayOfYear = new Date(year, 0, 1);
    const days = (week - 1) * 7;
    const dayOffset = firstDayOfYear.getDay() <= 4 ? 1 : -1;
    const weekStartDate = new Date(
      firstDayOfYear.setDate(
        firstDayOfYear.getDate() - firstDayOfYear.getDay() + dayOffset + days
      )
    );
    return weekStartDate;
  };

  const getEndDateOfWeek = (weekString) => {
    const startDate = getStartDateOfWeek(weekString);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    return endDate;
  };

  //   const formatDate = (date) => {
  //     return date.toLocaleDateString("en-US", {
  //       month: "long",
  //       day: "numeric",
  //       year: "numeric",
  //     });
  //   };

  const formatDateMonth = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    });
  };
  const formatDateOnly = (date) => {
    return date.toLocaleDateString("en-US", {
      day: "numeric",
    });
  };

  function getCurrentWeek() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const startOfYear = new Date(year, 0, 1);
    const days = Math.floor(
      (currentDate - startOfYear) / (1000 * 60 * 60 * 24)
    );
    const week = Math.ceil((days + startOfYear.getDay() + 1) / 7);
    return `${year}-W${String(week).padStart(2, "0")}`;
  }

  return (
    <div className="week_picker">
      <label htmlFor="week">Timeframe:</label>
      <input
        type="week"
        id="week"
        value={selectedWeek}
        onChange={handleWeekChange}
        // max={maxWeek} // Disable future weeks
      />
      {/* {weekRange && <p>{weekRange}</p>} */}
      <p>{weekRange}</p>
    </div>
  );
};

export default WeeklyDatePicker;
