export const NewPatientSidebar = ({ location, blockTexts }) => {
    const currentPageIndex = blockTexts.findIndex((_, index) => location.pathname === `/newPatient/page${index + 1}`);
    
    const PAGES = blockTexts.map((label, index) => ({
        label: label.text,
        icon: '',
        completed: index <= currentPageIndex,
    }));
    
    return (
        <div className="sideBar">
            <ul>
                {PAGES.map((page, index) => (
                    <li 
                        key={index}
                        className={`page-item ${page.completed ? 'completed' : ''} ${index === currentPageIndex ? 'current' : ''}`}
                    >
                        <div>
                            <span>
                                {index === currentPageIndex ? (
                                    <i className="far fa-circle pe-1"></i>
                                ) : page.completed ? (
                                    <i className="fa fa-check-circle pe-1"></i>
                                ) : null}
                            </span>
                            <span className='bold-text'>
                                {page.label}
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
