import React from 'react';

const ClinicalTaskModal = ({ clinicalInfo }) => {
  const { selectedClinicalData, setIsClinicalTaskModalOpen } = clinicalInfo;

  if (!selectedClinicalData) {
    return null;
  }

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
          <button type="button" className="btn-close" onClick={() => setIsClinicalTaskModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-3 pb-5 px-5">
          <div className='headingSec d-flex justify-content-between mb-4'>
            <h2 className='mb-0'> { selectedClinicalData.description } <img src="images/tinder.png" width={15} alt="" /></h2>

          </div>
          <div className='row gy-2'>
            <div className='col-md-6'>
              <div className="d-flex align-items-center">
                <label className="me-2">Date created:</label>
                <p className="mb-0">{ selectedClinicalData.createdDate }</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="d-flex align-items-center">
                <label className="me-2">Date updated:</label>
                <p className="mb-0">{ selectedClinicalData.updatedDate }</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="d-flex align-items-center">
                <label className="me-2">Provider:</label>
                <p className="mb-0"><img src={ selectedClinicalData.providerImage } alt="" /> { selectedClinicalData.provider } </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="d-flex align-items-center">
                <label className="me-2">Performer:</label>
                <p className="mb-0"><img src={ selectedClinicalData.performerImage } alt="" /> { selectedClinicalData.performer } </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="d-flex align-items-center">
                <label className="me-2">Patient:</label>
                <p className="mb-0"><img src="images/doctor-pic.png" alt="" /> Lori Briggs</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className="d-flex align-items-center">
                <label className="me-2">Status:</label>
                <button type="button" className={`btn ${selectedClinicalData.status === 'Pending' ? 'pendingBtn' : selectedClinicalData.status === 'Completed' ? 'completeBtn' : 'inProgressBtn'}`}>{selectedClinicalData.status}</button>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <hr></hr>
          </div>
          <div className='col-md-12 mb-3'>
            <label className="mb-2 fs-5">Leave a comment</label>
            <div className="d-flex align-items-center gap-2">
              <img src="images/ellipse.png" width={35} alt="" />
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Leave a comment..."></input>
              <img src="images/send.svg" alt="" />
            </div>
          </div>
          <div className='cardPaitent'>
            <div className='col-md-12'>
              <label className="mb-2 fs-6">All comments (4):</label>
            </div>
            <div className='col-md-12 mb-3'>
              <div className="d-flex align-items-center">
                <h6 className="mb-0"><img src="images/doctor-pic.png" alt="" /> Walter Pearlman</h6>
              </div>
              <p className='m-0 fs-6'>I’m a bit delayed with this task, I apologize <b className='text-green'>@Dr. Jones</b></p>
              <span class="date"> Nov 26 2023  05:12PM</span>
            </div>
            <div className='col-md-12 mb-3'>
              <div className="d-flex align-items-center">
                <h6 className="mb-0"><img src="images/doctor-pic.png" alt="" /> Dr. Jones</h6>
              </div>
              <p className='m-0 fs-6'>Insurance approved IOP not PHP will reassess in 10 days, this PTSD assessment will be used at the peer to peer at that time.</p>
              <span class="date"> Nov 26 2023  03:24PM</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalTaskModal;
