import React, { useState } from "react";

const PrimaryConcerns = ({ listingallPatients }) => {
  const patient = listingallPatients[0];
  const buttonClass =
    patient.status === null
      ? "pendingBtn"
      : patient.status === 1
      ? "Denied"
      : "completeBtn";

  const buttonLabel = patient.status === null ? "Pending" : "Accepted";

  // Parse Primary_concern string into an array
  let primaryConcerns = [];
  try {
    primaryConcerns = JSON.parse(patient.Primary_concern.replace(/'/g, '"'));
  } catch (error) {
    console.error("Failed to parse Primary_concern:", error);
  }

  // Initialize state to track the checked status of each concern
  const [checkedConcerns, setCheckedConcerns] = useState(
    primaryConcerns.reduce((acc, concern) => {
      acc[concern] = false;
      return acc;
    }, {})
  );

  const handleCheckboxChange = (concern) => {
    setCheckedConcerns((prevState) => ({
      ...prevState,
      [concern]: !prevState[concern],
    }));
  };

  return (
    <div className="col-md-12 assignmentList mt-4">
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-single"
          role="tabpanel"
          aria-labelledby="pills-single-tab"
        >
          <ul className="p-0 assignmentList d-grid gap-3">
            {primaryConcerns?.map((concern, index) => (
              <li key={index} className="border rounded-3 py-2 px-3">
                <div className="d-flex align-items-center w-100">
                  {/* <div className="flex-shrink-0">
                    <input
                      className="form-check-input mt-1"
                      type="checkbox"
                      name={`concern-${index}`}
                      id={`concern-${index}`}
                      width={40}
                      height={40}
                      checked={checkedConcerns[concern]}
                      onChange={() => handleCheckboxChange(concern)}
                    />
                  </div> */}
                  <div className="flex-grow-1 ms-3 gap-2">
                    <h1 className="mb-2 fs-6">{concern}</h1>
                  </div>
                  <div className="col-md-auto"></div>
                  {/* <div className="col-md-auto">
                    <button type="button" className={`btn ${buttonClass}`}>
                      {buttonLabel}
                    </button>
                  </div> */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const assessments = [
  {
    title: "Anxiety",
    description:
      "Anxiety is an overwhelming sense of worry and unease, often accompanied by physical symptoms, that can hinder daily life.",
    status: "",
  },
  {
    title: "Depression",
    description:
      "Depression is a pervasive mental health disorder marked by a range of emotional and physical symptoms.",
    status: "Selected",
  },
  {
    title: "PTSD",
    description:
      "Condition resulting from experiencing a traumatic event, leading to symptoms like flashbacks, nightmares, and heightened anxiety.",
    status: "",
  },
  {
    title: "Eating disorders",
    description:
      "Range of unhealthy eating behaviors and attitudes towards food, and can have serious physical and psychological consequences.",
    status: "",
  },
  {
    title: "OCD",
    description:
      "Condition characterized by intrusive, distressing thoughts paired with repetitive behaviors or mental rituals aimed at reducing anxiety.",
    status: "Selected",
  },
  {
    title: "Personality disorders",
    description:
      "Patterns of thoughts, behaviors, and emotions that lead to difficulties in relating to oneself and others, causing distress and impairment.",
    status: "",
  },
];
export default PrimaryConcerns;
