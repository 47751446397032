import React from "react";
import { ProgressBar } from "react-bootstrap";

const PatientBillingProfile = ({ listingProfile }) => {
  const dataToUse = listingProfile?.insurancebenefit;
  let percentageValue1 = dataToUse ? dataToUse.familyOOP1 : 0;
  let percentageValue2 = dataToUse ? dataToUse.familyOOP2 : 0;
  const remainingPercentageOne = 100 * (percentageValue1 / percentageValue2)?.toFixed(2);

  let PerceindividualOOP1 = dataToUse ? dataToUse.individualOOP1 : 0;
  let PerceindividualOOP2 = dataToUse ? dataToUse.individualOOP2 : 0;
  const percentageRemainingTwo = 100 * (PerceindividualOOP1 / PerceindividualOOP2)?.toFixed(2);

  let perFamilyDeductible1 = dataToUse ? dataToUse.familyDeductible1 : 0;
  let perCeindividualOOP2 = dataToUse ? dataToUse.familyDeductible2 : 0;
  const percentageRemainingThree = 100 * (perFamilyDeductible1 / perCeindividualOOP2)?.toFixed(2);

  let perIndividualDeductible1 = dataToUse ? dataToUse.individualDeductible1 : 0;
  let perIndividualDeductible2 = dataToUse ? dataToUse.individualDeductible2 : 0;
  const percentageRemainingFour = 100 * (perIndividualDeductible1 / perIndividualDeductible2)?.toFixed(2);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div id="treatmentPlan">
      <div className="headingTable cardPaitent">
        <div className="row d-flex">
          <div className="col-2">
            <div class="flex-shrink-0">
              <img src="/images/avatar.png" alt="" width={75} height={75} />
            </div>
          </div>
          <div className="col-10">
            <div className="row d-flex mb-2">
              <h1 className="mb-2">{`${listingProfile?.firstName} ${listingProfile?.lastName}`}</h1>
              <div className="col-4">
                <label>
                  Patient No: : <span>#{listingProfile?.id}</span>
                </label>
              </div>
              <div className="col-4">
                <label>
                  Gender: <span>{listingProfile?.gender}</span>
                </label>
              </div>
              <div className="col-4">
                <label>
                  LOC : <span>IOP</span>
                </label>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-4">
                <label>
                  AGE : <span>{listingProfile?.dob} Years</span>
                </label>
              </div>
              <div className="col-4">
                <label>
                  DOB : <span>{listingProfile?.dob}</span>
                </label>
              </div>
              <div className="col-4">
                <label>
                  Admission Date: <span>{formatDate(listingProfile?.createdAt)}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div id="treatmentPlan">
          <div className="headingTable cardPaitent">
            <div className="row d-flex mt-4">
              <div className="col-4">
                <div className="row d-flex mb-2">
                  <h1 className="mb-2">Outpatient Mental Health Services</h1>
                </div>
                <span>Individual therapy</span>
                <br />
                <br />
                <br />
                <span>Group therapy</span>
                <br />
                <br />
                <br />
                <span>Family therapy</span>
                <br />
                <br />
                <br />
                <span>Psychiatric consultations</span>
                <br />
                <br />
                <br />
                <span>Medication management</span>
                <div className="row d-flex mt-4">
                  <h1 className="mb-2">Inpatient Mental Health Services</h1>
                </div>
                <span>Inpatient psychiatric hospitalization</span>
                <div className="row d-flex mt-4">
                  <h1 className="mb-2">Network Providers</h1>
                </div>
                <span>Preferred network</span>
              </div>
              <div className="col-4">
                <div className="row d-flex mb-4">
                  <h1 className="mb-2">Insurance coverage</h1>
                </div>
                <span>
                  Covered at
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.coverage1 : 0}
                  </span>{" "}
                  after
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.copayment1 : 0}
                  </span>{" "}
                  copayment per session.
                </span>
                <br />
                <br />
                <span>
                  Covered at
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.coverage2 : 0}
                  </span>{" "}
                  after{" "}
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.copayment2 : 0}
                  </span>{" "}
                  copayment per session.
                </span>
                <br />
                <br />
                <span>
                  Covered at
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.coverage3 : 0}
                  </span>{" "}
                  after
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.copayment3 : 0}
                  </span>{" "}
                  copayment per session.
                </span>
                <br />
                <br />
                <span>
                  Covered at{" "}
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.coverage4 : 0}
                  </span>{" "}
                  after
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.copayment4 : 0}
                  </span>{" "}
                  copayment per visit.
                </span>
                <br />
                <br />
                <span>
                  Covered at
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.coverage5 : 0}
                  </span>{" "}
                  after
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.copayment5 : 0}
                  </span>{" "}
                  copayment per visit.
                </span>
                <br />
                <br />
                <br />
                <br />
                <span>
                  Covered at
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.coverage6 : 0}
                  </span>{" "}
                  after
                  <span style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                    ${dataToUse ? dataToUse.deductible : 0}
                  </span>{" "}
                  deductible per admission. Requires preauthorization.
                </span>
                <br />
                <br />
                <br />
                <span>
                  Trustory Insurance has a network of mental health providers.
                  Using in-network providers will result in lower out-of-pocket
                  costs.
                </span>
              </div>
              <div className="col-4" style={{ background: "#F8F9FE" }}>
                <div className="row d-flex mt-4">
                  <div className="col-2">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/Salary_Date.png"
                        alt=""
                        width={50}
                        height={50}
                      />
                    </div>
                  </div>
                  <div className="col-10">
                    <h2
                      className="mb-2"
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#5B6680",
                        lineHeight: "21px",
                      }}>
                      Benefits plan
                    </h2>
                    <h1 className="text-center">{listingProfile?.Inr_com_name}</h1>
                  </div>
                </div>
                <h1 className="text-left">{remainingPercentageOne? remainingPercentageOne?.toFixed(2) : 0}%</h1>
                <ProgressBar now={remainingPercentageOne?remainingPercentageOne:0} />
                <div className="row">
                  <span className="mt-4">
                    <span
                      style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                      ${dataToUse ? dataToUse.familyOOP1 : 0}
                    </span>{" "}
                    of your
                    <span
                      style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                      ${dataToUse ? dataToUse.familyOOP2 : 0}
                    </span>{" "}
                    Family Out-of-Pocket has been met for 2023
                  </span>
                </div>
                <h1 className="text-left">{percentageRemainingTwo? percentageRemainingTwo?.toFixed(2) :0}%</h1>
                <ProgressBar now={percentageRemainingTwo?percentageRemainingTwo:0} />
                <div className="row">
                  <span className="mt-4">
                    <span
                      style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                      ${dataToUse ? dataToUse.individualOOP1 : 0}
                    </span>{" "}
                    of your
                    <span
                      style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                      ${dataToUse ? dataToUse.individualOOP2 : 0}
                    </span>{" "}
                    Individual Out-of-Pocket Max has been met for 2023
                  </span>
                </div>
                <h1 className="text-left">{percentageRemainingThree?percentageRemainingThree?.toFixed(2):0}%</h1>
                <ProgressBar now={percentageRemainingThree?percentageRemainingThree:0} />
                <div className="row">
                  <span className="mt-4">
                    <span
                      style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                      ${dataToUse ? dataToUse.familyDeductible1 : 0}
                    </span>{" "}
                    of your
                    <span
                      style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                      ${dataToUse ? dataToUse.familyDeductible2 : 0}
                    </span>{" "}
                    Family Deductible has been met for 2023
                  </span>
                </div>
                <h1 className="text-left">{percentageRemainingFour?percentageRemainingFour?.toFixed(2):0}%</h1>
                <ProgressBar now={percentageRemainingFour?percentageRemainingFour:0} />
                <div className="row">
                  <span className="mt-4">
                    <span
                      style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                      ${dataToUse ? dataToUse.individualDeductible1 : 0}
                    </span>{" "}
                    of your
                    <span
                      style={{ border: "1px solid #ECECEC", padding: "5px" }}>
                      ${dataToUse ? dataToUse.individualDeductible2 : 0}
                    </span>{" "}
                    Individual Deductible has been met for 2023
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientBillingProfile;
