import React, { useState, useEffect } from "react";
import Header from "components/Header/Header";
import InsuranceBenefits from "../Requests/InsuranceBenefits";
import PatientBillingProfile from "../../Admin/Billing/PatientBillingProfile";
import BillingTabContent from "./BillingTabContent";
import Layout from "components/layout/layout";
import { assignUserGetDetails } from "lib/api/service";

const PatientBilling = () => {
  const [activeDiv, setActiveDiv] = useState("Insurance & Benefits");
  const [storeUserData, setStoreUserData] = useState(null);
  const [listingProfile, setlistingProfile] = useState([]);

  const showDiv = (divId) => {
    setActiveDiv(divId);
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    setStoreUserData(storedData);
  }, []);

  const getSingleViewProfile = async (id) => {
    try {
      const data = JSON.stringify({
        id: id,
        task: "patientdetails",
      });
      const response = await assignUserGetDetails(data);
      setlistingProfile(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (storeUserData?.id) {
      getSingleViewProfile(storeUserData?.id);
    }
  }, [storeUserData?.id]);

  const patient = listingProfile[0];

  return (
    <Layout>
      <div className="bg-white vh-100">
        <Header pageTitle="Billing" />
        <div id="clinical">
          <div className="main-content h-100">
            <div className="headingTable cardPaitent h-100">
              <div className="headingTable mb-4 px-4">
                <div className="row">
                  <div className="col-md-12">
                    <ul
                      className="nav nav-pills my-3 navTabUI"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li
                        className="nav-item flex-fill rounded-start-3"
                        role="presentation"
                      >
                        <button
                          className="nav-link active py-2 w-100"
                          id="pills-single-tab"
                          data-bs-toggle="pill"
                          onClick={() => showDiv("Insurance & Benefits")}
                          data-bs-target="#pills-single"
                          type="button"
                          role="tab"
                          aria-controls="pills-single"
                          aria-selected="true"
                        >
                          Insurance & Benefits
                        </button>
                      </li>
                      <li
                        className="nav-item flex-fill rounded-end-3"
                        role="presentation"
                      >
                        <button
                          className="nav-link py-2 w-100"
                          id="pills-reoccurring-tab"
                          data-bs-toggle="pill"
                          onClick={() => showDiv("Billing")}
                          data-bs-target="#pills-reoccurring"
                          type="button"
                          role="tab"
                          aria-controls="pills-reoccurring"
                          aria-selected="false"
                          disabled
                        >
                          Billing
                        </button>
                      </li>
                    </ul>
                  </div>
                  {activeDiv === "Insurance & Benefits" &&
                    listingProfile?.length > 0 && (
                      <PatientBillingProfile listingProfile={patient} />
                    )}
                  {activeDiv === "Billing" && (
                    <>
                      <BillingTabContent />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PatientBilling;
