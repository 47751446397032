import React, { useState } from 'react';

const AddNotesDataModal = ({ addNotesInfo }) => {

  const [newMedication, setNewMedication] = useState(null);
  const [isShowOrderBlood, setIsShowOrderBlood] = useState(false);

  const { setAddNotesModalOpen } = addNotesInfo;

  const handleBloodCheckboxChange = () => {
    setIsShowOrderBlood(!isShowOrderBlood);
  };

    // if (!selectedOrdersData) {
  //   return null;
  // }

  return (

    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setAddNotesModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-0 px-4">
          <div className='headingSec mb-4'>
            <h2 className='mb-0'>Add a new note</h2>
          </div>

          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">

              <div className="col-md-6 mb-2">

                <div className="col-md-12 mb-2">
                  <div className="d-flex align-items-center">
                    <label className="me-2">Provider:</label>
                    <p className="mb-0"><img src="images/doctor-pic.png" alt="" />
                      {/* { selectedOrdersData.provider } */}
                      Dr. Alvares
                    </p>
                  </div>
                </div>

                <div className="col-md-12 mb-2">
                  <div className="d-flex align-items-center">
                    <label className="me-2">Patient:</label>
                    <p className="mb-0"><img src="images/doctor-pic.png" alt="" />
                      {/* { selectedOrdersData.provider } */}
                      Dr. Alvares
                    </p>
                  </div>
                </div>

              </div>

              <div className='row mb-3'>

                <label className='mb-2'>Note name:</label>
                <div className='d-flex align-items-center gap-3'>

                  <div className='border rounded-3 p-3'>
                    <p className='mb-0 fs-6'>
                      Schedule a session with the patient to conduct a thorough clinical interview. Explore current symptoms and assess patient mental health status.
                    </p>
                  </div>
                </div>

              </div>

              <div className='row mb-3'>

                <div className='col-md-6'>

                  <div className='col-md-12'>

                    <div>
                      <label htmlFor="exampleFormControlInput1" className="form-label">Summary (optional):</label>
                    </div>

                    <div className='mt-3'>
                      <label htmlFor="exampleFormControlInput1" className="form-label">Select one or few options:</label>
                    </div>

                    {(newMedication && !isShowOrderBlood) && (

                      <div className="mb-3">
                        <div className='align-items-center gap-3'>
                          <div className='border rounded-3 p-3'>
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" id="inlineFormCheck" checked />
                              <label className="form-check-label form-label" htmlFor="inlineFormCheck">
                                Add new medication
                              </label>
                              <p className=''>Propranolol XR, 40 mg, 1 x per day AM, 90 days supply, Anxiety</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div>

                      {/* ////////////////////// */}


                      <div className="mb-3">
                        {/* <div className='d-flex align-items-center gap-3'> */}
                        <div className='align-items-center gap-3'>
                          <div className='border rounded-3 p-3'>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineFormCheck"
                                // checked={isChecked}
                                onChange={handleBloodCheckboxChange}
                              />
                              <label className="form-check-label" htmlFor="inlineFormCheck">
                                Order blood work
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="mb-3">
                        {/* <div className='d-flex align-items-center gap-3'> */}
                        <div className='align-items-center gap-3'>
                          <div className='border rounded-3 p-3'>
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" id="inlineFormCheck" />
                              <label className="form-check-label" htmlFor="inlineFormCheck">
                                Order genetic testing
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        {/* <div className='d-flex align-items-center gap-3'> */}
                        <div className='align-items-center gap-3'>
                          <div className='border rounded-3 p-3'>
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" id="inlineFormCheck" />
                              <label className="form-check-label" htmlFor="inlineFormCheck">
                                Send patient to specialist
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>


                      {/* ///////////////////////////// */}

                    </div>


                  </div>

                </div>

                <div className='col-md-6'>

                  <div className='col-md-12'>

                    <div className='headingSec mb-4'>

                      {!newMedication && !isShowOrderBlood && (
                        <>
                          <h2 className='mb-0'>Add a new medication</h2>
                          {/* add medication  */}
                          <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">

                              <div className='row mb-3  mt-3'>
                                <div className='col-md-12'>
                                  <label htmlFor="exampleFormControlInput1" className="form-label">Medication name:</label>
                                  <input type="text" placeholder='Propranolol XR' className="form-control" id="exampleFormControlInput1" />
                                </div>
                              </div>


                              <div className='row mb-3'>
                                <div className='col-md-6'>
                                  <label htmlFor="exampleFormControlInput1" className="form-label">Dose:</label>
                                  <input type="text" placeholder='40 mg' className="form-control" id="exampleFormControlInput1" />
                                </div>
                                <div className='col-md-6'>
                                  <label htmlFor="exampleFormControlInput1" className="form-label">Frequency:</label>
                                  <input type="text" placeholder='1x per day AM' className="form-control" id="exampleFormControlInput1" />
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-md-6'>
                                  <label htmlFor="exampleFormControlInput1" className="form-label">Reason:</label>
                                  <input type="text" placeholder='40 mg' className="form-control" id="exampleFormControlInput1" />
                                </div>
                                <div className='col-md-6'>
                                  <label htmlFor="exampleFormControlInput1" className="form-label">Fill:</label>
                                  <input type="text" placeholder='1x per day AM' className="form-control" id="exampleFormControlInput1" />
                                </div>
                              </div>

                              <div className='gap-4 d-flex justify-content-center p-4'>
                                <button type="button" className="btn btn-primary px-5 btnSize rounded-3" onClick={() => setNewMedication(true)}>Add a new medication</button>
                              </div>
                            </div>

                          </div>
                        </>
                      )}
                      {/* add medication  */}

                      {/* edit medication  */}
                      {newMedication && !isShowOrderBlood && (
                        <>
                          <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">

                              <div className='row mb-3  mt-3'>
                                <div className='col-md-12'>
                                  <label htmlFor="exampleFormControlInput1" className="form-label">Added:</label>
                                </div>
                              </div>


                              <div className='row'>
                                <div className='col-md-12'>

                                  <p className='mb-0 fs-6'>Propranolol XR, 40 mg, 1 x per day AM, 90 days supply, Anxiety</p>

                                </div>
                              </div>

                              <div className='gap-4 d-flex justify-content-center p-4'>
                                <button type="button" className="btn btn-primary px-5 btnSize rounded-3" onClick={() => setAddNotesModalOpen(false)}>Edit new medication</button>
                              </div>
                            </div>

                          </div>
                        </>
                      )}

                      {/* edit medication  */}

                    </div>

                  </div>

                  {/* //////////////// */}

                  <div className='col-md-12'>
                    <div className='headingSec mb-4'>
                      {isShowOrderBlood && (
                        <>
                          <h2 className='mb-0'>Order blood work</h2>
                          {/* add medication  */}
                          <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">

                              <div className='row mb-3  mt-3'>
                                <div className='col-md-12'>
                                  <label htmlFor="exampleFormControlInput1" style={{ color: "#18357A" }} className="form-label form-control">Select blood work type</label>
                                </div>
                              </div>

                              <div className='row mb-3  mt-3'>
                                <div className='col-md-12'>
                                  <label htmlFor="exampleFormControlInput1" className="form-label">Recipient Address:</label>
                                  <input type="text" placeholder='144 Lake Road, Camden, New Jers...' className="form-control" id="exampleFormControlInput1" />
                                </div>
                              </div>

                              <div className='row mb-3  mt-3'>
                                <div className='col-md-12'>
                                  <label htmlFor="exampleFormControlInput1" className="form-label">Recipient Name:</label>
                                  <input type="text" placeholder='MediLab' className="form-control" id="exampleFormControlInput1" />
                                </div>
                              </div>

                              <div className='row mb-3  mt-3'>
                                <div className='col-md-12'>
                                  <label htmlFor="exampleFormControlInput1" className="form-label">Recipient Fax:</label>
                                  <input type="text" placeholder='+17705644561' className="form-control" id="exampleFormControlInput1" />
                                </div>
                              </div>

                              <div className='gap-4 d-flex justify-content-center p-4'>
                                <button type="button" className="btn btn-primary px-5 btnSize rounded-3" onClick={() => setNewMedication(true)}>Submit Order</button>
                              </div>
                            </div>

                          </div>
                        </>
                      )}

                      {/* edit medication  */}

                    </div>

                  </div>


                  {/* /////////////////////////////// */}
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
};

export default AddNotesDataModal;
