import React, { useEffect, useState } from 'react';
import { TextInput, DateInput, Dropdown, DOBDateInput, CountryDropdown } from '../../../../components/FormComponents/FormComponents';
import { GENDER_IDENTITIES, SEXUAL_ORIENTATIONS, RACES } from '../../../../Data';
import { toast } from 'react-toastify';

const calculateDisableGuardianInputs = (dob) => {
    const dobDate = new Date(dob);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - dobDate.getFullYear();
    return age >= 18;
};

const NewPatientPage1 = ({ formData, handleChange, handleSubmit, setFormData, patientId, showFooter = true }) => {
    const [showNewEmergencyContact, setShowNewEmergencyContact] = useState(false);
    const [numEmergencyContacts, setNumEmergencyContacts] = useState(1); // Default to 1 initially
    const [addedEmergencyFormsIndices, setAddedEmergencyFormsIndices] = useState([0]);
    const [errors, setError] = useState(false);

    const handleAddEmergencyContact = () => {
        setNumEmergencyContacts(prevNum => prevNum + 1);
        const newIndex = numEmergencyContacts;
        setAddedEmergencyFormsIndices(prevIndices => [...prevIndices, newIndex]);
        setFormData(prevData => ({
            ...prevData,
            [`emergencyContact[${newIndex}]`]: '',
            [`emergencyPhoneNumber[${newIndex}]`]: '',
            [`emergencyPhoneNumberCountry[${newIndex}]`]: ''
        }));
        setShowNewEmergencyContact(true);
    };

    const handleRemoveEmergencyContact = () => {
        const lastAddedIndex = addedEmergencyFormsIndices.pop();
        setFormData(prevData => {
            const updatedFormData = { ...prevData };
            delete updatedFormData[`emergencyContact[${lastAddedIndex}]`];
            delete updatedFormData[`emergencyPhoneNumber[${lastAddedIndex}]`];
            delete updatedFormData[`emergencyPhoneNumberCountry[${lastAddedIndex}]`];
            return updatedFormData;
        });
        setNumEmergencyContacts(prevNum => Math.max(1, prevNum - 1));
        setAddedEmergencyFormsIndices(prevIndices => prevIndices.filter(index => index !== lastAddedIndex));
        if (addedEmergencyFormsIndices.length === 0) {
            setNumEmergencyContacts(0);
            setShowNewEmergencyContact(false);
        }
    };

    const formBeforeSubmit = (e) => {
        setError(false);
        e.preventDefault();
        const formData = new FormData(e.target);
        for (const [key, value] of formData.entries()) {
            if ((key === "guardianNumber" || key === "patientPhoneNumber" || key.startsWith('emergencyPhoneNumber[')) && (value.length < 10 || value.length > 12)) {
                const errorMessage = key === "guardianNumber" ? "Incorrect guardian phone number format" :
                    key === "patientPhoneNumber" ? "Incorrect patient phone number format" :
                        "Incorrect emergency phone number format";
                toast.error(errorMessage);
                setError(errorMessage);
                return;
            }
        }
        handleSubmit(e);
    };

// previous button
const handleGoBack = () => {
    window.history.back();
  };
    return (
        <div>
            <form onSubmit={formBeforeSubmit}>
                <div className="form-group row">
                    <div className="col-6">
                        <TextInput
                            id="clinicianName"
                            name="clinicianName"
                            value={formData.clinicianName}
                            onChange={handleChange}
                            label="Clinician Name:"
                            required
                        />
                    </div>
                    <div className='col-6'>
                        <DateInput
                            id="dateOfAdmission"
                            name="dateOfAdmission"
                            value={formData.dateOfAdmission}
                            onChange={handleChange}
                            label="Date of Admission/Intake:"
                            required
                        />
                    </div>
                </div>
                <div>
                    <TextInput
                        id="programName"
                        name="programName"
                        value={formData.programName}
                        onChange={handleChange}
                        label="Program Name:"
                        required
                    />
                </div>
                <div className='form-group row'>
                    <div className='col-6'>
                        <TextInput
                            id="clientName"
                            name="clientName"
                            value={formData.clientName}
                            onChange={handleChange}
                            label="Client Name:"
                        />
                        <TextInput
                            id="guardianName"
                            name="guardianName"
                            value={formData.guardianName}
                            onChange={handleChange}
                            label="Guardian Name:"
                            disabled={calculateDisableGuardianInputs(formData.dob)}
                            required
                        />
                        <div className='row'>
                            <label htmlFor="guardianNumber">
                                Guardian Phone Number:
                            </label>
                            <div className='col-12 col-md-4'>
                                <CountryDropdown
                                    label=""
                                    name="guardianNumberCountry"
                                    value={formData.guardianNumberCountry}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='col-12 col-md-8'>
                                <input
                                    type="number"
                                    id="guardianNumber"
                                    name="guardianNumber"
                                    value={formData.guardianNumber}
                                    onChange={handleChange}
                                    className="form-control"
                                    pattern=".{10,12}"
                                    minLength="10"
                                    disabled={calculateDisableGuardianInputs(formData.dob)}
                                    required
                                />
                            </div>
                        </div>
                        <div className='intake-dropdown'>
                            <Dropdown
                                label="Sex:"
                                name="sex"
                                value={formData.sex}
                                options={['', 'Male', 'Female', 'Other']}
                                onChange={handleChange}
                            />
                            <Dropdown
                                label="Sexual Orientation:"
                                name="sexualOrientation"
                                value={formData.sexualOrientation}
                                options={SEXUAL_ORIENTATIONS}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='row'>
                            <label htmlFor="patientPhoneNumber">
                                Patient Phone Number:
                            </label>
                            <div className='col-12 col-md-4'>
                                <CountryDropdown
                                    label=""
                                    name="patientPhoneNumberCountry"
                                    value={formData.patientPhoneNumberCountry}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='col-12 col-md-8'>
                                <input
                                    type="number"
                                    id="patientPhoneNumber"
                                    name="patientPhoneNumber"
                                    value={formData.patientPhoneNumber}
                                    onChange={handleChange}
                                    minLength="10"
                                    className="form-control"
                                    pattern=".{10,12}"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <DOBDateInput
                            id="dob"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            label="DOB: (If under 18, add guardian info)"
                            required
                        />
                        <TextInput
                            id="guardianRelationship"
                            name="guardianRelationship"
                            value={formData.guardianRelationship}
                            onChange={handleChange}
                            label="Relationship with the Client:"
                            disabled={calculateDisableGuardianInputs(formData.dob)}
                            required
                        />
                        <div className='invisible'><TextInput /></div>
                        <div className='intake-dropdown'>
                            <Dropdown
                                label="Gender Identity:"
                                name="genderIdentity"
                                value={formData.genderIdentity}
                                options={GENDER_IDENTITIES}
                                onChange={handleChange}
                            />
                            <Dropdown
                                label="Race:"
                                name="race"
                                value={formData.race}
                                options={RACES}
                                onChange={handleChange}
                            />
                        </div>
                        <TextInput
                            id="patientEmail"
                            name="patientEmail"
                            value={formData.patientEmail}
                            onChange={handleChange}
                            label="Patient Email:"
                        />
                    </div>
                    <div>
                        <TextInput
                            id="patientAddress"
                            name="patientAddress"
                            value={formData.patientAddress}
                            onChange={handleChange}
                            label="Patient Address:"
                            required
                        />
                    </div>
                    <div className='col-6'>
                        <div>
                            <label htmlFor="patientAtHome">
                                Was the patient located at home?
                            </label>
                            <div className='d-flex align-items-center'>
                                <input
                                    type='radio'
                                    id='yes'
                                    name='patientAtHome'
                                    value='Yes'
                                    onChange={handleChange}
                                    checked={formData.patientAtHome === 'Yes'}
                                />
                                <label htmlFor='yes' className="mx-2">Yes</label>
                                <input
                                    type='radio'
                                    id='no'
                                    name='patientAtHome'
                                    value='No'
                                    onChange={handleChange}
                                    checked={formData.patientAtHome === 'No'}
                                />
                                <label htmlFor='no' className="mx-2">No</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <TextInput
                            id="patientLocation"
                            name="patientLocation"
                            value={formData.patientLocation}
                            onChange={handleChange}
                            label="If no, where were they located?"
                            disabled={formData.patientAtHome === 'Yes'}
                        />
                        </div>
                </div>

                {/* Emergency Contacts */}
                <div className='col-12'>
                    <div className='row form-group'>
                        <div className='col-md-6 col-12'>
                            <TextInput
                                id={`emergencyContact[0]`}
                                name={`emergencyContact[0]`}
                                value={formData[`emergencyContact[0]`]}
                                onChange={handleChange}
                                label={`Emergency Contact Name 1:`}
                            />
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='row'>
                                <label htmlFor={`emergencyPhoneNumber[0]`}>
                                    Emergency Contact Phone Number:
                                </label>
                                <div className='col-md-4 col-12'>
                                    <CountryDropdown
                                        label=""
                                        name={`emergencyPhoneNumberCountry[0]`}
                                        value={formData[`emergencyPhoneNumberCountry[0]`]}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-8 col-12'>
                                    <input
                                        type="number"
                                        id={`emergencyPhoneNumber[0]`}
                                        name={`emergencyPhoneNumber[0]`}
                                        value={formData[`emergencyPhoneNumber[0]`]}
                                        onChange={handleChange}
                                        className="form-control"
                                        minLength="10"
                                        pattern=".{10,12}"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {addedEmergencyFormsIndices.map((index) => (
                        index !== 0 && (
                            <div key={`emergencyContact${index}`} className='row form-group'>
                                <div className='col-md-6 col-12'>
                                    <TextInput
                                        id={`emergencyContact[${index}]`}
                                        name={`emergencyContact[${index}]`}
                                        value={formData[`emergencyContact[${index}]`]}
                                        onChange={handleChange}
                                        label={`Emergency Contact Name ${index + 1}:`}
                                    />
                                </div>
                                <div className='col-md-6 col-12'>
                                    <div className='row'>
                                        <label htmlFor={`emergencyPhoneNumber[${index}]`}>
                                            Emergency Contact Phone Number:
                                        </label>
                                        <div className='col-md-4 col-12'>
                                            <CountryDropdown
                                                label=""
                                                name={`emergencyPhoneNumberCountry[${index}]`}
                                                value={formData[`emergencyPhoneNumberCountry[${index}]`]}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <input
                                                type="number"
                                                id={`emergencyPhoneNumber[${index}]`}
                                                name={`emergencyPhoneNumber[${index}]`}
                                                value={formData[`emergencyPhoneNumber[${index}]`]}
                                                onChange={handleChange}
                                                className="form-control"
                                                minLength="10"
                                                pattern=".{10,12}"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ))}
                    {showFooter && (<div className='row d-flex justify-content-between'>
                        <div>
                            <button type='button' onClick={handleAddEmergencyContact} className='text-button'>
                                + Add New Emergency Contact
                            </button>
                        </div>
                        {showNewEmergencyContact && (
                            <div>
                                <button type='button' onClick={handleRemoveEmergencyContact} className='text-button' style={{ color: '#FF6760' }}>
                                    - Remove Emergency Contact
                                </button>
                            </div>
                        )}
                    </div>)}
                </div>

                {showFooter && (<div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                    <div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center'>
                        <button
                            className='btn btn-outline-secondary px-5 btnSize rounded-3 mx-4'
                            type='button'
                            onClick={handleGoBack}
                        >
                            Cancel
                        </button>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div >
                    {errors && <div className='text-danger'>{errors}</div>}
                </div >)}
            </form >
        </div >
    );
};

export default NewPatientPage1;
