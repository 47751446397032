import React from 'react';
import { Link } from 'react-router-dom';

const MedicationsOperationModal = ({ medicationsOperaitonInfo }) => {

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
  const day = String(currentDate.getDate()).padStart(2, '0');


  const { setIsMedicationsOperationModalOpen, selectedMedicationTitle, selectedMedicationData , updateData , changeHandler } = medicationsOperaitonInfo;
  return (

    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setIsMedicationsOperationModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-0 px-4">
          <div className='headingSec mb-4'>
            <h2 className='mb-0 text-center'>{ selectedMedicationTitle }</h2>
          </div>

          <div className="tab-content justify-content-center mb-4" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
              <form className='edit_modal' onSubmit={updateData}>
              <input type='hidden' name='id' value={selectedMedicationData['id']} />
              <div className='row mb-3'>
                <div className='col-md-12'>
                  <label htmlFor="exampleFormControlInput1" className="form-label">Medication selected:</label>

                  <div className='col-md-3'>
                      <button type="button" className="btn btn-success border-0">
                        { selectedMedicationData.name }
                      </button>
                  </div>

                </div>

              {selectedMedicationTitle === "Edit start date" && (
              <div className='col-md-6'>
                <label htmlFor="exampleFormControlInput1" className="form-label">Start date:</label>
                <input type="date" name="startDate" value={selectedMedicationData['startDate']} onChange={changeHandler} placeholder='May 06, 2023' className="form-control" id="exampleFormControlInput1" />
              </div>
              )}

              {selectedMedicationTitle === "Change dosage" && (
              <div className='col-md-6'>
                <label htmlFor="exampleFormControlInput1" className="form-label">Dosage: </label>
                <input type="text"  name="dosage" placeholder='60 mg AM' value={selectedMedicationData['dosage']} onChange={changeHandler}  className="form-control" id="exampleFormControlInput1" />
              </div>
              )}

              {selectedMedicationTitle === "Discontinue medication" && (
                <>
              <input type='hidden' name="endDate" value={`${year}-${month}-${day}`} />
              <div className='col-md-6'>
                <label htmlFor="exampleFormControlInput1" className="form-label">Reason: </label>
                <input type="text" name="message" value={selectedMedicationData['message']} onChange={changeHandler} placeholder='Caused headaches and drowsiness' className="form-control" id="exampleFormControlInput1" />
              </div>
              </>
              )}

              </div>

              <div className='gap-4 d-flex justify-content-center p-4'>
                <button type="button" className="btn btn-outline-secondary px-5 btnSize rounded-3" onClick={() => setIsMedicationsOperationModalOpen(false)}>Cancel</button>
                <button type="submit" className="btn btn-primary px-5 btnSize rounded-3" >Save changes</button>
              </div>
              </form>
            </div>

          </div>
          
        </div>
      </div>
    </div>

  );
};

export default MedicationsOperationModal;
