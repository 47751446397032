// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomDropdown_custom_dropdown__lGHXW {
  position: relative;
  /* width: 250px; */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.CustomDropdown_selected_option__IxiFI {
  padding: 10px;
  background-color: white;
  /* my css */
  opacity: 0.75;
  font-size: 0.95rem;
}

.CustomDropdown_arrow_icon__65miq {
  margin-left: 8px; /* Space between text and arrow */
  transition: transform 0.2s ease; /* Smooth rotation */
  /* my css */
  float: right;
}

.CustomDropdown_options__2t0bg {
  display: block; /* Always visible when open */
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 10;
}

.CustomDropdown_option__5CXla {
  padding: 10px;
  display: flex;
  align-items: center;
  /* my css */
  font-size: 0.95rem;
  /* justify-content: space-between; */
}

.CustomDropdown_option__5CXla:hover {
  background-color: #f0f0f0;
}

.CustomDropdown_circle__cvy3U {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/therapist/customDropdown/CustomDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB,EAAE,iCAAiC;EACnD,+BAA+B,EAAE,oBAAoB;EACrD,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc,EAAE,6BAA6B;EAC7C,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".custom_dropdown {\n  position: relative;\n  /* width: 250px; */\n  width: 100%;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.selected_option {\n  padding: 10px;\n  background-color: white;\n  /* my css */\n  opacity: 0.75;\n  font-size: 0.95rem;\n}\n\n.arrow_icon {\n  margin-left: 8px; /* Space between text and arrow */\n  transition: transform 0.2s ease; /* Smooth rotation */\n  /* my css */\n  float: right;\n}\n\n.options {\n  display: block; /* Always visible when open */\n  position: absolute;\n  top: 100%;\n  left: 0;\n  right: 0;\n  border: 1px solid #ccc;\n  background-color: white;\n  z-index: 10;\n}\n\n.option {\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  /* my css */\n  font-size: 0.95rem;\n  /* justify-content: space-between; */\n}\n\n.option:hover {\n  background-color: #f0f0f0;\n}\n\n.circle {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_dropdown": `CustomDropdown_custom_dropdown__lGHXW`,
	"selected_option": `CustomDropdown_selected_option__IxiFI`,
	"arrow_icon": `CustomDropdown_arrow_icon__65miq`,
	"options": `CustomDropdown_options__2t0bg`,
	"option": `CustomDropdown_option__5CXla`,
	"circle": `CustomDropdown_circle__cvy3U`
};
export default ___CSS_LOADER_EXPORT___;
