// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn.btnEdit {
    background-color: #ECF0FA;
    color: #4264D0;
    font-weight: 600;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/Patient/Treatment/Treatment.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".btn.btnEdit {\n    background-color: #ECF0FA;\n    color: #4264D0;\n    font-weight: 600;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
