import { createContext, useEffect, useState } from "react";
import { fetchPatienPlotProfile, fetchProfile, updateProfile, fetchPatienProfileTopNotes, fetchPatientProfile } from "./Api";
import storage from "pages/lib/storage";

export const ProfileContext = createContext();
export const ProfileProvider = ({ children }) => {

  const { userId } = storage.getItem("sbCalls")
  const [profile, setProfile] = useState({});

  const [patientDetail, setPatientDetail] = useState(null);
  const [plotResult, setPlotResult] = useState(null);

  const [topNotes, setTopNotes] = useState([]);
  const [error, setError] = useState(null);


  function getProfile() {
    fetchProfile()
      .then((profileData) => {
        setProfile(profileData.data);
        setError(null);
      })
      .catch((error) => {
        setProfile({});
        setError(error.message || "Something went wrong..");
      });
  }

  function logout() {
    setProfile(null);
  }

  useEffect(() => {
    if (userId) {
      getProfile();
    }
  }, [userId]);



  const updateProfileHandler = async (updatedProfile) => {
    try {
      const updatedData = await updateProfile(updatedProfile);
      getProfile();
      return updatedData;
    } catch (error) {
      return null;
    }
  };

  const patientDetailApi = async (clinicianId, patientId) => {
    try {
      const profile = await fetchPatientProfile(clinicianId, patientId)
      setPatientDetail(profile)
    } catch (err) {
      setError("Something went wrong")
    }
  }

  const plotResultApi = async (patientId) => {
    try {
      const response = await fetchPatienPlotProfile(patientId)
      setPlotResult(response)
    } catch (err) {
      setError("Something went wrong")
    }
  }
  const topNotesApi = async (clinicianId, patientId) => {
    try {
      const response = await fetchPatienProfileTopNotes(clinicianId, patientId)
      setTopNotes(response)
    } catch (err) {
      setError("Something went wrong")
    }
  }


  return (
    <ProfileContext.Provider
      value={{ profile, logout, updateProfileHandler, getProfile, patientDetail, plotResult, topNotes, patientDetailApi, plotResultApi, topNotesApi }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
