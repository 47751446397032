import React, { useState, useEffect } from "react";
import Header from "components/Header/Header";
import axios from "axios";
import toast from "react-hot-toast";
import Layout from "components/layout/layout";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchData();
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/users/allpatientsdoctors`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const doctors = response.data.doctors.map((doctor) => ({
        ...doctor,
        type: "Doctor",
      }));
      const patients = response.data.patients.map((patient) => ({
        ...patient,
        type: "Patient",
      }));
      setUsers([...doctors, ...patients]);
    } catch (error) {
      console.error(error);
    }
  };

  const acceptPatientRequest = async (email) => {
    try {
      const data = { email: email };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/users/approval`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response?.data?.message);
      fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error assigning user");
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...users];
    if (sortConfig.key !== "") {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [users, sortConfig]);

  const filteredUsers = sortedUsers.filter((user) =>
    `${user.firstName} ${user.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"User Management"} />

        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-3">
            <div className="input-group custominp">
              <span className="input-group-text border-end-0" id="basic-addon1">
                <i className="far fa-search"></i>
              </span>
              <input
                type="text"
                className="form-control ps-0 border-start-0"
                placeholder="Search..."
                aria-label="Search"
                aria-describedby="basic-addon1"
                onKeyDown={handleKeyDown}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="tab-content mt-4" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="tableList">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th
                          className="cursor_click"
                          scope="col"
                          onClick={() => handleSort("firstName")}
                        >
                          Users <img src="../../images/short-icon.svg" alt="" />{" "}
                        </th>
                        <th
                          className="cursor_click"
                          scope="col"
                          onClick={() => handleSort("email")}
                        >
                          Email
                          <img src="../../images/short-icon.svg" alt="" />
                        </th>
                        <th
                          className="cursor_click"
                          scope="col"
                          onClick={() => handleSort("type")}
                        >
                          Type
                        </th>
                        <th className="cursor_click" scope="col">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((user, index) => (
                        <tr key={index}>
                          <td>{`${user.firstName} ${user.lastName}`}</td>
                          <td>
                            <h6 className="mb-0"> {user.email}</h6>
                          </td>
                          <td>{user.type}</td>
                          <td>
                            <button
                              type="button"
                              onClick={() => {
                                acceptPatientRequest(user.email);
                              }}
                              className={`btn ${
                                user.authentication === null
                                  ? "pendingBtn"
                                  : "completeBtn"
                              }`}
                            >
                              {user.authentication === null
                                ? "Pending"
                                : "Approved"}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserManagement;
