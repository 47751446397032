import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import toast from "react-hot-toast";
import { Row, Col, Form, Image, Button } from "react-bootstrap";
import { fileUpload } from "../../context/Api";
import OTPInput from "otp-input-react";
import Modal from "react-modal";
import Loader from "components/loader/Loader";
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa";
import { Specialties } from "Data";
import SpecialtyOption from "utils/SpecialtyOption";
import Validation from "utilities/Validation";
import { getOtp } from "lib/api/service";
import { FaCamera } from "react-icons/fa";

const Signup = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isMatching, setIsMatching] = useState(true);

  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [degree, setDegree] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [isValid, setIsValid] = useState(false);

  const [specialty, setSpecialty] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState("");

  const [profile, setProfile] = useState("images/user.png");
  const [randomProfile, setRandomProfile] = useState("images/patients.svg");
  const fileInputRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isResendOtp, setIsResendOtp] = useState(false);

  const navigate = useNavigate();

  const validationRules = [
    { regex: /.{8,}/, name: "8 characters minimum" },
    { regex: /[A-Z]/, name: "Uppercase character" },
    {
      regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      name: "One special symbol",
    },
    { regex: /[0-9]/, name: "One number" },
    { regex: /[a-z]/, name: "Lowercase character" },
  ];

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedSpecialty(value);
    if (value !== "Other") {
      setSpecialty(value);
    } else {
      setSpecialty("");
    }
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !Validation.validateStep(currentStep, {
        firstName,
        lastName,
        title,
        degree,
        licenseNumber,
        specialty,
        email,
        password,
        otp,
      })
    ) {
      return;
    }

    if (currentStep === 4 || isResendOtp) {
      setLoader(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/users/signup`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
              password,
              firstName,
              lastName,
              title,
              degree,
              licenseNumber,
              specialty,
            }),
          }
        );

        const responseData = await response.json();
        if (response.status === 409) {
          setLoader(false);
          toast.error(responseData.message || "Email already exists");
          return;
        }

        if (!response.ok) {
          setLoader(false);

          throw new Error(responseData.message || "An error occurred");
        }

        if (response.status === 201) {
          setLoader(false);
          toast.success("OTP sent successfully to your email.");
          setOtp("");
          setCurrentStep(5);
        } else {
          setLoader(false);

          toast.error(responseData.message || "An error occurred");
        }
      } catch (error) {
        setLoader(false);
        console.error("There was an error!", error);
      }
      setIsResendOtp(false);
    } else if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        toast.error("Email is not valid. Example: abc@gmail.com");
        return;
      }
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      const maxSizeInMB = 5;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (!validFileTypes.includes(file.type)) {
        toast.error("Please upload a valid image file (JPG, JPEG, PNG).");
        return;
      }

      if (file.size > maxSizeInBytes) {
        toast.error(`Please upload an image smaller than ${maxSizeInMB}MB.`);
        return;
      }
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const uploadResult = await fileUpload({
            task: "uploadImage",
            image: reader.result,
          });
          if (uploadResult.success) {
            setProfile(uploadResult.data);
          } else {
            setProfile("images/upload-profile.png");
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const uploadImage = () => {
    fileInputRef.current.click();
  };

  const handleOtpChange = async (newOtp) => {
    setOtp(newOtp);
  };
  const submitOtp = async () => {
    if (currentStep !== 5) return;
    if (!otp || otp.length !== 6) {
      return;
    }
    try {
      setLoader(true);
      if (otp?.length === 6) {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/users/verify-otp`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
              password,
              firstName,
              lastName,
              title,
              degree,
              licenseNumber,
              specialty,
              otp,
            }),
          }
        );

        if (response.ok) {
          setLoader(false);
          toast.success("OTP Verify.");
          setModalOpen(true);
        } else {
          setLoader(false);
          const responseData = await response.json();
          toast.error(responseData.message);
          // navigate("/login");
        }
      }
    } catch (e) {
      setLoader(false);
      toast.error(e?.message);
    }
  };
  const handleBanckClick = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleKeyDown = (e) => {
    const { value, selectionStart } = e.target;
    if (e.key === " " && selectionStart === 0) {
      e.preventDefault();
    }
    const precedingChar = value[selectionStart - 1];
    const followingChar = value[selectionStart];
    if (e.key === " " && (precedingChar === " " || followingChar === " ")) {
      e.preventDefault();
    }
  };

  const handleChangePassword = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePasswords(newPassword, confirmPassword);
  };

  const handleChangeConfirmPassword = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePasswords(password, newConfirmPassword);
  };

  const validatePasswords = (newPassword, newConfirmPassword) => {
    let isValidPassword = true;
    for (const rule of validationRules) {
      const isRuleValid = rule.regex.test(newPassword);
      isValidPassword = isValidPassword && isRuleValid;
      //TODO: will need to have isValid and isMatching to be true in order to submit!
      setIsValid(isValidPassword);
      setIsMatching(newPassword === newConfirmPassword);
    }
  };
  const handleSendOtp = async () => {
    setLoader(true);
    try {
      const response = await getOtp({
        email,
      });
      if (response) {
        setLoader(false);
        toast.success(response?.message);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data", error);
    }
  };

  return (
    <div className="vh-100 w-100 loginBglight px-3 overflow-hidden">
      <Row className="h-100">
        <Col md={5} className="h-100 p-0 signup_grid">
          <div className="d-flex align-content-between flex-wrap h-100">
            <Image
              onClick={() => navigate("/login")}
              src="images/logo-spero.png"
              className="ps-4 pt-3"
              alt=""
            />
            <Image src="images/signup.svg" className="w-100" alt="" />
          </div>
        </Col>
        <Col md={7} className="h-100 bg-white  startRounded overflow-auto">
          <div className="d-flex mb-0 px-4 pt-3 justify-content-between">
            <div>
              {currentStep > 1 && (
                <p className="back_btn" onClick={handleBanckClick}>
                  <img src="images/back.png" alt="" /> Back
                </p>
              )}
            </div>
            <div>
              <p>Step {currentStep} of 5</p>
            </div>
          </div>
          <div className="py-5 d-flex align-items-center justify-content-center">
            <Form onSubmit={handleSubmit} className="setWidthform signupForm">
              {currentStep === 1 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center" style={{ fontSize: "36px" }}>
                      Your name
                    </h1>
                    <p className="text-center mb-4">
                      To get started please fill in your first, last name and{" "}
                      <br></br> upload profile picture
                    </p>
                    <div className="text-center position-relative">
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="application/image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <Image
                        src={randomProfile}
                        onClick={uploadImage}
                        className="mb-4 back_btn image_upload"
                        width={100}
                        alt=""
                      />
                      <FaCamera className="camera_icon" />
                    </div>
                    <Form.Group className="mb-3">
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        label="FirstName"
                        id="firstName"
                        value={firstName}
                        placeholder="Enter first name"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setFirstName(e.target.value)}
                        variant="outlined"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Last name</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="text"
                          name="lastName"
                          label="LastName"
                          placeholder="Enter last name"
                          id="lastName"
                          value={lastName}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => setLastName(e.target.value)}
                          variant="outlined"
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              )}

              {currentStep === 2 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center" style={{ fontSize: "36px" }}>
                      Credentials
                    </h1>
                    <p className="text-center mb-5">
                      Please fill in your credentials, such as title, degree and{" "}
                      <br /> license number
                    </p>
                    <Form.Group className="mb-3">
                      <Form.Label>Title </Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        label="Title"
                        id="title"
                        value={title}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter title"
                        variant="outlined"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Degree</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter degree"
                        name="degree"
                        label="Degree"
                        id="degree"
                        value={degree}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setDegree(e.target.value)}
                        variant="outlined"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>License number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter license number"
                        name="licenseNumber"
                        label="LicenseNumber"
                        id="licenseNumber"
                        value={licenseNumber}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setLicenseNumber(e.target.value)}
                        variant="outlined"
                      />
                    </Form.Group>
                  </div>
                </div>
              )}

              {currentStep === 3 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth minWidth w-100">
                    <h1 className="text-center" style={{ fontSize: "36px" }}>
                      Speciality
                    </h1>
                    <p className="text-center mb-5">
                      Please select your specialty:
                    </p>
                    {Specialties.map((spec) => (
                      <SpecialtyOption
                        key={spec.id}
                        id={spec.id}
                        name={spec.name}
                        description={spec.description}
                        selectedSpecialty={selectedSpecialty}
                        handleChange={handleChange}
                        specialty={specialty}
                        setSpecialty={setSpecialty}
                        handleKeyDown={handleKeyDown}
                        isOther={spec.id === "Other"}
                      />
                    ))}
                  </div>
                </div>
              )}

              {currentStep === 4 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center" style={{ fontSize: "36px" }}>
                      Sign up
                    </h1>
                    <p className="text-center mb-5">Please fill your details</p>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        placeholder="Enter Email"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          placeholder="Enter Password"
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={handleChangePassword}
                          onKeyDown={handleKeyDown}
                          variant="outlined"
                        />
                        <p
                          type="button"
                          className="show_pass"
                          onClick={toggleShowPassword}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </p>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          placeholder="Enter Confirm Password"
                          name="password"
                          label="Password"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={handleChangeConfirmPassword}
                          onKeyDown={handleKeyDown}
                          variant="outlined"
                        />
                        <p
                          type="button"
                          className="show_pass"
                          onClick={toggleShowConfirmPassword}
                        >
                          {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                        </p>
                      </div>
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                      <div style={{ marginRight: "20px" }}>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                          {validationRules.slice(0, 3).map((rule, index) => (
                            <li key={index} style={{ fontSize: "12px" }}>
                              {rule.regex.test(password) ? (
                                <span style={{ color: "#00BB61" }}>
                                  <FaCheck style={{ marginRight: "5px" }} />
                                  {rule.name}
                                </span>
                              ) : (
                                <span style={{ color: "#8F99B0" }}>
                                  • {rule.name}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                          {validationRules.slice(3, 6).map((rule, index) => (
                            <li key={index} style={{ fontSize: "12px" }}>
                              {rule.regex.test(password) ? (
                                <span style={{ color: "#00BB61" }}>
                                  <FaCheck style={{ marginRight: "5px" }} />
                                  {rule.name}
                                </span>
                              ) : (
                                <span style={{ color: "#8F99B0" }}>
                                  • {rule.name}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="form-check d-flex p-0">
                      <Checkbox defaultChecked sx={{ padding: 0 }} />
                      <label
                        className="form-check-label"
                        htmlFor="sendEmailCheckbox"
                      >
                        <span style={{ fontSize: "12px" }}>I agree to the</span>
                        <Link className="ms-2 text-decoration-none">
                          Terms of Service{" "}
                        </Link>
                        <span style={{ fontSize: "12px" }}>and </span>
                        <Link>Privacy Policy</Link>
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 5 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center" style={{ fontSize: "36px" }}>
                      Verify email
                    </h1>
                    <p className="text-center mb-5">
                      We’ve sent you a 6-digit verification code to{" "}
                      <b>{email}</b>
                    </p>
                    <div className="row">
                      <div className="col otp">
                        <OTPInput
                          value={otp}
                          onChange={handleOtpChange}
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <Modal
                  style={customStyles}
                  isOpen={isModalOpen}
                  onRequestClose={() => setModalOpen(false)}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setModalOpen(false);
                            navigate("/login");
                          }}
                        ></button>
                      </div>
                      <Image
                        src="/images/successful.png"
                        width={115}
                        height={88}
                        style={{ margin: "auto" }}
                      />
                      <div className="modal-body pt-0 pb-5 px-4">
                        <div className="headingSec mb-4">
                          <h1
                            className="mb-0 text-center"
                            style={{
                              color: "#18357A",
                              fontSize: "36px",
                              fontWeight: 700,
                            }}
                          >
                            Account was sent for approval
                          </h1>
                        </div>
                        <p
                          className="mb-2"
                          style={{
                            color: "#5B6680",
                            textAlign: "center",
                            width: "75%",
                            margin: "auto",
                            fontSize: "14px",
                          }}
                        >
                          Once our administrator will check provided information
                          we will send confirmation notice on your email that
                          your account has been approved.
                          <br />
                          Thank you for patience!
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div className="gap-4 d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn btn-primary px-5 btnSize modal_btnn rounded-3"
                                onClick={() => {
                                  setModalOpen(false);
                                  navigate("/login");
                                }}
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
              <div className="text-center">
                <Button
                  type="submit"
                  className="btn btn-primary mt-4 py-3 w-100 mb-4"
                  onClick={submitOtp}
                  disabled={currentStep >= 4 ? !isValid || !isMatching : ""}
                >
                  {currentStep < 5 ? "Continue" : "Finish"}
                </Button>
                {currentStep !== 5 && (
                  <p className="mt-2 cursor_click">
                    Already have an account?{" "}
                    <a onClick={navigateToLogin}>Log in</a>
                  </p>
                )}

                {currentStep === 5 && (
                  <p className="mt-2">
                    Didn’t receive a code?{" "}
                    <a className="cursor_click" onClick={handleSendOtp}>
                      Resend code
                    </a>
                  </p>
                )}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
      {loader && <Loader />}
    </div>
  );
};

export default Signup;
