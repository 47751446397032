import { Routes, Route } from 'react-router-dom';
import PrivateRoute from 'components/atoms/PrivateRoute';
import GroupCallMain from '../GroupCallMain';

const GroupCallFullScreen = () => {
  return (
    <Routes>
      <PrivateRoute path="." element={<GroupCallMain />} />
    </Routes>
  );
};

export default GroupCallFullScreen;
