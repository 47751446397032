import axios from "axios";

import {
  PATIENT_PROFILE,
  DOCTORE_PROFILE,
  ASSESSMENTS,
  DOCUMENT_ASSIGNED,
  ALL_PATIENT_LIST,
  DOCUMENT_UPLOADS,
  DOCTORS_PROFILE_UPDATE_API,
  SINGLE_PATIENT_PROFILE_API,
  PASSWORD_CHANGE_API,
  NEW_PATIENTS_INTAKE_API,
  FILE_UPLOAD_API,
  DISCHARGE_PATIENTS_API,
  GetMedication,
  UpdateMedication,
  AddMedication,
} from "../const";
import storage from "pages/lib/storage";
const token = localStorage.getItem("token");
const isImageUrlValid = async (imageUrl) => {
  try {
    const response = await axios.head(imageUrl);
    const contentType = response.headers["content-type"];
    return contentType && contentType.startsWith("image");
  } catch (error) {
    console.error("Error occurred while checking image URL validity:", error);
    return false;
  }
};
export const fetchProfile = async () => {
  const { userId } = storage.getItem("sbCalls");

  try {
    if (!userId) {
      return false;
    }
    const response = await axios.get(`${DOCTORE_PROFILE}&userid=${userId}`);
    response.data.data["image"] =
      JSON.parse(response.data.data.profile_image).data || "";
    response.data.data.profile["front_docs"] =
      JSON.parse(response.data.data.front_docs).data || "";
    response.data.data.profile["back_docs"] =
      JSON.parse(response.data.data.back_docs).data || "";
    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

export const updateProfile = async (updatedProfile) => {
  try {
    const response = await axios.post(
      DOCTORS_PROFILE_UPDATE_API,
      updatedProfile,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

//dischargeForm
export const dischargePatients = async (body,id) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/dischargeform/save/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

//intakeform
export const intakePatients = async (body, patientId) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/intakeform/save/${patientId}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const singlePatientProfile = async (id) => {
  try {
    const response = await axios.get(
      `${SINGLE_PATIENT_PROFILE_API}&patient_id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const passwordChange = async (updatedProfile) => {
  try {
    const response = await axios.post(PASSWORD_CHANGE_API, updatedProfile, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const fileUpload = async (updatedProfile) => {
  try {
    const response = await axios.post(FILE_UPLOAD_API, updatedProfile, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const uploadDrDocuments = async (uploadDocuments) => {
  try {
    const response = await axios.post(`${DOCUMENT_UPLOADS}`, uploadDocuments, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

export const deleteDrDocuments = async (data) => {
  try {
    const response = await axios.post(`${DOCUMENT_UPLOADS}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

export const fetchDrDocuments = async (filters = "", doc_type = "") => {
  const { userId } = storage.getItem("sbCalls");
  try {
    const response = await axios.get(
      `${DOCUMENT_UPLOADS}?task=get_upload_docs&dr_id=${userId}&filters=${filters}&doc_type=${doc_type}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

export const fetchDrMedications = async () => {
  const { userId } = storage.getItem("sbCalls");
  try {
    const response = await axios.get(
      `${GetMedication}?task=allMedications&dr_id=${userId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

export const fetchTreatmentPlans = async () => {
  const { userId } = storage.getItem("sbCalls");
  try {
    const response = await axios.get(
      `${GetMedication}?task=allMedications&dr_id=${userId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

export const createDrMedications = async (data) => {
  try {
    const response = await axios.post(`${AddMedication}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};
export const updateDrMedications = async (data) => {
  try {
    const response = await axios.post(`${UpdateMedication}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

export const allPatients = async (userId, filter = "") => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const get_req_value1 = {
      clinicianId: userId,
      task: "allPatientData",
      search: filter,
    };
    const queryParams1 = new URLSearchParams(get_req_value1).toString();
    const apiUrl1 = `${ALL_PATIENT_LIST}?${queryParams1}`;

    const response = await axios.get(apiUrl1, {
      headers: headers,
    });

    return response.data.query_result;
  } catch (error) {
    console.error("Error fetching all patients:", error);
    return [];
  }
};

export const assignedDocuments = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await axios.post(DOCUMENT_ASSIGNED, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching all patients:", error);
    return [];
  }
};

export const store_update_Assessments = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await axios.post(ASSESSMENTS, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching all patients:", error);
    return [];
  }
};

 export const getAssessments = async (filter = "", name = "") => {
    const { userId } = storage.getItem("sbCalls");
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const get_req_value1 = {
        dr_id: userId,
        task: "get_assessments",
        filter: filter,
        name: name,
      };
      const queryParams1 = new URLSearchParams(get_req_value1).toString();
      const apiUrl1 = `${ASSESSMENTS}?${queryParams1}`;
      const response = await axios.get(apiUrl1, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching all patients:", error);
      return [];
    }
  };

export const deleteAssessments = async (id, dr_id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const get_req_value1 = { id: id, task: "delete_assessments", dr_id: dr_id };
    const queryParams1 = new URLSearchParams(get_req_value1).toString();
    const apiUrl1 = `${ASSESSMENTS}?${queryParams1}`;

    const response = await axios.get(apiUrl1, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

export const assignedAssessments = async (data) => {
  try {
    const response = await axios.post(`${ASSESSMENTS}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

export const fetchPatientProfile = async (clinicianId, patientId) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const get_req_value2 = {
      clinicianId: clinicianId,
      patientId: patientId,
      task: "getUsersProfileDetails",
    };
    const queryParams2 = new URLSearchParams(get_req_value2).toString();
    const apiUrl2 = `${PATIENT_PROFILE}?${queryParams2}`;
    const response1 = await axios.get(apiUrl2, { headers });
    return response1.data.data;
  } catch (error) {
    return null;
  }
};

export const fetchPatienPlotProfile = async (patientId) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const get_req_value = {
      patientId: patientId,
      task: "patientSubmitFormData",
    };
    const queryParams = new URLSearchParams(get_req_value);
    const apiUrl = `${PATIENT_PROFILE}?${queryParams}`;
    const response2 = await axios.get(apiUrl, { headers });
    return JSON.parse(
      response2.data.plot_query_result
        .replace(/None/g, "null")
        .replace(/'/g, '"')
    );
  } catch (error) {
    return null;
  }
};

export const fetchPatienProfileTopNotes = async (clinicianId, patientId) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const get_req_value2 = {
      clinicianId: clinicianId,
      patientId: patientId,
      task: "specificPatientData",
    };
    const queryParams2 = new URLSearchParams(get_req_value2).toString();
    const apiUrl2 = `${PATIENT_PROFILE}?${queryParams2}`;
    const response1 = await axios.get(apiUrl2, { headers });
    if (
      response1.data.clinical_response_result &&
      response1.data.clinical_response_result.length > 0
    ) {
      return response1.data.clinical_response_result.slice(0, 5);
    }
    return [];
  } catch (error) {
    return [];
  }
};
