import { Row, Col } from "react-bootstrap";
import "./Dasboard.css";
import Header from "../../../components/Header/Header";
import DashboardList from "./DashboardList";
import DashboardAppointmentDetails from "./DashboardAppointmentDetails";
import DashboardMessagesComponent from "./DashboardMessagesComponent";
import DashboardDetails from "./DashboardDetails";
import PatientAssessmentsTable from "table/PatientAssessmentsTable";
import Layout from "components/layout/layout";

const PatientsDashboard = () => {
  const name = localStorage.getItem("name");

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"My dashboard"} />
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-3 ">
            <Row className="d-flex align-items-center p-3 mb-4">
              <Col>
                <DashboardDetails name={name} />
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <div
                  className="tab-content tab_dashboard"
                  id="pills-tabContent"
                >
                  <DashboardList />
                </div>
              </Col>
              <Col lg={4}>
                <DashboardAppointmentDetails />
              </Col>
            </Row>
          </div>
          <div className="mb-5">
            <Row>
              <Col lg={6} className="mb-5">
                <PatientAssessmentsTable />
              </Col>
              <Col lg={6}>
                <DashboardMessagesComponent />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PatientsDashboard;
