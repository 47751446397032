import React, { useState, useEffect, useCallback } from "react";
import Header from "components/Header/Header";
import Layout from "components/layout/layout";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import AdminPatientsTable from "table/AdminPatientsTable";
import Loader from "components/loader/Loader";

const AdminPatients = () => {
  const [allPatientList, setAllPatientList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");

  const getAllPatientList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/assignuser/allassignusers`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllPatientList(response.data);
    } catch (error) {
      console.error("Error fetching all patients:", error);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getAllPatientList();
  }, [getAllPatientList]);

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle="Patients" />
        <div className="main-content px-3">
          <div className="bg-white rounded p-3">
            <Row className="d-flex align-items-center p-3">
              <Col>
                <AdminPatientsTable allPatientList={allPatientList} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </Layout>
  );
};

export default AdminPatients;
