import React from 'react';

const AppointmentModal = ( { appointmentDetailInfo  } ) => {

  const { selectedAppointmentDetailData, setIsAppointmentDetailModalOpen } = appointmentDetailInfo;

  if (!selectedAppointmentDetailData) {
    return null;
  }

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
      <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setIsAppointmentDetailModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
          <h2 className='mb-3'>Appointment details</h2>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Appointment date:</label>
              <p className="mb-0">{ selectedAppointmentDetailData.dateValue } <span>{ selectedAppointmentDetailData.timeValue }</span></p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Appointment duration:</label>
              <p className="mb-0">{ selectedAppointmentDetailData.duration }</p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Provider:</label>
              <p className="mb-0"><img src={ selectedAppointmentDetailData.providerImage } alt="" />{ selectedAppointmentDetailData.participants }</p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <label className="me-2">Appointment note:</label>
            <p className="mb-2">{ selectedAppointmentDetailData.appointmentDescription }</p>
            <p className="mb-2"><b>Assessment and Observations: </b> During the session, I conducted a thorough assessment of the patient's mental health. They reported that their symptoms have been present for the past three months and have started affecting their daily functioning, including work productivity and interpersonal relationships. The patient displayed signs of emotional distress, such as tearfulness and fidgeting during discussions about their struggles.</p>
            <p className="mb-2"><b>Clinical Impression:</b> Based on the assessment and observations, my clinical impression is that the patient may be experiencing an anxiety disorder, possibly accompanied by symptoms of depression. The patient's symptoms, duration, and impact on daily life suggest the need for further evaluation and potential treatment.
              Intervention: During the session, I employed various therapeutic techniques to provide support and psychoeducation to the patient. I utilized active listening to empathize with their experiences and validate their emotions. I introduced relaxation exercises, such as deep breathing and progressive muscle relaxation, to help the patient manage their anxiety symptoms in the moment. We discussed the importance of self-care, including regular exercise, adequate sleep, and engaging in activities that bring joy and relaxation.</p>
              <p className="mb-1"><b>Treatment Plan:</b> In collaboration with the patient, we developed a preliminary treatment plan to address their mental health concerns. The plan includes the following:</p>
            <ol className='p-0'>
              <li><p className='mb-1'><b>Referral: </b> I provided the patient with a referral to a licensed mental health professional, such as a psychologist or psychiatrist, for a comprehensive evaluation and potential diagnosis.</p></li>
              <li><p className='mb-1'><b>Therapy:</b> I recommended the patient consider engaging in therapy, such as cognitive-behavioral therapy (CBT) or psychotherapy, to address their anxiety and depression symptoms. We discussed the potential benefits of therapy in providing coping strategies, exploring underlying issues, and developing a personalized treatment approach.</p></li>
              <li><p className='mb-1'><b>Self-Care Strategies:</b> I encouraged the patient to prioritize self-care activities, including maintaining a balanced lifestyle, engaging in hobbies, practicing relaxation techniques, and seeking social support from loved ones.</p></li>
              <li><p className='mb-1'><b>Medication Evaluation:</b> Given the severity of the symptoms and their impact on daily functioning, I suggested that the patient discuss with the referred mental health professional the possibility of medication evaluation to provide additional support and symptom relief.</p></li>
              <li><p className='mb-1'><b>Follow-up:</b> We scheduled a follow-up session in two weeks to assess the patient's progress, discuss any updates from the referral process, and adjust the treatment plan as needed.</p></li>
            </ol>
            <p className='mb-0'>Confidentiality and Safety: I reiterated the importance of confidentiality and informed the patient about the limits to confidentiality, particularly in cases of potential harm to self or others. I ensured the patient's safety by assessing any immediate risk and creating a safety plan together.
Session Summary: During today's session, the patient shared their concerns regarding symptoms of anxiety and depression. We discussed potential diagnoses, developed a preliminary treatment plan, and established a referral for further evaluation. The patient appeared receptive to the suggested interventions and expressed a willingness to engage in the recommended treatment options.
Next Session Goals: In the next session, our goals will include reviewing the outcome of the referral process, discussing any diagnosis or treatment recommendations from the mental health professional, and further exploring coping strategies to manage the patient's symptoms.</p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AppointmentModal;
