import React, { useState, useEffect } from "react";
import "./Assessment.css";
import AssessmentResultModal from "../../../modals/AssessmentResultModal";
import PtsdResultModal from "../../../modals/PtsdResultModal";
import AssessmentPatientModal from "../../../modals/AssessmentPatientModal";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { ProgressBar } from "react-bootstrap";
import Header from "components/Header/Header";
import Layout from "components/layout/layout";
import axios from "axios";

const AssessmentsMain = () => {
  const [isAssessmentResultModalOpen, setIsAssessmentResultModalOpen] =
    useState(false);
  const [selectedAssessmentResultData, setSelectedAssessmentResultData] =
    useState("");
  const [patientAssessments, setPatientAssessments] = useState([]);
  const token = localStorage.getItem("token");

  const getAllAssessmentsList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/assessments/getAllAssessments`,
        {
          headers: headers,
        }
      );
      setPatientAssessments(response.data?.assessments);
    } catch (error) {
      console.error("Error fetching all assessments:", error);
      return [];
    }
  };
  useEffect(() => {
    getAllAssessmentsList();
  }, []);

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"Assessments"} />
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-3">
            <section>
              <div id="assessment">
                <div className="headingTable cardPaitent">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="bg-white rounded-3 p-3 cardPaitent">
                        <div className="rounded-3 py-2 px-3 topBar mb-4">
                          <div className="row align-items-end">
                            <div className="col-md-4 px-1">
                              <div className="d-flex align-items-center w-full">
                                <div className="flex-shrink-0">
                                  <img
                                    src="../../images/avatar.png"
                                    alt=""
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-4">
                                  <div className="d-flex justify-content-between">
                                    <h4>Kristy Sherman</h4>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <label className="fw-normal">
                                        9 Assessments
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 px-1"></div>
                            <div className="col-md-4 px-1">
                              <img
                                src="../../images/timeschdule.png"
                                alt=""
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "10px",
                                }}
                              />
                              You have 3 pending assessments for today
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 assignmentList ">
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-single"
                          role="tabpanel"
                          aria-labelledby="pills-single-tab"
                        >
                          <ul className="p-0 assignmentList d-grid gap-3">
                            {patientAssessments.map((item, index) => (
                              <li
                                key={index}
                                className="border rounded-3 py-2 px-3"
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{ width: "100%" }}
                                >
                                  <div className="flex-shrink-0">
                                    <img
                                      src="../../images/book-icon.svg"
                                      alt="..."
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-3 gap-2">
                                    <div className="flex-grow-1 ms-3 gap-2">
                                      <h3
                                        onClick={() => {
                                          setSelectedAssessmentResultData(item);
                                          setIsAssessmentResultModalOpen(true);
                                        }}
                                        className="mb-2 fs-6"
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "16px",
                                          color: "#18357A",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.assessmentName}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-auto"
                                    style={{ width: "30%" }}
                                  >
                                    <ProgressBar
                                      now={item.progress}
                                      // className="progress-bar bg-success"
                                    />
                                    <span>%{item.progress} complete</span>
                                  </div>
                                  <div
                                    className="col-md-auto"
                                    style={{
                                      width: "20%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span>{item.dueDate}</span>
                                  </div>
                                  <div
                                    className="col-md-auto"
                                    style={{
                                      width: "20%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className={`btn ms-3 ${
                                        item.id === "Pending"
                                          ? "Denied"
                                          : item.id === "Completed"
                                          ? "completeBtn"
                                          : "inProgressBtn"
                                      }`}
                                    >
                                      {item.id}
                                    </button>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  isOpen={isAssessmentResultModalOpen}
                  onRequestClose={() => setIsAssessmentResultModalOpen(false)}
                >
                  <AssessmentResultModal
                    assessmentInfo={{
                      selectedAssessmentResultData:
                        selectedAssessmentResultData,
                      setIsAssessmentResultModalOpen:
                        setIsAssessmentResultModalOpen,
                    }}
                  />
                </Modal>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AssessmentsMain;
