import React from 'react';

const PsychotherapyModal = ( { psychotherapyDetailInfo  } ) => {

  const { selectedPsychotherapyData, setIsPsychotherapyModalOpen } = psychotherapyDetailInfo;

  if (!selectedPsychotherapyData) {
    return null;
  }

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
      <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" onClick={() => setIsPsychotherapyModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
          {/* <h2 className='mb-3'>Appointment details</h2> */}
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Date created:</label>
              <p className="mb-0">{ selectedPsychotherapyData.date } <span>{ selectedPsychotherapyData.time }</span></p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Provider:</label>
              <p className="mb-0">{ selectedPsychotherapyData.provider }</p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Patient:</label>
              <p className="mb-0"><img src={ selectedPsychotherapyData.providerImage } alt="" />Lori Briggs</p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Patient DOB:</label>
              <p className="mb-0"><img src={ selectedPsychotherapyData.providerImage } alt="" />21 Dec 1993</p>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="d-flex align-items-center">
              <label className="me-2">Diagnosis:</label>
            </div>
            <p className="mb-0">
              F50.89 - Other specified feeding or eating disorder<br/>
              </p>
              <p className="mb-0">
              F33.2 - Major depressive disorder, recurrent, severe without psychotic features<br/>
              </p>
              <p className="mb-0">
              F43.12 - Post-traumatic stress disorder, chronic<br/>
              </p>
          </div>
          <div className="col-md-12 mb-2">
            <label className="me-2">Patient arrived on time</label>
            <p className="mb-2">
            Talked about the week being hard because work is stressful and busy
            Which has impacted quality of sleep, only getting 4-5 hrs per night, which also increases anxiety
            **talk to psychiatrist about mild sleep aid options
            She had 2 panic attacks this week that were triggered by flashbacks of childhood trauma
            **talk to treatment team about Mary potentially doing EMDR
            When talking about the panic attacks patient became visibly stressed and tearful
            There is improvement because she used coping skills, she found that fidget toys like putty are most effective for her because the aggressive pulling and tearing motions help get out anger
            **in future we should explore this anger response to trauma

            Patient talked about the dreams when her brother and her would hide while father was drunk and inflicted violence on mom - we explored primary emotions around fear, and secondary emotions of shame and guilt for not doing something to help mom.
            Had a breakthrough where she said that as a child she could not have protected her mom from the harm, and she was using self preservation as a child to stay safe.
            </p>
          </div>

          <div className='row mb-3 mt-3'>
            <div className='col-md-12 mt-3'>
              <div class="gap-4 d-flex justify-content-center">
                <button type="button" class="btn btn-outline-secondary px-5 btnSize rounded-3" onClick={() => setIsPsychotherapyModalOpen(false)}>Cancel</button>
                <button type="button" class="btn btn-primary px-5 btnSize rounded-3" onClick={() => setIsPsychotherapyModalOpen(false)}>Convert to documentation</button></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PsychotherapyModal;
