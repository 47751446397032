import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "./Settings.css";
import Header from "../../components/Header/Header";
import PersonalDataTab from "./PersonalDataTab";
import ProfileSettingsTab from "./ProfileSettingsTab";
import PasswordAndSupportTab from "./PasswordAndSupportTab";
import { ProfileContext } from "../../context/ProfileProvider";
import Layout from "components/layout/layout";

const Settings = () => {
  const { profile } = useContext(ProfileContext);

  const [activeDiv, setActiveDiv] = useState("Personal Data");
  const showDiv = (divId) => {
    setActiveDiv(divId);
  };

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"Settings"} />
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-3 cardPaitent d-flex">
            <div className="row d-flex align-items-center p-3">
              {/* profile image and info */}
              <div className="col">
                {profile ? (
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="profile-img"
                        src={profile.image}
                        alt="profile-icon"
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="row">
                        <div className="d-flex justify-content-between">
                          <h4 className="profile-name">
                            {profile.firstName} {profile.lastName}
                          </h4>
                        </div>
                        <label>
                          License No: <span>{profile.licenseNumber || ""}</span>
                        </label>
                        <label>
                          <span className="small-text">
                            Profile Created: {profile.createdAt}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
              {/* tabs */}
              {/* TODO: these tabs work, but will likely utilize previously made tabs instead */}
              <div className="col-lg-8">
                <div className="tabBar">
                  <div className="row text-center flex-grow-1">
                    <div className="col">
                      <Link
                        className={`text-decoration-none ${
                          activeDiv === "Personal Data" ? "active" : ""
                        }`}
                        onClick={() => showDiv("Personal Data")}
                      >
                        Personal Data
                      </Link>
                    </div>
                    <div className="col">
                      <Link
                        className={`text-decoration-none ${
                          activeDiv === "Profile Settings" ? "active" : ""
                        }`}
                        onClick={() => showDiv("Profile Settings")}
                      >
                        Profile Settings
                      </Link>
                    </div>
                    <div className="col">
                      <Link
                        className={`text-decoration-none ${
                          activeDiv === "Password & Support" ? "active" : ""
                        }`}
                        onClick={() => showDiv("Password & Support")}
                      >
                        Password & Support
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {activeDiv === "Personal Data" ? (
                <PersonalDataTab />
              ) : activeDiv === "Profile Settings" ? (
                <ProfileSettingsTab />
              ) : activeDiv === "Password & Support" ? (
                <PasswordAndSupportTab />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
