import React from 'react';
import { CheckboxInput, TextInput, RadioButtons, TextAreaInput , RadioButton } from '../../../../components/FormComponents/FormComponents';
import { STAGES_OF_CHANGE_RADIO } from '../../../../const/PatientIntakeConst';

const NewPatientPage11 = ({ formData, handleChange, handleSubmit , handleGoBack, showFooter = true }) => {

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="d-flex align-items-center justify-content-between">
                        <span>
                            Does patient engage in hobbies regularly?
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='patientHobbies' formData={formData} handleChange={handleChange}/>
                        </div>
                </div>
                <div className='form-group row'>
                    <TextInput
                        id="patientHobbiesDescription"
                        name="patientHobbiesDescription"
                        value={formData.patientHobbiesDescription}
                        onChange={handleChange}
                        label="What hobbies or interests does patient engage in their free time?"
                        required
                    />
                    <h4 className='mt-3'>Hobbies/Interests</h4>
                    <TextInput
                        id="patientStrengthsDescription"
                        name="patientStrengthsDescription"
                        value={formData.patientStrengthsDescription}
                        onChange={handleChange}
                        label="Strengths:"
                        required
                    />
                    <TextInput
                        id="patientBarriersDescription"
                        name="patientBarriersDescription"
                        value={formData.patientBarriersDescription}
                        onChange={handleChange}
                        label="Barriers:"
                        required
                    />
                    <TextInput
                        id="patientProtectiveFactorsDescription"
                        name="patientProtectiveFactorsDescription"
                        value={formData.patientProtectiveFactorsDescription}
                        onChange={handleChange}
                        label="Protective/Motivating Factors (add checklist of protective factors):"
                        required
                    />
                    <TextInput
                        id="patientPastTreatmentSuccessDescription"
                        name="patientPastTreatmentSuccessDescription"
                        value={formData.patientPastTreatmentSuccessDescription}
                        onChange={handleChange}
                        label="Past Success in Treatment:"
                        required
                    />
                    <TextInput
                        id="providerPastHelpfulTreatmentDescription"
                        name="providerPastHelpfulTreatmentDescription"
                        value={formData.providerPastHelpfulTreatmentDescription}
                        onChange={handleChange}
                        label="What have you found helpful in the past?"
                        placeholder="i.e., more structured treatment approach vs narrative style therapy"
                        required
                    />
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            Have you found skill-based treatment helpful in the past?
                        </span>
                        <div className="d-flex align-items-center">
                            <RadioButtons name='providerSkillBasedTreatmentHelpfulYesOrNo' formData={formData} handleChange={handleChange}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="motivationTowardsChange">
                            Motivation towards Treatment/Change
                        </label>
                        <div className='d-flex align-items-center'>
                        {STAGES_OF_CHANGE_RADIO.map((option, index) => {
                        return (
                            <div key={index} className='pe-2'>
                                <RadioButton
                                    id={option.label}
                                    name={option.name}
                                    label={option.label}
                                    value={option.value}
                                    checked={formData[`${option.name}`] == option.value}
                                    handleChange={handleChange}
                                />
                            </div>
                        )
                        })}
                           
                        </div>
                    </div>
                    <TextInput
                        id="providerAreasToFocusOn"
                        name="providerAreasToFocusOn"
                        value={formData.providerAreasToFocusOn}
                        onChange={handleChange}
                        label="What are the 2-3 areas you would like to focus on?"
                        required
                    />
                    <h3 className='mt-3'>CPT Codes</h3>
                    <TextAreaInput
                        id="CPTCodes"
                        name="CPTCodes"
                        value={formData.CPTCodes}
                        onChange={handleChange}
                    />
                </div>
                <CheckboxInput
                    id='correctFormInformationAcknowledgement'
                    name='correctFormInformationAcknowledgement'
                    label='I acknowledge that the above information is correct and has been reviewed prior to submitting.'
                    checked={formData.correctFormInformationAcknowledgement}
                    onChange={handleChange}
                    required={true}
                />
                    <div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                   { showFooter && (<div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center'>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3 mx-4'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>)}
                </div>
            </form>
        </div>
    );
};

export default NewPatientPage11;