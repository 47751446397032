import React, { useState, useEffect } from "react";
import Header from "components/Header/Header";
import RequestTab from "./RequestTab";
import { Form } from "react-bootstrap";
import Modal from "react-modal";
import toast from "react-hot-toast";
import Layout from "components/layout/layout";
import Loader from "components/loader/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { handleKeyDown } from "utils/inputSpace";
import {
  DenyRequest,
  assignUserGetDetails,
  assignUserToTherapist,
  usersAllPatients,
} from "lib/api/service";

const PatientRequests = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDeniedOpen, setModalDeniedOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [listingallPatients, setListingallPatients] = useState([]);
  const [listingProfile, setlistingProfile] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const [toggle, setToggle] = useState(false);
  const [newFilteredPatients, setNewFilteredPatients] = useState([]);

  const [activeDiv, setActiveDiv] = useState("Patient profile");

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(
      object().shape({
        reason: string().required("The Reason field is required"),
      })
    ),
  });
  const { errors } = formState;

  const onSubmit = async (data) => {
    const requestBody = {
      id: patient.id,
      reason: data.reason,
    };
    try {
      setLoader(true);

      const response = await DenyRequest(requestBody);
      if (response.status === 200) {
        setLoader(false);
        toast.success(response?.data?.message);
        setModalDeniedOpen(false);
        reset();
      } else {
        setLoader(false);
        toast.error("Failed to change status:");
      }
    } catch (error) {
      setLoader(false);
      console.error("Error changing status:", error);
    }
  };

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const fetchData = async () => {
    try {
      const response = await usersAllPatients();
      setListingallPatients(response?.patients);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedRow]);

  const patient = listingProfile[0];

  const acceptPatientRequest = async () => {
    try {
      const storedClinician = localStorage.getItem("selectedClinicians");
      if (!storedClinician || storedClinician === "undefined") {
        toast.error("Please select a clinician.");
        return;
      }

      const selectedClinicians = JSON.parse(storedClinician);
      // console.log(
      //   "dfasdfjasdfjdj",
      //   typeof selectedClinicians,
      //   "asdf",
      //   selectedClinicians
      // );
      if (!selectedClinicians || !Array.isArray(selectedClinicians)) {
        toast.error("Invalid clinician data. Please select a clinician.");
        return;
      }

      setLoader(true);

      const data = {
        patientId: patient.id,
        email: patient.email,
        patientName: patient.firstName,
        doctors: selectedClinicians.map((clinician) => ({
          doctorId: clinician.id,
          doctorEmail: clinician.email,
          doctorName: `${clinician.firstName} ${clinician.lastName}`,
        })),
      };

      const response = await assignUserToTherapist(data);
      if (response.message) {
        toast.success(response?.message);
      }

      setLoader(false);
      setModalOpen(false);
      fetchData();
      getSingleViewProfile(patient.id);
    } catch (error) {
      setLoader(false);
      setModalOpen(false);
      toast.error(error?.response?.data?.message || "Error assigning user");
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const getSingleViewProfile = async (id) => {
    try {
      const data = JSON.stringify({
        id: id,
        task: "patientdetails",
      });
      const response = await assignUserGetDetails(data);
      setlistingProfile(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  let filteredPatients = listingallPatients?.filter((patient) => {
    const fullName = `${patient.firstName} ${patient.lastName}`.toLowerCase();
    const matchesSearch = fullName.includes(searchTerm.toLowerCase());

    const patientCreatedAt = new Date(patient.createdAt)
      .toISOString()
      .split("T")[0];

    const matchesStatus =
      !selectedStatus ||
      (selectedStatus === "Pending" &&
        (patient.status === null || patient.status === "0")) ||
      (selectedStatus === "Accepted" && patient.status === "1") ||
      (selectedStatus === "Denied" && patient.status === "2");

    // Check if selectedDate is valid and in the right format
    const formattedSelectedDate = selectedDate
      ? new Date(selectedDate).toISOString().split("T")[0]
      : null;
    const matchesDate =
      !formattedSelectedDate || patientCreatedAt === formattedSelectedDate;

    return matchesStatus && matchesSearch && matchesDate;
  });

  const getButtonDetails = (status) => {
    let className, buttonText;
    if (status === null || status === "0") {
      className = "pendingBtn";
      buttonText = "Pending";
    } else if (status === "1") {
      className = "completeBtn";
      buttonText = "Accepted";
    } else if (status === "2") {
      className = "Denied";
      buttonText = "Denied";
    }
    return { className, buttonText };
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateOrder = () => {
    setNewFilteredPatients(filteredPatients.reverse());
    setToggle((prev) => !prev);
  };

  const filteredPatientData = toggle ? newFilteredPatients : filteredPatients;

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle="Requests" />
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-3 mb-4">
            <div id="clinical">
              <div className="main-content">
                <div className="headingTable cardPaitent">
                  <div id="tracker">
                    <div className="headingTable mb-4 px-3">
                      <div className="row mb-3">
                        <div className="col-md-5">
                          <div className="input-group  w-100 my-input-group">
                            <span
                              className="input-group-text border-end-0 my-span-class"
                              id="basic-addon1"
                            >
                              <i className="far fa-search"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control ps-0 border-start-0 bg_search_input"
                              placeholder="Search..."
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={searchTerm}
                              onChange={handleSearchChange}
                            />
                          </div>
                          <div className="row my-3">
                            <div className="col-md-6">
                              <div className="d-flex col-md-auto">
                                <div className="col-md">
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                name="Status"
                              >
                                <option value="">Select Status</option>
                                <option value="Pending">Pending</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Denied">Denied</option>
                              </select>
                            </div>
                          </div>
                          <div className="rounded-3 pt-1 pb-5">
                            <div
                              className="tab-content mt-1"
                              id="pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                              >
                                <div className="">
                                  <table className="table table-borderless">
                                    <thead className="w-100 d-flex">
                                      <tr className="d-flex w-100 justify-content-between">
                                        <th scope="col">Patient name</th>
                                        <th scope="col">
                                          Date received{" "}
                                          <button
                                            onClick={handleDateOrder}
                                            style={{ border: "none" }}
                                          >
                                            <img
                                              src="../../images/short-icon.svg"
                                              alt=""
                                            />
                                          </button>
                                        </th>
                                        <th
                                          scope="col"
                                          className="my-status-cls"
                                        >
                                          Status
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {filteredPatientData
                                        ?.reverse()
                                        .map((item, index) => {
                                          const createdAtDate = new Date(
                                            item.createdAt
                                          );
                                          const formattedDate =
                                            createdAtDate.toLocaleDateString(
                                              "en-US",
                                              {
                                                month: "long",
                                                day: "2-digit",
                                                year: "numeric",
                                              }
                                            );
                                          return (
                                            <tr
                                              key={item.id}
                                              className={
                                                selectedRow === index
                                                  ? "activeRow normalRow"
                                                  : "normalRow"
                                              }
                                            >
                                              <td
                                                className={`text-capitalize w-33 ${
                                                  selectedRow === index
                                                    ? "text-white my-td-class"
                                                    : "my-td-class"
                                                }`}
                                              >{`${item.firstName} ${item.lastName}`}</td>
                                              <td className="my-td-snd-cls">
                                                <h6
                                                  className={`mb-0 ${
                                                    selectedRow === index
                                                      ? "text-white text-start"
                                                      : ""
                                                  }`}
                                                >
                                                  {formattedDate}
                                                </h6>
                                              </td>
                                              <td>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    handleRowClick(index);
                                                    getSingleViewProfile(
                                                      item.id
                                                    );
                                                  }}
                                                  className={`btn my-btn-cls ${
                                                    getButtonDetails(
                                                      item.status
                                                    ).className
                                                  }`}
                                                >
                                                  {
                                                    getButtonDetails(
                                                      item.status
                                                    ).buttonText
                                                  }
                                                </button>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      {filteredPatientData?.length == 0 && (
                                        <td colspan="3" class="text-center">
                                          <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                              height: "200px",
                                              fontSize: "24px",
                                            }}
                                          >
                                            Patient Requests not found
                                          </div>
                                        </td>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          {listingProfile.length > 0 && (
                            <RequestTab
                              listingallPatients={listingProfile}
                              activeDiv={activeDiv}
                              setActiveDiv={setActiveDiv}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {activeDiv === "Assigned clinicians" ? (
                      listingProfile.length > 0 && (
                        <div
                          style={{
                            boxShadow: "0px -2px 14px 0px #0000001A",
                          }}
                        >
                          <div className="row m-0">
                            <div className="col-6">
                              <div className="row my-4">
                                <div className="col-md-12">
                                  <div className="gap-4 d-flex align-items-center p-3">
                                    <div>
                                      <button
                                        type="button"
                                        className={`btn ${
                                          getButtonDetails(
                                            listingProfile[0].status
                                          ).className
                                        }`}
                                      >
                                        {
                                          getButtonDetails(
                                            listingProfile[0].status
                                          ).buttonText
                                        }
                                      </button>
                                    </div>
                                    <h1 className="mb-2">
                                      <div className="mb-2 text-capitalize">
                                        {listingProfile[0].firstName}{" "}
                                        {listingProfile[0].lastName}
                                      </div>
                                      <div>
                                        <p>
                                          Request received:{" "}
                                          {listingProfile[0]
                                            ? formatDate(patient.createdAt)
                                            : ""}
                                        </p>
                                      </div>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 gap-4 d-flex align-items-center justify-content-center">
                              <div className="row my-4">
                                <div className="col-md-12">
                                  <div className="gap-4 d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={() => setModalDeniedOpen(true)}
                                      className="btn btn-outline-secondary px-5 btnSize rounded-3"
                                    >
                                      Deny
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary px-5 btnSize rounded-3"
                                      onClick={() => {
                                        setModalOpen(true);
                                      }}
                                    >
                                      {patient.status == 1
                                        ? "Update"
                                        : "Accept"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          style={customStyles}
          isOpen={isModalOpen}
          onRequestClose={() => setModalOpen(false)}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalOpen(false)}
                ></button>
              </div>
              <div
                className="modal-body pt-0 pb-5 px-4"
                style={{ width: "60%", margin: "auto" }}
              >
                <div className="headingSec mb-4">
                  <h1
                    className="mb-0 text-center"
                    style={{
                      color: "#18357A",
                      fontSize: "28px",
                      fontWeight: 700,
                    }}
                  >
                    Accept patient request
                  </h1>
                </div>
                <p
                  className="mb-2"
                  style={{
                    color: "#5B6680",
                    textAlign: "center",
                  }}
                >
                  Do you want to accept this request? Patient will be notified
                  about request acceptance through email.
                </p>
              </div>
              <div className="col-12">
                <div className="row my-4">
                  <div className="col-md-12">
                    <div className="gap-4 d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-outline-secondary px-5 btnSize rounded-3"
                        onClick={() => {
                          setModalOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary px-5 btnSize rounded-3"
                        onClick={() => {
                          acceptPatientRequest();
                        }}
                      >
                        Confirm accept
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          style={customStyles}
          isOpen={isModalDeniedOpen}
          onRequestClose={() => setModalDeniedOpen(false)}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalDeniedOpen(false)}
                ></button>
              </div>
              <div className="modal-body pt-0 pb-5 px-4">
                <div className="headingSec mb-2">
                  <h1
                    className="mb-0 text-left"
                    style={{
                      color: "#18357A",
                      fontSize: "28px",
                      fontWeight: 700,
                    }}
                  >
                    Deny patient request
                  </h1>
                </div>
                <p
                  style={{
                    color: "#5B6680",
                    textAlign: "left",
                  }}
                >
                  Patient will be notified about denial reason through email
                </p>

                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Denial reason:</Form.Label>
                    <Form.Control
                      {...register("reason")}
                      name="reason"
                      onKeyDown={handleKeyDown}
                      as="textarea"
                      rows={3}
                    />
                    <div className="text-danger">
                      {errors.reason?.message?.toString()}
                    </div>
                  </Form.Group>
                  <div className="gap-4 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-outline-secondary px-5 btnSize rounded-3"
                      onClick={() => {
                        setModalDeniedOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary px-5 btnSize rounded-3"
                    >
                      Confirm Denied
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal>

        {loader && <Loader />}
      </div>
    </Layout>
  );
};

export default PatientRequests;
