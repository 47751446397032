import { css } from 'styled-components';

import { colors } from 'utils/variables';

/** Layouts */
export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const flexRow = css`
  display: flex;
  flex-direction: row;
`;

export const fullScreen = css`
  width: 100vw;
  height: 100vh;
  overflow: scroll;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

/** Colors */
export const bgWhite = css`
  background-color: ${colors.white};
  color: ${colors.navy900};
`;

/** Components */
export const btnMid = css`
  width: 80px;
  height: 40px;
`;

export const btnBig = css`
  width: 180px;
  height: 50px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const btnPrimary = css`
  color: var(--white);
  background-color: var(--purple-300);
`;


export const tablestyle={
  border: "none",
  "& .MuiDataGrid-cell": {
    border: "none",
    "&:focus": {
      outline: "none",
    },
    "&:focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-row": {
    borderBottom: "none",
    "&:focus": {
      outline: "none",
    },
    "&:focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-cell--editable": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: "none",
    color: "#18357A", // Text color for the headers
    fontSize: "14px", // Font size for the headers
    fontWeight: 600, // Font weight for the headers
  },
}