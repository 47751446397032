import toast from "react-hot-toast";

const Validation = {
  validateStep: (
    currentStep,
    {
      firstName,
      lastName,
      title,
      degree,
      licenseNumber,
      specialty,
      email,
      password,
      otp,
    }
  ) => {
    const regexAlpha = /^[a-zA-Z\s]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    switch (currentStep) {
      case 1:
        if (!firstName) {
          toast.error("First name is required.");
          return false;
        }
        if (!regexAlpha.test(firstName)) {
          toast.error("First name should contain only alphabets.");
          return false;
        }
        if (firstName.length > 20) {
          toast.error("First name should be only 20 characters.");
          return false;
        }
        if (!lastName) {
          toast.error("Last Name is required.");
          return false;
        }
        if (!regexAlpha.test(lastName)) {
          toast.error("Last Name should contain only alphabets.");
          return false;
        }
        if (lastName.length > 20) {
          toast.error("Last name should be only 20 characters.");
          return false;
        }
        break;

      case 2:
        if (!title) {
          toast.error("Title is required.");
          return false;
        }
        if (!regexAlpha.test(title)) {
          toast.error("Title should contain only alphabets.");
          return false;
        }
        if (title.length > 20) {
          toast.error("Title should be only 20 characters.");
          return false;
        }
        if (!degree) {
          toast.error("Degree is required.");
          return false;
        }
        if (degree.length > 25) {
          toast.error("Degree should be only 20 characters.");
          return false;
        }
        if (!licenseNumber) {
          toast.error("License Number is required.");
          return false;
        }
        if (licenseNumber.length > 20) {
          toast.error("License Number should be only 20 characters.");
          return false;
        }
        break;

      case 3:
        if (!specialty) {
          toast.error("Specialty is required.");
          return false;
        }
        if (specialty === "Other") {
          toast.error("Other field is required.");
          return false;
        }
        if (!regexAlpha.test(specialty)) {
          toast.error("text should contain only alphabets.");
          return false;
        }
        if (specialty.length > 20) {
          toast.error("Specialty should be only 20 characters.");
          return false;
        }
        break;

      case 4:
        if (!email) {
          toast.error("Email is required.");
          return false;
        }
        if (!emailPattern.test(email)) {
          toast.error(`Email is not valid.\nExample: abc@gmail.com`);
          return false;
        }
        if (email.length > 30) {
          toast.error("Email should be only 30 characters.");
          return false;
        }
        if (!password) {
          toast.error("Password is required.");
          return false;
        }
        break;

      case 5:
        if (!otp || otp.length !== 6) {
          toast.error("OTP field is required.");
          return false;
        }
        break;
      default:
        return false;
    }
    return true;
  },
};

export default Validation;
export const validatePatientStep = (currentStep, formData, toast) => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    country,
    state,
    city,
    street,
    zipCode,
    phoneNumber,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactRelationship,
    insuranceCompanyName,
    policyHolderName,
    policyHolderId,
    insurancePolicyHolerDOB,
    groupNumber,
    relationship,
    insurancePolicyPhoneNumber,
    concerns,
    outpatient,
    providerName,
    providerEmail,
    treatmentStartDate,
    treatmentDate,
    referralSelect,
    showFields,
    referralName,
    referralInfo,
    email,
    password,
    otp,
  } = formData;

  const regexAlpha = /^[a-zA-Z\s]+$/;
  const numberRegex = /[0-9]/;
  const phoneNumberPattern = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;
  const emptyOrWhitespacePattern = /^.+\s.+$/;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const usaPhoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  const usPhoneNumberRegex =
    /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;

  switch (currentStep) {
    case 1:
      if (!firstName) {
        toast.error("First name is required.");
        return false;
      }
      // validation condition for white space
      // if (!firstName || emptyOrWhitespacePattern.test(firstName)) {
      //   toast.error("First name is required.");
      //   return false;
      // }
      if (!regexAlpha.test(firstName)) {
        toast.error("First name should contain only alphabets.");
        return false;
      }
      if (firstName.length > 20) {
        toast.error("First name should be only 20 characters.");
        return false;
      }
      if (!lastName) {
        toast.error("Last name is required.");
        return false;
      }
      // if (!lastName || emptyOrWhitespacePattern.test(lastName)) {
      //   toast.error("Last name is required.");
      //   return false;
      // }
      if (!regexAlpha.test(lastName)) {
        toast.error("Last name should contain only alphabets.");
        return false;
      }
      if (lastName.length > 20) {
        toast.error("Last name should be only 20 characters.");
        return false;
      }
      if (!dateOfBirth) {
        toast.error("Date of birth is required.");
        return false;
      }
      if (!gender) {
        toast.error("Gender is required.");
        return false;
      }
      break;

    case 2:
      if (!country) {
        toast.error("Country is required.");
        return false;
      }
      if (!regexAlpha.test(country)) {
        toast.error("Country name should contain only alphabets.");
        return false;
      }
      if (country.length > 20) {
        toast.error("Country name should be only 20 characters.");
        return false;
      }
      if (!state) {
        toast.error("State is required.");
        return false;
      }
      if (!regexAlpha.test(state)) {
        toast.error("State name should contain only alphabets.");
        return false;
      }
      if (state.length > 20) {
        toast.error("State name should be only 20 characters.");
        return false;
      }
      if (!city) {
        toast.error("City is required.");
        return false;
      }
      if (city.length > 20) {
        toast.error("City name should be only 20 characters.");
        return false;
      }
      if (!regexAlpha.test(city)) {
        toast.error("City name should contain only alphabets.");
        return false;
      }
      if (!street) {
        toast.error("Street, building, apt is required.");
        return false;
      }
      if (street.length > 30) {
        toast.error("Street, building, apt should be only 30 characters.");
        return false;
      }
      if (!zipCode) {
        toast.error("Zip code is required.");
        return false;
      }
      if (!numberRegex.test(zipCode)) {
        toast.error("Zip code should contain only number.");
        return false;
      }
      if (zipCode.length > 6) {
        toast.error("Zip code should be only 6 characters.");
        return false;
      }
      break;

    case 3:
      if (!phoneNumber) {
        toast.error("Phone number is required.");
        return false;
      }
      if (
        !usPhoneNumberRegex.test(phoneNumber) &&
        !usaPhoneNumberRegex.test(phoneNumber)
      ) {
        toast.error("Invalid US phone number format. \nFormat: (123)-456-7890");
        return false;
      }
      if (!emergencyContactName) {
        toast.error("Emergency contact name field is required.");
        return false;
      }
      if (!regexAlpha.test(emergencyContactName)) {
        toast.error("Emergency contact name should contain only alphabets.");
        return false;
      }
      if (emergencyContactName.length > 20) {
        toast.error("Emergency contact name should be only 20 characters.");
        return false;
      }
      if (!emergencyContactNumber) {
        toast.error("Emergency contact number field is required.");
        return false;
      }
      if (
        !usPhoneNumberRegex.test(emergencyContactNumber) &&
        !usaPhoneNumberRegex.test(emergencyContactNumber)
      ) {
        toast.error("Invalid US phone number format. \nFormat: (123)-456-7890");
        return false;
      }
      if (!emergencyContactRelationship) {
        toast.error("Emergency contact relationship field is required.");
        return false;
      }
      if (!regexAlpha.test(emergencyContactRelationship)) {
        toast.error(
          "Emergency contact relationship should contain only alphabets."
        );
        return false;
      }
      if (emergencyContactRelationship.length > 20) {
        toast.error(
          "Emergency contact relationship should be only 20 characters."
        );
        return false;
      }
      break;

    case 4:
      if (!insuranceCompanyName) {
        toast.error("Insurance company name field is required.");
        return false;
      }
      if (!regexAlpha.test(insuranceCompanyName)) {
        toast.error("Insurance company name should contain only alphabets.");
        return false;
      }
      if (insuranceCompanyName.length > 20) {
        toast.error("Insurance company name should be only 20 characters.");
        return false;
      }
      if (!policyHolderName) {
        toast.error("Policy holder’s name is required.");
        return false;
      }
      if (!regexAlpha.test(policyHolderName)) {
        toast.error("Policy holder’s name should contain only alphabets.");
        return false;
      }
      if (policyHolderName.length > 20) {
        toast.error("Policy holder’s name should be only 20 characters.");
        return false;
      }
      if (!policyHolderId) {
        toast.error("Policy holder's Id field is required.");
        return false;
      }
      if (!numberRegex.test(policyHolderId)) {
        toast.error("Policy holder's Id should contain only number.");
        return false;
      }
      if (policyHolderId.length > 20) {
        toast.error("Policy holder's Id should be only 20 characters.");
        return false;
      }
      if (!insurancePolicyHolerDOB) {
        toast.error("Date of birth is required.");
        return false;
      }
      if (!groupNumber) {
        toast.error("Group number is required.");
        return false;
      }
      if (!numberRegex.test(groupNumber)) {
        toast.error("Group number should contain only number.");
        return false;
      }
      if (groupNumber.length > 20) {
        toast.error("Group number should be only 20 characters.");
        return false;
      }
      if (!relationship) {
        toast.error("Relationship field is required.");
        return false;
      }
      if (!regexAlpha.test(relationship)) {
        toast.error("Relationship should contain only alphabets.");
        return false;
      }
      if (relationship.length > 20) {
        toast.error("Relationship should be only 20 characters.");
        return false;
      }
      if (!insurancePolicyPhoneNumber) {
        toast.error("Phone number field is required.");
        return false;
      }

      if (
        !usPhoneNumberRegex.test(insurancePolicyPhoneNumber) &&
        !usaPhoneNumberRegex.test(insurancePolicyPhoneNumber)
      ) {
        toast.error("Invalid US phone number format.");
        return false;
      }
      break;

    case 5:
      if (concerns.length === 0) {
        toast.error("Concerns is required.");
        return false;
      }
      break;

    case 6:
      if (!outpatient) {
        toast.error("Outpatient field is required.");
        return false;
      }
      if (outpatient === "Other") {
        toast.error("Response field is required.");
        return false;
      }
      if (!regexAlpha.test(outpatient)) {
        toast.error("text should contain only alphabets.");
        return false;
      }
      if (outpatient.length > 20) {
        toast.error("text should be only 20 characters.");
        return false;
      }
      break;

    case 7:
      if (!providerName) {
        toast.error("Provider name field is required.");
        return false;
      }
      if (!regexAlpha.test(providerName)) {
        toast.error("Provider name should contain only alphabets.");
        return false;
      }
      if (providerName.length > 20) {
        toast.error("Provider name should be only 20 characters.");
        return false;
      }
      if (!providerEmail) {
        toast.error("Email address field is required.");
        return false;
      }
      if (!emailPattern.test(providerEmail)) {
        toast.error("Email address should be valid.");
        return false;
      }
      if (providerEmail.length > 30) {
        toast.error("Email address should be only 30 characters.");
        return false;
      }
      if (!treatmentStartDate) {
        toast.error("Treatment start date field is required.");
        return false;
      }
      if (!treatmentDate) {
        toast.error("Treatment date field is required.");
        return false;
      }
      break;

    case 8:
      if (!referralSelect) {
        toast.error("Please select an option");
        return false;
      }
      if (showFields) {
        if (!referralName) {
          toast.error("Referral source field is required.");
          return false;
        }
        if (!regexAlpha.test(referralName)) {
          toast.error("Referral source should contain only alphabets.");
          return false;
        }
        if (referralName.length > 20) {
          toast.error("Referral source should be only 20 characters.");
          return false;
        }
        if (!referralInfo) {
          toast.error("Referral contact field is required.");
          return false;
        }
        if (!phoneNumberPattern.test(referralInfo)) {
          toast.error("Referral contact should be a 10-digit number.");
          return false;
        }
        if (referralInfo.length > 20) {
          toast.error("Referral contact should be only 10 characters.");
          return false;
        }
      }
      break;

    case 9:
      if (!email) {
        toast.error("Email field is required.");
        return false;
      }
      if (!emailPattern.test(email)) {
        toast.error("Email should be valid.");
        return false;
      }
      if (email.length > 30) {
        toast.error("Email should be only 30 characters.");
        return false;
      }
      if (!password) {
        toast.error("Password field is required.");
        return false;
      }
      break;

    case 10:
      if (!otp || otp.length !== 6) {
        toast.error("OTP field is required.");
        return false;
      }
      break;

    default:
      return true;
  }
  return true;
};
