import React, {useRef, useEffect} from 'react';
import { Image } from 'react-bootstrap';
import user_small from '../../assets/images/user_small.png';
import moment from 'moment';
import './Messages.css';

const MessageChatSection = ({ msgData, userId }) => {
  const scrollRef = useRef(null);


  useEffect(() => {
		const fetchDataAndScroll = async () => {
			try {
					scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
			} catch (e) {
				console.error(e);
			}
		};

		fetchDataAndScroll();
	}, [scrollRef, msgData]);

  return (


    <>
      {msgData?.length === 0 ? (
        <div className="chat-messages-default-center">
          <div>
            
          </div>
        </div>
      ) : (
        <div className="chat-messages">
          {msgData?.map((message) => {
            const formattedDate = moment(message?.createdAt).format('DD-MM-YYYY hh:mm A');

            return (
              <div key={message.id} ref={scrollRef} className={`chat-message-${message.type} pb-4`}>
                {message.senderId !== String(userId) ? (
                  <div className="flex-shrink-1 Amelia_text py-2 px-3 ml-3">
                    <div className="d-flex align-items-baseline gap-2">
                      <div>
                        <Image
                          src={user_small}
                          width={16}
                          height={16}
                          className="img-fluid"
                          alt="user_img"
                        />
                      </div>
                      <div className="cht_time">
                        <h3>{message.senderName}</h3>
                      </div>
                    </div>
                    <p>
                      {message.message}
                      <div className="small text-nowrap text-end chat-date">
                        {message.createdAt ? formattedDate : ''}
                      </div>
                    </p>
                  </div>
                ) : (
                  <div className="right_msg py-2 px-3 mr-3">
                    {message.message}
                    <div className="small text-nowrap text-end chat-date">
                      {message.createdAt ? formattedDate : ''}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  // <>

  //   {msgData === undefined ? <div className="chat-messages-default-center">
  //     <div>

  //     <h4 className='default-chat-box' color="#444444">
  //       Welcome to Chat Box!
  //       <br />
  //       {/* <br /> Let the conversations begin! */}
  //     </h4>
  //     <div style={{ textAlign: "center" }}>

  //       <h5> <br />Select a group or person to start conversation.</h5>
  //       <h6><br />Let the conversations begin!</h6>
  //     </div>

  //     </div>
  //   </div>
  //    : <div className="chat-messages">
  //     {msgData?.map((message) => {
  //       const formattedDate = moment(message?.createdAt).format('DD-MM-YYYY hh:mm A');
       
  //       return (

  //         <div key={message.id} ref={scrollRef} className={`chat-message-${message.type} pb-4`}>
  //           {message.senderId !== String(userId) ? (
  //             <div className="flex-shrink-1 Amelia_text py-2 px-3 ml-3">
  //               <div className="d-flex align-items-baseline gap-2">
  //                 <div>
  //                   <Image
  //                     src={user_small}
  //                     width={16}
  //                     height={16}
  //                     className="img-fluid"
  //                     alt="user_img"
  //                   />
  //                 </div>
  //                 <div className="cht_time">
  //                   <h3>{message.senderName}</h3>
  //                 </div>
  //               </div>
  //               <p>
  //                 {message.message}
  //                 <div className="small text-nowrap text-end chat-date">
  //                   {message.createdAt ? formattedDate : ''} {/* Format createdAt here */}
  //                 </div>
  //               </p>
  //             </div>
  //           ) : (
  //             <div className="right_msg py-2 px-3 mr-3">
  //               {message.message}
  //               <div className="small text-nowrap text-end chat-date">
  //                 {message.createdAt ? formattedDate : ''} {/* Format createdAt here */}
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //       )
  //     }

  //     )}
  //   </div>}
  // </>
  );
};


export default MessageChatSection;
