import { Routes, Route, Navigate } from 'react-router-dom';
import GroupCallFullScreen from './components/pages/GroupCallFullScreen';

const GroupCallApp = () => {
  return (
    <Routes>
      <Route path="/full-screen" element={<GroupCallFullScreen />} />
      <Route path="*" element={<Navigate to="/full-screen" />} />
    </Routes>
  );
};

export default GroupCallApp;
