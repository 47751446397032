import dayjs from 'dayjs';
import { MEDIA_SIZES } from 'utils/variables';

type Labels = keyof typeof MEDIA_SIZES;

export const isBrowser = typeof window !== 'undefined';
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const media = (Object.keys(MEDIA_SIZES) as Labels[])
  .reduce((acc, label) => {
    acc[label] = `
      @media (min-width: ${MEDIA_SIZES[label] / 16}em)`;
    return acc;
  }, {} as Record<Labels, string>);

export const mediaMax = (Object.keys(MEDIA_SIZES) as Labels[])
  .reduce((acc, label) => {
    acc[label] = `
      @media (max-width: ${(MEDIA_SIZES[label] - 1) / 16}em)`;
    return acc;
  }, {} as Record<Labels, string>);

  function convertDurationString(durationStr: { split: (arg0: string) => [any, any]; }) {
    const [value, unit] = durationStr.split(' '); // Split the input string by space
    const minutes = parseInt(value); // Parse the numeric value

    if (unit === 'mins' || unit === 'minutes') {
        return minutes;
    }

    return 0; // Handle invalid input
}

export function getFormattedTimes(date: any, time: any, duration: { split: (arg0: string) => [any, any]; }) {
  const startDateTime = dayjs(`${date}T${time}`);
  const durationMinutes = convertDurationString(duration);
  const endDateTime = startDateTime.add(durationMinutes, 'minute');
  const formattedStartTime = startDateTime.format('HH:mm A');
  const formattedEndTime = endDateTime.format('HH:mm A');
  return {
      formattedStartTime,
      formattedEndTime,
  };
}

export const mapSchedulesToCalendarEvents = (schedules: any[]) => {
    if (!schedules || !Array.isArray(schedules)) {
        return [];
    }

    return schedules.map(event => {
        const startDateTime = new Date(event.date) // Combine date and time
        const { formattedStartTime, formattedEndTime } = getFormattedTimes(event.date, event.time, event.duration);
        return {
            id: event.id,
            title: event.title,
            start: startDateTime,
            end: null,
            endtime:formattedEndTime,
            starttime:formattedStartTime,
            description: event.description,
            participants: event.participants.map((participant: { name: string; }) => participant.name).join(', '),
            extendedProps: {
                time: event.time,
                duration: event.duration,
                frequency: event.frequency,
                stype: event.stype,
                status: event.status,
                createdAt: event.createdAt,
                updatedAt: event.updatedAt,
            },
            backgroundColor: getColor(event.appointmenttype)?.color, 
            borderColor:getColor(event.appointmenttype)?.borderColor
        };
    });
};


const getColor=(name: any)=>{
  switch (name) {
    case "Clinical Consultations":
      return {color:"#e6ecff",borderColor:"#5955B3"}
    case "Individual Therapy":
      return {color:"#e3f8ee",borderColor:"#00BB61"}
    case "Insurance Meeting":
      return {color:"#f9fbff",borderColor:"#4264D0"}
    case "Clinical Team Meeting":
      return {color:"#ffe8e7",borderColor:"#FF6760"}
    case "Group Therapy":
        return {color:"#fff7e1",borderColor:"#FFC739"}
    default:
      break;
  }
}

export const getInitials = (name: string) => {
  if (!name) return '';
  const nameParts = name.split(' ');
  const firstNameInitial = nameParts[0]?.[0] || '';
  const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.[0] || '' : '';
  return `${firstNameInitial}${lastNameInitial}`;
};


export const formatDateWithMonth = (dateString: string | number | Date | dayjs.Dayjs | null | undefined) => {
  return dayjs(dateString).format('MMMDD');
};