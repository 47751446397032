import React, { useState,useEffect } from 'react';
import Modal from 'react-modal';
import Psychotherapy from './Psychotherapy/Psychotherapy';
import GroupNotes from './GroupNotes/GroupNotes';
import OtherTask from './OtherTask/OtherTask';
import { documentationPatientNotes } from 'lib/api/service';
import { useParams } from 'react-router-dom';
const Other = () => {
  const { patientId } = useParams();
  const [clinicalData, setclinicalData] = useState([]);
  const [activeTab, setActiveTab] = useState('tab1');
  const [nestedTab, setNestedTab] = useState('nestedTab1');

  useEffect(() => {
    if (patientId) {
      const numericPatientId = Number(patientId);
      fetchNotesById(numericPatientId);
    }
  }, [patientId]);

  const fetchNotesById = async (patientId) => {
    try {
      if (patientId) {
        const response = await documentationPatientNotes({
          patientID: patientId,
          noteType: "other",
        });
        setclinicalData(response?.documentation?.documentation);
      } else {
        console.error("Patient email is not available");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const handleTabChange = (tabId) => {

    setActiveTab(tabId);

    if (tabId === 'tab1') {
      setNestedTab('nestedTab1');
    }
    else if (tabId === 'tab2') {
      setNestedTab('nestedTab3');
    }
  };

  const handleNestedTabChange = (tabId) => {
    setNestedTab(tabId);
  };

  return (

    <div id="other">
      <div className="position-relative topRelative text-right w-auto float-end">
        <div className='row align-items-center justify-content-between'>
          <div className='col-md-auto'></div>
          <div className='col-md-auto'>
            <div className='d-flex'>

              {nestedTab === 'nestedTab1' &&
                <></>
              }

              {nestedTab === 'nestedTab2' &&
                <></>
              }

              {nestedTab === 'nestedTab3' &&
                <>
                </>
              }

              {nestedTab === 'nestedTab4' &&
                <>

                </>
              }

            </div>
          </div>
        </div>
      </div>



      {/* Tab content */}
      {activeTab === 'tab1' && (
        <div>

          {/* Nested Tab */}
          <button className='border-0 bg-transparent ps-0' onClick={() => handleNestedTabChange('nestedTab1')}>
            <div className='col-md'>
              <ul className="nav nav-pills mb-0 dbSkillTab gap-2" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${nestedTab === 'nestedTab1' ? 'active' : ''}`}
                    id="pills-diryCard-tab" data-bs-toggle="pill" data-bs-target="#pills-diryCard" type="button" role="tab" aria-controls="pills-diryCard"
                    aria-selected={nestedTab === 'nestedTab1'}
                  >PSYCHIATRY NOTES</button>
                </li>
              </ul>
            </div>
          </button>
          <button className='border-0 bg-transparent' onClick={() => handleNestedTabChange('nestedTab2')}><div className='col-md'>
            <ul class="nav nav-pills mb-0 dbSkillTab gap-2" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  className={`nav-link ${nestedTab === 'nestedTab2' ? 'active' : ''}`}
                  id="pills-skill-tab" data-bs-toggle="pill" data-bs-target="#pills-skill" type="button" role="tab" aria-controls="pills-skill"
                  aria-selected={nestedTab === 'nestedTab2'}
                >GROUP NOTES</button>
              </li>
            </ul>
          </div></button>

          <button className='border-0 bg-transparent' onClick={() => handleNestedTabChange('nestedTab3')}><div className='col-md'>
            <ul class="nav nav-pills mb-0 dbSkillTab gap-2" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  className={`nav-link ${nestedTab === 'nestedTab3' ? 'active' : ''}`}
                  id="pills-skill-tab" data-bs-toggle="pill" data-bs-target="#pills-skill" type="button" role="tab" aria-controls="pills-skill"
                  aria-selected={nestedTab === 'nestedTab3'}
                >OTHER NOTES</button>
              </li>
            </ul>
          </div></button>

          {/* Nested Tab content */}
          {nestedTab === 'nestedTab1' && <div class="tab-content">
            <div class="tab-pane fade show active" aria-labelledby="pills-diryCard-tab">
              <Psychotherapy clinicalData={clinicalData}/>
            </div>
          </div>}
          {nestedTab === 'nestedTab2' && <div>
            <GroupNotes />
          </div>
          }

          {nestedTab === 'nestedTab3' && <div>
            <OtherTask />
          </div>}

        </div>
      )}

    </div>
  );

};

export default Other;
