import React from 'react';
import { Toast } from 'react-bootstrap';

const ToastComponent = ({ show, onClose, message, variant }) => {
  return (
    <Toast show={show} onClose={onClose} delay={3000} autohide>
      <Toast.Header closeButton={false} className={`bg-${variant} text-white`}>
        <strong className="me-auto">{variant === 'success' ? 'Success' : 'Error'}</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export const SuccessMessage = ({ message }) => {
  return (
    <ToastComponent
      show={true}
      message={message}
      variant="success"
    />
  );
};

export const ErrorMessage = ({ message }) => {
  return (
    <ToastComponent
      show={true}
      message={message}
      variant="danger"
    />
  );
};
