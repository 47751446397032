import React, { useState, useContext, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import Header from "../../../components/Header/Header";
import { Page1, Page2, Page3, Page4 } from "./DischargePages";
import "./Discharge.css";
import { DischargeContext } from "../../../context/DischargeProvider";

import PatientProfileSidebar from "../../../components/Patient/PatientProfileSidebar";
import Layout from "components/layout/layout";
import toast from "react-hot-toast";

const Discharge = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const {
    setDischaregeData,
    getDischaregeData,
    dischargePatientsApi,
    deleteDischaregeData,
  } = useContext(DischargeContext);

  const { patientId } = useParams();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const pageId = parts[parts.length - 1];

  const [formData, setFormData] = useState({});

  const blockTexts = [
    { page: 1, text: "Discharge summary" },
    { page: 2, text: "Clinical information" },
    { page: 3, text: "Summary of treatment" },
    { page: 4, text: "Aftercare information" },
  ];
  const currentPageIndex = parseInt(location.pathname.match(/\d+$/)[0], 10) - 1;
  const currentBlockText = blockTexts[currentPageIndex];

  useEffect(() => {
    // setFormData({});
    // const current_page = async () => {
    //   const data = await getDischaregeData(patientId);
    //   console.log(data.hasOwnProperty(pageId));
    //   if (data.hasOwnProperty(pageId)) {
    //     setFormData(data[pageId]); //  setFormData(data.pageId)
    //   }
    // };

    // current_page();
    setFormData({});
    const current_page = async () => {
      const data = await getDischaregeData(patientId);
      if (data.hasOwnProperty(pageId)) {
        setFormData(data[pageId]);
      }
    };
    current_page();
  }, [pageId, patientId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));
    await setDischaregeData(data, patientId, pageId);
    navigate(getNextPage());
  };

  const getNextPage = () => {
    const currentPageIndex = parseInt(pageId.match(/\d+$/)[0], 10);
    const nextPageIndex = (currentPageIndex % 4) + 1;
    return `/profile/discharge/${patientId}/page${nextPageIndex}`;
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      const datas = {};
      formData.forEach((value, key) => (datas[key] = value));
      await setDischaregeData(datas, patientId, pageId);
      const data = await getDischaregeData(patientId);
  
      // Remove patient_id from every page
      delete data["page1"]["patient_id"];
      delete data["page2"]["patient_id"];
      delete data["page3"]["patient_id"];
      delete data["page4"]["patient_id"];
  
      setLoader(true);
  
      const store = await dischargePatientsApi(data, patientId);
      if (store.message) {
        toast.success(store.message);
        setLoader(false);
        await deleteDischaregeData(patientId);
        setFormData({});
        navigate("/");
        return false;
      }
      setTimeout(() => {
        setLoader(false);
        setFormData({});
        navigate("/");
        return false;
      }, 5000);
    } catch (err) {
      setLoader(false);
    }
  };
  

  return (
    <Layout>
      <div className="vh-100 mainBglight">
        <Header pageTitle="Discharge" />
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-4 dischargeForm cardPaitent">
            <div className="d-flex justify-content-between">
              <h2>Discharge patient</h2>
              <span>
                Block {currentPageIndex + 1}: {currentBlockText.text}
              </span>
            </div>
            <div className="row w-100">
              <div className="col-4">
                <PatientProfileSidebar
                  location={location}
                  formName="discharge"
                  patientId={patientId}
                  blockTexts={blockTexts}
                />
              </div>
              <div className="col-8">
                <Routes>
                  <Route
                    path={`/page1`}
                    element={
                      <Page1
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                      />
                    }
                  />
                  <Route
                    path={`/page2`}
                    element={
                      <Page2
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                      />
                    }
                  />
                  <Route
                    path={`/page3`}
                    element={
                      <Page3
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                      />
                    }
                  />
                  <Route
                    path={`/page4`}
                    element={
                      <Page4
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={formSubmit}
                        loader={loader}
                      />
                    }
                  />
                  <Route
                    path={`/`}
                    element={
                      <Navigate to={`/profile/discharge/${patientId}/page1`} />
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Discharge;
