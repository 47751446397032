import axios from 'axios';
export async function createUser(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/add-user`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}catch(e){
		console.error(e);
	}
}

export async function loginUser(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/login-user`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}catch(e){
		console.error(e);
	}
}
export async function getUsers(token: string) {
	try{
		return axios.get(`${process.env.REACT_APP_REST_API_URL}/get-user`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	}catch(e){
		console.error(e);
	}
}
export async function getAllUsers(token: string) {
	try{
		return axios.get(`${process.env.REACT_APP_REST_API_URL}/get-all-user`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	}catch(e){
		console.error(e);
	}
}
export async function createRoom(token: string, data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/create-room`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	}catch(e){
		console.error(e);
	}
}
export async function createGroup(token: any, data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/grouproom`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	}catch(e){	
		console.error(e);
	}
}


export async function updateGroup(token: any, data: any) {
	try{
		return axios.put(`${process.env.REACT_APP_REST_API_URL}/update/grouproom/${data?.id}`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	}catch(e){	
		console.error(e);
	}
}
export async function getGroupRooms(id: any) {
	try {
		return await axios.get(`${process.env.REACT_APP_REST_API_URL}/grouproom/${id}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	} catch (error) {
		return error;
	}
}


export async function searchGroup(data: any){
	try{
		return await axios.get(`${process.env.REACT_APP_REST_API_URL}/groups/search?searchTerm=${data}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}catch(error){
		return error;
	}
}
export async function getRooms(token: string) {
	try{
		return axios.get(`${process.env.REACT_APP_REST_API_URL}/get-rooms`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	}catch(e){
		console.error(e);
	}
}
export async function SendMessage(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/singlechatmsg`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}
export async function getMessage(token: string, data: any, id: any) {
	try{
		return axios.get(`${process.env.REACT_APP_REST_API_URL}/getsinglechatmsg/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	}catch(e){
		console.error(e);
	}
}

export async function getChatList(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/singlechatroom`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){	
		console.error(e);
	}
}

export async function getChatListAll(data: any) {
	try{
	return axios.get(`${process.env.REACT_APP_REST_API_URL}/getsinglechatroomuserid/${data}`);

	}catch(e){
		console.error(e);
	}
}

export async function getChatMsgData(data: any) {
	try {

		return axios.get(`${process.env.REACT_APP_REST_API_URL}/getsinglechatmsg/${data}`);
	} catch (error) {
		console.error(error)
	}
}

export async function getChatMsgDataAllUnRead(data: any) {
	try{
		return axios.get(`${process.env.REACT_APP_REST_API_URL}/getAllUnreadMsg/${data}`);
	}catch(e){
		console.error(e);
	}
}

export async function deleteMessage(token: any, id: any) {
	try{
		return axios.delete(`${process.env.REACT_APP_REST_API_URL}/deletechatmsg/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	}catch(e){
		console.error(e);
	}
}
export async function updateMessage(token: any, id: any, data: any) {
	try{
		return axios.put(`${process.env.REACT_APP_REST_API_URL}/editchatmsg/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	}catch(e){
		console.error(e);
	}
}

export async function chatGlobalSearch(data: any, loginUserDetail?: any) {
	try{
		return axios.post(
			`${process.env.REACT_APP_REST_API_URL}/search`,
			{ data, loginUserDetail },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + 'RRRR',
				},
			}
		);
	}catch(e){
		console.error(e);
	}
}

//_______________--------------Bot Message---------------------________________---//

export async function addBotMsg(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/botMsg`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}

//-------------------------------GetBotMsgbyId---------------------------------------------//

export async function getBotMessageById(data: any) {
try{
	return axios.get(`${process.env.REACT_APP_REST_API_URL}/getbotMsgbyId/${data}`);
}catch(e){
	console.error(e);
}
}

export const getPublicIp = async () => {
	try {
		const response = await fetch(`${process.env.IP_URL}`);
		const data = await response.json();
		return data.ip;
	} catch (error) {
		console.error('Error fetching public IP:', error);
		return null;
	}
};
// defaultMessage

//----------------------Default Message ----------------------------------------//

export async function defaultMessageData(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/defaultMessage`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}

export async function getDefaultMessageData(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/getDefaultMessageBy-Id`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);}
}

export async function deletedefaultMessage(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/deletedefaultMessage`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}

export async function getAllDefaultMsg() {
	try{
		return axios.get(`${process.env.REACT_APP_REST_API_URL}/getAllDefault-msg`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){	
		console.error(e);
}
};

///// ---------------------------Unread_Bot_Count-----------------------------/////

export async function getBotUnreadCount(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/bot-unread-count`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}

export async function updateBotUnreadCount(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/updatebot-unread-count`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}

///////------------------------Pop-Notification-----------------------------//////

export async function getStatusPopNotification(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/getpop-notification`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}

export async function updatePopNotification(data: any) {
	try{
		return axios.post(`${process.env.REACT_APP_REST_API_URL}/updatepop-notification`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}

///// -----------------Billing Department _________________________--------/////

export async function getAllBillingDepartment() {
	try{
		return axios.get(`${process.env.REACT_APP_REST_API_URL}/get-billingDepartment`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}

//////-----------------------Admin Department-----------------------------------------/////

export async function getAllAdmin() {
	try{
		return axios.get(`${process.env.REACT_APP_REST_API_URL}/get-allAdmin`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + 'RRRR',
			},
		});
	}catch(e){
		console.error(e);
	}
}
