import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import "./Assessment.css";
import AssessmentResultModal from "../../../modals/AssessmentResultModal";
import PtsdResultModal from "../../../modals/PtsdResultModal";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { AssessmentsContext } from "context/AssessmentsProvider";
import { ProfileContext } from "context/ProfileProvider";
import { ASSESSMENTS_TYPES } from "Data";
import { PatientIntakContext } from "context/PatientIntakeProvider";
import AssignedAssessmentPatientModal from "modals/AssignedAssessmentPatientModal";

import toast from "react-hot-toast";
import AccessmentAddEditModal from "modals/AccessmentAddEditModal";
import axios from "axios";

const Assessment = ({ patientId }) => {
  const [assessmentsLists, setAssessmentsLists] = useState([]);
  const [allAssessmentsLists, setAllAssessmentsLists] = useState([]);
  const {
    assessments,
    getAllAssessments,
    deleteAssessmentsRecords,
    assignedDocumentsForm,
    AddUpdateAssessments,
  } = useContext(AssessmentsContext);
  const { emptyPatientsSearchItme } = useContext(PatientIntakContext);
  const { profile, patientDetail } = useContext(ProfileContext);
  const [selectedOption, setSelectedOption] = useState("1");
  const [assignFormData, setAssignFormData] = useState([]);
  const [isAssessmentResultModalOpen, setIsAssessmentResultModalOpen] =
    useState(false);
  const [IsAddEditAssessmentModalOpen, setIsAddEditAssessmentModalOpen] =
    useState(false);
  const [assessmentsType, setAssessmentsType] = useState(true);
  const [selectedAssessmentResultData, setSelectedAssessmentResultData] =
    useState("");
  const [isAssignedToPatientModalOpen, setIsAssignedToPatientModalOpen] =
    useState(false);
  const [formData, setFormData] = useState({});
  const [isPtsdResultModalModalOpen, setIsPtsdResultModalModalOpen] =
    useState(false);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [filterAssessments, setFilterAssessments] = useState([]);
  const token = localStorage.getItem("token");

  useLayoutEffect(() => {
    setSelectedOption("1");
    setAssessmentsType(true);
    getAllAssessments(selectedOption || "1");
  }, []);

  const setAddNewTaskCloseModal = () => {
    setIsAssignedToPatientModalOpen(false);
  };

  const assignedAssessments = (assessmentItems) => {
    setIsAssignedToPatientModalOpen(true);
    setCurrentAssessment(assessmentItems);
  };

  const editAccessment = (item) => {
    setCurrentAssessment(item);
    setIsAddEditAssessmentModalOpen(true);
  };

  const updateAssessments = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    data["task"] = data.assessment_id ? "UpdateAssessments" : "AddAssessments";
    await AddUpdateAssessments(data);
    setIsAddEditAssessmentModalOpen(false);
    setCurrentAssessment({});
    toast.success("Successfully done");
  };

  const assignedFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData.entries());

      data["task"] = "assessmentsAssigned";
      data["dr_id"] = profile?.user_id;
      data["patient_id"] = patientId;
      await assignedDocumentsForm(data);
      toast.success("Assigned successfully");
      emptyPatientsSearchItme();
      setIsAssignedToPatientModalOpen(false);
      setAssignFormData([]);
    } catch (err) {
      console.error(err, "err");
    }
  };

  const filterData = (filter) => {
    const search = filter.target.value;
    var getAssessmentData = assessments.filter((assesst) =>
      assesst.title.includes(search)
    );
    setFilterAssessments(getAssessmentData);
  };

  const handleAddEditAssessmentsChange = (e) => {
    const { name, value, type, checked } = e.target;

    let newValue;
    if (type === "checkbox") {
      newValue = checked ? "on" : "off";
    } else {
      newValue = value;
    }

    setCurrentAssessment((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleAssignedAssessmentsChange = (e) => {
    const { name, value, type, checked } = e.target;

    let newValue;
    if (type === "checkbox") {
      newValue = checked ? "on" : "off";
    } else {
      newValue = value;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (assessmentsType) {
      getAllAssessments(selectedValue);
    }
  };

  const setAssessments = (type) => {
    setAssessmentsType(type);
    if (type) {
      getAllAssessments(selectedOption);
    } else {
      getAllAssessments("");
    }
  };

  const deleteRecord = async (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      await deleteAssessmentsRecords(id, profile?.user_id);
    } else {
      return false;
    }
  };

  const getAssessments = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/assessments/getAssessmentAssigned/${patientId}`,
        {
          headers: headers,
        }
      );
      setAssessmentsLists(response.data?.assessment);
    } catch (error) {
      console.error("Error fetching all assessments:", error);
      return [];
    }
  };

  useEffect(() => {
    if (patientId) {
      getAssessments();
    }
  }, [patientId]);

  const getAllAssessmentsList = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/assessments/getAllAssessments`,
        {
          headers: headers,
        }
      );
      setAllAssessmentsLists(response.data?.assessments);
    } catch (error) {
      console.error("Error fetching all assessments:", error);
      return [];
    }
  };

  useEffect(() => {
    getAllAssessmentsList();
  }, []);

  const assignAssessmentsToPatient = async (id) => {
    try {
      const data = JSON.stringify({
        assessment_id: id,
        patient_id: patientId,
      });
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}/assessments/assessmentAssigned`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      const response = await axios(config);
      toast.success(response?.data.message);
    } catch (error) {
      console.error(error, "Error");
    }
  };

  const getButtonClass = (status) => {
    if (status === "NA" || status === 0) {
      return "pendingBtn";
    } else if (status === 1) {
      return "inProgressBtn";
    } else {
      return "completeBtn";
    }
  };

  const getButtonLabel = (status) => {
    if (status === "NA" || status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Completed";
    } else {
      return "";
    }
  };

  return (
    <div id="assessment">
      <div className="headingTable cardPaitent">
        <div className="row">
          <div className="col-md-12 mb-3">
            <h1 className="mb-0 fs-5">Assessments</h1>
          </div>
          <div className="col-md-12">
            <ul
              className="nav nav-pills mb-3 navTabUI"
              id="pills-tab"
              role="tablist"
            >
              <li
                className="nav-item flex-fill bg-light rounded-start-3"
                role="presentation"
              >
                <button
                  className="nav-link active px-5 py-2 w-100"
                  id="pills-single-tab"
                  data-bs-toggle="pill"
                  onClick={() => setAssessments(true)}
                  data-bs-target="#pills-single"
                  type="button"
                  role="tab"
                  aria-controls="pills-single"
                  aria-selected="true"
                >
                  Assigned assessments
                </button>
              </li>
              <li
                className="nav-item flex-fill bg-light rounded-end-3"
                role="presentation"
              >
                <button
                  className="nav-link px-5 py-2 w-100"
                  id="pills-reoccurring-tab"
                  data-bs-toggle="pill"
                  onClick={() => setAssessments(false)}
                  data-bs-target="#pills-reoccurring"
                  type="button"
                  role="tab"
                  aria-controls="pills-reoccurring"
                  aria-selected="false"
                >
                  All assessments
                </button>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-md-6 assignmentList ">
              <div className="mb-3 row align-items-center">
                <div className="col-md-12">
                  <select
                    className="form-select"
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    {ASSESSMENTS_TYPES.length &&
                      ASSESSMENTS_TYPES.map((item) => (
                        <option key={item.name} value={item.name}>
                          {item.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/* <button
                type="button"
                className="btn btn-success border-0"
                // onClick={() => editAccessment({})}
              >
                <i className="fas fa-list-alt"></i> Add new assessments
              </button> */}
            </div>
          </div>
          <div className="col-md-12 assignmentList ">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-single"
                role="tabpanel"
                aria-labelledby="pills-single-tab"
              >
                <ul className="p-0 assignmentList d-grid gap-3">
                  {assessmentsLists.length > 0 &&
                    assessmentsLists?.map((item) => (
                      <li className="border rounded-3 py-2 px-3" key={item.id}>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img src="/images/book-icon.svg" alt="..." />
                          </div>
                          <div className="flex-grow-1 ms-3 gap-2">
                            <h1 className="mb-2 fs-6">{item.assessmentName}</h1>
                            <p className="mb-0 pe-3">{item.description}</p>
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn bg-transparent px-0"
                                onClick={() => {
                                  setSelectedAssessmentResultData(item);
                                  setIsAssessmentResultModalOpen(true);
                                }}
                              >
                                Assessment results
                              </button>
                              <button
                                type="button"
                                className="btn bg-transparent px-0"
                                onClick={() => {
                                  setSelectedAssessmentResultData(item);
                                  setIsPtsdResultModalModalOpen(true);
                                }}
                              >
                                Progress graph
                              </button>
                            </div>
                          </div>
                          <div className="col-md-auto">
                            <span>{item.dueDate}</span>
                          </div>
                          <div className="col-md-auto">
                            <button
                              type="button"
                              className={`btn ms-3 ${getButtonClass(
                                item.status
                              )}`}
                            >
                              {getButtonLabel(item.status)}
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div
                className="tab-pane fade"
                id="pills-reoccurring"
                role="tabpanel"
                aria-labelledby="pills-reoccurring-tab"
              >
                <ul className="p-0 assignmentList d-grid gap-3">
                  {allAssessmentsLists.length > 0 &&
                    allAssessmentsLists?.map((assessmentItems) => (
                      <li
                        className="border rounded-3 py-2 px-3"
                        key={`item_${assessmentItems.id}`}
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img src="/images/book-icon.svg" alt="..." />
                          </div>
                          <div className="flex-grow-1 ms-3 gap-2">
                            <h1 className="mb-2 fs-6">
                              {assessmentItems.assessmentName}
                            </h1>
                            <p className="mb-0 pe-3">
                              {assessmentItems.description ||
                                "You often overreact when something triggers your emotions."}
                            </p>
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn bg-transparent px-0"
                                onClick={() => {
                                  setSelectedAssessmentResultData(
                                    assessmentItems
                                  );
                                  setIsAssessmentResultModalOpen(true);
                                }}
                              >
                                Assessment results
                              </button>
                              <button
                                type="button"
                                className="btn bg-transparent px-0"
                                onClick={() => {
                                  setSelectedAssessmentResultData(
                                    assessmentItems
                                  );
                                  setIsPtsdResultModalModalOpen(true);
                                }}
                              >
                                Progress graph
                              </button>
                            </div>
                          </div>
                          <div className="col-md-auto">
                            <div className="btn-group dropMenu">
                              <button
                                type="button"
                                className="btn bg-transparent border-0 dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-h"></i>
                              </button>
                              <ul className="dropdown-menu dropdown-menu-end border-0">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() => {
                                      assignAssessmentsToPatient(
                                        assessmentItems.id
                                      );
                                    }}
                                  >
                                    Assign to patient
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    onClick={() => {
                                      editAccessment(assessmentItems);
                                      assignedAssessments();
                                    }}
                                  >
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    onClick={() =>
                                      deleteRecord(assessmentItems.id)
                                    }
                                    className="dropdown-item"
                                    to="#"
                                  >
                                    Delete
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isAssessmentResultModalOpen}
        onRequestClose={() => setIsAssessmentResultModalOpen(false)}
      >
        <AssessmentResultModal
          assessmentInfo={{
            selectedAssessmentResultData: selectedAssessmentResultData,
            setIsAssessmentResultModalOpen: setIsAssessmentResultModalOpen,
          }}
        />
      </Modal>

      <Modal
        isOpen={isPtsdResultModalModalOpen}
        onRequestClose={() => setIsPtsdResultModalModalOpen(false)}
      >
        <PtsdResultModal
          ptsdResultInfo={{
            selectedAssessmentResultData: selectedAssessmentResultData,
            setIsPtsdResultModalModalOpen: setIsPtsdResultModalModalOpen,
          }}
        />
      </Modal>

      <Modal
        isOpen={isAssignedToPatientModalOpen}
        onRequestClose={() => setIsAssignedToPatientModalOpen(false)}
      >
        <AssignedAssessmentPatientModal
          assessments={
            filterAssessments.length > 0 ? filterAssessments : assessments
          }
          formData={formData}
          filterData={filterData}
          setIsAssignedToPatientModalOpen={setIsAssignedToPatientModalOpen}
          handleChange={handleAssignedAssessmentsChange}
          currentAssessment={currentAssessment}
          assignedAssessments={assignedFormSubmit}
          closeModal={setAddNewTaskCloseModal}
          patientDetail={patientDetail}
        />
      </Modal>

      <Modal
        isOpen={IsAddEditAssessmentModalOpen}
        onRequestClose={() => setIsAddEditAssessmentModalOpen(false)}
      >
        <AccessmentAddEditModal
          currentAssessment={currentAssessment}
          setIsAddEditAssessmentModalOpen={setIsAddEditAssessmentModalOpen}
          handleChange={handleAddEditAssessmentsChange}
          updateAssessments={updateAssessments}
          dr_id={profile?.user_id}
        />
      </Modal>
    </div>
  );
};

export default Assessment;
