import { createContext, useEffect, useState } from "react";
import { fetchDrDocuments, uploadDrDocuments, deleteDrDocuments, assignedDocuments } from "./Api";
import storage from "pages/lib/storage";

export const DocumentUploadContext = createContext();


export const DocumentUploadProvider = ({ children }) => {
  const [documents, setDocuments] = useState({});
  const [error, setError] = useState(null);

  const { userId } = storage.getItem("sbCalls")


  function getDocuments(filter = '', docs_type = '') {
    fetchDrDocuments(filter, docs_type)
      .then((profileData) => {
        setDocuments(profileData.data);
        setError(null);
      })
      .catch((error) => {
        setDocuments({});
        setError(error.message || "Something went wrong..");
      });
  }



  function uploadDocuments(uploadDocuments) {
    uploadDrDocuments(uploadDocuments)
      .then((profileData) => {
        setError(null);
        getDocuments();
        return profileData;
      })
      .catch((error) => {
        setError(error.message || "Something went wrong..");
        return null;
      });
  }

  function deleteDocuments(data) {
    deleteDrDocuments(data)
      .then((profileData) => {
        setError(null);
        getDocuments();
        return profileData;
      })
      .catch((error) => {
        setError(error.message || "Something went wrong..");
        return null;
      });
  }

  function assignedDocumentsForm(data) {
    assignedDocuments(data)
      .then((profileData) => {
        getDocuments();
        setError(null);
        return true;
      })
      .catch((error) => {
        setDocuments({});
        setError(error.message || "Something went wrong..");
        return false;
      });
  }

  useEffect(() => {
    if (userId) {
      getDocuments()
    }
  }, [userId]);

  return (
    <DocumentUploadContext.Provider
      value={{ getDocuments, documents, uploadDocuments, error, deleteDocuments, assignedDocumentsForm }}
    >
      {children}
    </DocumentUploadContext.Provider>
  );
};
