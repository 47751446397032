import { CheckboxInput, TextInput, TextAreaInput, Dropdown, DateInput, RadioButtons } from '../../../components/FormComponents/FormComponents';
import { dropdownNumOptions } from '../../../const/PatientIntakeConst';

export const PatientIntakeCustomTable = ({ headers, rowData, formData, handleChange, secondColumn, thirdColumn, tableName }) => {
    
    return (
        <table className='custom-table'>
            <thead>
                <tr>
                    {headers && (
                        headers.map((header, index) => (
                        <th key={index}>
                                {index !== 0 ? (
                                    <label>{header.label}</label>
                                ) : (
                                    <span>{header.label}</span>
                                )
                                }
                        </th>
                    )))}
                </tr>
            </thead>
            <tbody>
                {rowData.map((row, index) => (
                    <tr key={index}>
                        <td className='w-75' >
                            <label>{index+1}. {row.label}</label>
                            <div><span>{row.text}</span></div>
                            {typeof row.boldText === 'string' ? (
                                <div><span><strong>{row.boldText}</strong></span></div>
                            ) : typeof row.boldText === 'object' ? (
                                row.boldText.map((text) => {
                                    return (
                                        <div><span><strong>{text}</strong></span></div>
                                    )
                                    
                                })
                            ) : (
                                <></>
                            )
                            }
                            
                            {(tableName === 'suicideIdeationTable' || tableName === 'suicidalBehaviorTable') && (
                                <div>
                                    <div><p>If yes, describe:</p></div>
                                    <div>
                                        <TextInput
                                            id={row.label}
                                            name={row.name}
                                            value={formData[row.name]}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                
                            )}
                            
                        </td>
                        <td className='intake-dropdown' >
                            {secondColumn === 'radio' ? (
                                <RadioButtons name={`${row.name}_lifetime`} formData={formData} handleChange={handleChange} />
                            ) : secondColumn === 'dropdown' ? (
                                <Dropdown 
                                    name={`${row.name}_lifetime`}
                                    value={formData[`${row.name}_lifetime`]}
                                    options={dropdownNumOptions[0]}
                                    onChange={handleChange}
                                />
                            ) : <></>
                            }
                            
                        </td>
                        <td className='intake-dropdown'>
                            {thirdColumn === 'radio' ? (
                                <RadioButtons name={`${row.name}_past_month`} formData={formData} handleChange={handleChange} />
                            ) : thirdColumn === 'dropdown' ? (
                                <Dropdown 
                                    name={`${row.name}_past_month`}
                                    value={formData[`${row.name}_past_month`]}
                                    options={dropdownNumOptions[0]}
                                    onChange={handleChange}
                                />
                            ) : <></>
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};