import React, { useState, useContext, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import Header from "../../../components/Header/Header";
import NewPatientPage1 from "./NewPatientPages/NewPatientPage1";
import NewPatientPage2 from "./NewPatientPages/NewPatientPage2";
import NewPatientPage3 from "./NewPatientPages/NewPatientPage3";
import NewPatientPage4 from "./NewPatientPages/NewPatientPage4";
import NewPatientPage5 from "./NewPatientPages/NewPatientPage5";
import NewPatientPage6 from "./NewPatientPages/NewPatientPage6";
import NewPatientPage7 from "./NewPatientPages/NewPatientPage7";
import NewPatientPage8 from "./NewPatientPages/NewPatientPage8";
import NewPatientPage9 from "./NewPatientPages/NewPatientPage9";
import NewPatientPage10 from "./NewPatientPages/NewPatientPage10";
import NewPatientPage11 from "./NewPatientPages/NewPatientPage11";
import "./NewPatient.css";
import { PatientIntakContext } from "../../../context/PatientIntakeProvider";

import { NewPatientSidebar } from "./NewPatientSlider";
import { blockTexts } from "../../../Data";
import { ProfileContext } from "../../../context/ProfileProvider";
import toast from "react-hot-toast";

import PatientProfileSidebar from "../../../components/Patient/PatientProfileSidebar";
import Layout from "components/layout/layout";
import Loader from "components/loader/Loader";

const NewPatient = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [submitMessage, setSubmitMessage] = useState(null);
  const {
    getPatientIntake,
    setPatientIntake,
    PatientIntakeApi,
    deletePatientIntake,
    singlePatientProfileData,
  } = useContext(PatientIntakContext);
  const { profile } = useContext(ProfileContext);

  const pathname = location.pathname;
  const parts = pathname.split("/");
  const pageId = parts[parts.length - 1];

  useEffect(() => {
    // if (patientId) {
    //   setFormData({});
    //   singlePatientProfileData(patientId).then((res) => {
    //     console.log(res,'res123');
    //     if (res.success) {
    //       let json_data = res?.data?.patients_info[0];
    //       setFormData(JSON.parse(json_data[pageId]));
    //     }
    //   });
    // } else {
      setFormData({});
      const current_page = async () => {
        const data = await getPatientIntake(patientId); 
        if (data.hasOwnProperty(pageId)) {
          setFormData(data[pageId]);
        }
      };
      current_page();
    // }
  }, [pageId, patientId]);

  // retrieves form data from local storage
  const getFormDataFromLocalStorage = () => {
    const storedFormData = localStorage.getItem("formData");
    return storedFormData ? JSON.parse(storedFormData) : {};
  };

  // retrieves form data when the component mounts
  useEffect(() => {
    const storedFormData = getFormDataFromLocalStorage();
    setFormData(storedFormData);
  }, []);

  // renders on top right corner of page
  const blockTexts = [
    { page: 1, text: "General Information" },
    { page: 2, text: "General Screen & Presenting Concerns" },
    { page: 3, text: "Suicide Risk Assessment" },
    { page: 4, text: "Mental Status Exam" },
    { page: 5, text: "Treatment History" },
    { page: 6, text: "Mental Health History & Trauma History" },
    { page: 7, text: "Physical Health" },
    { page: 8, text: "Family & Social Support, Religion and Culture" },
    { page: 9, text: "Legal History & Financial Status" },
    { page: 10, text: "Educational/Vocational Background & Housing" },
    {
      page: 11,
      text: "Hobbies/Interests & Additional Treatment Considerations",
    },
  ];
  const currentPageIndex = parseInt(location.pathname.match(/\d+$/)[0], 10) - 1;
  const currentBlockText = blockTexts[currentPageIndex];

  // updating formData with form inputs
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked === true ? "on" : "off") : value,
    }));
  };

  // previous button
  const handleGoBack = () => {
    setSubmitMessage(null);
    window.history.back();
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));
    await setPatientIntake(data, pageId, patientId);
    navigate(getNextPage());
  };

  const getNextPage = () => {
    const currentPageIndexs = parseInt(pageId.match(/\d+$/)[0], 10);
    const nextPageIndex = (currentPageIndexs % blockTexts.length) + 1;
    if (patientId) {
      return `/profile/newPatient/${patientId}/page${nextPageIndex}`;
    }
    return `/newPatient/page${nextPageIndex}`;
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = {};
      formData.forEach((value, key) => (data[key] = value));
      await setPatientIntake(data, pageId, patientId);
      const final_data = await getPatientIntake(patientId);
      final_data["task"] = "addPatientsDetails";
      final_data["dr_id"] = profile?.user_id;

      const response = await PatientIntakeApi(final_data, patientId);
      if (response.message) {
        setIsLoading(false);
        toast.success(response.message);
        await deletePatientIntake(patientId);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {isLoading && <Loader />}
      <div className="vh-100 mainBglight">
        <Header pageTitle="New Patient" />
        <div className="main-content px-3">
          <div className="bg-white rounded-top-3 p-4 cardPaitent">
            <div className="d-flex justify-content-between">
              <h2>Intake patient</h2>
              <span>
                Block {currentPageIndex + 1}: {currentBlockText?.text}
              </span>
            </div>

            <div className="row w-100">
              <div className="col-4">
                {patientId ? (
                  <PatientProfileSidebar
                    location={location}
                    blockTexts={blockTexts}
                    patientId={patientId}
                    formName="newPatient"
                  />
                ) : (
                  <NewPatientSidebar
                    location={location}
                    blockTexts={blockTexts}
                  />
                )}
              </div>
              <div className="col-8">
                <h3>{currentBlockText?.text}</h3>
                <Routes>
                  <Route
                    path="page1"
                    element={
                      <NewPatientPage1
                        patientId={patientId}
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        setFormData={setFormData}
                      />
                    }
                  />
                  <Route
                    path="page2"
                    element={
                      <NewPatientPage2
                        patientId={patientId}
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                  <Route
                    path="page3"
                    element={
                      <NewPatientPage3
                        patientId={patientId}
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                  <Route
                    path="page4"
                    element={
                      <NewPatientPage4
                        patientId={patientId}
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                  <Route
                    path="page5"
                    element={
                      <NewPatientPage5
                        patientId={patientId}
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        setFormData={setFormData}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                  <Route
                    path="page6"
                    element={
                      <NewPatientPage6
                        patientId={patientId}
                        formData={formData}
                        setFormData={setFormData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                  <Route
                    path="page7"
                    element={
                      <NewPatientPage7
                        patientId={patientId}
                        formData={formData}
                        setFormData={setFormData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                  <Route
                    path="page8"
                    element={
                      <NewPatientPage8
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                  <Route
                    path="page9"
                    element={
                      <NewPatientPage9
                        patientId={patientId}
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                  <Route
                    path="page10"
                    element={
                      <NewPatientPage10
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleContinue}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                  <Route
                    path="page11"
                    element={
                      <NewPatientPage11
                        patientId={patientId}
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleGoBack={handleGoBack}
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewPatient;
