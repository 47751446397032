import { useNavigate, useMatch, Route } from 'react-router-dom';
import { useSbCalls } from 'pages/lib/sendbird-calls';

const PrivateRoute = ({ element: Element, ...props }) => {
  const navigate = useNavigate();
  const match = useMatch(props.path);
  const { isAuthenticated } = useSbCalls();
  const searchParams = new URLSearchParams(window.location.search);

  if (!isAuthenticated) {
    searchParams.set('referrer', match ? match.pathname : '');
    navigate(`/login?${searchParams.toString()}`);
    return null;
  }

  return <Route {...props} element={<Element />} />;
};

export default PrivateRoute;
