import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useNavigate } from "react-router-dom";

const getCurrentDateWithTime = (hours, minutes) => {
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date.toISOString();
};

const events = [
  {
    id: 1,
    title: "Isabella Hernandes 1x1",
    start: getCurrentDateWithTime(4, 0),
    end: getCurrentDateWithTime(5, 0),
    color: "#E6F8EF",
    textColor: "#18357A",
    borderLeft: "6px solid #00BB61",
  },
  {
    id: 2,
    title: "PHP process group",
    start: getCurrentDateWithTime(10, 0),
    end: getCurrentDateWithTime(11, 0),
    color: "#FFE8E7",
    textColor: "#18357A",
    borderLeft: "6px solid #FFC739",
  },
  {
    id: 3,
    title: "IOP Tuesday ACT group",
    start: getCurrentDateWithTime(8, 0),
    end: getCurrentDateWithTime(9, 0),
    color: "#E6F8EF",
    borderLeft: "6px solid #00BB61",
    textColor: "#18357A",
  },
  {
    id: 4,
    title: "Lori Briggs 1x1",
    start: getCurrentDateWithTime(7, 0),
    end: getCurrentDateWithTime(8, 0),
    color: "#FFF7E1",
    textColor: "#18357A",
    borderLeft: "6px solid #FFC739",
  },
  {
    id: 5,
    title: "Lori Briggs 1x1",
    start: getCurrentDateWithTime(5, 0),
    end: getCurrentDateWithTime(6, 0),
    color: "#FFF7E1",
    textColor: "#18357A",
    borderLeft: "6px solid #FFC739",
  },
];

function renderEventContent(eventInfo) {
  return (
    <div
      className="box_border"
      style={{ borderLeft: eventInfo.event.extendedProps.borderLeft }}
    >
      <b>{eventInfo.timeText}</b>
      <p style={{ color: "#5B6680" }}>{eventInfo.event.title}</p>
    </div>
  );
}

const DashboardList = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="calendar_div">
        <h6 className="btn_primary" onClick={()=>navigate('/schedule')}>View all</h6>
      </div>
      <div>
        <FullCalendar
          plugins={[timeGridPlugin]}
          locale="en"
          initialView="timeGridDay"
          headerToolbar={{
            left: "title",
            center: "",
            right: "",
          }}
          titleFormat={{
            month: "short",
            day: "numeric",
            year: "numeric",
            weekday: "long",
          }}
          events={events}
          eventContent={renderEventContent}
          editable={true}
          eventResizableFromStart={true}
          droppable={true}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
          }}
        />
      </div>
    </>
  );
};

export default DashboardList;
