const DBSuccessModal = ({ assessmentInfo }) => {
  const { setOpenModal } = assessmentInfo;

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpenModal(false)}
          ></button>
        </div>
        <img
          src="/images/successful.png"
          width={115}
          height={88}
          style={{ margin: "auto" }}
        />
        <div className="modal-body pt-0 pb-5 px-4">
          <div className="headingSec mb-4">
            <h1
              className="mb-0 text-center"
              style={{
                color: "#18357A",
                fontSize: "36px",
                fontWeight: 700,
              }}
            >
              DBT tracker has been submitted
            </h1>
          </div>
          <p
            className="mb-2"
            style={{
              color: "#5B6680",
              textAlign: "center",
              width: "75%",
              margin: "auto",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            vitae elit pharetra, feugiat orci at, bibendum velit. Nam venenatis
            accumsan tellus ac finibus. Nullam hendrerit nisl et purus interdum
            lacinia.
          </p>
        </div>
        <div className="col-12">
          <div className="row my-4">
            <div className="col-md-12">
              <div className="gap-4 d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary px-5 btnSize rounded-3"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DBSuccessModal;
