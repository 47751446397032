import React from "react";
import { ProgressBar } from "react-bootstrap";

const InsuranceBenefits = ({
  formData,
  handleChange,
  handleSubmit,
  setFormData,
  listingallPatients,
}) => {
  return (
    <div id="treatmentPlan">
      <div className="headingTable cardPaitent">
        <div className="row d-flex mt-4">
          <div className="col-4">
            <div className="row d-flex mb-2">
              <h1 className="mb-2">Outpatient Mental Health Services</h1>
            </div>
            <br />
            <span>Individual therapy</span>
            <br />
            <br />
            <br />
            <span>Group therapy</span>
            <br />
            <br />
            <br />
            <span>Family therapy</span>
            <br />
            <br />
            <br />
            <span>Psychiatric consultations</span>
            <br />
            <br />
            <br />
            <span>Medication management</span>
            <div className="row d-flex mt-4">
              <h1 className="mb-2 mt-4">Inpatient Mental Health Services</h1>
            </div>
            <span>Inpatient psychiatric hospitalization</span>
            <div className="row d-flex mt-5">
              <h1 className="mb-1">Network Providers</h1>
            </div>
            <span>Preferred network</span>
          </div>
          <div className="col-4">
            <div className="row d-flex mb-4">
              <h1 className="mb-2">Insurance coverage</h1>
            </div>
            <span>
              Covered at
              <input
                placeholder="%"
                type="text"
                name="coverage1"
                value={formData.coverage1}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="%"
                type="text"
                name="copayment1"
                value={formData.copayment1}
                onChange={handleChange}
                style={style}
              />
              copayment per session.
            </span>
            <br />
            <br />
            <span>
              Covered at
              <input
                placeholder="%"
                type="text"
                name="coverage2"
                value={formData.coverage2}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="%"
                type="text"
                name="copayment2"
                value={formData.copayment2}
                onChange={handleChange}
                style={style}
              />
              copayment per session.
            </span>
            <br />
            <br />
            <span>
              Covered at
              <input
                placeholder="%"
                type="text"
                name="coverage3"
                value={formData.coverage3}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="%"
                type="text"
                name="copayment3"
                value={formData.copayment3}
                onChange={handleChange}
                style={style}
              />
              copayment per session.
            </span>
            <br />
            <br />
            <span>
              Covered at{" "}
              <input
                placeholder="%"
                type="text"
                name="coverage4"
                value={formData.coverage4}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="%"
                type="text"
                name="copayment4"
                value={formData.copayment4}
                onChange={handleChange}
                style={style}
              />
              copayment per visit.
            </span>
            <br />
            <br />
            <span>
              Covered at
              <input
                placeholder="%"
                type="text"
                name="coverage5"
                value={formData.coverage5}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="%"
                type="text"
                name="copayment5"
                value={formData.copayment5}
                onChange={handleChange}
                style={style}
              />
              copayment per visit.
            </span>
            <br />
            <br />
            <br />
            <br />
            <span>
              Covered at
              <input
                placeholder="%"
                type="text"
                name="coverage6"
                value={formData.coverage6}
                onChange={handleChange}
                style={style}
              />
              after
              <input
                placeholder="%"
                type="text"
                name="deductible"
                value={formData.deductible}
                onChange={handleChange}
                style={style}
              />
              deductible per admission. Requires preauthorization.
            </span>
            <br />
            <br />
            <br />
            <span>
              Trustory Insurance has a network of mental health providers. Using
              in-network providers will result in lower out-of-pocket costs.
            </span>
          </div>
          <div className="col-4" style={{ background: "#F8F9FE" }}>
            <div className="row d-flex mt-4">
              <div className="col-2">
                <div className="flex-shrink-0">
                  <img
                    src="/images/Salary_Date.png"
                    alt=""
                    width={50}
                    height={50}
                  />
                </div>
              </div>
              <div className="col-10">
                <h2
                  className="mb-2"
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#5B6680",
                    lineHeight: "21px",
                  }}
                >
                  Benefits plan
                </h2>
                <h1 className="text-center">
                  {listingallPatients[0]?.Inr_com_name}
                </h1>
              </div>
            </div>
            <h1 className="text-left">48%</h1>
            <ProgressBar now={48} />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder="%"
                  type="text"
                  name="familyOOP1"
                  value={formData.familyOOP1}
                  onChange={handleChange}
                  style={style}
                />
                of your
                <input
                  placeholder="%"
                  type="text"
                  name="familyOOP2"
                  value={formData.familyOOP2}
                  onChange={handleChange}
                  style={style}
                />
                Family Out-of-Pocket has been met for 2023
              </span>
            </div>
            <h1 className="text-left">40%</h1>
            <ProgressBar now={40} />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder="%"
                  type="text"
                  name="individualOOP1"
                  value={formData.individualOOP1}
                  onChange={handleChange}
                  style={style}
                />
                of your
                <input
                  placeholder="%"
                  type="text"
                  name="individualOOP2"
                  value={formData.individualOOP2}
                  onChange={handleChange}
                  style={style}
                />
                Individual Out-of-Pocket Max has been met for 2023
              </span>
            </div>
            <h1 className="text-left">73%</h1>
            <ProgressBar now={73} />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder="%"
                  type="text"
                  name="familyDeductible1"
                  value={formData.familyDeductible1}
                  onChange={handleChange}
                  style={style}
                />
                of your
                <input
                  placeholder="%"
                  type="text"
                  name="familyDeductible2"
                  value={formData.familyDeductible2}
                  onChange={handleChange}
                  style={style}
                />
                Family Deductible has been met for 2023
              </span>
            </div>
            <h1 className="text-left">100%</h1>
            <ProgressBar now={100} />
            <div className="row">
              <span className="mt-4">
                <input
                  placeholder="%"
                  type="text"
                  name="individualDeductible1"
                  value={formData.individualDeductible1}
                  onChange={handleChange}
                  style={style}
                />
                of your
                <input
                  placeholder="%"
                  type="text"
                  name="individualDeductible2"
                  value={formData.individualDeductible2}
                  onChange={handleChange}
                  style={style}
                />
                Individual Deductible has been met for 2023
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const style = {
  border: "1px solid #ECECEC",
  padding: "2px",
  width: "27px",
  height: "27px",
  textAlign: "center",
  marginLeft: "5px",
  marginRight: "5px",
};

export default InsuranceBenefits;
