import React from 'react';

const UploadDocModal = (  { uploadDocInfo  }  ) => {

  // const { selectedUploadDocData, setUploadDocCloseModal } = uploadDocInfo;
  const { setUploadDocCloseModal , setInputName , handleFileChange } = uploadDocInfo;



  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setUploadDocCloseModal(false)}>
           </button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
          <div className='headingSec mb-4'>
            <h2 className='mb-0'>Upload document</h2>
          </div>
          <div className='uploadFile border rounded-3 p-5 text-center'>
            <img src='images/upload.png' width={80} alt='' />
            <p className='mb-5'>JPG, PNG, PDF or DOC, smaller than 10MB</p>
            <span>Drag and drop your file or image here or </span>
            <div className="gap-4 d-flex justify-content-center mt-4 position-relative">
              <input type='file' name={setInputName} className='position-absolute' onChange={handleFileChange} />
              <button type="button" className="btn btn-primary px-4 btnSize rounded-3">Select file</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocModal;
