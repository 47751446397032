import React, { useState } from "react";
import countryJson from "../country.json";

//for short inputs
const TextInput = ({
  id,
  name,
  value,
  onChange,
  label,
  required,
  placeholder,
  disabled,
  classFiled = "",
}) => (
  <div>
    {label && <label htmlFor={id}>{label}</label>}
    <input
      type="text"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className="form-control"
      required={required}
      placeholder={placeholder}
      disabled={disabled}
    />
  </div>
);
const PasswordInput = ({
  id,
  name,
  value,
  onChange,
  label,
  required,
  placeholder,
  disabled,
  classFiled = "",
}) => (
  <div>
    {label && <label htmlFor={id}>{label}</label>}
    <input
      type="password"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className="form-control"
      required={required}
      placeholder={placeholder}
      disabled={disabled}
    />
  </div>
);

const NumberInput = ({
  id,
  name,
  value,
  onChange,
  label,
  required,
  placeholder,
  disabled,
  classFiled = "",
}) => (
  <div>
    {label && <label htmlFor={id}>{label}</label>}
    <input
      type="number"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className="form-control"
      required={required}
      placeholder={placeholder}
      disabled={disabled}
    />
  </div>
);

//for longer inputs
const TextAreaInput = ({
  id,
  name,
  label,
  value,
  onChange,
  placeholder,
  disabled,
}) => {
  return (
    <div>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}
      <textarea
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

const DateInput = ({
  id,
  name,
  value,
  onChange,
  label,
  required,
  disabled,
}) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <input
      type="date"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className="form-control"
      required={required}
      disabled={disabled}
    />
  </div>
);

const DOBDateInput = ({
  id,
  name,
  value,
  onChange,
  label,
  required,
  disabled,
}) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <input
      type="date"
      id={id}
      name={name}
      max={new Date().toISOString().split("T")[0]}
      value={value}
      onChange={onChange}
      className="form-control"
      required={required}
      disabled={disabled}
    />
  </div>
);

const CheckboxInput = ({
  id,
  name,
  checked,
  onChange,
  label,
  disabled,
  required = false,
}) => (
  <li>
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={checked == true || checked == "on"}
      onChange={onChange}
      disabled={disabled}
      required={required}
    />
    <span className="checkbox-text">{label}</span>
  </li>
);

const Dropdown = ({ label, name, value, options, onChange }) => {
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <select
        className="dropdown"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const SelectDropdown = ({
  name,
  value,
  options,
  labelKey,
  nameKey,
  onChange,
}) => {
  return (
    <div>
      <select
        className="dropdown"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option[labelKey]} value={option[labelKey]}>
            {option[nameKey]}
          </option>
        ))}
      </select>
    </div>
  );
};

const CountryDropdown = ({ name, value, onChange }) => {
  const [isActive, setIsActive] = useState(false);

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };
  return (
    <div>
      <select
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="dropdown w-100"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
      >
        {countryJson.map((option) => (
          <option key={option.dial_code} value={option.dial_code}>
            {isActive
              ? `${option.flag} ${option.name}`
              : `${option.flag} ${option.dial_code}`}
          </option>
        ))}
      </select>
    </div>
  );
};

const RadioButtons = ({ name, formData, handleChange }) => {
  return (
    <div className="d-flex align-items-center">
      <input
        id="Yes"
        type="radio"
        name={name}
        value="Yes"
        checked={formData[name] === "Yes"}
        onChange={handleChange}
      />
      <label>
        <span className="p-1">Yes</span>
      </label>
      <input
        id="No"
        type="radio"
        name={name}
        value="No"
        checked={formData[name] === "No"}
        onChange={handleChange}
      />
      <label>
        <span className="p-1">No</span>
      </label>
    </div>
  );
};

const RadioButton = ({
  id,
  name,
  label,
  value,
  formData,
  handleChange,
  checked,
}) => {
  return (
    <div className="d-flex align-items-center">
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <label>
        <span className="p-1">{label}</span>
      </label>
    </div>
  );
};

export {
  TextInput,
  SelectDropdown,
  PasswordInput,
  NumberInput,
  TextAreaInput,
  DateInput,
  CheckboxInput,
  Dropdown,
  RadioButtons,
  RadioButton,
  DOBDateInput,
  CountryDropdown,
};
