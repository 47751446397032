import { Row, Col, Image } from "react-bootstrap";
import avatar from "../../../assets/images/avatar.png";

const DashboardDetails = ({ name }) => {
  const today = new Date().toString().split(" ").splice(1, 3).join(" ");
  return (
    <>
      <Row className="align-items-center">
        <Col xs="auto">
          <Image
            className="profile-img"
            src={avatar}
            alt="profile-icon"
            roundedCircle
          />
        </Col>
        <Col>
          <Row>
            <Col className="d-flex justify-content-between">
              <h4 className="dashboard_name">{`Hi ${name}`}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="dashboard_msg">
                {`here is your schedule for today ${today}`}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DashboardDetails;
