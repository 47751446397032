import React, { useState, useEffect, useContext } from "react";
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa";
import { passwordChange } from "../../context/Api";
import { ProfileContext } from "../../context/ProfileProvider";
import toast from "react-hot-toast";

const PasswordChecker = () => {
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isMatching, setIsMatching] = useState(true);
  const { profile } = useContext(ProfileContext);

  const validationRules = [
    { regex: /[A-Z]/, name: "Uppercase character" },
    { regex: /[a-z]/, name: "Lowercase character" },
    { regex: /[0-9]/, name: "Number" },
    { regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, name: "Special character" },
    { regex: /.{8,}/, name: "Minimum 8 characters" },
  ];

  const handleChangeCurrentPassword = (event) => {
    const newPassword = event.target.value;
    setCurrentPassword(newPassword);
  };

  const handleChangePassword = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePasswords(newPassword, confirmPassword);
  };

  const handleChangeConfirmPassword = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePasswords(password, newConfirmPassword);
  };

  const validatePasswords = (newPassword, newConfirmPassword) => {
    let isValidPassword = true;
    for (const rule of validationRules) {
      const isRuleValid = rule.regex.test(newPassword);
      isValidPassword = isValidPassword && isRuleValid;
      //TODO: will need to have isValid and isMatching to be true in order to submit!
      setIsValid(isValidPassword);
      setIsMatching(newPassword === newConfirmPassword);
    }
  };

  const handleCancel = () => {
    setCurrentPassword("");
    setPassword("");
    setConfirmPassword("");
  };

  const handleSubmit = async () => {
    if (isValid && isMatching) {
      const data = {
        task: "changePassword",
        userid: profile?.user_id,
        current_password: currentPassword,
        new_password: password,
        c_password: confirmPassword,
      };
      try {
        const changePassword = await passwordChange(data);
        if (changePassword.success) {
          toast.success(
            changePassword.message || "Password change successfully"
          );
          setCurrentPassword("");
          setPassword("");
          setConfirmPassword("");
          return;
        }
        toast.error("Something went wrong");
      } catch (error) {
        toast.error(error?.response?.data?.message || "Something went wrong");
      }
    } else {
      return <alert>Must have valid password</alert>;
    }
  };

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <form href="#" onSubmit="">
        <div className="form-group">
          <label htmlFor="password">Current Password</label>
          <div className="input-group">
            <input
              id="currentPassword"
              className="form-control"
              name="currentPasswordInput"
              type={showCurrentPassword ? "text" : "password"}
              value={currentPassword}
              onChange={handleChangeCurrentPassword}
            />
            <button
              type="button"
              className="btn btn-outline-secondary password-toggle-button"
              style={{ color: "#8F99B0" }}
              onClick={toggleShowCurrentPassword}
            >
              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <label htmlFor="confirmPassword">Password</label>
          <div className="input-group">
            <input
              id="password"
              className="form-control"
              name="passwordInput"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handleChangePassword}
            />
            <button
              type="button"
              className="btn btn-outline-secondary password-toggle-button"
              style={{ color: "#8F99B0" }}
              onClick={toggleShowPassword}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <label htmlFor="confirmPassword">Confirm Password</label>
          <div className="input-group">
            <input
              id="confirmPassword"
              className="form-control"
              name="confirmPasswordInput"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
            />
            <button
              type="button"
              className="btn btn-outline-secondary password-toggle-button"
              style={{ color: "#8F99B0" }}
              onClick={toggleShowConfirmPassword}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
      </form>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "20px" }}>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {validationRules.slice(0, 3).map((rule, index) => (
              <li key={index}>
                {rule.regex.test(password) ? (
                  <span style={{ color: "#00BB61" }}>
                    <FaCheck style={{ marginRight: "5px" }} />
                    {rule.name}
                  </span>
                ) : (
                  <span style={{ color: "#8F99B0" }}>• {rule.name}</span>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {validationRules.slice(3, 6).map((rule, index) => (
              <li key={index}>
                {rule.regex.test(password) ? (
                  <span style={{ color: "#00BB61" }}>
                    <FaCheck style={{ marginRight: "5px" }} />
                    {rule.name}
                  </span>
                ) : (
                  <span style={{ color: "#8F99B0" }}>• {rule.name}</span>
                )}
              </li>
            ))}
            <li style={{ color: isMatching ? "#00BB61" : "#8F99B0" }}>
              {password === confirmPassword ? (
                <span style={{ color: "#00BB61" }}>
                  <FaCheck style={{ marginRight: "5px" }} />
                  Passwords are the same
                </span>
              ) : (
                <span style={{ color: "#8F99B0" }}>
                  • Passwords are not the same
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div style={{ textAlign: "right" }}>
        <button className="ftrBtn cancelButton" onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="ftrBtn blueSubmitButton"
          onClick={handleSubmit}
          disabled={!isValid || !isMatching}
        >
          Change password
        </button>
      </div>
      {message && <h5 className="text text-warning">{message}</h5>}
    </div>
  );
};

export default PasswordChecker;
