import {  createContext } from "react";
import { dischargePatients } from "./Api";

export const DischargeContext = createContext();







export const DischargeProvider = (props) => {
  const setDischaregeData = async (updatedFormData , patient_id , page_id) => {
  const storedData = JSON.parse(localStorage.getItem(`discharge_${patient_id}`)) || {};
  if (storedData[page_id]) {
    storedData[page_id] = updatedFormData;
  } else {
    storedData[page_id] =  updatedFormData ;
  }
  localStorage.setItem(`discharge_${patient_id}`, JSON.stringify(storedData));
  return  JSON.parse(localStorage.getItem(`discharge_${patient_id}`)) || {};

};
   const getDischaregeData = async (patient_id) => {
       return  JSON.parse(localStorage.getItem(`discharge_${patient_id}`)) || {};
    };

    const deleteDischaregeData = async (patient_id) => {
        localStorage.removeItem(`discharge_${patient_id}`);
   };

    const dischargePatientsApi = async (updatedProfile, id) => {
      try {
          const updatedData = await dischargePatients(updatedProfile, id);
          return updatedData;
      } catch (error) {
         return null;
      }
  };

  return (
    <DischargeContext.Provider
      value={{  setDischaregeData , getDischaregeData , dischargePatientsApi , deleteDischaregeData }}
    >
      {props.children}
    </DischargeContext.Provider>
  );
};
