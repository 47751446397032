
import { useSbCalls } from '../../../pages/lib/sendbird-calls';
import { useMemo } from 'react';
import CallView from '../../views/CallView';
import DialView from '../../views/DialView';
import styled from 'styled-components';
import { media } from '../../../utils';
import Screen from '../../templates/Screen/Screen';
import * as mixins from '../../../styles/mixins';
import Overlay from '../../atoms/Overlay';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding-bottom: 55px; // TabToolbar height
  ${media.main} {
    padding-bottom: 0;
  }
`;

const Contents = styled(Screen)`
  ${mixins.flexCenter};
  flex-direction: column;
  height: calc(100% - 80px - 57px);
  ${media.main} {
    height: calc(100% - 48px - 56px);
  }
`;

const DirectCallMain = () => {
  const { calls } = useSbCalls();

  const onCall = useMemo(() => {
    return calls.find(call => call.isOngoing);
  }, [calls]);


  return (
    <Wrapper>
      {!!calls.length && <CallView call={calls[calls.length - 1]} />}
      <Contents className="cardInside">
        <DialView />
        {onCall && (
          <Overlay>
            <CallView call={onCall} />
          </Overlay>
        )}
      </Contents>
    </Wrapper>
  );
};

export default DirectCallMain;
