import React, { useState,useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import MessageTabs from "components/tabs/MessageTabs";
import TabForms from "components/forms/therapist/TabForms";

const RequestAppoinmentModal = ({ onModalhandle, title,refetch ,defaultdata, isScheduleEditMode}) => {
  const [activeTab, setActiveTab] = useState("Single");
  const isReoccurring=activeTab==="Reoccurring" || !!defaultdata?.frequency;
  useEffect(() => {
      if(defaultdata?.frequency){
        setActiveTab("Reoccurring")
      }
      else{
        setActiveTab("Single")
      }
  }, [defaultdata?.frequency])
  
  const onCancel=()=>onModalhandle(false)

  return (
    <div className="modal-dialog modal-dialog-centered Uimodal">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
          <button
            type="button"
            className="btn-close"
            onClick={onCancel}
          ></button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
          <p className="request-appoinment">{title}</p>
          <div className="my-3">
            <MessageTabs
              tabs={tabs}
              handleSelect={(tab) => {!defaultdata && setActiveTab(tab) }}
              activeTab={activeTab}
            />
          </div>
          <TabForms onCancel={onCancel} isReoccurring={isReoccurring} refetch={refetch} defaultdata={defaultdata} isScheduleEditMode ={isScheduleEditMode}/>
        </div>
      </div>
    </div>
  );
};

export default RequestAppoinmentModal;
const tabs = [
  { eventKey: "Single", label: "Single occurrence" },
  { eventKey: "Reoccurring", label: "Reoccurring appointment" },
];