import styled from 'styled-components';
import type { DirectCallOption } from 'sendbird-calls';
import storage from 'pages/lib/storage';

import '../../../styles/dialview.css'

import { useSbCalls } from 'pages/lib/sendbird-calls';
import React , {useEffect, useState} from 'react';
import { getUsersListing   } from 'utils/helpers';


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled.div`
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
`;

const CardBody = styled.div`
  padding: 1rem;
`;

const CardTitle = styled.h5`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

const CardText = styled.p`
  font-size: 1rem;
`;

const CardImage = styled.img`
  width: 200px;
  height:200px;

  margin-bottom: 1rem;
  border-radius:100px;
  object-fit:cover;
`;

const CardLink = styled.a`
  display: block;
  margin-top: 1rem;
  text-decoration: none;
  color: #007bff;
`;




interface CustomDirectCallOption extends DirectCallOption {
}

function getCallOption(callOption: CustomDirectCallOption): CustomDirectCallOption {
  return {
    localMediaView: undefined,
    remoteMediaView: undefined,
    videoEnabled: true,
    audioEnabled: true,
    ...callOption,
  };
}


interface DialViewProps {}
const DialView: React.FC<DialViewProps> = props => {

  const stored = storage.getItem('sbCalls');

  const [users, setUsers] = useState<any>([]);

  useEffect(() => {
    const fetchUsers = async () => {
 
      try {
       let users : any = await getUsersListing();
       if(users.length > 0){
        setUsers(users)

       }
      } catch (error) {
        setUsers([])

      }
    };

    fetchUsers();
  }, []);

  const sbCall = useSbCalls();
  const dial = async (isVideoCall: boolean , userId:string) => {
     sbCall.dial({ userId, isVideoCall, callOption: getCallOption({}) });
  };

  return (
      <Container className='cardInside' >
      <CardContainer >
      {users.length > 0 && 
          users.map((user:any) => (
         
            <Card key={user.userId} >
              <CardBody>
                <CardImage src={user.profileUrl || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf9wWcT-wuvBmWj1Vqb6qX-qKZGmJgvKmuMQ&usqp=CAU'} alt={user.nickname} />
                <CardTitle>{user.nickname || 'User'}</CardTitle>
                <CardLink onClick={() => dial(true , user.userId)}>Connect</CardLink>
              </CardBody>
            </Card>
          ))
        }

      </CardContainer>
    </Container>
  );
};

export default DialView;
