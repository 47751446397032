import ProfileAvatar from "components/common/DBTracker/ProfileAvatar";
import { Link } from "react-router-dom";

const FolderCard = ({ data }) => {
  if (!data) return null;
  const { patient_id, patient_name, imageSrc, totalnotes, last_update } = data;
  return (
    <div className="col-lg-3" key={patient_id}>
      <div className="bg-white rounded-3 cardPaitent bgFolder">
        <div className="text-end w-100">
          <i className="fas fa-ellipsis-h"></i>
        </div>
        <Link
          to={`/profile/indivisual/${data?.patient_id}`}
          className="text-decoration-none"
          state={data}
        >
          <div className="d-flex mt-2 align-items-center mb-2 gap-3">
            <div className="">
              <ProfileAvatar patient_name={patient_name} imageSrc={imageSrc} />
            </div>
                <h4 className="m-0">{patient_name}</h4>
          </div>
        </Link>
        <div className="row row-cols-2">
          <div className="col-12">
            <label className="fw-normal">{totalnotes} Notes</label>
          </div>

          <div className="col-12">
            <label className="fw-normal text-secondary">
              Last added: {last_update}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolderCard;
