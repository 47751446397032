import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import ClinicalTaskModal from '../../../../../modals/Document/Group/Active/All/ClinicalTaskModal';

const ClinicalTask = () => {

  const [isClinicalTaskModalOpen, setIsClinicalTaskModalOpen] = useState(false);
  const [selectedClinicalData, setSelectedClinicalData] = useState('');

  const clinicalData = [
    {
      name: "LoBri_Nov28clin",
      comments: "4",
      provider: "Dr. Jones",
      providerImage: "../images/doctor-pic.png",
      createdDate: "Due: 23 Jan, 2024",
      updatedDate: "Due: 23 Mar, 2024",
      performer: "Walter Pearlman",
      performerImage: "../images/doctor-pic.png",
      status: "Pending"
    },
    {
      name: "LoBri_Nov28clin",
      comments: "4",
      provider: "Dr. Alvares",
      providerImage: "../images/doctor-pic.png",
      createdDate: "Due: 23 Jan, 2024",
      updatedDate: "Due: 3 Feb, 2024",
      performer: "Dr. Thompson",
      performerImage: "../images/doctor-pic.png",
      status: "Completed"
    },
    {
      name: "LoBri_Nov28clin",
      comments: "4",
      provider: "Dr. Smith",
      providerImage: "../images/doctor-pic.png",
      createdDate: "Due: 23 Jan, 2024",
      updatedDate: "Due: 4 Dec, 2024",
      performer: "Walter Pearlman",
      performerImage: "../images/doctor-pic.png",
      status: "In Progress"
    },
  ];

  return (
    <>
      <div id="clinical">

        <div className="tab-content mt-4" id="pills-tabContent">

          <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className='tableList'>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Document Name</th>
                    <th scope="col">Provider</th>
                    <th scope="col">Created
                      <img src='images/short-icon.svg' alt='' />
                    </th>
                    {/* <th scope="col">Performer</th> */}
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>

                  {clinicalData.map(clinicalData => (
                    <tr key={clinicalData.description}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img src="images/doc-icon.png" alt="..." />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <span className='curser-pointer'
                              onClick={() => {
                                setSelectedClinicalData(clinicalData);
                                setIsClinicalTaskModalOpen(true);
                              }}
                            >
                              {clinicalData.name}
                            </span>
                          </div>
                        </div>
                      </td>
                     
                      <td><h6 className='mb-0'><img src={clinicalData.providerImage} alt='' /> {clinicalData.provider}</h6></td>
                      <td>
                        <h6 className='mb-0'> {clinicalData.createdDate}</h6>
                      </td>

                      <td><button type="button" className={`btn ${clinicalData.status === 'Pending' ? 'pendingBtn' : clinicalData.status === 'Completed' ? 'completeBtn' : 'inProgressBtn'}`}>{clinicalData.status}</button></td>

                      <td>
                        <span>
                          <img src='../images/edit.svg' alt='' />
                          <img src='../images/download.svg' alt='' />
                          <img src='../images/delete.svg' alt='' />
                        </span>
                      </td>

                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isClinicalTaskModalOpen} onRequestClose={() => setIsClinicalTaskModalOpen(false)} >

        <ClinicalTaskModal

          clinicalInfo={{
            selectedClinicalData: selectedClinicalData,
            setIsClinicalTaskModalOpen: setIsClinicalTaskModalOpen
          }}

        />

      </Modal>

    </>
  );

};

export default ClinicalTask;
