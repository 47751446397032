export const PATIENT_SIGNUP =
  "https://2dfk583bhh.execute-api.us-east-1.amazonaws.com/default/patientDetails";
export const CLINICAL_SIGNUP = "172.16.16.98:3000/users/signup";
export const CLINICAL_LOGIN =
  "https://8mqrim6al3.execute-api.us-east-1.amazonaws.com/dev/login";
export const PATIENT_LOGIN =
  "https://2dfk583bhh.execute-api.us-east-1.amazonaws.com/default/patientDetails";
export const PATIENT_LIST =
  "https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query";
export const PATIENT_PROFILE =
  "https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query";
export const DOCTORS_API_LIST =
  "https://o7hwl5ths3.execute-api.us-east-1.amazonaws.com/default/resourceTest";
export const DOCTORS_PROFILE_UPDATE_API =
  "https://o7hwl5ths3.execute-api.us-east-1.amazonaws.com/default/resourceTest";
export const DOCTORE_PROFILE =
  "https://o7hwl5ths3.execute-api.us-east-1.amazonaws.com/default/resourceTest?task=getProfile";

export const PASSWORD_CHANGE_API =
  "https://o7hwl5ths3.execute-api.us-east-1.amazonaws.com/default/resourceTest";
export const FILE_UPLOAD_API =
  "https://o7hwl5ths3.execute-api.us-east-1.amazonaws.com/default/resourceTest?task=uploadImage";
export const DISCHARGE_PATIENTS_API =
  "https://o7hwl5ths3.execute-api.us-east-1.amazonaws.com/default/resourceTest";
export const NEW_PATIENTS_INTAKE_API =
  "https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query?task=addPatientsDetails";
export const SINGLE_PATIENT_PROFILE_API =
  "https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query?task=patients_detail_page";
export const DOCUMENT_UPLOADS =
  "https://o0lwn2zar6.execute-api.us-east-1.amazonaws.com/default/documentModule";
export const DOCUMENT_ASSIGNED =
  "https://o0lwn2zar6.execute-api.us-east-1.amazonaws.com/default/documentModule";
export const AddMedication =
  "https://1ab7tbginl.execute-api.us-east-1.amazonaws.com/default/medications";
export const UpdateMedication =
  "https://1ab7tbginl.execute-api.us-east-1.amazonaws.com/default/medications";
export const GetMedication =
  "https://1ab7tbginl.execute-api.us-east-1.amazonaws.com/default/medications";
export const ALL_PATIENT_LIST = "http://172.16.16.98:3000/users/allpatients";
export const ASSESSMENTS =
  "https://3l8tejzp80.execute-api.us-east-1.amazonaws.com/default/assessments";
