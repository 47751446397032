import React, { useState } from "react";
import styles from "./CustomDropdown.module.css";

const CustomDropdown = ({ options, selectedValue, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <div className={styles.custom_dropdown}>
      <div className={styles.selected_option} onClick={handleToggle}>
        {selectedValue || "Select an option"}
        <span className={styles.arrow_icon}>
          {/* SVG for down arrow */}
          <svg width="25" height="25" viewBox="0 0 16 16">
            <path d="M4.5 5.5l3 3 3-3H4.5z" fill="currentColor" />
          </svg>
        </span>
      </div>
      {isOpen && (
        <div className={styles.options}>
          {options.map((option) => (
            <div
              key={option.value}
              className={styles.option}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}{" "}
              <span
                className={styles.circle}
                style={{ backgroundColor: option.color }}
              ></span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
