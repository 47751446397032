import styled from 'styled-components';

// Define styled component
const BadgeWrapper = styled.div`
  width: 40px; /* Set the width and height according to your preference */
  height: 40px;
  background-color: #CCCCCC; /* Gray color */
  color: #FFFFFF; /* Text color */
  border-radius: 50%; /* Make it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; /* Adjust the font size as needed */
  font-weight: bold;
`;

interface BadgeProps {
  userName: string;
}

const Badge: React.FC<BadgeProps> = ({ userName }) => {
  const firstLetter = userName.charAt(0).toUpperCase();
  return (
    <BadgeWrapper>
      {firstLetter}
    </BadgeWrapper>
  );
};

export default Badge;
