import React from 'react';

const GroupOtherModal = ({ groupInfo }) => {
  const { selectedGroupData, setIsGroupModalOpen } = groupInfo;

  if (!selectedGroupData) {
    return null;
  }

  return (
<div className="modal-dialog modal-dialog-centered" id="psychotherapy">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
          <button type="button" className="btn-close" onClick={() => setIsGroupModalOpen(false)}>
          </button>
        </div>
        <div className="modal-body pt-3 pb-5 px-5">
          <div className='headingSec d-flex justify-content-between mb-4'>
            <h2 className='mb-0'>
              {selectedGroupData.name}
              <img src="images/tinder.png" width={15} alt="" />
            </h2>

          </div>
          <div className='row gy-2'>

            <div className='col-md-12'>

              <div className="d-flex align-items-center">
                <label className="me-2">Session date:</label>
                <p className="mb-0">
                  {selectedGroupData.sessionDate}
                </p>
              </div>

              <div className="d-flex align-items-center mt-1">
                <label className="me-2">Date created:</label>
                <p className="mb-0">
                  {selectedGroupData.date}
                </p>
              </div>

              <div className="d-flex align-items-center mt-1">
                <label className="me-2">Provider:</label>
                <p className="mb-0">
                  {selectedGroupData.provider}
                </p>
              </div>

              <div className="d-flex align-items-center mt-1">
                <label className="me-2">Procedure code:</label>
                <p className="mb-0">
                  {selectedGroupData.procedureCode}
                </p>
              </div>

              <div className="d-flex align-items-center mt-1">
                <label className="me-2">Provider license: </label>
                <p className="mb-0">
                  {selectedGroupData.providerLicense}
                </p>
              </div>

              <div className="d-flex align-items-center mt-1">
                <label className="me-2">Type of group:</label>
                <p className="mb-0">
                  {selectedGroupData.typeGroup}
                </p>
              </div>

              <div class="mt-2">

                <div className="mb-3">
                  <label className="me-2">Topic</label>
                  <texarea className="form-control mt-2 greyColor border-2">
                    Emotion Regulation Skills for Coping with Urges
                  </texarea>
                </div>

                <div className="mb-3">
                  <label className="me-2">Skills</label>
                  <texarea className="form-control mt-2 greyColor">
                    Urge Surfing, TIPP
                  </texarea>
                </div>

                <div className="mb-3">
                  <label className="me-2">Materials</label>
                  <texarea className="form-control mt-2 greyColor">
                   Urge Action Plan
                  </texarea>
                </div>

                <div className="mb-3">
                  <label className="me-2">Session Summary</label>

                  <texarea className="form-control mt-2 greyColor">
                    DBT group session aimed at enhancing emotion regulation skills specifically tailored to coping with urges related to eating disorders. Members actively participated in discussions, skill-building exercises, and shared personal experiences in applying DBT skills to manage distress.
                  </texarea>

                </div>

                <div className="mb-3">
                  <label className="me-2">Interventions</label>
                  <texarea className="form-control mt-2 greyColor">
                    DBT focused interventions including: discussion and practice with mindfulness regarding urges, introduction to the concept of “urge surfing”, reviewing distress tolerance techniques such as TIPP and identifying scenarios where distress tolerance techniques would be beneficial, and a discussion on the benefits of building mastery and a sense of accomplishment for improved self esteem and finding alternative outlets for emotions. Throughout each discussion, patients discussed their experiences related to each topic.
                  </texarea>
                </div>

                <div className="mb-3">
                  <label className="me-2">Homework</label>
                  <texarea className="form-control mt-2 greyColor">
                    Create a personal 'urge action plan' with specific DBT skills to use when confronted with urges related to eating disorders.
                  </texarea>
                </div>

                <div className="mb-3">
                  <label className="me-2">Plan:</label>
                  <texarea className="form-control mt-2 greyColor">
                    The next session will focus on interpersonal effectiveness skills (assertiveness and setting boundaries in relationships that may impact patients' eating behaviors). The therapist will guide members in applying these skills to improve communication and navigate social situations.
                  </texarea>
                </div>

                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src='../../images/profileSide.png' alt='' style={{ width: "50px", height: "50px" }} />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <div className='d-flex justify-content-between'>
                      <p>Lori Briggs</p>
                    </div>
                  </div>
                </div>

                <div className='col-md-12 mt-2'>
                  <label className="fs-6">Patient(s) comments:</label>
                </div>

                <div className='row mb-1'>

                  <div className='col-md-12'>
                    <div className='cardPaitent mt-3'>
                      <div className='col-md-12 mb-3'>
                        <label className="fs-6">Mood:</label>
                      </div>

                      <div className='col-md-12 mb-3'>
                        <div className="">
                          <button className='btn blueLightBg' >Euthymic</button>
                          <button className='btn blueLightBg' >Anxious</button>
                          <button className='btn blueLightBg' >Angry</button>
                          <button className='btn blueLightBg' >Depressed</button>
                          <button className='btn blueLightBg' >Euphoric</button>
                          <button className='btn blueLightBg' >Irritable</button>
                          <button className='btn blueLightBg' >Other</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div className='cardPaitent mt-3'>
                      <div className='col-md-12 mb-3'>
                        <label className="fs-6">Behavior:</label>
                      </div>

                      <div className='col-md-12 mb-3'>
                        <div className="">
                          <button className='btn blueLightBg' >Cooperative</button>
                          <button className='btn blueLightBg' >Guarded</button>
                          <button className='btn blueLightBg' >Hyperactive</button>
                          <button className='btn blueLightBg' >Agitated</button>
                          <button className='btn blueLightBg' >Paranoid</button>
                          <button className='btn blueLightBg' >Aggressive</button>
                          <button className='btn blueLightBg' >Bizarre</button>
                          <button className='btn blueLightBg' >Withdrawn</button>
                          <button className='btn blueLightBg' >Other</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div className='cardPaitent mt-3'>
                      <div className='col-md-12 mb-3' >
                        <label className="fs-6">Engagement:</label>
                      </div>

                      <div className='col-md-12 mb-3'>
                        <div className="">
                          <button className='btn blueLightBg' >Good</button>
                          <button className='btn blueLightBg' >Fair</button>
                          <button className='btn blueLightBg' >Poor</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="mb-3">
                <texarea className="form-control mt-2 greyColor">
                  Patient mood was euthymic, behavior was cooperative, and patient demonstrated a good level of engagement. Pt engaged actively in the mindfulness warm-up and shared her experience of using the 'surfing' concept in real-life situations. Patient found distress tolerance exercises challenging. She resonated with the idea of creating mastery moments and shared her plan to try a new recipe this week.
                </texarea>
                </div>

                <div className='gap-4 d-flex justify-content-center'>
                  <button type="button" className="btn btn-outline-secondary px-5 btnSize rounded-3">Cancel</button>
                  <button type="submit" className="btn btn-primary px-5 btnSize rounded-3"
                  >Complete</button>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupOtherModal;
