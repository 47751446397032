import React, { useEffect, useState } from "react";
import "./Card.css";
import toast from "react-hot-toast";
import { addDbtTrack, getTherapistFields } from "lib/api/service";

const EditDairyCardModal = ({ editInfo }) => {
  const { setIsEditModalOpen, patientId } = editInfo;
  const [DBTData, setDBTData] = useState([]);
  const [selections, setSelections] = useState(() => {
    const initialSelections = {};
    Object.keys(categories).forEach((category) => {
      initialSelections[category] = {};
      categories[category].forEach((subcategory) => {
        initialSelections[category][subcategory] = false;
      });
    });
    return initialSelections;
  });

  const handleSelectionChange = (category, subcategory) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [category]: {
        ...prevSelections[category],
        [subcategory]: !prevSelections[category][subcategory],
      },
    }));
  };

  const handleSaveChanges = async () => {
    const transformedSelections = {};
    transformedSelections["patientId"] = patientId;
    transformedSelections["updates"] = {};

    Object.keys(selections).forEach((category) => {
      Object.keys(selections[category]).forEach((subcategory) => {
        let formattedSubcategory = subcategory
          .replace(/\s+/g, "_")
          .toLowerCase();

        if (subcategory === "Meditation") {
          formattedSubcategory = "meditation_minutes";
        }
        if (subcategory === "Skin Picking/Pulling Hair") {
          formattedSubcategory = "skin_picking_or_pulling_hair";
        }

        const key = `${category}_${formattedSubcategory}`;
        transformedSelections["updates"][key] = selections[category][
          subcategory
        ]
          ? 1
          : 0;
      });
    });

    const req_value3 = {
      patientId: patientId,
      updates: transformedSelections.updates,
    };

    try {
      if (req_value3) {
        const response = await addDbtTrack(req_value3);
        if(response?.status == 200){
          toast.success(response?.message);
        }
        setIsEditModalOpen(false);
      } else {
        console.error("something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(error?.message);
    }
  };

  const initializeSelections = (dbtFields) => {
    const newSelections = { ...selections };
  
    // Flatten the DBT Fields into a key-value map
    const fieldsMap = dbtFields.reduce((acc, field) => {
      Object.keys(field).forEach((key) => {
        if (key !== "dbtAssignmentId" && key !== "clinicianId" && key !== "patientId" && key !== "date_value") {
          acc[key] = field[key];
        }
      });
      return acc;
    }, {});
  
    // Update selections based on the flattened fields
    Object.keys(newSelections).forEach((category) => {
      Object.keys(newSelections[category]).forEach((subcategory) => {
        const formattedKey = `${category.toLowerCase()}_${subcategory
          .replace(/\s+/g, '_')
          .toLowerCase()}`;
          
        newSelections[category][subcategory] = fieldsMap[formattedKey] === '1'; // Set true if '1', else false
      });
    });
  
    setSelections(newSelections);
  };
  
  // Ensure that `getTherapistFieldsData` calls `initializeSelections` with the correct data
  const getTherapistFieldsData = async () => {
    try {
      const response = await getTherapistFields({ patientId });
      const dbtFields = response?.dbtFields || [];
      initializeSelections(dbtFields);
      console.log('DBT Fields:', dbtFields);
    } catch (error) {
      console.error('Error fetching all assessments:', error);
    }
  };
  

  useEffect(() => {
    getTherapistFieldsData();
  }, []);

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsEditModalOpen(false)}
          ></button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4 cardPaitent">
          <div className="headingSec mb-3">
            <h2 className="mb-0">{editInfo.title}</h2>
            <span className="darkBlue py-2 d-inline-block">
              Add or remove options from Urges and Emotions to {editInfo.title}
            </span>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered tableCustmization">
              <tbody>
                {Object.keys(categories).map((category) => (
                  <div className="box" key={category.toUpperCase()}>
                    <div className="left">
                      <h3>{category.toUpperCase()}</h3>
                    </div>

                    <div className="right">
                    {categories[category].length > 0 &&
                        categories[category].map((subcategory) => (
                          <div className="check-box" key={subcategory}>
                            <label htmlFor="">{subcategory}</label>
                            <input
                              type="checkbox"
                              name="flexRadioDefault"
                              id={subcategory}
                              checked={selections[category][subcategory] || false}
                              onChange={() =>
                                handleSelectionChange(category, subcategory)
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </tbody>
            </table>
          </div>

          <div className="row mb-3 mt-3">
            <div className="col-md-12 mt-3">
              <div className="gap-4 d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-secondary px-5 btnSize rounded-3"
                  onClick={() => setIsEditModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary px-5 btnSize rounded-3"
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDairyCardModal;

const categories = {
  behaviors: [
    "Sleep",
    "Nightmares",
    "Exercise",
    "Meditation",
    "Relaxation",
    "Social Interaction",
    "Reached out for Support",
    "Joyful Activity",
    "Medication Compliance",
    "Stress",
    "Physical Pain",
  ],
  urges: [
    "Suicidal Ideation",
    "Self Harm",
    "Skin Picking/Pulling Hair",
    "Overspending",
    "Procrastination",
    "Disordered Eating Behaviors",
    "Negative Self Talk",
    "Pushing Boundaries",
    "Substance Use",
    "Risky Behavior",
  ],
  emotions: [
    "Anxiety",
    "Fear",
    "Anger",
    "Shame",
    "Sadness",
    "Self Acceptance",
    "Resilience",
    "Compassion",
    "Joy",
  ],
};

const skillCategories = {
  mindfulness: [
    "Observe",
    "Describe",
    "Participate",
    "Wise Mind",
    "Spirituality",
    "Loving Kindness",
    "Breathing Exercises",
  ],
  interpersonalEffectiveness: [
    "DEARMAN",
    "GIVE",
    "FAST",
    "Saying No and Observing Limits",
    "Effective Listening",
    "I Feel Statements",
  ],
  emotionRegulation: [
    "ABC",
    "PLEASE",
    "Pros and Cons",
    "Check the Facts",
    "Opposite Action",
    "Build Mastery",
    "Cope Ahead",
    "Ride the Wave of Emotion",
    "Problem Solving",
  ],
  distressTolerance: [
    "Improve the Moment",
    "ACCEPTS",
    "STOP",
    "TIPP",
    "Self Soothe",
    "Alternative Rebellion",
    "Radical Acceptance",
    "Turning the Mind",
    "Willing Hands and Half Smile",
  ],
};
