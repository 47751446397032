

import React, { useEffect, useState } from 'react';
import { CheckboxInput, TextInput, RadioButtons } from '../../../../components/FormComponents/FormComponents';


export const FamilyHistoryTable = ({ formData,type, label, handleChange }) => {
    // Headers array
    const headers = [
        { name: 'family_member', label: 'Family Member' },
        { name: 'diagnosis', label: 'Diagnosis' }
    ];  
    return (
      <div>
        <label>{label}</label>
        <table className="custom-table w-100 mb-2">
          <thead>
            <tr>
              {headers.map(header => (
                <th key={header.label}><label>{header.label}</label></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, index) => (
                  <td key={index}>
                    <TextInput 
                        id={`${header.name}_${type}[${rowIndex}]`}
                        name={`${header.name}_${type}[${rowIndex}]`}
                        value={formData[`${header.name}_${type}[${rowIndex}]`]}
                        onChange={handleChange}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};

export const RelationshipTable = ({ formData, handleChange }) => {
    const headers = [
        { name: 'deceased', label: 'Deceased' },
        { name: 'regular_contact', label: 'Regular Contact' },
        { name: 'infrequent_no_contact', label: 'Infrequent/No contact' },
        { name: 'supports_recovery', label: 'Supports recovery' },
        { name: 'does_not_understand_recovery', label: 'Does not understand recovery' },
        { name: 'used_substances', label: 'Used substances' },
        { name: 'conflict_in_relationship', label: 'Conflict in relationship' }
    ];

    const rows = [
        { name: 'spouse_partner', label: 'Spouse/Partner' },
        { name: 'mother', label: 'Mother' },
        { name: 'father', label: 'Father' },
        { name: 'sibling', label: 'Sibling' },
        { name: 'sibling', label: 'Sibling' },
        { name: 'child', label: 'Child' },
        { name: 'child', label: 'Child' }
    ];

    return (
        <div style={{ overflowX: 'auto' }}>
            <table className="custom-table w-100">
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}><span>{header.label}</span></th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>
                                <span>
                                    {row.label}
                                    {rowIndex >= 3 && (
                                        <TextInput
                                            id={`hadInvolvement[${rowIndex}]`}
                                            name={`hadInvolvement[${rowIndex}]`}
                                            value={formData[`hadInvolvement[${rowIndex}]`]}
                                            onChange={handleChange}
                                            placeholder={'Name:'}
                                        />
                                    )}
                                </span>
                            </td>

                            {headers.slice(1).map((header, colIndex) => (
                                <td key={colIndex}>
                                    <CheckboxInput
                                        id={`deceased_${header.name}[${rowIndex}]`}
                                        name={`deceased_${header.name}[${rowIndex}]`}
                                        checked={formData[`deceased_${header.name}[${rowIndex}]`]}
                                        onChange={handleChange}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};




export const LegalTable = ({ formData, handleChange }) => {
    const [legalCharges, setLegalCharges] = useState([
        { charge: '', yearArrested: '', outcome: '' },
    ]);

    const handleAddLegalCharge = () => {
        setLegalCharges([...legalCharges, { charge: '', yearArrested: '', outcome: '' }]);
    };

    const handleRemoveLegalCharge = (indexToRemove) => {
        setLegalCharges(legalCharges.filter((charge, index) => index !== indexToRemove));
    };

    useEffect(() => {
        const otherTraumaHistoryIndices = Object.keys(formData)
            .filter(key => key.startsWith('legalCharge['))
            .map(key => parseInt(key.match(/\d+/)[0]));

        if (otherTraumaHistoryIndices.length > 0) {
            const updatedTraumaHistory = [...legalCharges];
            otherTraumaHistoryIndices.forEach(index => {
                updatedTraumaHistory[index] = {
                    charge: formData[`legalCharge[${index}]`] || '',
                    yearArrested: formData[`yearArrested[${index}]`] || '',
                    outcome: formData[`legalChargeOutcome[${index}]`] || '',
                };
            });
            setLegalCharges(updatedTraumaHistory);
        }
    }, [formData]);

    return (
        <div>
            <table className='custom-table w-100'>
                <thead>
                    <tr>
                        <th><label>Charge (most recent first)</label></th>
                        <th><label>Year Arrested for Charge</label></th>
                        <th><label>Outcome</label></th>
                        <th></th> {/* Empty cell for remove button */}
                    </tr>
                </thead>
                <tbody>
                    {legalCharges.map((legalCharge, index) => (
                        <tr key={index}>
                            <td>
                                <TextInput
                                    id={`legalCharge[${index}]`}
                                    name={`legalCharge[${index}]`}
                                    value={formData[`legalCharge[${index}]`]}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <TextInput
                                    id={`yearArrested[${index}]`}
                                    name={`yearArrested[${index}]`}
                                    value={formData[`yearArrested[${index}]`]}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                                <TextInput
                                    id={`legalChargeOutcome[${index}]`}
                                    name={`legalChargeOutcome[${index}]`}
                                    value={formData[`legalChargeOutcome[${index}]`]}
                                    onChange={handleChange}
                                />
                            </td>
                            <td>
                            <button type='button' onClick={() => handleRemoveLegalCharge(index)} className='text-button bg-danger ' style={{ color: '#ffff','border-radius':'100px'}}>
                                x
                            </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" onClick={handleAddLegalCharge} className='text-button'>+ Add Legal Charge</button>
        </div>
    );
};





export const MedicationTable = ({ formData, setFormData, handleChange }) => {
    const [showNewInsurance, setShowNewInsurance] = useState(false);
    const [numAdditionalInsurance, setNumAdditionalInsurance] = useState(0);
    const [addedInsuranceFormsIndices, setAddedInsuranceFormsIndices] = useState([]);

    const handleAddMedication = () => {
        const newIndex = numAdditionalInsurance + 1;
        const newFormData = {
            ...formData,
            [`medicationName[${newIndex}]`]: '',
            [`medicationFrequency[${newIndex}]`]: '',
            [`medicationPurpose[${newIndex}]`]: '',
        };
        setNumAdditionalInsurance(newIndex);
        setFormData(newFormData);
        setAddedInsuranceFormsIndices(prevIndices => [...prevIndices, newIndex]);
        setShowNewInsurance(true);

        
    };

    const handleRemoveEmergencyContact = () => {
        const lastAddedIndex = addedInsuranceFormsIndices.pop();
        const updatedFormData = { ...formData };
        delete updatedFormData[`medicationName[${lastAddedIndex}]`];
        delete updatedFormData[`medicationFrequency[${lastAddedIndex}]`];
        delete updatedFormData[`medicationPurpose[${lastAddedIndex}]`];
        setFormData(updatedFormData);
        setNumAdditionalInsurance(prevNum => Math.max(1, prevNum - 1));
        setAddedInsuranceFormsIndices(prevIndices => prevIndices.filter(index => index !== lastAddedIndex));
        if (addedInsuranceFormsIndices.length === 0) {
            setNumAdditionalInsurance(0)
            setShowNewInsurance(false);
        }
    };

    useEffect(() => {
        const medicationIndices = Object.keys(formData)
            .filter(key => key.startsWith('medicationName[') && !key.endsWith('[0]'))
            .map(key => parseInt(key.match(/\d+/)[0]));

        if (medicationIndices.length > 0) {
            setShowNewInsurance(true);
            setNumAdditionalInsurance(medicationIndices.length);
            setAddedInsuranceFormsIndices(medicationIndices);
        }
    }, [formData]);

    const renderMedicationRows = () => {
        return [...Array(numAdditionalInsurance)].map((_, index) => (
            <tr key={index + 1}>
                <td>
                    <TextInput
                        id={`medicationName[${index + 1}]`}
                        name={`medicationName[${index + 1}]`}
                        value={formData[`medicationName[${index + 1}]`]}
                        onChange={handleChange}
                    />
                </td>
                <td>
                    <TextInput
                        id={`medicationFrequency[${index + 1}]`}
                        name={`medicationFrequency[${index + 1}]`}
                        value={formData[`medicationFrequency[${index + 1}]`]}
                        onChange={handleChange}
                    />
                </td>
                <td>
                    <TextInput
                        id={`medicationPurpose[${index + 1}]`}
                        name={`medicationPurpose[${index + 1}]`}
                        value={formData[`medicationPurpose[${index + 1}]`]}
                        onChange={handleChange}
                    />
                </td>
            </tr>
        ));
    };

    return (
        <div>
            <table className='custom-table w-100'>
                <thead>
                    <tr>
                        <th><label>Name of Medication</label></th>
                        <th><label>Dose/Frequency</label></th>
                        <th><label>Taken For</label></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <TextInput
                                id={`medicationName[0]`}
                                name={`medicationName[0]`}
                                value={formData[`medicationName[0]`]}
                                onChange={handleChange}
                            />
                        </td>
                        <td>
                            <TextInput
                                id={`medicationFrequency[0]`}
                                name={`medicationFrequency[0]`}
                                value={formData[`medicationFrequency[0]`]}
                                onChange={handleChange}
                            />
                        </td>
                        <td>
                            <TextInput
                                id={`medicationPurpose[0]`}
                                name={`medicationPurpose[0]`}
                                value={formData[`medicationPurpose[0]`]}
                                onChange={handleChange}
                            />
                        </td>
                    </tr>
                    {showNewInsurance && renderMedicationRows()}
                </tbody>
            </table>
            <div className='row d-flex justify-content-between'>
                <div>
                    <button type='button' onClick={handleAddMedication} className='text-button'>
                        + Add new medication
                    </button>
                </div>
                {showNewInsurance && (
                    <div>
                        <button type='button' onClick={handleRemoveEmergencyContact} className='text-button' style={{ color: '#FF6760' }}>
                            - Remove existing medication
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};



const MSECheckbox = ({ option, isChecked, onChange }) => (

    <div className={`d-flex flex-wrap align-items-center checkbox-wrapper`}>
        <input
            type="checkbox"
            id={`${option.name}`}
            name={`${option.name}`}
            checked={isChecked}
            onChange={onChange}
        />
        <label htmlFor={`${option.name}`} className="checkbox-label">
            {isChecked && (
                <span className="checkmark pe-1" style={{color: '#00BB61'}}>&#10003;</span>
            )}
            <span>{option.label}</span>
        </label>
    </div>
);

export const MSECheckboxComponent = ({ options, selectedOptions, handleChange, label }) => {
    return (
        <div>
            <div className='pt-2 pb-2'>
                <label>{label}</label>
            </div>
            <div className='d-flex flex-wrap'>
                {options.map((option, index) => (
                    <MSECheckbox
                        key={index}
                        option={option}
                        isChecked={selectedOptions.hasOwnProperty(option.name) && (selectedOptions[option.name] === true || selectedOptions[option.name] === 'on')}
                        onChange={handleChange}
                    />
                ))}
            </div>
        </div>
    );
};