import React , {useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextInput, TextAreaInput, DateInput, CheckboxInput , DOBDateInput} from '../../../components/FormComponents/FormComponents';

const Page1 = ({ formData, handleChange, handleSubmit, showFooter = true }) => {
    const currentDate = new Date().toISOString().split('T')[0];
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="block">
                    <h3>Patient Information</h3>
                    <div className="form-group row">
                        <div className="col">
                            <TextInput
                                id="clinicianName"
                                name="clinicianName"
                                value={formData.clinicianName}
                                onChange={handleChange}
                                label="Clinician Name:"
                                required
                            />
                            <DateInput
                                id="dateOfAdmission"
                                name="dateOfAdmission"
                                value={formData.dateOfAdmission}
                                onChange={handleChange}
                                label="Date of Admission/Intake:"
                                required
                            />
                            <TextInput
                                id="guardianName"
                                name="guardianName"
                                value={formData.guardianName}
                                onChange={handleChange}
                                label="Guardian Name:"
                            />
                            <TextInput
                                id="sex"
                                name="sex"
                                value={formData.sex}
                                onChange={handleChange}
                                label="Sex:"
                            />
                        </div>
                        <div className="col">
                            <TextInput
                                id="clientName"
                                name="clientName"
                                value={formData.clientName}
                                onChange={handleChange}
                                label="Client Name:"
                                required
                            />
                            <DOBDateInput
                                id="dob"
                                name="dob"
                                value={formData.dob}
                                onChange={handleChange}
                                label="DOB:"
                                max={currentDate} // Set max attribute to current date
                                required
                            />
                            <TextInput
                                id="relationship"
                                name="relationship"
                                value={formData.relationship}
                                onChange={handleChange}
                                label="Relationship with the Client:"
                            />
                            <TextInput
                                id="genderIdentity"
                                name="genderIdentity"
                                value={formData.genderIdentity}
                                onChange={handleChange}
                                label="Gender Identity:"
                            />
                        </div>
                    </div>
                </div>
                <div className="block">
                    <h3>Program Information</h3>
                    <div className="form-group row">
                        <div className="col">
                            <TextInput
                                id="transitionFrom"
                                name="transitionFrom"
                                value={formData.transitionFrom}
                                onChange={handleChange}
                                label="Transition From (Program Name):"
                            />
                            <DateInput
                                id="dateOfDischarge"
                                name="dateOfDischarge"
                                value={formData.dateOfDischarge}
                                onChange={handleChange}
                                label="Date of Discharge:"
                            />
                        </div>
                        <div className="col">
                            <TextInput
                                id="levelOfCare"
                                name="levelOfCare"
                                value={formData.levelOfCare}
                                onChange={handleChange}
                                label="Level of Care Discharged From:"
                            />
                            <TextInput
                                id="livingSituation"
                                name="livingSituation"
                                value={formData.livingSituation}
                                onChange={handleChange}
                                label="Living Situation at the Time of Discharge:"
                            />
                        </div>
                    </div>
                </div>
                {showFooter && (<div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                    <div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end'>
                        <button 
                            className='btn btn-primary px-5 btnSize rounded-3' type="submit"
                    
                        >
                            Continue
                        </button>
                    </div>
                </div>)}
            </form>


           
        </div>
    );
};

const Page2 = ({ formData, handleChange, handleSubmit, showFooter = true  }) => {

    const handleGoBack = () => {
        window.history.back();
      };
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="block">
                    <h3>Clinical Information</h3>
                    <div className="form-group">
                        <div className="col">
                            <TextInput
                                id="intakeDiagnosis"
                                name="intakeDiagnosis"
                                label="Diagnosis at intake:"
                                value={formData.intakeDiagnosis}
                                onChange={handleChange}
                                required
                            />
                            <TextInput
                                id="dischargeDiagnosis"
                                name="dischargeDiagnosis"
                                label="Discharge Diagnosis:"
                                value={formData.dischargeDiagnosis}
                                onChange={handleChange}
                                required
                            />
                            <TextInput
                                id="pharmacy"
                                name="pharmacy"
                                label="Pharmacy:"
                                value={formData.pharmacy}
                                onChange={handleChange}
                            />
                            <TextAreaInput
                                id="dischargeReason"
                                name="dischargeReason"
                                label="Reason for discharge:"
                                value={formData.dischargeReason}
                                onChange={handleChange}
                            />
                            <div className="row">
                                <div className="col">
                                    <ul>
                                        <li>
                                            <CheckboxInput
                                                name="decreaseLevelOfCare"
                                                label="Decrease Level of Care"
                                                checked={formData.decreaseLevelOfCare}
                                                onChange={handleChange}
                                            />
                                        </li>
                                        <li>
                                            <CheckboxInput
                                                name="increaseLevelOfCare"
                                                label="Increase Level of Care"
                                                checked={formData.increaseLevelOfCare}
                                                onChange={handleChange}
                                            />
                                        </li>
                                        <li>
                                            <CheckboxInput
                                                name="goalsMetNoServicesNeeded"
                                                label="Goals Met, No Services Needed"
                                                checked={formData.goalsMetNoServicesNeeded}
                                                onChange={handleChange}
                                            />
                                        </li>
                                        <li>
                                                <CheckboxInput
                                                    name="patientTerminatedServicesAMA"
                                                    label="Patient Terminated Services AMA*"
                                                    checked={formData.patientTerminatedServicesAMA}
                                                    onChange={handleChange}
                                                />
                                            </li>
                                            {formData.patientTerminatedServicesAMA && (
                                                <div className='nomargin'>
                                                    <TextInput
                                                        id="AMADescription"
                                                        name="AMADescription"
                                                        value={formData.AMADescription}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        placeholder='Describe:'
                                                    />
                                                </div>
                                            )}
                                        
                                        <li>
                                            <CheckboxInput
                                                name="patientRefusedReferral"
                                                label="Patient Refused Referral for Other Services*"
                                                checked={formData.patientRefusedReferral}
                                                onChange={handleChange}
                                            />
                                        </li>
                                        {formData.patientRefusedReferral && (
                                            <div className='nomargin'>
                                            <TextInput
                                                    id="refusalDescription"
                                                    name="refusalDescription"
                                                    placeholder='Describe:'
                                                    value={formData.refusalDescription}
                                                    onChange={handleChange}
                                            />

                                            </div>

                                        )}
                                        <li>
                                            <CheckboxInput
                                                name="involuntaryDischarge"
                                                label="Involuntary discharge, patient informed of right to appeal*"
                                                checked={formData.involuntaryDischarge}
                                                onChange={handleChange}
                                            />
                                        </li>
                                        {formData.involuntaryDischarge && (
                                            <div className='nomargin'>
                                            <TextInput
                                                    id="involuntaryDischargeDescription"
                                                    name="involuntaryDischargeDescription"
                                                    placeholder='Describe:'
                                                    value={formData.involuntaryDischargeDescription}
                                                    onChange={handleChange}
                                            />

                                            </div>

                                        )}
                                        <li>
                                            <CheckboxInput
                                                name="nonResponsive"
                                                label="Patient did not return and was non-responsive to outreach attempts*"
                                                checked={formData.nonResponsive}
                                                onChange={handleChange}
                                            />
                                        </li>
                                            {formData.nonResponsive && (
                                                <div className='nomargin'>
                                                <TextInput
                                                    id="nonResponsiveDescription"
                                                    name="nonResponsiveDescription"
                                                    placeholder='Describe:'
                                                    value={formData.nonResponsiveDescription}
                                                    onChange={handleChange}
                                                />

                                                </div>

                                            )}
                                        <li>
                                            <CheckboxInput
                                                name="otherDischargeReason"
                                                label="Other*"
                                                checked={formData.otherDischargeReason}
                                                onChange={handleChange}
                                                
                                            />
                                        </li>
                                            {formData.otherDischargeReason && (
                                                <div className='nomargin'>
                                                <TextInput
                                                    id="otherDischargeReasonDescription"
                                                    name="otherDischargeReasonDescription"
                                                    placeholder='Describe:'
                                                    value={formData.otherDischargeReasonDescription}
                                                    onChange={handleChange}
                                                    
                                                    />
                                                </div>

                                            )}
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul>
                                        <li>
                                            <CheckboxInput
                                                name="patientDied"
                                                label="Patient Died"
                                                checked={formData.patientDied}
                                                onChange={handleChange}
                                            />
                                        </li>
                                        <li>
                                            <CheckboxInput
                                                name="patientMoved"
                                                label="Patient Moved"
                                                checked={formData.patientMoved}
                                                onChange={handleChange}
                                            />
                                        </li>
                                    </ul>
                                    

                                </div>
                                {formData.involuntaryDischarge && (
                                        <div>
                                            <TextInput
                                                id="involuntarySummary"
                                                name="involuntarySummary"
                                                label="If involuntary/administratively discharged, summary of action taken:"
                                                value={formData.involuntarySummary}
                                                onChange={handleChange}
                                                required
                                            />
                                            <TextInput
                                                id="involuntaryPreventionSummary"
                                                name="involuntaryPreventionSummary"
                                                label="If involuntary/administratively discharged, explain efforts taken to prevent this outcome:"
                                                value={formData.involuntaryPreventionSummary}
                                                onChange={handleChange}
                                                required
                                            />
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <label htmlFor="appealProcess">Patient involuntary/administratively discharged notified of appeal process (if no, explain below):</label>
                                                <div className='d-flex align-items-center'>
                                                    <input
                                                        type='radio'
                                                        id='yes'
                                                        name='appealProcess'
                                                        value='Yes'
                                                        onChange={handleChange}
                                                        checked={formData.appealProcess === 'Yes'}
                                                    />
                                                    <label htmlFor='yes' className="mx-2">Yes</label>
                                                    <input
                                                        type='radio'
                                                        id='no'
                                                        name='appealProcess'
                                                        value='No'
                                                        onChange={handleChange}
                                                        checked={formData.appealProcess === 'No'}
                                                    />
                                                    <label htmlFor='no' className="mx-2">No</label>
                                                </div>
                                            </div>
                                            {formData.appealProcess === 'No' && (
                                                <TextInput
                                                    id="appealSummary"
                                                    name="appealSummary"
                                                    label="Explain below."
                                                    value={formData.appealSummary}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            )}
                                        </div>
                                    )}
                                {formData.patientTerminatedServicesAMA && (
                                        <TextInput
                                            id="AMASummary"
                                            name="AMASummary"
                                            label="If patient terminated services AMA, explain precipitating events below:"
                                            value={formData.AMASummary}
                                            onChange={handleChange}
                                            required
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                {showFooter && (<div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                    <div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end'>
                    <button 
                            className='btn btn-outline-secondary px-5 btnSize rounded-3 mx-4'
                            type='button'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button 
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>
                </div>)}
            </form>
      
        </div>
    );
};
const Page3 = ({ formData, handleChange, handleSubmit, showFooter = true  }) => {



    const handleGoBack = () => {
        window.history.back();
      };
      
    // TODO: when backend created, utilize treatment plan goals to pull in each goal that needs to be assessed
    const TREATMENTPLANDUMMYGOALS = ['Client will identify and clarify nature of fears and symptoms associated with generalized anxiety.', 'Client will identify and clarify nature of fears and symptoms associated with depression.'];

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="block">
                    <h3>Summary of Treatment</h3>
                    <div className="form-group">
                        <div className="col">
                            <TextInput
                                id="presentingConcern"
                                name="presentingConcern"
                                label="Presenting Concern at Time of Admission:"
                                value={formData.presentingConcern}
                                onChange={handleChange}
                                required
                            />
                            <TextInput
                                id="treatmentModality"
                                name="treatmentModality"
                                label="Treatment Modality and Interventions Used:"
                                value={formData.treatmentModality}
                                onChange={handleChange}
                                required
                            />
                            <TextInput
                                id="responseToTreatment"
                                name="responseToTreatment"
                                label="Response to Treatment:"
                                value={formData.responseToTreatment}
                                onChange={handleChange}
                            />
                            <label htmlFor="treatmentGoalStatus">Status of Treatment Goals:</label>
                            <table className="border" style={{ width: '100%', padding: '10px' }}>
                                <tbody>
                                    {/* //TODO: will need to update once backend is created of fetching treatment goals */}
                                    {TREATMENTPLANDUMMYGOALS.map((goal, index) => (
                                        <>
                                            <tr>
                                                <td className="col-6">
                                                    <label htmlFor={`goal-${index}`} className="w-100">Goal:</label>
                                                    <span>{goal}</span>
                                                </td>
                                                <td className="vertical-divider"></td>
                                                <td className="col-6">
                                                    <TextInput
                                                        id={`outcome-${index}`}
                                                        name={`outcome[${index}]`}
                                                        label="Outcome:"
                                                        value={formData[`outcome[${index}]`] }
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                            <tr className="horizontal-divider"></tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                            <TextInput
                                id="clientStrengths"
                                name="clientStrengths"
                                label="Client strengths:"
                                value={formData.clientStrengths}
                                onChange={handleChange}
                                required
                            />
                            <TextInput
                                id="currentBarriers"
                                name="currentBarriers"
                                label="Current Barriers/Needs:"
                                value={formData.currentBarriers}
                                onChange={handleChange}
                                required
                            />
                            <div className="d-flex align-items-center justify-content-between">
                                <label htmlFor="healthSafetyConcerns">Are there current health/safety concerns?</label>
                                <div className="d-flex align-items-center">
                                    <input type="radio" id="yes" name="healthSafetyConcerns" value="Yes" onChange={handleChange} checked={formData.healthSafetyConcerns === 'Yes'} />
                                    <label htmlFor="yes" className="mx-2">Yes</label>
                                    <input type="radio" id="no" name="healthSafetyConcerns" value="No" onChange={handleChange} checked={formData.healthSafetyConcerns === 'No'} />
                                    <label htmlFor="no" className="mx-2">No</label>
                                </div>
                            </div>
                            {formData.healthSafetyConcerns === 'Yes' && (
                                <React.Fragment>
                                    <span className="grey-text">If yes, please elaborate.</span>
                                    <TextInput
                                        id="healthSafetyConcernsSummary"
                                        name="healthSafetyConcernsSummary"
                                        value={formData.healthSafetyConcernsSummary}
                                        onChange={handleChange}
                                        required
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
                {showFooter && (<div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                    <div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end'>
                    <button 
                            className='btn btn-outline-secondary px-5 btnSize rounded-3 mx-4'
                            type='button'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button 
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                        >
                            Continue
                        </button>
                    </div>
                </div>)}
            </form>
       
        </div>
    );
};

const Page4 = ({  formData, handleChange, handleSubmit , loader,  showFooter = true  }) => {
    const handleGoBack = () => {
        window.history.back();
      };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="block">
                    <h3>Aftercare Information</h3>
                    <div className="form-group">
                        <div className="col">
                            <div className='d-flex align-items-center justify-content-between'>
                                <label htmlFor="followUpAppointment">Does the patient have an appointment/group scheduled within 7 days of discharge?</label>
                                    <div className='d-flex align-items-center'>
                                        <input type='radio' id='yes' name='followUpAppointment' value='Yes' onChange={handleChange} defaultChecked={formData.followUpAppointment === 'Yes'} />
                                        <label htmlFor='yes' className="mx-2">Yes</label>
                                        <input type='radio' id='no' name='followUpAppointment' value='No' onChange={handleChange} defaultChecked={formData.followUpAppointment === 'No'} />
                                        <label htmlFor='no' className="mx-2">No</label>
                                    </div>    
                            </div>
                            {formData.followUpAppointment === 'No' && (
                                <div>
                                    <span className='grey-text'>If no, elaborate on rational/efforts taken to schedule within this timeframe.</span>
                                    <input
                                            type="text"
                                            id="followUpAppointmentSummary"
                                            name="followUpAppointmentSummary"
                                            value={formData.followUpAppointmentSummary}
                                            onChange={handleChange}
                                            className="form-control"
                                            required
                                        />
                                </div>
                            )}
                            <label htmlFor="transitionTo">Transition to (Program Name/Outpatient care):</label>
                            <input type="text" id="transitionTo" name="transitionTo" value={formData.transitionTo} onChange={handleChange} className="form-control" required />
                            <label htmlFor="levelOfDischargeCare">Level of Care Discharged to:</label>
                            <input type="text" id="levelOfDischargeCare" name="levelOfDischargeCare" value={formData.levelOfDischargeCare} onChange={handleChange} className="form-control" />
                        </div>
                        {/* TODO: would like to refactor using reusable input components */}
                        <div className="form-group row">
                            <div className="col">
                                <label htmlFor="firstAppointmentDate">First Appointment/Group Date:</label>
                                <input type="date" id="firstAppointmentDate" name="firstAppointmentDate" value={formData.firstAppointmentDate} onChange={handleChange} className="form-control" required />
                                <label htmlFor="providerName">Therapy Provider/Clinic Name:</label>
                                <input type="text" id="providerName" name="providerName" value={formData.providerName} onChange={handleChange} className="form-control" />
                                <label htmlFor="psychiatristName">Psychiatrist Name:</label>
                                <input type="text" id="psychiatristName" name="psychiatristName" value={formData.psychiatristName} onChange={handleChange} className="form-control" required />
                                <label htmlFor="psychiatristApptDate">Psychiatrist Appointment Date:</label>
                                <input type="date" id="psychiatristApptDate" name="psychiatristApptDate" value={formData.psychiatristApptDate} onChange={handleChange} className="form-control" required />
                                </div>
                            <div className="col">
                                <label htmlFor="firstAppointmentTime">Time:</label>
                                <input type="time" id="firstAppointmentTime" name="firstAppointmentTime" value={formData.firstAppointmentTime} onChange={handleChange} className="form-control" required />
                                <label htmlFor="providerNumber">Therapy Provider/Clinic Phone Number:</label>
                                <input type="number" id="providerNumber" name="providerNumber" value={formData.providerNumber} onChange={handleChange} className="form-control" />
                                <label htmlFor="psychiatristNumber">Psychiatrist Phone Number:</label>
                                <input type="number" id="psychiatristNumber" name="psychiatristNumber" value={formData.psychiatristNumber} onChange={handleChange} className="form-control" required />
                                <label htmlFor="psychiatristApptTime">Psychiatrist Appointment Time:</label>
                                <input type="time" id="psychiatristApptTime" name="psychiatristApptTime" value={formData.psychiatristApptTime} onChange={handleChange} className="form-control" required />
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <label htmlFor="comorbidMedicalConcerns">Are there comorbid medical concerns or substance use concerns requiring follow up?</label>
                                    <div className='d-flex align-items-center'>
                                        <input type='radio' id='yes' name='comorbidMedicalConcerns' value='Yes' onChange={handleChange} defaultChecked={formData.comorbidMedicalConcerns === 'Yes'} />
                                        <label htmlFor='yes' className="mx-2">Yes</label>
                                        <input type='radio' id='no' name='comorbidMedicalConcerns' value='No' onChange={handleChange} defaultChecked={formData.comorbidMedicalConcerns === 'No'} />
                                        <label htmlFor='no' className="mx-2">No</label>
                                    </div>    
                            </div>
                            {formData.comorbidMedicalConcerns === 'Yes' && (
                                <div>
                                    <span className='grey-text'>If yes, please elaborate.</span>
                                    <input
                                            type="text"
                                            id="comorbidMedicalConcernsSummary"
                                            name="comorbidMedicalConcernsSummary"
                                            value={formData.comorbidMedicalConcernsSummary}
                                            onChange={handleChange}
                                            className="form-control"
                                            required
                                    />
                                    <div className='row'>
                                        <div className="col">
                                            <label htmlFor="PCPAppointmentDate">PCP Follow Up Appointment Date (if yes to above):</label>
                                            <input type="date" id="PCPAppointmentDate" name="PCPAppointmentDate" value={formData.PCPAppointmentDate} onChange={handleChange} className="form-control" required />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="PCPAppointmentTime">Time:</label>
                                            <input type="time" id="PCPAppointmentTime" name="PCPAppointmentTime" value={formData.PCPAppointmentTime} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='col'>
                                <label htmlFor="aftercarePlan">Aftercare Plan (include symptoms to watch for as signs that higher level of care is needed):</label>
                                <input type="text" id="aftercarePlan" name="aftercarePlan" value={formData.aftercarePlan} onChange={handleChange} className="form-control" required />
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <label htmlFor="clinicianSignature">Clinician Signature</label>
                                    <input type="text" id="clinicianSignature" name="clinicianSignature" value={formData.clinicianSignature} onChange={handleChange} className="form-control" required />
                                    <label htmlFor="supervisorSignature">Supervisor Signature (if applicable):</label>
                                    <input type="text" id="supervisorSignature" name="supervisorSignature" value={formData.supervisorSignature} onChange={handleChange} className="form-control" required />
                                    <label htmlFor="psychiatristSignature">Psychiatrist Signature</label>
                                    <input type="text" id="psychiatristSignature" name="psychiatristSignature" value={formData.psychiatristSignature} onChange={handleChange} className="form-control" required />
                                    <label htmlFor="patientSignature">Patient Signature</label>
                                    <input type="text" id="patientSignature" name="patientSignature" value={formData.patientSignature} onChange={handleChange} className="form-control" required />
                                </div>
                                <div className='col'>
                                    <label htmlFor="clinicianSignatureDate">Date:</label>
                                    <input type="date" id="clinicianSignatureDate" name="clinicianSignatureDate" value={formData.clinicianSignatureDate} onChange={handleChange} className="form-control" required />
                                    <label htmlFor="supervisorSignatureDate">Date:</label>
                                    <input type="date" id="supervisorSignatureDate" name="supervisorSignatureDate" value={formData.supervisorSignatureDate} onChange={handleChange} className="form-control" required />
                                    <label htmlFor="psychiatristSignatureDate">Date:</label>
                                    <input type="date" id="psychiatristSignatureDate" name="psychiatristSignatureDate" value={formData.psychiatristSignatureDate} onChange={handleChange} className="form-control" required />
                                    <label htmlFor="patientSignatureDate">Date:</label>
                                    <input type="date" id="patientSignatureDate" name="patientSignatureDate" value={formData.patientSignatureDate} onChange={handleChange} className="form-control" required />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {showFooter && (<div className='white-rounded-footer'>
                    <hr className="whitebox-footer-divider"></hr>
                    {loader ? (
                        <div className="loader-overlay">
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                      ) : (
                    <div className='bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end'>
                    <button 
                            className='btn btn-outline-secondary px-5 btnSize rounded-3 mx-4'
                            type='button'
                            onClick={handleGoBack}
                        >
                            Previous
                        </button>
                        <button 
                            className='btn btn-primary px-5 btnSize rounded-3'
                            type='submit'
                      
                        >
                            Continue
                        </button>
                    </div>
                    )}
                </div>)}
            </form>
     
        </div>
    )
    
};

export { Page1, Page2, Page3, Page4 };