import React from "react";
import {
  CheckboxInput,
  TextAreaInput,
} from "../../../../components/FormComponents/FormComponents";
import {
  AVOIDANCE_QUESTIONS,
  GENERAL_SCREEN_OPTIONS,
  PRESENTING_CONCERNS,
} from "../../../../Data";

const NewPatientPage2 = ({
  formData,
  handleChange,
  handleSubmit,
  handleGoBack,
  showFooter = true 
}) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h4 className="my-3">General Screen</h4>
        <h4>Does the patient endorse (select all that apply): </h4>
        <div className="row form-group">
          {GENERAL_SCREEN_OPTIONS.map((option, index) => (
            <div className="col-6" key={index}>
              <CheckboxInput
                id={option.id}
                name={option.name}
                label={option.label}
                checked={formData[option.name]}
                onChange={handleChange}
              />
            </div>
          ))}
        </div>

        {/* <h4 className="mt-3">Avoidance Questions</h4> */}
        <div className="row form-group">
          {formData["avoidance"] &&
            (formData["avoidance"] === "on" ||
              formData["avoidance"] === true) &&
            AVOIDANCE_QUESTIONS.map((question, index) => (
              <div className="col-4" key={index}>
                <CheckboxInput
                  id={question.id}
                  name={question.name}
                  label={question.label}
                  checked={formData[question.name]}
                  onChange={handleChange}
                />
              </div>
            ))}
        </div>

        <h4 className="mt-3">Presenting Concerns</h4>
        {PRESENTING_CONCERNS.map((concern, index) => (
          <div key={index}>
            <TextAreaInput
              id={concern.name}
              name={concern.name}
              label={`${concern.label}:`}
              value={formData[concern.name]}
              onChange={handleChange}
            />
          </div>
        ))}

        {/* Rest of your form */}

        {showFooter && (<div className="white-rounded-footer">
          <hr className="whitebox-footer-divider" />
          <div className="bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center">
            <button className="btn btn-primary px-5 btnSize rounded-3 mx-4" onClick={handleGoBack}>
              Previous
            </button>
            <button className="btn btn-primary px-5 btnSize rounded-3" type="submit">
              Continue
            </button>
          </div>
        </div>)}
      </form>
    </div>
  );
};

export default NewPatientPage2;
