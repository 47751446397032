import React from "react";
import "../Card.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Button from "components/button/Button";

const UserModal = ({ show, onHide, title }) => {
  const navigate = useNavigate();

  const BUTTON_INFO = [
    {
      name: "Patient",
      onClick: () => {
        navigate("/patients-signup");
      },
    },
    {
      name: "Clinician",
      onClick: () => {
        navigate("/signup");
      },
    },
  ];

  const handleClose = () => {
    onHide();
  };

  return (
    <div>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        className="modal-boots"
        centered
      >
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body>
          <div className="text-center mb-4">
            <Modal.Title id="contained-modal-title-vcenter">
              {title}
            </Modal.Title>
          </div>
          <div className="d-flex justify-content-center gap-4 mb-5">
            {BUTTON_INFO.map((item, index) => (
              <Button
                key={index}
                variant="primary"
                className="btn btn-primary rounded-1"
                onClick={item.onClick}
              >
                {item.name}
              </Button>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserModal;
