import React from "react";
import { Link } from "react-router-dom";

const DashboardAppointmentDetails = () => {
  return (
    <div className="bg-light p-3 roundedCustom cardPaitent h-100 min-h-100">
      <div className="d-flex justify-content-between">
        <Link className="text-decoration-none">
          <h4 className="mb-1">Dr. Williams 1x1 </h4>
        </Link>
        <i className="fas fa-ellipsis-h"></i>
      </div>
      <p className="mb-0">Tuesday, Nov 28 2023 02:30-03:30PM</p>

      {/* First Div */}
      <div className="participants mt-4">
        <h3>Participants:</h3>
        <ul className="p-0 gap-2 d-grid">
          <li className="d-flex flex-wrap align-items-center">
            <i className="far fa-check"></i>
            <img
              src="images/ellipse-5.png"
              className="mx-2"
              width={25}
              alt=""
            />
            <span>Lori Briggs</span>
          </li>
          <li className="d-flex flex-wrap align-items-center">
            <i className="far fa-check"></i>
            <img
              src="images/ellipse-6.png"
              className="mx-2"
              width={25}
              alt=""
            />
            <span>Dr. Williams</span>
            <img src="images/send-message.png" className="mx-2" alt="" />
          </li>
        </ul>
      </div>

      {/* Secound Div */}
      <div className="participants mt-4">
        <h3>Appointment link:</h3>
        <ul className="p-0 gap-2 d-grid">
          <li className="d-flex flex-wrap align-items-center">
            <span>
              You’re meeting with Harper Adams To join the appointment, visit
              this link:
            </span>
          </li>
          <li className="d-flex flex-wrap align-items-center">
            <span className="link">
              https://usj/71977234075pwd=b2o nzjPRxsXTrjpsYWp4RjBenFL8AR.1
            </span>
          </li>
        </ul>
      </div>

      {/* Third div */}
      <div className="participants mt-4">
        <h3>Your response:</h3>
        <ul className="p-0 gap-2 d-flex justify-content-between">
          <li className="d-flex flex-wrap align-items-center">
            <i className="far fa-check"></i>
            <span className="px-2 accept_msg">Accepted</span>
          </li>

          <li className="d-flex flex-wrap align-items-center">
            <span className="px-2 edit_rsvp">Edit RSVP</span>
          </li>
        </ul>
      </div>

      {/* Last Div */}
      <div className="participants mt-4">
        <h3>Participants availability:</h3>
        <ul className="p-0 gap-2 d-grid">
          <li className="d-flex flex-wrap align-items-center">
            <i className="far fa-check"></i>
            <img
              src="images/ellipse-5.png"
              className="mx-2"
              width={25}
              alt=""
            />
            <span>Lori Briggs</span>
          </li>
          <li className="d-flex flex-wrap align-items-center">
            <i className="far fa-check"></i>
            <img
              src="images/ellipse-6.png"
              className="mx-2"
              width={25}
              alt=""
            />
            <span>Dr. Williams</span>
            <img src="images/send-message.png" className="mx-2" alt="" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardAppointmentDetails;
