// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.therapy-card {
  width: 100%;
  min-width: 410px;
  height: 431px;
  border-radius: 10px;
  border: none;
  padding: 15px;
}

.text_size {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #5b6680;
}

.label_text {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #18357a;
}

.discrption_text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #5b6680;
}

.text_name {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #5b6680;
}

.text_span {
  font-weight: 400;
}

.card_footer_text {
  cursor: pointer;
  color: #4264d0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

@media only screen and (min-width: 1200px) {
  .therapy-card {
    min-width: 372px;
    border-radius: 10px;
    padding: 9px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/cards/Cards.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;EACd;AACF","sourcesContent":[".therapy-card {\n  width: 100%;\n  min-width: 410px;\n  height: 431px;\n  border-radius: 10px;\n  border: none;\n  padding: 15px;\n}\n\n.text_size {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 16px;\n  color: #5b6680;\n}\n\n.label_text {\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 21px;\n  color: #18357a;\n}\n\n.discrption_text {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 18px;\n  color: #5b6680;\n}\n\n.text_name {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 16px;\n  color: #5b6680;\n}\n\n.text_span {\n  font-weight: 400;\n}\n\n.card_footer_text {\n  cursor: pointer;\n  color: #4264d0;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 16px;\n}\n\n@media only screen and (min-width: 1200px) {\n  .therapy-card {\n    min-width: 372px;\n    border-radius: 10px;\n    padding: 9px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
