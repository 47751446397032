import React, { useState } from "react";
import { documentationSave } from "lib/api/service";
import toast from "react-hot-toast";
const ClinicalTaskModal = ({ clinicalInfo }) => {

  const { selectedClinicalData, setIsClinicalTaskModalOpen } = clinicalInfo;
  
  const [locatedAtHome, setLocatedAtHome] = useState(true);
  const [sessionNote, setSessionNote] = useState(true);
  const [doespatientendorse, setDoespatientendorse] = useState(true);
  const [appearance, setAppearance] = useState(selectedClinicalData?.appearance?.status);
  const [appearanceSpeech, setAppearanceSpeech] = useState(selectedClinicalData?.speech?.status[0]);
  const [appearanceEyeContact, setAppearanceEyeContact] = useState(selectedClinicalData?.eye_contact?.status);
  const [motorActivity, setMotorActivity] = useState(selectedClinicalData?.motor_activity?.status);
  const [affectActivity, setAffectActivity] = useState(selectedClinicalData?.affect?.status);
  const [moodButtonActivity, setMoodButtonActivity] = useState(selectedClinicalData?.mood?.status[0]);
  const [orientationButtonActivity, setOrientationButtonActivity] =
    useState(selectedClinicalData?.orientation?.status);
  const [memoryButtonActivity, setMemoryButtonActivity] = useState(selectedClinicalData?.memory?.status);
  const [attentionButtonActivity, setAttentionButtonActivity] = useState(selectedClinicalData?.attention?.status);
  const [perceptionButtonActivity, setPerceptionButtonActivity] = useState(selectedClinicalData?.perception?.status);
  const [thoughtProcessButtonActivity, setThoughtProcessButtonActivity] =
    useState(selectedClinicalData?.thought_process?.status[0]);
  const [thoughtContentButtonActivity, setThoughtContentButtonActivity] =
    useState(selectedClinicalData?.thought_content?.status);
  const [behaviorButtonActivity, setBehaviorButtonActivity] = useState(selectedClinicalData?.behavior?.status);
  const [insightButtonActivity, setInsightButtonActivity] = useState(selectedClinicalData?.insight?.status);
  const [judgementButtonActivity, setJudgementButtonActivity] = useState(selectedClinicalData?.judgement?.status);

  const [appearanceInput, setAppearanceInput] = useState(selectedClinicalData?.appearance?.description);
  const [speechChange, setSpeechChange] = useState(selectedClinicalData?.speech?.description);
  const [speechChangeEyeContact, setSpeechChangeEyeContact] = useState(selectedClinicalData?.eye_contact?.description);
  const [motorActivityInputChange, setMotorActivityInputChange] = useState(selectedClinicalData?.motor_activity?.description);
  const [affectInputValue, setAffectInputValue] = useState(selectedClinicalData?.affect?.description);
  const [moodInputValue, setMoodInputValue] = useState(selectedClinicalData?.mood?.description);
  const [orientationInputValue, setOrientationInputValue] = useState(selectedClinicalData?.orientation?.description);
  const [memoryInputValue, setMemoryInputValue] = useState(selectedClinicalData?.memory?.description);
  const [attentionInputValue, setAttentionInputValue] = useState(selectedClinicalData?.attention?.description);
  const [perceptionInputValue, setPerceptionInputValue] = useState(selectedClinicalData?.perception?.description);
  const [thoughtProcessInputValue, setThoughtProcessInputValue] = useState(selectedClinicalData?.thought_process?.description);
  const [thoughtContentInputValue, setThoughtContentInputValue] = useState(selectedClinicalData?.thought_content?.description);
  const [behaviorInputValue, setBehaviorInputValue] = useState(selectedClinicalData?.behavior?.description);
  const [addressInputValue, setAddressInputValue] = useState(selectedClinicalData?.address);
  const [insightInputValue, setInsightInputValue] = useState(selectedClinicalData?.insight?.description);
  const [judgementInputValue, setJudgementInputValue] = useState(selectedClinicalData?.judgement?.description);

  const [responses, setResponses] = useState({
    medicationResponse: selectedClinicalData?.medication_responce,
    providerConcerns: selectedClinicalData?.medication_concern,
  });

  const [symptoms, setSymptoms] = useState({
    currentSymptoms: selectedClinicalData?.current_symptoms,
    psychosocialStressors: selectedClinicalData?.psychosocial_stressors,
  });

  const [formValues, setFormValues] = useState({
    patientReports: selectedClinicalData?.patient_reports,
    clinicalObservations: selectedClinicalData?.clinical_observations,
    progressUpdates: selectedClinicalData?.progress,
    clinicalInterventions: selectedClinicalData?.clinical_interventions,
    copingSkills: selectedClinicalData?.coping_skills,
    assessmentOfProgress: selectedClinicalData?.assessment_progress,
    plan: selectedClinicalData?.plan,
    cptCodes: selectedClinicalData?.CPT,
  });


  if (!selectedClinicalData) {
    return null;
  }

  const handleRadioChange = (event) => {
    setLocatedAtHome(event.target.value === "yes");
  };

  const handleRadioChangeSessionNote = (event) => {
    setSessionNote(event.target.value === "yes");
  };

  const handleDoesEndorsehange = (event) => {
    setDoespatientendorse(event.target.value === "yes");
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setResponses((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleInputChangeSessionNote = (event) => {
    const { id, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleInputChangeSymptoms = (event) => {
    const { id, value } = event.target;
    setSymptoms((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleButtonClick = (value) => {
    setAppearance(value);
  };

  const handleButtonClickSpeech = (value) => {
    setAppearanceSpeech(value);
  };

  const handleAppearanceInputChange = (event) => {
    setAppearanceInput(event.target.value);
  };

  const handleSpeechChange = (event) => {
    setSpeechChange(event.target.value);
  };

  const handleButtonClickEyeContact = (value) => {
    setAppearanceEyeContact(value);
  };

  const handleSpeechChangeEyeContact = (event) => {
    setSpeechChangeEyeContact(event.target.value);
  };

  const handleButtonClickMotorActivity = (value) => {
    setMotorActivity(value);
  };

  const handleMotorActivityChange = (event) => {
    setMotorActivityInputChange(event.target.value);
  };

  const handleChangeAffectInput = (event) => {
    setAffectInputValue(event.target.value);
  };

  const handleChangeMoodInput = (event) => {
    setMoodInputValue(event.target.value);
  };

  const handleButtonClickAffect = (value) => {
    setAffectActivity(value);
  };

  const handleMoodButtonClick = (value) => {
    setMoodButtonActivity(value);
  };

  const handleOrientationButtonClick = (value) => {
    setOrientationButtonActivity(value);
  };

  const handleMemoryButtonClick = (value) => {
    setMemoryButtonActivity(value);
  };

  const handleChangeOrientInput = (event) => {
    setOrientationInputValue(event.target.value);
  };

  const handleChangeMemoryInput = (event) => {
    setMemoryInputValue(event.target.value);
  };

  const handleAttentionButtonClick = (value) => {
    setAttentionButtonActivity(value);
  };

  const handleChangeAttentionInput = (event) => {
    setAttentionInputValue(event.target.value);
  };

  const handlePerceptionButtonClick = (value) => {
    setPerceptionButtonActivity(value);
  };

  const handleChangePerceptionInput = (event) => {
    setPerceptionInputValue(event.target.value);
  };

  const handleThoughtProcessButtonClick = (value) => {
    setThoughtProcessButtonActivity(value);
  };

  const handleChangeThoughtProcessInput = (event) => {
    setThoughtProcessInputValue(event.target.value);
  };

  const handleThoughtContentButtonClick = (value) => {
    setThoughtContentButtonActivity(value);
  };

  const handleChangeThoughtContentInput = (event) => {
    setThoughtContentInputValue(event.target.value);
  };

  const handleBehaviorButtonClick = (value) => {
    setBehaviorButtonActivity(value);
  };

  const handleChangeBehaviorInput = (event) => {
    setBehaviorInputValue(event.target.value);
  };

  const handleChangeInsightInput = (event) => {
    setInsightInputValue(event.target.value);
  };

  const handleChangeJudgementInput = (event) => {
    setJudgementInputValue(event.target.value);
  };

  const handleInsightButtonClick = (value) => {
    setInsightButtonActivity(value);
  };

  const handleJudgementButtonClick = (value) => {
    setJudgementButtonActivity(value);
  };

  const handleSubmitDocumentModal = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    const payload = {
      id: selectedClinicalData?.id,
      address: addressInputValue,
      medication_responce: responses.medicationResponse,
      medication_concern: responses.providerConcerns,
      current_symptoms: symptoms.currentSymptoms,
      psychosocial_stressors: symptoms.psychosocialStressors,
      appearance: {
        status: appearance,
        description: appearanceInput,
      },
      speech: {
        status: [appearanceSpeech],
        description: speechChange,
      },
      eye_contact: {
        status: appearanceEyeContact,
        description: speechChangeEyeContact,
      },
      motor_activity: {
        status: motorActivity,
        description: motorActivityInputChange,
      },
      affect: {
        status: affectActivity,
        description: affectInputValue,
      },
      mood: {
        status: [moodButtonActivity],
        description: moodInputValue,
      },
      orientation: {
        status: orientationButtonActivity,
        description: orientationInputValue,
      },
      attention: {
        status: attentionButtonActivity,
        description: attentionInputValue,
      },
      memory: {
        status: memoryButtonActivity,
        description: memoryInputValue,
      },
      perception: {
        status: perceptionButtonActivity,
        description: perceptionInputValue,
      },
      thought_process: {
        status: [thoughtProcessButtonActivity],
        description: thoughtProcessInputValue,
      },
      thought_content: {
        status: thoughtContentButtonActivity,
        description: thoughtContentInputValue,
      },
      behavior: {
        status: behaviorButtonActivity,
        description: behaviorInputValue,
      },
      insight: {
        status: insightButtonActivity,
        description: insightInputValue,
      },
      judgement: {
        status: judgementButtonActivity,
        description: judgementInputValue,
      },
      patient_reports: formValues.patientReports,
      clinical_observations: formValues.clinicalObservations,
      progress: formValues.progressUpdates,
      clinical_interventions: formValues.clinicalInterventions,
      coping_skills: formValues.copingSkills,
      assessment_progress: formValues.assessmentOfProgress,
      plan: formValues.plan,
      CPT: formValues.cptCodes,
    };
    try {
      if (selectedClinicalData) {
        const response = await documentationSave(payload);
        setIsClinicalTaskModalOpen(false);
        toast.success(response?.message);
      } else {
        console.error("Patient email is not available");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      <form>
        <div className="modal-content">
          <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
            <button
              type="button"
              className="btn-close"
              onClick={() => setIsClinicalTaskModalOpen(false)}></button>
          </div>
          <div className="modal-body pt-3 pb-5 px-5">
            <div className="headingSec d-flex justify-content-between mb-4">
              <h2 className="mb-0">
                {selectedClinicalData?.name}
                <img src="images/tinder.png" width={15} alt="" />
              </h2>
              <div className="col-md-auto">
                <button type="button" className="btn btnEdit">
                  <i className="far fa-edit"></i> Edit document
                </button>
              </div>
            </div>
            <div className="row gy-2">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="me-2">Date created:</label>
                  <p className="mb-0">{selectedClinicalData?.createdate}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="me-2">Procedure code:</label>
                  <p className="mb-0">9095</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="me-2">Session date:</label>
                  <p className="mb-0">{selectedClinicalData?.createdDate}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="me-2">Provider lisence:</label>
                  <p className="mb-0">
                    <img src={selectedClinicalData?.performerImage} alt="" />{" "}
                    {selectedClinicalData?.performer}{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="me-2">Provider:</label>
                  <p className="mb-0">
                    <img src="images/doctor-pic.png" alt="" />
                    {selectedClinicalData?.name}
                  </p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="me-2">Patient:</label>
                  <p className="mb-0">
                    <img src="images/doctor-pic.png" alt="" />
                    {selectedClinicalData?.patients[0]?.name}
                  </p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="me-2">
                    Was the patient located at home:
                  </label>
                  <input
                    type="radio"
                    value="yes"
                    checked={locatedAtHome}
                    onChange={handleRadioChange}
                  />{" "}
                  Yes
                  <input
                    type="radio"
                    className="ms-1"
                    value="no"
                    checked={!locatedAtHome}
                    onChange={handleRadioChange}
                  />{" "}
                  No
                </div>
              </div>

              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="me-2"> Patient DOB:</label>
                  <p className="mb-0">{selectedClinicalData.dob}</p>
                </div>
              </div>

              {locatedAtHome ? (
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    value={addressInputValue}
                    onChange={(e) => setAddressInputValue(e.target.value)}
                    placeholder="Where were they located?"
                  />
                </div>
              ) : null}
            </div>

            <div className="cardPaitent mt-3">
              <div className="col-md-12">
                <label className="mb-2 fs-6">Diagnosis:</label>
              </div>
              <div className="col-md-12 mb-3">
                <div className="d-flex align-items-center">
                  <h6 className="mb-0">
                    F50.89 - Other specified feeding or eating disorder{" "}
                    <span className="orageColor">
                      <i className="far fa-times"></i> Assessment
                    </span>
                  </h6>
                </div>
                <div className="d-flex align-items-center">
                  <h6 className="mb-0">
                    F33.2 - Major depressive disorder, recurrent, severe without
                    psychotic features{" "}
                    <span className="greenColor">
                      <i className="far fa-check"></i> Assessment
                    </span>
                  </h6>
                </div>
                <div className="d-flex align-items-center">
                  <h6 className="mb-0">
                    F43.12 - Post-traumatic stress disorder, chronic{" "}
                    <span className="orageColor">
                      <i className="far fa-times"></i> Assessment
                    </span>
                  </h6>
                </div>
              </div>
            </div>

            <p className="tabingUI mb-1">
              <button
                className="btn btn-primary d-flex justify-content-between w-100 align-items-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#safetyAssessment"
                aria-expanded="false"
                aria-controls="safetyAssessment">
                <span>Safety Assessment</span>
                <i className="fal fa-plus"></i>
              </button>
            </p>

            <div className="collapse" id="safetyAssessment">
              <div className="px-3">
                <div className="d-flex align-items-center">
                  <label className="me-2">
                    Does patient endorse SI/HI/AVH?
                  </label>
                  <input
                    type="radio"
                    value="yes"
                    className="ms-2"
                    checked={doespatientendorse}
                    onChange={handleDoesEndorsehange}
                  />{" "}
                  Yes
                  <input
                    type="radio"
                    value="no"
                    className="ms-2"
                    checked={!doespatientendorse}
                    onChange={handleDoesEndorsehange}
                  />{" "}
                  No
                </div>
              </div>
            </div>

            <p className="tabingUI mb-2">
              <button
                className="btn btn-primary d-flex justify-content-between w-100 align-items-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#medications"
                aria-expanded="false"
                aria-controls="collapseExample">
                <span>Medications</span>
                <i className="fal fa-plus"></i>
              </button>
            </p>

            <div className="collapse" id="medications">
              <div className="px-3">
                <p>
                  Sed fringilla facilisis eros, at posuere quam luctus vitae.
                  Mauris sollicitudin nulla sem, sit amet maximus est tristique
                  .
                </p>

                <div
                  className="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab">
                  <div className="tableList">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Dosage</th>
                          <th scope="col">Start date</th>
                          <th scope="col">End date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h6 className="mb-0">Zoloft</h6>
                          </td>
                          <td>
                            <h6 className="mb-0">100 mg AM</h6>
                          </td>
                          <td>
                            <h6 className="mb-0">Nov 10, 2023</h6>
                          </td>
                          <td>
                            <h6 className="mb-0">active</h6>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div>
                  <div className="mb-3">
                    <label className="me-2">
                      Response to medication/adherence:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter response"
                      id="medicationResponse"
                      value={responses.medicationResponse}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="me-2">
                      Medication concerns for prescribing provider:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter concerns"
                      id="providerConcerns"
                      value={responses.providerConcerns}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <p className="tabingUI  mb-2">
              <button
                className="btn btn-primary d-flex justify-content-between w-100 align-items-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#clinical-overview"
                aria-expanded="false"
                aria-controls="collapseExample">
                <span>Clinical overview</span>
                <i className="fal fa-plus"></i>
              </button>
            </p>

            <div className="collapse" id="clinical-overview">
              <div className="px-3">
                <p>
                  Sed fringilla facilisis eros, at posuere quam luctus vitae.
                  Mauris sollicitudin nulla sem, sit amet maximus est tristique
                  .
                </p>

                <div className="cardPaitent mt-3">
                  <div className="col-md-12">
                    <label className="mb-2 fs-6">Diagnosis:</label>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0">
                        F50.89 - Other specified feeding or eating disorder
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0">
                        F33.2 - Major depressive disorder, recurrent, severe
                        without psychotic features
                      </h6>
                    </div>
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0">
                        F43.12 - Post-traumatic stress disorder, chronic
                      </h6>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="mb-3">
                    <label className="me-2">Current Symptoms:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="currentSymptoms"
                      placeholder="Enter symptoms"
                      value={symptoms.currentSymptoms}
                      onChange={handleInputChangeSymptoms}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="me-2">Psychosocial stressors:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="psychosocialStressors"
                      placeholder="Enter stressors"
                      value={symptoms.psychosocialStressors}
                      onChange={handleInputChangeSymptoms}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <p className="tabingUI mb-2">
              <button
                className="btn btn-primary d-flex justify-content-between w-100 align-items-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mental"
                aria-expanded="false"
                aria-controls="collapseExample">
                <span>Mental Status Exam</span>
                <i className="fal fa-plus"></i>
              </button>
            </p>

            <div className="collapse" id="mental">
              <div className="px-3">
                <p>
                  Sed fringilla facilisis eros, at posuere quam luctus vitae.
                  Mauris sollicitudin nulla sem, sit amet maximus est tristique
                  .
                </p>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Appearance:</label>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div>
                          <button
                           type="button"
                            className={`btn blueLightBg ${
                              appearance === "Neat" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClick("Neat")}>
                            {appearance === "Neat" && (
                              <i className="far fa-check me-2"></i>
                            )}{" "}
                            Neat
                          </button>
                          <button
                           type="button"
                            className={`btn blueLightBg ${
                              appearance === "Disheveled" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClick("Disheveled")}>
                            {appearance === "Disheveled" && (
                              <i className="far fa-check me-2"></i>
                            )}{" "}
                            Disheveled
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              appearance === "Inappropriate" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClick("Inappropriate")}>
                            {appearance === "Inappropriate" && (
                              <i className="far fa-check me-2"></i>
                            )}{" "}
                            Inappropriate
                          </button>
                          <br />
                          <button type="button"
                            className={`btn blueLightBg ${
                              appearance === "Bizarre" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClick("Bizarre")}>
                            {appearance === "Bizarre" && (
                              <i className="far fa-check me-2"></i>
                            )}{" "}
                            Bizarre
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              appearance === "Other" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClick("Other")}>
                            {appearance === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}{" "}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="appearanceInput"
                          value={appearanceInput}
                          onChange={handleAppearanceInputChange}
                          placeholder="Enter other appearance details"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Speech:</label>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div>
                          <button type="button"
                            className={`btn blueLightBg ${
                              appearanceSpeech === "Clear & Coherent"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleButtonClickSpeech("Clear & Coherent")
                            }>
                            {appearanceSpeech === "Clear & Coherent" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Clear & Coherent
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              appearanceSpeech === "Tangential" ? "checked" : ""
                            }`}
                            onClick={() =>
                              handleButtonClickSpeech("Tangential")
                            }>
                            {appearanceSpeech === "Tangential" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Tangential
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              appearanceSpeech === "Impoverished"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleButtonClickSpeech("Impoverished")
                            }>
                            {appearanceSpeech === "Impoverished" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Impoverished
                          </button>
                          <br />
                          <button type="button"
                            className={`btn blueLightBg ${
                              appearanceSpeech === "Rapid" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClickSpeech("Rapid")}>
                            {appearanceSpeech === "Rapid" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Rapid
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              appearanceSpeech === "Loud" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClickSpeech("Loud")}>
                            {appearanceSpeech === "Loud" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Loud
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              appearanceSpeech === "Pressured" ? "checked" : ""
                            }`}
                            onClick={() =>
                              handleButtonClickSpeech("Pressured")
                            }>
                            {appearanceSpeech === "Pressured" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Pressured
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="speechInput"
                          value={speechChange}
                          onChange={handleSpeechChange}
                          placeholder="Enter Speech Details"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Eye contact:</label>
                      </div>

                      <div className="col-md-12 mb-3">
                        <button type="button"
                          className={`btn blueLightBg ${
                            appearanceEyeContact === "Intense" ? "checked" : ""
                          }`}
                          onClick={() =>
                            handleButtonClickEyeContact("Intense")
                          }>
                          {appearanceEyeContact === "Intense" && (
                            <i className="far fa-check me-2"></i>
                          )}
                          Intense
                        </button>
                        <button type="button"
                          className={`btn blueLightBg ${
                            appearanceEyeContact === "Within Normal Limits"
                              ? "checked"
                              : ""
                          }`}
                          onClick={() =>
                            handleButtonClickEyeContact("Within Normal Limits")
                          }>
                          {appearanceEyeContact === "Within Normal Limits" && (
                            <i className="far fa-check me-2"></i>
                          )}
                          Within Normal Limits
                        </button>
                        <button type="button"
                          className={`btn blueLightBg ${
                            appearanceEyeContact === "Avoidant" ? "checked" : ""
                          }`}
                          onClick={() =>
                            handleButtonClickEyeContact("Avoidant")
                          }>
                          {appearanceEyeContact === "Avoidant" && (
                            <i className="far fa-check me-2"></i>
                          )}
                          Avoidant
                        </button>
                        <button type="button"
                          className={`btn blueLightBg ${
                            appearanceEyeContact === "Other" ? "checked" : ""
                          }`}
                          onClick={() => handleButtonClickEyeContact("Other")}>
                          {appearanceEyeContact === "Other" && (
                            <i className="far fa-check me-2"></i>
                          )}
                          Other
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="eyeContactInput"
                          value={speechChangeEyeContact}
                          onChange={handleSpeechChangeEyeContact}
                          placeholder="Enter Eye contact Details"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Motor Activity:</label>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              motorActivity === "Within Normal Limits"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleButtonClickMotorActivity(
                                "Within Normal Limits"
                              )
                            }>
                            {motorActivity === "Within Normal Limits" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Within Normal Limits
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              motorActivity === "Restless" ? "checked" : ""
                            }`}
                            onClick={() =>
                              handleButtonClickMotorActivity("Restless")
                            }>
                            {motorActivity === "Restless" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Restless
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              motorActivity === "Tics" ? "checked" : ""
                            }`}
                            onClick={() =>
                              handleButtonClickMotorActivity("Tics")
                            }>
                            {motorActivity === "Tics" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Tics
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              motorActivity === "Other" ? "checked" : ""
                            }`}
                            onClick={() =>
                              handleButtonClickMotorActivity("Other")
                            }>
                            {motorActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={motorActivityInputChange}
                          onChange={handleMotorActivityChange}
                          placeholder="Enter Eye contact Details"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Affect:</label>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              affectActivity === "Full Range" ? "checked" : ""
                            }`}
                            onClick={() =>
                              handleButtonClickAffect("Full Range")
                            }>
                            {affectActivity === "Full Range" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Full Range
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              affectActivity === "Restricted" ? "checked" : ""
                            }`}
                            onClick={() =>
                              handleButtonClickAffect("Restricted")
                            }>
                            {affectActivity === "Restricted" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Restricted
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              affectActivity === "Congruent" ? "checked" : ""
                            }`}
                            onClick={() =>
                              handleButtonClickAffect("Congruent")
                            }>
                            {affectActivity === "Congruent" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Congruent
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              affectActivity === "Labile" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClickAffect("Labile")}>
                            {affectActivity === "Labile" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Labile
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              affectActivity === "Blunted" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClickAffect("Blunted")}>
                            {affectActivity === "Blunted" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Blunted
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              affectActivity === "Other" ? "checked" : ""
                            }`}
                            onClick={() => handleButtonClickAffect("Other")}>
                            {affectActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="affectInput"
                          value={affectInputValue}
                          onChange={handleChangeAffectInput}
                          placeholder="Enter Eye Affect Details"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Mood:</label>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              moodButtonActivity === "Full Range"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() => handleMoodButtonClick("Full Range")}>
                            {moodButtonActivity === "Full Range" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Full Range
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              moodButtonActivity === "Restricted"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() => handleMoodButtonClick("Restricted")}>
                            {moodButtonActivity === "Restricted" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Restricted
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              moodButtonActivity === "Congruent"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() => handleMoodButtonClick("Congruent")}>
                            {moodButtonActivity === "Congruent" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Congruent
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              moodButtonActivity === "Labile" ? "checked" : ""
                            }`}
                            onClick={() => handleMoodButtonClick("Labile")}>
                            {moodButtonActivity === "Labile" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Labile
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              moodButtonActivity === "Blunted" ? "checked" : ""
                            }`}
                            onClick={() => handleMoodButtonClick("Blunted")}>
                            {moodButtonActivity === "Blunted" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Blunted
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              moodButtonActivity === "Other" ? "checked" : ""
                            }`}
                            onClick={() => handleMoodButtonClick("Other")}>
                            {moodButtonActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={moodInputValue}
                          onChange={handleChangeMoodInput}
                          placeholder="Enter Eye Affect Details"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Orientation:</label>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              orientationButtonActivity === "Person"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleOrientationButtonClick("Person")
                            }>
                            {orientationButtonActivity === "Person" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Person
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              orientationButtonActivity === "Place"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleOrientationButtonClick("Place")
                            }>
                            {orientationButtonActivity === "Place" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Place
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              orientationButtonActivity === "Time"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleOrientationButtonClick("Time")
                            }>
                            {orientationButtonActivity === "Time" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Time
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              orientationButtonActivity === "Other"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleOrientationButtonClick("Other")
                            }>
                            {orientationButtonActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={orientationInputValue}
                          onChange={handleChangeOrientInput}
                          placeholder="Enter Orientation Details"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Memory:</label>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              memoryButtonActivity === "Intact" ? "checked" : ""
                            }`}
                            onClick={() => handleMemoryButtonClick("Intact")}>
                            {memoryButtonActivity === "Intact" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Intact
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              memoryButtonActivity === "Short Term Impairment"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleMemoryButtonClick("Short Term Impairment")
                            }>
                            {memoryButtonActivity ===
                              "Short Term Impairment" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Short Term Impairment
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              memoryButtonActivity === "Long Term Impairment"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleMemoryButtonClick("Long Term Impairment")
                            }>
                            {memoryButtonActivity ===
                              "Long Term Impairment" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Long Term Impairment
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              memoryButtonActivity === "Other" ? "checked" : ""
                            }`}
                            onClick={() => handleMemoryButtonClick("Other")}>
                            {memoryButtonActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={memoryInputValue}
                          onChange={handleChangeMemoryInput}
                          placeholder="Enter Memory Details"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Attention:</label>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              attentionButtonActivity === "Intact"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleAttentionButtonClick("Intact")
                            }>
                            {attentionButtonActivity === "Intact" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Intact
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              attentionButtonActivity === "Distracted"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleAttentionButtonClick("Distracted")
                            }>
                            {attentionButtonActivity === "Distracted" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Distracted
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              attentionButtonActivity === "Other"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() => handleAttentionButtonClick("Other")}>
                            {attentionButtonActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={attentionInputValue}
                          onChange={handleChangeAttentionInput}
                          placeholder="Enter Attention Details"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Perception:</label>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              perceptionButtonActivity === "Depersonalization"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handlePerceptionButtonClick("Depersonalization")
                            }>
                            {perceptionButtonActivity ===
                              "Depersonalization" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Depersonalization
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              perceptionButtonActivity === "Derealization"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handlePerceptionButtonClick("Derealization")
                            }>
                            {perceptionButtonActivity === "Derealization" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Derealization
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              perceptionButtonActivity === "Hallucinations (V)"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handlePerceptionButtonClick("Hallucinations (V)")
                            }>
                            {perceptionButtonActivity ===
                              "Hallucinations (V)" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Hallucinations (V)
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              perceptionButtonActivity ===
                              "Within Normal Limits"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handlePerceptionButtonClick(
                                "Within Normal Limits"
                              )
                            }>
                            {perceptionButtonActivity ===
                              "Within Normal Limits" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Within Normal Limits
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              perceptionButtonActivity === "Other"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handlePerceptionButtonClick("Other")
                            }>
                            {perceptionButtonActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={perceptionInputValue}
                          onChange={handleChangePerceptionInput}
                          placeholder="Enter Perception Details"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Thought Process:</label>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtProcessButtonActivity === " Goal Directed"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtProcessButtonClick(" Goal Directed")
                            }>
                            {thoughtProcessButtonActivity ===
                              " Goal Directed" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Goal Directed
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtProcessButtonActivity === "Logical"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtProcessButtonClick("Logical")
                            }>
                            {thoughtProcessButtonActivity === "Logical" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Logical
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtProcessButtonActivity === "Disorganized"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtProcessButtonClick("Disorganized")
                            }>
                            {thoughtProcessButtonActivity ===
                              "Disorganized" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Disorganized
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtProcessButtonActivity === "Perseveration"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtProcessButtonClick("Perseveration")
                            }>
                            {thoughtProcessButtonActivity ===
                              "Perseveration" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Perseveration
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtProcessButtonActivity ===
                              "Loose Association"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtProcessButtonClick(
                                "Loose Association"
                              )
                            }>
                            {thoughtProcessButtonActivity ===
                              "Loose Association" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Loose Association
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtProcessButtonActivity === "Flight of Ideas"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtProcessButtonClick("Flight of Ideas")
                            }>
                            {thoughtProcessButtonActivity ===
                              "Flight of Ideas" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Flight of Ideas
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtProcessButtonActivity ===
                              "Thought Blocking"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtProcessButtonClick(
                                "Thought Blocking"
                              )
                            }>
                            {thoughtProcessButtonActivity ===
                              "Thought Blocking" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Thought Blocking
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtProcessButtonActivity ===
                              "Magical Thinking"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtProcessButtonClick(
                                "Magical Thinking"
                              )
                            }>
                            {thoughtProcessButtonActivity ===
                              "Magical Thinking" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Magical Thinking
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtProcessButtonActivity === "Other"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtProcessButtonClick("Other")
                            }>
                            {thoughtProcessButtonActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={thoughtProcessInputValue}
                          onChange={handleChangeThoughtProcessInput}
                          placeholder="Enter Thought Process Details"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Thought Content:</label>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity ===
                              "Within Normal Limits"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick(
                                "Within Normal Limits"
                              )
                            }>
                            {thoughtContentButtonActivity ===
                              "Within Normal Limits" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Within Normal Limits
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity === "SI"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick("SI")
                            }>
                            {thoughtContentButtonActivity === "SI" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            SI
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity === "Delusions"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick("Delusions")
                            }>
                            {thoughtContentButtonActivity === "Delusions" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Delusions
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity === "Obsessions"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick("Obsessions")
                            }>
                            {thoughtContentButtonActivity === "Obsessions" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Obsessions
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity === "Phobias"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick("Phobias")
                            }>
                            {thoughtContentButtonActivity === "Phobias" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Phobias
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity === "HI"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick("HI")
                            }>
                            {thoughtContentButtonActivity === "HI" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            HI
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity === "Paranoia"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick("Paranoia")
                            }>
                            {thoughtContentButtonActivity === "Paranoia" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Paranoia
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity ===
                              "Ideas of Reference"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick(
                                "Ideas of Reference"
                              )
                            }>
                            {thoughtContentButtonActivity ===
                              "Ideas of Reference" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Ideas of Reference
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity === "Ruminations"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick("Ruminations")
                            }>
                            {thoughtContentButtonActivity === "Ruminations" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Ruminations
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              thoughtContentButtonActivity === "Other"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleThoughtContentButtonClick("Other")
                            }>
                            {thoughtContentButtonActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={thoughtContentInputValue}
                          onChange={handleChangeThoughtContentInput}
                          placeholder="Enter Thought Content Details"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Behavior:</label>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              behaviorButtonActivity === "Cooperative"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleBehaviorButtonClick("Cooperative")
                            }>
                            {behaviorButtonActivity === "Cooperative" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Cooperative
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              behaviorButtonActivity === "Guarded"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleBehaviorButtonClick("Guarded")
                            }>
                            {behaviorButtonActivity === "Guarded" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Guarded
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              behaviorButtonActivity === "Hyperactive"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleBehaviorButtonClick("Hyperactive")
                            }>
                            {behaviorButtonActivity === "Hyperactive" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Hyperactive
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              behaviorButtonActivity === "Agitated"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleBehaviorButtonClick("Agitated")
                            }>
                            {behaviorButtonActivity === "Agitated" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Agitated
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              behaviorButtonActivity === "Paranoid"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleBehaviorButtonClick("Paranoid")
                            }>
                            {behaviorButtonActivity === "Paranoid" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Paranoid
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              behaviorButtonActivity === "Aggressive"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleBehaviorButtonClick("Aggressive")
                            }>
                            {behaviorButtonActivity === "Aggressive" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Aggressive
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              behaviorButtonActivity === "Bizarre"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleBehaviorButtonClick("Bizarre")
                            }>
                            {behaviorButtonActivity === "Bizarre" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Bizarre
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              behaviorButtonActivity === "Withdrawn"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() =>
                              handleBehaviorButtonClick("Withdrawn")
                            }>
                            {behaviorButtonActivity === "Withdrawn" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Withdrawn
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              behaviorButtonActivity === "Other"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() => handleBehaviorButtonClick("Other")}>
                            {behaviorButtonActivity === "Other" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Other
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={behaviorInputValue}
                          onChange={handleChangeBehaviorInput}
                          placeholder="Enter Thought Behavior Details"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Insight:</label>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              insightButtonActivity === "Good" ? "checked" : ""
                            }`}
                            onClick={() => handleInsightButtonClick("Good")}>
                            {insightButtonActivity === "Good" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Good
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              insightButtonActivity === "Fair" ? "checked" : ""
                            }`}
                            onClick={() => handleInsightButtonClick("Fair")}>
                            {insightButtonActivity === "Fair" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Fair
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              insightButtonActivity === "Poor" ? "checked" : ""
                            }`}
                            onClick={() => handleInsightButtonClick("Poor")}>
                            {insightButtonActivity === "Poor" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Poor
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={insightInputValue}
                          onChange={handleChangeInsightInput}
                          placeholder="Enter Insight Details"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="cardPaitent mt-3">
                      <div className="col-md-12">
                        <label className="mb-2 fs-6">Judgement:</label>
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="">
                          <button type="button"
                            className={`btn blueLightBg ${
                              judgementButtonActivity === "Good"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() => handleJudgementButtonClick("Good")}>
                            {judgementButtonActivity === "Good" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Good
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              judgementButtonActivity === "Fair"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() => handleJudgementButtonClick("Fair")}>
                            {judgementButtonActivity === "Fair" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Fair
                          </button>
                          <button type="button"
                            className={`btn blueLightBg ${
                              judgementButtonActivity === "Poor"
                                ? "checked"
                                : ""
                            }`}
                            onClick={() => handleJudgementButtonClick("Poor")}>
                            {judgementButtonActivity === "Poor" && (
                              <i className="far fa-check me-2"></i>
                            )}
                            Poor
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={judgementInputValue}
                          onChange={handleChangeJudgementInput}
                          placeholder="Enter Judgement Details"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p className="tabingUI mb-2">
              <button
                className="btn btn-primary d-flex justify-content-between w-100 align-items-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#session"
                aria-expanded="false"
                aria-controls="collapseExample">
                <span>Session Note</span>
                <i className="fal fa-plus"></i>
              </button>
            </p>

            <div className="collapse" id="session">
              <div className="px-3">
                <p>
                  Sed fringilla facilisis eros, at posuere quam luctus vitae.
                  Mauris sollicitudin nulla sem, sit amet maximus est tristique
                  .
                </p>

                <div className="d-flex align-items-center">
                  <label className="me-2">
                    Would you like to use Session Note Template?
                  </label>
                  <input
                    type="radio"
                    value="yes"
                    checked={sessionNote}
                    onChange={handleRadioChangeSessionNote}
                  />{" "}
                  Yes
                  <input
                    type="radio"
                    className="ms-1"
                    value="no"
                    checked={!sessionNote}
                    onChange={handleRadioChangeSessionNote}
                  />{" "}
                  No
                </div>

                <div className="">
                  <label className="me-2">Your Psychotherapy Note:</label>
                  <p className="bgYellowscroll overflow-auto">
                    {selectedClinicalData?.psychotherapy_note}
                  </p>
                </div>

                {sessionNote ? (
                  <div>
                    <div className="mb-3">
                      <label className="me-2">
                        Patient Reports (Subjective Information):
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="patientReports"
                        value={formValues.patientReports}
                        onChange={handleInputChangeSessionNote}
                        placeholder="Enter subjective information"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="me-2">
                        Clinical Observations (Objective Information):
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="clinicalObservations"
                        value={formValues.clinicalObservations}
                        onChange={handleInputChangeSessionNote}
                        placeholder="Enter objective information"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="me-2">
                        Progress/Updates Since Last Session:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="progressUpdates"
                        value={formValues.progressUpdates}
                        onChange={handleInputChangeSessionNote}
                        placeholder="Enter progress updates"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="me-2">
                        Clinical Interventions Used:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="clinicalInterventions"
                        value={formValues.clinicalInterventions}
                        onChange={handleInputChangeSessionNote}
                        placeholder="Enter interventions used"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="me-2">
                        Coping Skills Reviewed or Introduced:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="copingSkills"
                        value={formValues.copingSkills}
                        onChange={handleInputChangeSessionNote}
                        placeholder="Enter coping skills"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="me-2">Assessment of Progress:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="assessmentOfProgress"
                        value={formValues.assessmentOfProgress}
                        onChange={handleInputChangeSessionNote}
                        placeholder="Enter progress assessment"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="me-2">Plan:</label>
                      <input
                        type="text"
                        id="plan"
                        className="form-control"
                        value={formValues.plan}
                        onChange={handleInputChangeSessionNote}
                        placeholder="Enter plan"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="me-2">CPT Codes:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cptCodes"
                        value={formValues.cptCodes}
                        onChange={handleInputChangeSessionNote}
                        placeholder="Enter CPT codes"
                        required
                      />
                    </div>
                    <button type="button" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                ) : null}
              </div>
            </div>

            {sessionNote ? (
              <div className="mb-3">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" />

                  <label className="form-check-label" htmlFor="sendCheckbox">
                    I acknowledge that the above information is correct and has
                    been reviewed prior to submitting.
                  </label>
                </div>
              </div>
            ) : null}

            <div className="gap-4 d-flex justify-content-center">
              <button
               onClick={() => setIsClinicalTaskModalOpen(false)}
                type="button"
                className="btn btn-outline-secondary px-5 btnSize rounded-3">
                Cancel
              </button>
              <button
                onClick={(e) => handleSubmitDocumentModal(e)}
                type="button"
                className="btn btn-primary px-5 btnSize rounded-3">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ClinicalTaskModal;
