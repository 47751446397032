import React, { useEffect, useState } from "react";

const EditDairySkillsCardModal = ({ editInfo }) => {
  const { setIsDBTSkillsEditModalOpen, patientId } = editInfo;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [DBTData, setDBTData] = useState([]);
  const headers = {
    "Content-Type": "application/json",
  };
  const apiEndpoint =
    "https://vaz40kx3ck.execute-api.us-east-1.amazonaws.com/v1/db_query";

  useEffect(() => {
    const get_req_value2 = { patientId: patientId, task: "getDbtTrackerData" };
    const queryParams2 = new URLSearchParams(get_req_value2).toString();
    const apiUrl2 = `${apiEndpoint}?${queryParams2}`;
    fetch(apiUrl2, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setDBTData(data.query_result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [isDialogOpen]);

  const [selections, setSelections] = useState(() => {
    const initialSelections = {};
    Object.keys(categories).forEach((category) => {
      initialSelections[category] = {};
      categories[category].forEach((subcategory) => {
        initialSelections[category][subcategory] = false; // Initialize all as unselected
      });
    });
    return initialSelections;
  });

  const handleDbtSelectionChange = (category, subcategory) => {
    setDbtSelections((prevSelections) => ({
      ...prevSelections,
      [category]: {
        ...prevSelections[category],
        [subcategory]: !prevSelections[category][subcategory],
      },
    }));
  };

  const [dbtSelections, setDbtSelections] = useState(() => {
    const initialSelections = {};
    Object.keys(skillCategories).forEach((category) => {
      initialSelections[category] = {};
      skillCategories[category].forEach((subcategory) => {
        initialSelections[category][subcategory] = false; // Initialize all as unselected
      });
    });
    return initialSelections;
  });

  const handleSkillSaveChanges = () => {
    const transformedSelections = {};
    transformedSelections["patientId"] = patientId;
    Object.keys(dbtSelections).forEach((category) => {
      const formattedCategory = category
        .replace(/([A-Z])/g, "_$1")
        .toLowerCase();

      Object.keys(dbtSelections[category]).forEach((subcategory) => {
        const formattedSubcategory = subcategory
          .replace(/\s+/g, "_")
          .toLowerCase();
        const key = `${formattedCategory}0${formattedSubcategory}`;
        transformedSelections[key] = dbtSelections[category][subcategory]
          ? "True"
          : "False";
      });
    });
    const currentDateTime = new Date().toISOString();
    transformedSelections["uploaded_datetime"] = currentDateTime;

    const requestBody = {
      task: "customDBTSkillSetData",
      query_dict: JSON.stringify(transformedSelections),
    };

    fetch(apiEndpoint, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setIsDBTSkillsEditModalOpen(false);
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsDBTSkillsEditModalOpen(false)}
          ></button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4 cardPaitent">
          <div className="headingSec mb-3">
            <h2 className="mb-0">{editInfo.title}</h2>
            <span className="darkBlue py-2 d-inline-block">
              Add or remove options from Urges and Emotions to {editInfo.title}
            </span>
          </div>

          <div class="table-responsive">
            <table class="table table-bordered tableCustmization">
              <tbody>
                {Object.keys(skillCategories).map((category) => (
                  <div className="box" key={category.toUpperCase()}>
                    <div className="left">
                      <h3>
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </h3>
                    </div>

                    <div className="right">
                      {skillCategories[category].length > 0 &&
                        skillCategories[category].map((subcategory) => (
                          <div className="check-box">
                            <label htmlFor="">{subcategory}</label>
                            <input
                              type="checkbox"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              checked={dbtSelections[category][subcategory]}
                              onChange={() =>
                                handleDbtSelectionChange(category, subcategory)
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </tbody>
            </table>
          </div>

          <div className="row mb-3 mt-3">
            <div className="col-md-12 mt-3">
              <div class="gap-4 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-outline-secondary px-5 btnSize rounded-3"
                  onClick={() => setIsDBTSkillsEditModalOpen(false)}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  class="btn btn-primary px-5 btnSize rounded-3"
                  onClick={handleSkillSaveChanges}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDairySkillsCardModal;

const categories = {
  behaviors: [
    "Sleep",
    "Nightmares",
    "Exercise",
    "Meditation",
    "Relaxation",
    "Social Interaction",
    "Reached out for Support",
    "Joyful Activity",
    "Medication Compliance",
    "Stress",
    "Physical Pain",
  ],
  urges: [
    "Suicidal Ideation",
    "Self Harm",
    "Skin Picking/Pulling Hair",
    "Overspending",
    "Procrastination",
    "Disordered Eating Behaviors",
    "Negative Self Talk",
    "Pushing Boundaries",
    "Substance Use",
    "Risky Behavior",
  ],
  emotions: [
    "Anxiety",
    "Fear",
    "Anger",
    "Shame",
    "Sadness",
    "Self Acceptance",
    "Resilience",
    "Compassion",
    "Joy",
  ],
};

const skillCategories = {
  mindfulness: [
    "Observe",
    "Describe",
    "Participate",
    "Wise Mind",
    "Spirituality",
    "Loving Kindness",
    "Breathing Exercises",
  ],
  interpersonalEffectiveness: [
    "DEARMAN",
    "GIVE",
    "FAST",
    "Saying No and Observing Limits",
    "Effective Listening",
    "I Feel Statements",
  ],
  emotionRegulation: [
    "ABC",
    "PLEASE",
    "Pros and Cons",
    "Check the Facts",
    "Opposite Action",
    "Build Mastery",
    "Cope Ahead",
    "Ride the Wave of Emotion",
    "Problem Solving",
  ],
  distressTolerance: [
    "Improve the Moment",
    "ACCEPTS",
    "STOP",
    "TIPP",
    "Self Soothe",
    "Alternative Rebellion",
    "Radical Acceptance",
    "Turning the Mind",
    "Willing Hands and Half Smile",
  ],
};
