import axios from 'axios';
import storage from 'pages/lib/storage';

import { useSbCalls } from 'pages/lib/sendbird-calls';
import { SoundType } from 'sendbird-calls';




const SendBird = require('sendbird');


export function createSendBirdUser(randomId: string, nickname: string): Promise<any> {
    const APP_ID = process.env.REACT_APP_APP_ID;
    const sb = new SendBird({ 'appId': APP_ID });

    return new Promise((resolve, reject) => {
        sb.connect(randomId, (user: any, error: any) => {
            if (error) {
                reject(error);
            } else {
                sb.updateCurrentUserInfo(nickname, null, (response: any, updateError: any) => {
                    if (updateError) {
                        reject(updateError);
                    } else {
                        resolve(user);
                    }
                });
            }
        });
    });
}

export function getUsersListing() {
    const APP_ID = process.env.REACT_APP_APP_ID;
    const sb = new SendBird({ 'appId': APP_ID });
    const stored = storage.getItem('sbCalls');

    const excludedUserIds = ['ADMIN_USER_ID', stored?.userId || '0'];

    return new Promise((resolve, reject) => {
        sb.connect('ADMIN_USER_ID', (user: any, error: any) => {
            if (error) {
                return reject(error);
            } else {
                const userListQuery = sb.createUserListQuery();
                userListQuery.limit = 100;
                userListQuery.next((userList: any[], error: any) => {
                    if (error) {
                        return reject(error);
                    } else {
                        const filteredUsers = userList.filter(user => !excludedUserIds.includes(user.userId));
                        return resolve(filteredUsers);
                    }
                });
            }
        });
    });
}


export async function getUserDetail(userId: string) {
    const APP_ID = process.env.REACT_APP_APP_ID;
    const API_TOKEN = process.env.REACT_APP_API_TOKEN
    try {
        const response = await axios.get(`https://api-${APP_ID}.sendbird.com/v3/users/${userId}`, {
            headers: {
                'Api-Token': API_TOKEN
            }
        });

        return response.data;

    } catch (err) {
        return false;
    }
}


export function randomDigitsId() {

    const timestamp = Date.now().toString();
    const randomDigits = Math.floor(10000000000000000000 + Math.random() * 90000000000000000000).toString();
    return timestamp + randomDigits.substring(0, 20 - timestamp.length);


}