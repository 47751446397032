// CommonTable.js
import { DataGrid } from "@mui/x-data-grid";
import { tablestyle } from "styles/mixins";

const CommonTable = ({ rows = [], columns = [], rowHeight = 100 }) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={rows.length} // Display all rows
        hideFooterPagination // Hide pagination controls
        hideFooter // Hide footer
        disableColumnMenu // Disable column menu
        disableSelectionOnClick // Disable row selection
        autoHeight // Adjust height based on content
        rowHeight={rowHeight} // Set the row height
        sx={tablestyle}
      />
    </div>
  );
};

export default CommonTable;
