import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import RootModal from "modals/RootModal";
import "../../pages/Schedule/Schedule.css";
import { addNotes } from "lib/api/service";
import toast from "react-hot-toast";
import { useLocalStorageGet } from "utils/hooks/useLoacalStorage";

// Define the validation schema
const schema = yup.object().shape({
  notes: yup.string().required("Notes cannot be empty"),
});

const ScheduleNotes = ({ onClose, show, updatedData }) => {
  const { rows: { email, Name } } = useLocalStorageGet("userDetails");
  
  // Initialize react-hook-form
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  // Function to handle form submission
  const createNotes = async (data) => {
    const response = await addNotes({
      name: Name,
      email: email,
      patients: updatedData?.participants,
      psychotherapy_note: data.notes,
    });

    if (response.message) {
      toast.success(response.message);
      onClose();
    }
  };

  // Define footer buttons for the modal
  const footerButtons = [
    {
      label: "Cancel",
      variant: "secondary",
      onClick: onClose,
      classname: "notes_close_btn",
    },
    {
      label: "Create Notes",
      variant: "primary",
      onClick: handleSubmit(createNotes),
      classname: "notes_create_btn",
    },
  ];

  return (
    <RootModal show={show} handleClose={onClose} isHeader footerButtons={footerButtons}>
      <div>
        <Form.Control
          as="textarea"
          {...register("notes")}
          style={{ minHeight: "300px" }}
          isInvalid={!!errors.notes}
        />
        {errors.notes && (
          <Form.Text className="text-danger">
            {errors.notes.message}
          </Form.Text>
        )}
      </div>
    </RootModal>
  );
};

export default ScheduleNotes;
