import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#5A55B3',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 20%)',
    width: 12,
    height: 12,
    backgroundColor: 'white',
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#8F99B0',
    boxSizing: 'border-box',
  },
}));

const SwitchComponent = ({isChecked , onChangedHandeler , name}) => {
  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <StyledSwitch   
        checked={isChecked}
        onChange={onChangedHandeler}
        name={name}
        inputProps={{ 'aria-label': 'ant design' }} />
      </Stack>
    </FormGroup>
  );
}

export default SwitchComponent;