import React from 'react';

const TelementalHealthModal = ( { assessmentInfo  } ) => {
  const { selectedAssignedData, setIsAssignedModalOpen } = assessmentInfo;

  if (!selectedAssignedData) {
    return null;
  }

  return (
       <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
              <button type="button" className="btn-close" onClick={() => setIsAssignedModalOpen(false)} >
              </button>
            </div>
            <div className="modal-body pt-3 pb-5 px-5">
              <div className='headingSec d-flex justify-content-between mb-4'>
                <h2 className='mb-0'> { selectedAssignedData.name }</h2>
                <button type="button" className="btn pendingBtn">Signature required</button>
              </div>
              <div className="col-md-12 mb-2">
                <div className="d-flex align-items-center">
                  <label className="me-2">Appointment date:</label>
                  <p className="mb-0">{ selectedAssignedData.createdDate } <span>2:00PM</span></p>
                </div>
              </div>
              <div className="col-md-12 mb-2">
                <div className="d-flex align-items-center">
                  <label className="me-2">Provider:</label>
                  <p className="mb-0"><img src="images/doctor-pic.png" alt="" /> { selectedAssignedData.provider }</p>
                </div>
              </div>
              <div className='col-md-12 mb-2'>
                <p className='mb-2'>Legal Notice: This document contains general information and is not provided as advice, and should not be treated as such. If you have any specific questions about any legal matter you should consult your attorney or other professional legal services provider. For questions, contact info and communications please visit us at: Discovery Counseling Austin: www.discoverycounselingaustin.com</p>
                <p className='mb-2'>
                  <b>Intended Use</b> <br />
                  This form is provided as an additional consent and will be added to your file along with your initial paperwork which includes informed consent and practice policies. This form is used when a provider and client have determined that online counseling is a necessary and supportive service for client’s treatment. A discussion about the contents of this form is conducted in a face-to-face meeting (via video or in-person).
                </p>
              </div>
              <div className='col-md-12 mb-2'>
                <label className="me-2 my-2">Tele-mental Health Informed Consent</label>
                <p className='mb-1'>
                  <b>What is Telemental Health?</b> <br />
                  Telemental healthcare is the use of technology, like video conferencing software, to provide mental health services at a distance. Your treatment will be provided through interactive audio, video, and/or telephone communication.
                </p>
                <p className='mb-1'>
                  <b>Benefits & Risks</b> <br />
                  There are several benefits to using telemental health services. It increases accessibility to treatment, reduces travel time, and allows for participation in therapy from an environment of your choosing. Years of empirical research has established telemental healthcare as a useful and effective mode of healthcare delivery.
                  When using technology, however, there is the risk of security and technical difficulty (e.g., disconnection of internet, computer or software not working, etc.). Please refer to the “Telemental Health Checklist” to reduce technical issues, maximize security, and make the most of your therapeutic experience.
                  Additionally, technical issues can sometimes limit visual or auditory cues and contribute to miscommunication or misunderstanding. Please know that open, clear, and meaningful communication is one of our highest priorities. Please talk to us about any communication challenges or perceived misunderstandings during our sessions. If issues persist and impact your treatment, we will discuss alternatives and consider referrals for in-person sessions.
                </p>
                <p className='mb-1'>
                  <b>Privacy & Confidentiality</b> <br />
                  The laws that protect your privacy and the confidentiality of your health information also apply to tele-mental health services. For more information about exceptions to confidentiality, please refer to the HIPAA NOTICE OF PRIVACY PRACTICES & PRACTICE POLICIES.

                </p>
                <p className='mb-1'>
                  <b>Video Recording</b> <br />
                  No permanent video or voice recordings are kept from our tele-mental health sessions. To preserve your privacy and confidentiality, it is also required that you do not record or store videoconference or phone sessions. You must talk with your provider before recording any telemental health interaction.
                </p>
                <p className='mb-1'>
                  <b>Location</b> <br />
                  For safety reasons, you will be asked to disclose your physical address in your session. If you anticipate that you will be traveling or changing locations, please let me know in advance so that we can make the appropriate arrangements for privacy and format of our sessions.
                </p>
                <p className='mb-1'>
                  <b>Bringing Someone to Sessions</b> <br />
                  If you would like to have a family member or another person join you in your session, please first discuss this with me to make arrangements. If you do not make prior arrangements, sessions that include unapproved individuals will be terminated.
                </p>
                <p className='mb-1'>
                  <b>Emergency Contact Person</b> <br />
                  You are required to provide contact information to an emergency person of your choice. This person would be contacted only in cases of an emergency. This person must be over the age of 18 and willing and able to physically go to your location in the event of an emergency. Alternatively, and depending on the nature of the emergency, I might contact local authorities or mental health deputies.
                </p>
                <p className='mb-1'>
                  <b>Technical Difficulties</b> <br />
                  If reception is bad, or if our session gets disconnected, we will try to reconnect by restarting the video platform. If we still experience technical difficulties, we will use phone to continue with our session. You also have the option to cancel or reschedule your session.
                </p>
                <p className='my-3 fs-small'>
                  Legal Notice: This document contains general information and is not provided as advice, and should not be treated as such. If you have any specific questions about any legal matter you should consult your attorney or other professional legal services provider. For questions, contact info and communications please visit us at: Discovery Counseling Austin: www.discoverycounselingaustin.com
                </p>

              </div>
              <div className='col-md-12 mb-2'>
                <label className="me-2 my-2">Discovery Counseling Telemental Health Consent</label>
                <p className='mb-1'>
                  <b>Telemental Health Checklist</b> <br />
                  Choose a “meeting” space or environment that allows you to focus on the discussions you want to have with your counselor. If you have technology options (phone, laptop, tablet, etc.), choose the one that allows you to have the best telemental health experience. If your environment or your technology is creating distractions, you won’t get as much out of the sessions.
                  Situate yourself in a private space
                </p>
                <p className='mb-3'>
                  Think about the best way to make your privacy a priority so you feel comfortable talking freely. For example:
                  <ul className='p-0'>
                    <li>• Close the door</li>
                    <li>• Put a Do Not Disturb sign out (if appropriate)</li>
                    <li>• Go for a walk in a park or neighborhood with privacy</li>
                    <li>• Inform people in your house or office that you have an important meeting and ask that they not interrupt you unless absolutely necessary.</li>
                    <li>• Consider who might enter the room/space during your meeting time and prepare your response to get back to the session quickly.</li>
                  </ul>
                </p>
                <p className='mb-3'>
                  Use optimal technology
                  <ul className='p-0'>
                    <li className='mb-2'>• For video sessions: Your laptop with a microphone and speaker are ideal because it will feel most natural for talking to one another. Using headphones with your laptop might work even better for you. Confirm that your internet speed and therefore your video quality allow us to see and hear each other. Good lighting and a stabilized video camera (not walking with your phone or moving around often) will help both you and I get the most out of our video experience with each other.</li>
                    <li>• For Phone/Voice sessions: Consider whether you need to be on wifi to get the best experience and choose your location accordingly. Using headphones when possible is the best way to have a clear and volume regulated conversation.</li>
                  </ul>
                </p>
                <p className='mb-3'>
                  With a little attention to a comfortable environment and technology, telemental health offers a wonderful alternative to in-person meetings and can have many benefits to your personal counseling goals.
                </p>
                <p className='mb-1'>
                  <b>Signature Required</b> <br />
                  By signing this document, I acknowledge that I have been informed about the policies of Discovery Counseling Austin as it relates to telemental health services. I affirm that I have been provided all necessary information about the policies of the practice in order to make a decision to engage in telemental health. My signature also shows my understanding of my rights related to confidentiality and that I know that I can ask questions at any time about the services I’m receiving and that I can terminate at any time.
                </p>
              </div>
            </div>
          </div>
        </div>
  );
};

export default TelementalHealthModal;
