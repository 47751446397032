import React, { useState, useEffect } from "react";
import {
  TextInput,
  RadioButtons,
  CountryDropdown,
  NumberInput,
  DateInput,
} from "../../../../components/FormComponents/FormComponents";

const NewPatientPage5 = ({
  formData,
  setFormData,
  handleChange,
  handleSubmit,
  handleGoBack,
  showFooter = true
}) => {
 
  //provider
  const [showNewProvider, setShowProvider] = useState(false);
  const [numProviders, setNumProviders] = useState(0);
  const [addedProviderFormsIndices, setAddedProviderFormsIndices] = useState(
    []
  );

  // hospitalization
  const [numHospitalization, setNumHospitalization] = useState(0);
  const [addedHospitalizationProvider, setAddedHospitalizationProvider] =
    useState([]);
  const [showNewHospitalization, setShowHospitalization] = useState(false);

  // medicationhistory
  const [numMedicationHistory, setMedicationHistory] = useState(0);
  const [addedMedicationHistory, setAddedMedicationHistory] =
    useState([]);
  const [showNewNedicationHistory, setShowMedicationHistory] = useState(false);

  const handleAddProvider = () => {
    const newIndex = numProviders + 1;
    setNumProviders(newIndex);
    const newProviderKey = `provider[${newIndex}]`;
    const newPhoneNumberKey = `providerPhoneNumber[${newIndex}]`;
    const newEmailKey = `providerEmail[${newIndex}]`;
    setFormData((prevData) => ({
      ...prevData,
      [newProviderKey]: "",
      [newPhoneNumberKey]: "",
      [newEmailKey]: "",
    }));
    setAddedProviderFormsIndices((prevIndices) => [...prevIndices, newIndex]);
    setShowProvider(true);
  };

  const handleRemoveProvider = () => {
    if (addedProviderFormsIndices.length === 0) return;

    const lastAddedIndex =
      addedProviderFormsIndices[addedProviderFormsIndices.length - 1];
    const providerKey = `provider[${lastAddedIndex}]`;
    const phoneNumberKey = `providerPhoneNumber[${lastAddedIndex}]`;
    const emailKey = `providerEmail[${lastAddedIndex}]`;
    setFormData((prevData) => {
      const updatedFormData = { ...prevData };
      delete updatedFormData[providerKey];
      delete updatedFormData[phoneNumberKey];
      delete updatedFormData[emailKey];
      return updatedFormData;
    });
    setNumProviders((prevNum) => Math.max(0, prevNum - 1));
    setAddedProviderFormsIndices((prevIndices) => prevIndices.slice(0, -1));
    if (addedProviderFormsIndices.length === 1) {
      setShowProvider(false);
    }
  };

  useEffect(() => {
    const providerIndices = Object.keys(formData)
      .filter((key) => key.startsWith("provider["))
      .map((key) => parseInt(key.match(/\d+/)[0]));

    if (providerIndices.length > 0) {
      setShowProvider(true);
      setNumProviders(providerIndices.length);
      setAddedProviderFormsIndices(providerIndices);
    } else {
      setShowProvider(false);
      setNumProviders(0);
      setAddedProviderFormsIndices([]);
    }
  }, [formData]);

  // Hospitalization

  const handleAddHospitalization = () => {
    const newIndex = numHospitalization + 1;
    setNumHospitalization(newIndex);
    const newDateKey = `hospitalizationDate[${newIndex}]`;
    const newDurationKey = `hospitalizationDuration[${newIndex}]`;
    const newLocKey = `hospitalizationLOC[${newIndex}]`;
    const newFacilityKey = `hospitalizationFacility[${newIndex}]`;
    setFormData((prevData) => ({
      ...prevData,
      [newDateKey]: "",
      [newDurationKey]: "",
      [newLocKey]: "",
      [newFacilityKey]: "",
    }));
    setAddedHospitalizationProvider((prevIndices) => [
      ...prevIndices,
      newIndex,
    ]);
    setShowHospitalization(true);
  };

  const handleRemoveHospitalization = () => {
    if (addedHospitalizationProvider.length === 0) return;
    const lastAddedIndex =
      addedHospitalizationProvider[addedHospitalizationProvider.length - 1];
    const newDateKey = `hospitalizationDate[${lastAddedIndex}]`;
    const newDurationKey = `hospitalizationDuration[${lastAddedIndex}]`;
    const newLocKey = `hospitalizationLOC[${lastAddedIndex}]`;
    const newFacilityKey = `hospitalizationFacility[${lastAddedIndex}]`;
    setFormData((prevData) => {
      const updatedFormData = { ...prevData };
      delete updatedFormData[newDateKey];
      delete updatedFormData[newDurationKey];
      delete updatedFormData[newLocKey];
      delete updatedFormData[newFacilityKey];
      return updatedFormData;
    });
    setNumHospitalization((prevNum) => Math.max(0, prevNum - 1));
    setAddedHospitalizationProvider((prevIndices) => prevIndices.slice(0, -1));
    if (addedHospitalizationProvider.length === 1) {
      setShowHospitalization(false);
    }
  };

  useEffect(() => {
    const hospitalizaeIndices = Object.keys(formData)
      .filter((key) => key.startsWith("hospitalizationDate["))
      .map((key) => parseInt(key.match(/\d+/)[0]));

    if (hospitalizaeIndices.length > 0) {
      setShowHospitalization(true);
      setNumHospitalization(hospitalizaeIndices.length);
      setAddedHospitalizationProvider(hospitalizaeIndices);
    } else {
      setShowHospitalization(false);
      setNumHospitalization(0);
      setAddedHospitalizationProvider([]);
    }
  }, [formData]);


    // Medication History
    
    const handleAddMedicationHistory = () => {
        const newIndex = numMedicationHistory + 1;
        setMedicationHistory(newIndex);
        const newDateKey = `medicationHistoryDate[${newIndex}]`;
        const newDurationKey = `medicationhistoryFrequency[${newIndex}]`;
        const newLocKey = `medicationhistoryEffect[${newIndex}]`;
        const newFacilityKey = `medicationhistoryCheck[${newIndex}]`;
        setFormData((prevData) => ({
          ...prevData,
          [newDateKey]: "",
          [newDurationKey]: "",
          [newLocKey]: "",
          [newFacilityKey]: "",
        }));
        setAddedMedicationHistory((prevIndices) => [
          ...prevIndices,
          newIndex,
        ]);
        setShowMedicationHistory(true);
      };

      const handleRemoveMedicationHistory = () => {
        if (addedMedicationHistory.length === 0) return;
        const lastAddedIndex =
        addedMedicationHistory[addedMedicationHistory.length - 1];
        const newDateKey = `medicationHistoryDate[${lastAddedIndex}]`;
        const newDurationKey = `medicationhistoryFrequency[${lastAddedIndex}]`;
        const newLocKey = `medicationhistoryEffect[${lastAddedIndex}]`;
        const newFacilityKey = `medicationhistoryCheck[${lastAddedIndex}]`;
        setFormData((prevData) => {
          const updatedFormData = { ...prevData };
          delete updatedFormData[newDateKey];
          delete updatedFormData[newDurationKey];
          delete updatedFormData[newLocKey];
          delete updatedFormData[newFacilityKey];
          return updatedFormData;
        });
        setMedicationHistory((prevNum) => Math.max(0, prevNum - 1));
        setAddedMedicationHistory((prevIndices) => prevIndices.slice(0, -1));
        if (addedMedicationHistory.length === 1) {
            setShowMedicationHistory(false);
        }
      };
    
      useEffect(() => {
        const hospitalizaeIndices = Object.keys(formData)
          .filter((key) => key.startsWith("medicationHistoryDate["))
          .map((key) => parseInt(key.match(/\d+/)[0]));
    
        if (hospitalizaeIndices.length > 0) {
            setShowMedicationHistory(true);
            setMedicationHistory(hospitalizaeIndices.length);
            setAddedMedicationHistory(hospitalizaeIndices);
        } else {
            setShowMedicationHistory(false);
            setMedicationHistory(0);
            setAddedMedicationHistory([]);
        }
      }, [formData]);


  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-group row">
          <div className="d-flex align-items-center justify-content-between">
            <span>
              Does this patient have current outpatient mental health providers?
            </span>
            <div className="d-flex align-items-center">
              <RadioButtons
                name="currentOutpatientMentalHealthProvider"
                formData={formData}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
        {formData.currentOutpatientMentalHealthProvider === "Yes" && (
          <div className="form-group row">
            <div className="col-4">
              <TextInput
                id="providerName"
                name="providerName"
                value={formData.providerName}
                onChange={handleChange}
                label="Provider Name:"
                required
              />
            </div>
            <div className="col-4">
              <TextInput
                id="providerEmail"
                name="providerEmail"
                value={formData.providerEmail}
                onChange={handleChange}
                label="Email:"
                required
              />
            </div>
            <div className="col-4">
              <label
                htmlFor="therapistPhoneNumber"
                style={{ marginLeft: "-11px" }}>
                Phone Number:
              </label>
              <div className="row">
                <input
                  type="number"
                  id="providerPhoneNumber"
                  name="providerPhoneNumber"
                  value={formData.providerPhoneNumber}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
            </div>
          </div>
        )}
        {showNewProvider &&
          addedProviderFormsIndices.map((index) => (
            <div key={`provider${index}`} className="row form-group">
              <div className="col-4">
                <TextInput
                  id={`provider${index}`}
                  name={`provider[${index}]`}
                  value={formData[`provider[${index}]`]}
                  onChange={handleChange}
                  label={`Provider Name:`}
                />
              </div>
              <div className="col-4">
                <TextInput
                  id={`providerEmail${index}`}
                  name={`providerEmail[${index}]`}
                  value={formData[`providerEmail[${index}]`]}
                  onChange={handleChange}
                  label="Email:"
                  required
                />
              </div>
              <div className="col-4">
                <label
                  htmlFor="therapistPhoneNumber"
                  style={{ marginLeft: "-11px" }}>
                  Phone Number:
                </label>
                <div className="row">
                  <input
                    type="number"
                    id={`providerPhoneNumber${index}`}
                    name={`providerPhoneNumber[${index}]`}
                    value={formData[`providerPhoneNumber[${index}]`]}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
              </div>
            </div>
          ))}
       {showFooter && (<div className="row d-flex justify-content-between">
          <div>
            <button
              type="button"
              onClick={handleAddProvider}
              className="text-button">
              + Add New Provider
            </button>
          </div>
          {showNewProvider && (
            <div>
              <button
                type="button"
                onClick={handleRemoveProvider}
                className="text-button"
                style={{ color: "#FF6760" }}>
                - Remove Provider
              </button>
            </div>
          )}
        </div>)}
        <div className="form-group row">
          <div className="d-flex align-items-center justify-content-between">
            <span>Has patient signed any ROIs for the above providers?</span>
            <div className="d-flex align-items-center">
              <RadioButtons
                name="signedROIs"
                formData={formData}
                handleChange={handleChange}
              />
            </div>
          </div>
          {formData.signedROIs === "Yes" && (
            <TextInput
              id="signedROIProviderName"
              name="signedROIProviderName"
              value={formData.signedROIProviderName}
              onChange={handleChange}
              label="ROIs needed for the following providers :"
              required
            />
          )}
        </div>
        <h3 className="mt-2">Hospitalization</h3>
        <div className="d-flex align-items-center justify-content-between">
          <span>
            Does patient have hx of inpatient hospitalization/PHP/detox
            admission?
          </span>
          <div className="d-flex align-items-center">
            <RadioButtons
              name="previousHospitalizationPHPorDetoxAdmission"
              formData={formData}
              handleChange={handleChange}
            />
          </div>
        </div>
        {formData.previousHospitalizationPHPorDetoxAdmission === "Yes" && (
          <div className="form-group row">
            <div className="col-3">
              <DateInput
                id="hospitalizationDate"
                name="hospitalizationDate"
                value={formData.hospitalizationDate}
                onChange={handleChange}
                label="Hospitalization date:"
                required
              />
            </div>
            <div className="col-3">
              <TextInput
                id="hospitalizationDuration"
                name="hospitalizationDuration"
                value={formData.hospitalizationDuration}
                onChange={handleChange}
                label="Duration"
                required
              />
            </div>
            <div className="col-3">
              <TextInput
                id="hospitalizationLOC"
                name="hospitalizationLOC"
                value={formData.hospitalizationLOC}
                onChange={handleChange}
                label="Type of Tx/LOC"
                required
              />
            </div>

            <div className="col-3">
              <TextInput
                id="hospitalizationFacility"
                name="hospitalizationFacility"
                value={formData.hospitalizationFacility}
                onChange={handleChange}
                label="Name of Facility"
                required
              />
            </div>
          </div>
        )}
        {showNewHospitalization &&
          addedHospitalizationProvider.map((index) => (
            <div key={`hospitalization_${index}`} className="row form-group">
              <div className="col-3">
                <DateInput
                  id={`hospitalizationDate${index}`}
                  name={`hospitalizationDate[${index}]`}
                  value={formData[`hospitalizationDate[${index}]`]}
                  onChange={handleChange}
                  label="Hospitalization date:"
                  required
                />
              </div>
              <div className="col-3">
                <TextInput
                  id={`hospitalizationDuration${index}`}
                  name={`hospitalizationDuration[${index}]`}
                  value={formData[`hospitalizationDuration[${index}]`]}
                  onChange={handleChange}
                  label="Duration"
                  required
                />
              </div>
              <div className="col-3">
                <TextInput
                  id={`hospitalizationLOC${index}`}
                  name={`hospitalizationLOC[${index}]`}
                  value={formData[`hospitalizationLOC[${index}]`]}
                  onChange={handleChange}
                  label="Type of Tx/LOC"
                  required
                />
              </div>
              <div className="col-3">
                <TextInput
                  id={`hospitalizationFacility${index}`}
                  name={`hospitalizationFacility[${index}]`}
                  value={formData[`hospitalizationFacility[${index}]`]}
                  onChange={handleChange}
                  label="Name of Facility"
                  required
                />
              </div>
            </div>
          ))}
        {showFooter && (<div className="row d-flex justify-content-between">
          <div>
            <button
              type="button"
              onClick={handleAddHospitalization}
              className="text-button">
              + Add New hospitalization
            </button>
          </div>
          {showNewHospitalization && (
            <div>
              <button
                type="button"
                onClick={handleRemoveHospitalization}
                className="text-button"
                style={{ color: "#FF6760" }}>
                - Remove hospitalization
              </button>
            </div>
          )}
        </div>)}

        {/* Medication History Satrt */}

        <h3 className="mt-2">Medication History</h3>
        <div className="d-flex align-items-center justify-content-between">
          <span>Medication History</span>
          <div className="d-flex align-items-center">
            <RadioButtons
              name="medicationhistory"
              formData={formData}
              handleChange={handleChange}
            />
          </div>
        </div>
        {formData.medicationhistory === "Yes" && (
          <div className="form-group row">
            <div className="col-3">
              <DateInput
                id="medicationhistoryName"
                name="medicationhistoryName"
                value={formData.medicationhistoryName}
                onChange={handleChange}
                label="Name of medication"
                required
              />
            </div>
            <div className="col-3">
              <TextInput
                id="medicationhistoryFrequency"
                name="medicationhistoryFrequency"
                value={formData.medicationhistoryFrequency}
                onChange={handleChange}
                label="Dose/Frequency"
                required
              />
            </div>
            <div className="col-3">
              <TextInput
                id="medicationhistoryEffect"
                name="medicationhistoryEffect"
                value={formData.medicationhistoryEffect}
                onChange={handleChange}
                label="Effect"
                required
              />
            </div>

            <div className="col-3">
              <label htmlFor={""}>{"Currently Taking?"}</label>
              <div className="d-flex align-items-center">
                <RadioButtons
                  name="currentlytaking"
                  formData={formData}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        )}
        {showNewNedicationHistory &&
          addedMedicationHistory.map((index) => (
            <div key={`medicationhistory_${index}`} className="row form-group">
              <div className="col-3">
                <DateInput
                  id={`medicationHistoryDate${index}`}
                  name={`medicationHistoryDate[${index}]`}
                  value={formData[`medicationHistoryDate[${index}]`]}
                  onChange={handleChange}
                  label="Name of medication"
                  required
                />
              </div>
              <div className="col-3">
                <TextInput
                  id={`medicationhistoryFrequency${index}`}
                  name={`medicationhistoryFrequency[${index}]`}
                  value={formData[`medicationhistoryFrequency[${index}]`]}
                  onChange={handleChange}
                  label="Dose/Frequency"
                  required
                />
              </div>
              <div className="col-3">
                <TextInput
                  id={`medicationhistoryEffect${index}`}
                  name={`medicationhistoryEffect[${index}]`}
                  value={formData[`medicationhistoryEffect[${index}]`]}
                  onChange={handleChange}
                  label="Effect"
                  required
                />
              </div>
              <div className="col-3">
              <label htmlFor={""}>{"Currently Taking?"}</label>
              <div className="d-flex align-items-center">
                <RadioButtons
                  name={`medicationhistoryCheck[${index}]`}
                  formData={formData}
                  handleChange={handleChange}
                />
              </div>
            </div>
            </div>
          ))}
        {showFooter && (<div className="row d-flex justify-content-between">
          <div>
            <button
              type="button"
              onClick={handleAddMedicationHistory}
              className="text-button">
              + Add New Medication
            </button>
          </div>
          {showNewNedicationHistory && (
            <div>
              <button
                type="button"
                onClick={handleRemoveMedicationHistory}
                className="text-button"
                style={{ color: "#FF6760" }}>
                - Remove Medication
              </button>
            </div>
          )}
        </div>)}

        {/* Medication History End */}

        <div className="white-rounded-footer mt-2">
          <hr className="whitebox-footer-divider"></hr>
          {showFooter && (<div className="bg-white rounded-bottom-3 px-3 py-2 d-flex justify-content-end align-items-center">
            <button className="btn btn-primary px-5 btnSize rounded-3 mx-4" onClick={handleGoBack}>
              Previous
            </button>
            <button className="btn btn-primary px-5 btnSize rounded-3" type="submit">
              Continue
            </button>
          </div>)}
        </div>
      </form>
    </div>
  );
};

export default NewPatientPage5;
