import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import Modal from "react-modal";
const PaymentTabTable = () => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const filteredData = selectedStatus
    ? clinicalData.filter((data) => data.status === selectedStatus)
    : clinicalData;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <>
      <div className="col-9">
        <div className="title-dbttracker d-flex gap-1rem justify-content-between py-4">
          <div>
            <h2>Payments</h2>
          </div>
          <div
            className="d-flex  align-items-center"
            style={{
              padding: "0px 10px",
              backgroundColor: "#F8F9FE",
              marginRight: "10px",
            }}>
            <FaPlus color="#4264D0" width={16} height={16} />
            <h1
              onClick={() => setModalOpen(true)}
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#4264D0",
                cursor: "pointer",
                marginLeft: "10px",
              }}>
              Initiate payment
            </h1>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-3">
            <div className="d-flex col-md-auto">
              <div className="col-md">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Select Date"
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex col-md-auto">
              <div className="col-md">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Code"
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <select
              className="form-select"
              aria-label="Default select example"
              value={selectedStatus}
              onChange={handleStatusChange}
              name="Status">
              <option value="">Amount % Covered</option>
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
              <option value="Denied">Denied</option>
            </select>
          </div>
          <div className="col-md-3">
            <div className="d-flex col-md-auto">
              <div className="check-box align-items-center p-0">
                <input
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label htmlFor="" className="px-2">
                  {"Show only uncovered payments"}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-3 px-3 pt-4 pb-5">
          <div className="tab-content mt-4" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab">
              <div className="tableList  payment-list-tbl">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">
                        Dates of service
                        <img src="../../images/short-icon.svg" alt="" />{" "}
                      </th>
                      <th scope="col">
                        Procedure code
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        RMK code
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Amount billed
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Not covered
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Discount amount
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Discount code
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Allowed amount
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Deductible amount
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Co-pay amount
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Covered amount
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Paid At
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                      <th scope="col">
                        Payment Amount
                        <img src="../../images/short-icon.svg" alt="" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((clinicalData) => (
                      <tr key={clinicalData.description}>
                        <td>{clinicalData.name}</td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.createdDate}</h6>
                        </td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.updatedDate}</h6>
                        </td>
                        <td>{clinicalData.name}</td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.createdDate}</h6>
                        </td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.updatedDate}</h6>
                        </td>
                        <td>{clinicalData.name}</td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.createdDate}</h6>
                        </td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.updatedDate}</h6>
                        </td>
                        <td>{clinicalData.name}</td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.createdDate}</h6>
                        </td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.updatedDate}</h6>
                        </td>
                        <td>
                          <h6 className="mb-0"> {clinicalData.updatedDate}</h6>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalOpen(false)}></button>
            </div>
            <div className="modal-body pt-0 px-4">
              <div className="headingSec mb-2">
                <h1
                  className="mb-0"
                  style={{
                    color: "#18357A",
                    fontSize: "28px",
                    fontWeight: 700,
                  }}>
                  Initiate payment
                </h1>
              </div>
              <p
                className="mb-4"
                style={{
                  color: "#5B6680",
                }}>
                Select receipt(s) that require payment by patient:
              </p>
              <h1
                className="mb-2"
                style={{
                  color: "#18357A",
                  fontSize: "16px",
                  fontWeight: 700,
                }}>
                Receipt(s):
              </h1>
              <div className="input-group custominp">
                <span
                  className="input-group-text border-end-0"
                  id="basic-addon1">
                  <i className="far fa-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control ps-0 border-start-0"
                  placeholder="Search..."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div className="rounded-3 px-4 pt-2">
              <div className="tab-content mt-4" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab">
                  <div className="tableList">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th></th>
                          <th scope="col">Dates of service</th>
                          <th scope="col">Amount billed</th>
                          <th scope="col">RMK Code</th>
                          <th scope="col">Procedure code</th>
                          <th scope="col">Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <div className="d-flex col-md-auto mt-2">
                            <div className="check-box align-items-center p-0">
                              <input
                                type="checkbox"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked
                              />
                            </div>
                          </div>
                          <td>Mar 27 2023</td>
                          <td>$175.00</td>
                          <td>94</td>
                          <td>90847-95</td>
                          <td>Aug 27, 2023</td>
                        </tr>
                        <tr>
                          <div className="d-flex col-md-auto mt-2">
                            <div className="check-box align-items-center p-0">
                              <input
                                type="checkbox"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked
                              />
                            </div>
                          </div>
                          <td>Mar 27 2023</td>
                          <td>$175.00</td>
                          <td>94</td>
                          <td>90847-95</td>
                          <td>Aug 27, 2023</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-between py-2 px-4 rounded-3 mx-4"
              style={{ backgroundColor: "#F8F9FE" }}>
              {" "}
              <h1
                className="mb-2"
                style={{
                  color: "#18357A",
                  fontSize: "16px",
                  fontWeight: 700,
                }}>
                Amount total:
              </h1>
              <h1
                className="mb-2"
                style={{
                  color: "#18357A",
                  fontSize: "16px",
                  fontWeight: 700,
                }}>
                $1,100.00 (2 receipts selected)
              </h1>
            </div>
            <div className="py-2 px-4 mt-4 mx-4 add-message">
              <h1
                className="mb-2"
                style={{
                  color: "#18357A",
                  fontSize: "16px",
                  fontWeight: 700,
                }}>
                Add message:
              </h1>
              <textarea
                id="w3review"
                name="w3review"
                rows="4"
                cols="50"
                style={{
                  color: "#8F99B0",
                  fontSize: "12px",
                }}
                placeholder="Hi Charlotte,
                Attached is your invoice for the recent services provided. The total amount due is $2,300.00.
                Kindly process the payment by Feb 22, 2024. Feel free to reach out if you have any questions.
                Thanks,
                Spero Institute"></textarea>
            </div>
            <div className="col-12">
              <div className="row my-4">
                <div className="col-md-12">
                  <div className="gap-4 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-outline-secondary px-5 btnSize rounded-3"
                      onClick={() => {
                        setModalOpen(false);
                      }}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary px-5 btnSize rounded-3"
                      onClick={() => {
                        setModalOpen(false);
                      }}>
                      Initiate payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
const clinicalData = [
  {
    name: "Jane Cooper",
    createdDate: "Due: 23 Jan, 2024",
    updatedDate: "Due: 23 Mar, 2024",
    status: "Pending",
  },
  {
    name: "Jane Cooper",
    createdDate: "Due: 23 Jan, 2024",
    updatedDate: "Due: 3 Feb, 2024",
    status: "Accepted",
  },
  {
    name: "Kristy Sherman",
    createdDate: "Due: 23 Jan, 2024",
    updatedDate: "Due: 3 Feb, 2024",
    status: "Pending",
  },
  {
    name: "Mason Taylor",
    createdDate: "Due: 23 Jan, 2024",
    updatedDate: "Due: 4 Dec, 2024",
    status: "Denied",
  },
];

export default PaymentTabTable;
