import React from "react";
import { Form } from "react-bootstrap";

interface SpecialtyOptionProps {
  id: string;
  name: string;
  description: string;
  selectedSpecialty: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  specialty: string;
  setSpecialty: (value: string) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const SpecialtyOption: React.FC<SpecialtyOptionProps> = ({
  id,
  name,
  description,
  selectedSpecialty,
  handleChange,
  specialty,
  setSpecialty,
  handleKeyDown,
}) => {
  return (
    <div
      className={`mb-3 border rounded-3 p-3 spacify ${
        selectedSpecialty === id ? "active" : ""
      }`}
    >
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">
          <input
            className="me-2"
            type="radio"
            id={id}
            name="specialty"
            value={id}
            checked={selectedSpecialty === id}
            onChange={handleChange}
          />
        </div>
        <div className="flex-grow-1 ms-3">
          <label htmlFor={id}>{name}</label>
          {description && <p>{description}</p>}
          {id === "Other" && (
            <Form.Group>
              <Form.Control
                type="text"
                id="specialtyOther"
                className="form_other_field"
                value={selectedSpecialty === "Other" ? specialty : ""}
                onKeyDown={handleKeyDown}
                placeholder="Enter specialty"
                onChange={(e) => setSpecialty(e.target.value)}
              />
            </Form.Group>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecialtyOption;
