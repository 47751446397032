export const handleKeyDown = (e: any) => {
  const { value, selectionStart } = e.target;
  if (e.key === " " && selectionStart === 0) {
    e.preventDefault();
  }
  const precedingChar = value[selectionStart - 1];
  const followingChar = value[selectionStart];
  if (e.key === " " && (precedingChar === " " || followingChar === " ")) {
    e.preventDefault();
  }
};
