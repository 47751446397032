import { DASHBOARD_TASK_DATA } from "Data";
import { Table } from "react-bootstrap";
const AdminDashboardTaskTable = () => {
  return (
    <>
      <div className="tableList">
        <Table borderless>
          <thead>
            <tr>
              <th scope="col">Patient Name</th>
              <th scope="col" className="text-center">
                Date received
              </th>
              <th scope="col" className="text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {DASHBOARD_TASK_DATA.map((data, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex">
                    <h6 className="h6_font">{data.name}</h6>
                  </div>
                </td>

                <td className="text-center">
                  <h6 className="h6_font">
                    <img src={data.providerImage} alt="" /> {data.createdDate}
                  </h6>
                </td>

                <td className="text-center">
                  <button
                    type="button"
                    className={`btn ${
                      data.status === "Pending"
                        ? "pendingBtn"
                        : data.status === "Completed"
                        ? "completeBtn"
                        : "inProgressBtn"
                    }`}
                  >
                    {data.status}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default AdminDashboardTaskTable;
