import React, { useState } from "react";
import MessageTabs from "components/tabs/MessageTabs";
import "../Card.css";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RadioButton from "utils/RadioButton";
import CustomChips from "utils/CustomChips";
import { APPOINTEMENT_TITLE } from "Data";

const SeheduleAppointmentModal = ({ assessmentInfo, title }) => {
  const [activeTab, setActiveTab] = useState("Single");
  const { setOpenSeheduleAppointmentModal } = assessmentInfo;
  const [selectedChcekInDate, setSelectedChcekInDate] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const tabs = [
    { eventKey: "Single", label: "Single occurrence" },
    { eventKey: "Reoccurring", label: "Reoccurring appointment " },
  ];

  const handleCheckInDate = (date) => {
    setSelectedChcekInDate(date);
  };

  // Change a Tabs
  const handleSelect = (selectedKey) => {
    setActiveTab(selectedKey);
  };

  const handleRadioChange = (id) => {
    setSelectedId(id);
  };

  return (
    <div className="modal-dialog modal-dialog-centered modal_width">
      <div className="modal-content">
        <div className="modal-header border-bottom-0 px-4 pt-4 pb-0 justify-content-end mb-3">
          <button
            type="button"
            className="btn-close"
            onClick={() => setOpenSeheduleAppointmentModal(false)}
          ></button>
        </div>
        <div className="modal-body pt-0 pb-5 px-4">
          <div className="headingSec">
            <h2 className="mb-0">{title}</h2>
          </div>
          <div className="d-flex mt-3 align-items-center gap-3 mb-2">
            <MessageTabs
              tabs={tabs}
              handleSelect={handleSelect}
              activeTab={activeTab}
            />
          </div>

          <div className="patientList">
            <div>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Appointment title:</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Dr. Williams  1x1 Consult"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Participant(s):</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <i className="far fa-search"></i>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search participants..."
                      aria-label="search"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Form.Group>
                <div className="mb-2">
                  <CustomChips />
                </div>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Date:</Form.Label>
                      <div>
                        <DatePicker
                          selected={selectedChcekInDate}
                          onChange={handleCheckInDate}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Dec1 2023"
                          className="form-control w-100 date_input"
                          minDate={new Date()}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Time:</Form.Label>
                      <Form.Control
                        type="time"
                        placeholder="Dr. Williams  1x1 Consult"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Appointment title:</Form.Label>
                  <div className="d-flex gap-3 align-items-center">
                    {APPOINTEMENT_TITLE.map((item) => (
                      <RadioButton
                        className="d-flex gap-2 align-items-center"
                        key={item.id}
                        id={item.id}
                        label={item.title}
                        checked={selectedId === item.id}
                        name="appointmentGroup"
                        onChange={handleRadioChange}
                      />
                    ))}
                  </div>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Appointment description:</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Clinical consult on a new patient"
                    rows={2}
                  />
                </Form.Group>

                <Form.Group className="mb-3" id="formGridCheckbox">
                  <div className="d-flex gap-2">
                    <Form.Check
                      type="checkbox"
                      label="Notify me via different from main account email"
                    />
                    <Form.Label className="mb-0">
                      Enter email address
                    </Form.Label>
                  </div>
                </Form.Group>
              </Form>
            </div>
            <div className="gap-4 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-outline-secondary px-5 btnSize rounded-3"
                onClick={() => setOpenSeheduleAppointmentModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary px-5 btnSize rounded-3"
                onClick={() => setOpenSeheduleAppointmentModal(false)}
              >
                Send an invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeheduleAppointmentModal;
