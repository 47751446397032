import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Image } from "react-bootstrap";
import "./Patient.css";
import toast from "react-hot-toast";
import { fileUpload } from "../../context/Api";
import RadioButton from "utils/RadioButton";
import Modal from "react-modal";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FaEye, FaEyeSlash, FaCheck } from "react-icons/fa";
import dayjs from "dayjs";
import { FaCamera } from "react-icons/fa";

import {
  CHECKBOX_OUTPATIENT,
  CONCERNS_DATA,
  GENDER_DATA,
  PATIENTCHECKBOX_DATA,
  RADIO_DATA,
} from "Data";
import OTPInput from "otp-input-react";
import { formatDate } from "utils/dateFormat";
import Loader from "components/loader/Loader";
import { validatePatientStep } from "utilities/Validation";
import { getOtp } from "lib/api/service";

const PatientsSignUp = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isMatching, setIsMatching] = useState(true);
  const [isValid, setIsValid] = useState(false);

  const [loader, setLoader] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateBirth, setDateBirth] = useState(null);
  const [gender, setGender] = useState("");

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactNumber, setEmergencyContactNumber] = useState("");
  const [emergencyContactRelationship, setEmergencyContactRelationship] =
    useState("");

  const [insuranceCompanyName, setInsuranceCompanyName] = useState("");
  const [policyHolderName, setPolicyHolderName] = useState("");
  const [policyHolderId, setPolicyHolderId] = useState("");
  const [insurancePolicyHolerDOB, setInsurancePolicyHolerDOB] = useState(null);
  const [insurancedob, setInsurancedob] = useState(null);
  const [groupNumber, setGroupNumber] = useState("");
  const [relationship, setRelationship] = useState("");
  const [insurancePolicyPhoneNumber, setInsurancePolicyPhoneNumber] =
    useState("");

  const [concerns, setConcerns] = useState([]);

  const [outpatient, setOutpatient] = useState("");
  const [isOther, setIsOther] = useState(false);
  const [yourResponse, setYourResponse] = useState("");

  const [providerName, setProviderName] = useState("");
  const [providerEmail, setProviderEmail] = useState("");
  const [treatmentStartDate, setTreatmentStartDate] = useState(null);
  const [treatmentDateStart, setTreatmentDateStart] = useState(null);
  const [treatmentDate, setTreatmentDate] = useState(null);
  const [treatmentEndDate, setTreatmentEndDate] = useState(null);

  const [referralSelect, setReferralSelect] = useState("");
  const [referralName, setReferralName] = useState("");
  const [referralInfo, setReferralInfo] = useState("");
  const [showFields, setShowFields] = useState(true); // Initially show fields

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const [responseMessage, setResponseMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [profile, setProfile] = useState("images/user.png");
  const fileInputRef = useRef(null);
  const [stepPatient, setStepPatient] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [mainStep, setMainStep] = useState(1);
  const [mainStepContent, setMainStepContent] = useState(
    "Personal Information"
  );
  const [randomProfile, setRandomProfile] = useState("images/patients.svg");

  const validationRules = [
    { regex: /.{8,}/, name: "8 characters minimum" },
    { regex: /[A-Z]/, name: "Uppercase character" },
    {
      regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      name: "One special symbol",
    },
    { regex: /[0-9]/, name: "One number" },
    { regex: /[a-z]/, name: "Lowercase character" },
  ];

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setOutpatient(value);
    setIsOther(value === "Other");
  };

  const handleSelectChange = (event) => {
    setReferralSelect(event.target.value);

    if (event.target.value === "Advertisement in Google search") {
      setShowFields(false);
      if (showFields && referralName === "") {
        return true; // Skip validation if showFields is true
      }
      return true;
    } else {
      setShowFields(true);
    }
  };
  const handleConcernChange = (event) => {
    const value = event.target.value;
    if (concerns.includes(value)) {
      setConcerns(concerns.filter((concern) => concern !== value));
    } else {
      setConcerns([...concerns, value]);
    }
  };
  const navigateToLogin = () => {
    navigate("/login");
  };

  const handleRadioChange = (event) => {
    setGender(event.target.value);
  };

  const handleBirthDateChange = (date) => {
    if (date && date.isValid()) {
      const inputDate = new Date(date.$d);
      const today = new Date();
      if (inputDate > today) {
        toast.error("Date of birth must be less then current date.");
        setDateOfBirth(null);
        setDateBirth(null);
        return;
      }

      const formattedDateOfBirth = formatDate(inputDate);
      setDateOfBirth(formattedDateOfBirth);
      setDateBirth(date);
    } else {
      setDateOfBirth(null);
      setDateBirth(null);
    }
  };
  const handlePolicyHolerChange = (date) => {
    if (date && date.isValid()) {
      const inputDate = new Date(date.$d);
      const today = new Date();

      if (inputDate > today) {
        toast.error("Date of birth must be less then current date.");
        setInsurancePolicyHolerDOB(null);
        setInsurancedob(null);
        return;
      }

      const formattedInsurancePolicyHolerDOB = formatDate(inputDate);
      setInsurancePolicyHolerDOB(formattedInsurancePolicyHolerDOB);
      setInsurancedob(date);
    } else {
      setInsurancePolicyHolerDOB(null);
      setInsurancedob(null);
    }
  };

  const handleTreatmentDateChange = (date) => {
    if (date && date.isValid()) {
      const inputDate = new Date(date.$d);
      const today = new Date();

      if (inputDate > today) {
        toast.error("Treatment start date must be less then current date.");
        setTreatmentStartDate(null);
        setTreatmentDateStart(null);
        return;
      }

      const formattedTreatmentDate = formatDate(inputDate);
      setTreatmentStartDate(formattedTreatmentDate);
      setTreatmentDateStart(date);
    } else {
      setTreatmentStartDate(null);
      setTreatmentDateStart(null);
    }
  };

  const handleTreatmentChange = (date) => {
    if (date?.$d !== null && date?.$d) {
      const inputDate = new Date(date.$d);
      const formattedTreatmentDate = formatDate(inputDate);
      setTreatmentDate(formattedTreatmentDate);
      setTreatmentEndDate(date);
    } else {
      setTreatmentDate(null);
      setTreatmentEndDate(null);
    }
  };
  const handleOtpChange = async (newOtp) => {
    setOtp(newOtp);
  };

  const handleKeyDown = (e) => {
    const { value, selectionStart } = e.target;
    if (e.key === " " && selectionStart === 0) {
      e.preventDefault();
    }
    const precedingChar = value[selectionStart - 1];
    const followingChar = value[selectionStart];
    if (e.key === " " && (precedingChar === " " || followingChar === " ")) {
      e.preventDefault();
    }
  };

  const handleChangePassword = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePasswords(newPassword, confirmPassword);
  };

  const handleChangeConfirmPassword = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePasswords(password, newConfirmPassword);
  };

  const validatePasswords = (newPassword, newConfirmPassword) => {
    let isValidPassword = true;
    for (const rule of validationRules) {
      const isRuleValid = rule.regex.test(newPassword);
      isValidPassword = isValidPassword && isRuleValid;
      setIsValid(isValidPassword);
      setIsMatching(newPassword === newConfirmPassword);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      firstName,
      lastName,
      dateOfBirth,
      gender,
      country,
      state,
      city,
      street,
      zipCode,
      phoneNumber,
      emergencyContactName,
      emergencyContactNumber,
      emergencyContactRelationship,
      insuranceCompanyName,
      policyHolderName,
      policyHolderId,
      insurancePolicyHolerDOB,
      groupNumber,
      relationship,
      insurancePolicyPhoneNumber,
      concerns,
      outpatient,
      providerName,
      providerEmail,
      treatmentStartDate,
      treatmentDate,
      referralSelect,
      showFields,
      referralName,
      referralInfo,
      email,
      password,
      otp,
    };

    if (!validatePatientStep(currentStep, formData, toast)) return;

    if (currentStep === 9) {
      setLoader(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/users/patientsignup`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              firstName: firstName,
              lastName: lastName,
              dob: dateOfBirth,
              gender: gender,
              country: country,
              state: state,
              city: city,
              street: street,
              zcode: zipCode,
              phoneNumber: phoneNumber,
              Emergency_contact_name: emergencyContactName,
              Emergency_contact_no: emergencyContactNumber,
              Emergency_contact_relation: emergencyContactRelationship,
              Inr_com_name: insuranceCompanyName,
              Policy_holder_name: policyHolderName,
              policy_holder_id: policyHolderId,
              Inr_dob: insurancePolicyHolerDOB,
              Inr_grp_no: groupNumber,
              Inr_relation: relationship,
              Inr_phone_no: insurancePolicyPhoneNumber,
              Primary_concern: JSON.stringify(concerns),
              Out_patient: outpatient,
              Responce: yourResponse,
              Provider_name: providerName,
              Provider_holder_Email: providerEmail,
              Treatment_start_date: treatmentStartDate,
              Treatment_end_date: treatmentDate,
              source: referralSelect,
              source_name: referralName,
              source_phoneNumber: referralInfo,
              email: email,
              password: password,
            }),
          }
        );

        const responseData = await response.json();
        if (response.status === 409) {
          toast.error(responseData.message || "Email already exists");
          return;
        }
        if (!response.ok) {
          setLoader(false);
          throw new Error(responseData.message || "An error occurred");
        }

        if (response.status === 201) {
          setLoader(false);
          toast.success("OTP sent successfully to your email.");
          setOtp("");
          setCurrentStep(10);
        } else {
          toast.error(responseData.message || "An error occurred");
        }
      } catch (error) {
        setLoader(false);
        console.error("There was an error!", error);
        setResponseMessage(error.toString());
        setShowPopup(true);
      }
    } else if (currentStep < 10) {
      setCurrentStep(currentStep + 1);
    } else {
      setResponseMessage("");
      setShowPopup(false);

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email, providerEmail)) {
        toast.error("Email is not valid. Example: abc@gmail.com");
        return;
      }
    }
  };

  const submitOtp = async () => {
    if (currentStep !== 10) return;
    if (!otp || otp.length !== 6) {
      return;
    }
    try {
      setLoader(true);
      if (otp?.length === 6) {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/users/verify-otp`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
              otp,
            }),
          }
        );

        if (response.ok) {
          setLoader(false);

          toast.success("Otp verify.");
          setModalOpen(true);
        } else {
          setLoader(false);
          const responseData = await response.json();
          toast.error(responseData.message);
          navigate("/login");
        }
      }
    } catch (e) {
      setLoader(false);
      toast.error(e?.message);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      const maxSizeInMB = 5;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (!validFileTypes.includes(file.type)) {
        toast.error("Please upload a valid image file (JPG, JPEG, PNG).");
        return;
      }

      if (file.size > maxSizeInBytes) {
        toast.error(`Please upload an image smaller than ${maxSizeInMB}MB.`);
        return;
      }
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const uploadResult = await fileUpload({
            task: "uploadImage",
            image: reader.result,
          });
          if (uploadResult.success) {
            setProfile(uploadResult.data);
          } else {
            setProfile("images/upload-profile.png");
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };
  const uploadImage = () => {
    fileInputRef.current.click();
  };

  const handleBanckClick = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSendOtp = async () => {
    setLoader(true);
    try {
      const response = await getOtp({
        email,
      });
      if (response) {
        setLoader(false);
        toast.success(response?.message);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    const steps = [0];
    if (currentStep >= 5) {
      steps.push(1);
      setMainStep(2);
      setMainStepContent("Privacy Concerns");
    }
    if (currentStep >= 6) {
      steps.push(2);
      setMainStep(3);
      setMainStepContent("Treatment");
    }
    if (currentStep >= 8) {
      steps.push(3);
      setMainStep(4);
      setMainStepContent("Information Sources");
    }
    if (currentStep >= 9) {
      steps.push(4);
      setMainStep(5);
      setMainStepContent("Sign Up");
    }
    if (currentStep === 11) steps.push(5);
    setStepPatient(steps);
  }, [currentStep]);

  const patientCheck = PATIENTCHECKBOX_DATA(stepPatient);

  return (
    <div className="vh-100 w-100 loginBglight px-3 overflow-hidden">
      <Row className="h-100">
        <Col md={5} className="h-100 col_divv">
          <div className="mb-4">
            <Image src="images/logo-spero.png" alt="logo" />
          </div>
          <div className="p-3 for-top-index">
            {patientCheck?.map((data) => (
              <Form className="mb-5 form_checkbox">
                <Form.Check
                  label={data.label}
                  name={data.label}
                  checked={data?.checkedStatus}
                  disabled={!data?.status}
                  type="checkbox"
                  className="checkbox_signup"
                  id={data.id}
                />
                <p
                  className={`${
                    !data?.status ? "disabled_class" : "label_discription"
                  }`}
                >
                  {data.discription}
                </p>
              </Form>
            ))}
          </div>
        </Col>
        <Col md={7} className="h-100 bg-white startRounded overflow-auto">
          <div className="d-flex mb-0 px-4 pt-3 justify-content-between">
            <div>
              {currentStep > 1 && (
                <p className="back_btn" onClick={handleBanckClick}>
                  <img src="images/back.png" alt="" /> Back
                </p>
              )}
            </div>
            <div>
              <p>
                Block {mainStep}. {mainStepContent}
              </p>
            </div>
          </div>
          <div className="py-5 d-flex align-items-center justify-content-center">
            {showPopup && (
              <div className="alert alert-info" role="alert">
                {responseMessage}
              </div>
            )}
            <form onSubmit={handleSubmit} className="setWidthform signupForm">
              {currentStep === 1 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center step_titlte">Your name</h1>
                    <p className="text-center mb-4">
                      To get started please fill in your first, last name and{" "}
                      <br></br> upload profile picture
                    </p>
                    <div className="text-center position-relative">
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="application/image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <img
                        src={randomProfile}
                        onClick={uploadImage}
                        className="mb-4 back_btn image_upload"
                        width={100}
                        alt=""
                      />
                      <FaCamera className="camera_icon" />
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        First name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter first name"
                        name="firstName"
                        label="FirstName"
                        id="firstName"
                        value={firstName}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setFirstName(e.target.value)}
                        variant="outlined"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Last name
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          name="lastName"
                          label="LastName"
                          id="lastName"
                          value={lastName}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => setLastName(e.target.value)}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Date of birth
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            className="form-control"
                            value={dateBirth}
                            onChange={handleBirthDateChange}
                            maxDate={dayjs()}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Gender
                      </label>
                      <div className="d-flex gap-3 align-items-center">
                        {GENDER_DATA.map((item) => (
                          <RadioButton
                            key={item.id}
                            id={item.id}
                            label={item.name}
                            name="gender"
                            checked={gender === item.name}
                            value={item.name}
                            onChange={handleRadioChange}
                            className="d-flex gap-2 align-items-center"
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 2 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center step_titlte">Address</h1>
                    <p className="text-center mb-5">
                      Please fill in your address information
                    </p>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Country
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        label="country"
                        id="country"
                        value={country}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setCountry(e.target.value)}
                        placeholder="Enter country"
                        variant="outlined"
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        State
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter State"
                          name="state"
                          label="state"
                          id="state"
                          value={state}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => setState(e.target.value)}
                          variant="outlined"
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        City
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter City"
                          name="city"
                          label="city"
                          id="city"
                          value={city}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => setCity(e.target.value)}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Street, building, apt.
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter street"
                          name="street"
                          label="street"
                          id="street"
                          value={street}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => setStreet(e.target.value)}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        ZIP code
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter zip Code"
                          name="zipCode"
                          label="zipCode"
                          id="zipCode"
                          value={zipCode}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => setZipCode(e.target.value)}
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 3 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center title_width_text">
                      Contact information
                    </h1>
                    <p className="text-center mb-5">
                      Please fill in your contact information
                    </p>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Your phone number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNumber"
                        label="phoneNumber"
                        id="phoneNumber"
                        value={phoneNumber}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter phone number"
                        variant="outlined"
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Emergency contact name
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Emergency contact name"
                          name="emergencyContactName"
                          label="emergencyContactName"
                          id="emergencyContactName"
                          onKeyDown={handleKeyDown}
                          value={emergencyContactName}
                          onChange={(e) =>
                            setEmergencyContactName(e.target.value)
                          }
                          variant="outlined"
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Emergency contact phone number
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Emergency contact phone number"
                          name="emergencyContactNumber"
                          label="emergencyContactNumber"
                          id="emergencyContactNumber"
                          onKeyDown={handleKeyDown}
                          value={emergencyContactNumber}
                          onChange={(e) =>
                            setEmergencyContactNumber(e.target.value)
                          }
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Emergency contact relationship
                      </label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Emergency contact relationship"
                          name="emergencyContactRelationship"
                          label="emergencyContactRelationship"
                          id="emergencyContactRelationship"
                          onKeyDown={handleKeyDown}
                          value={emergencyContactRelationship}
                          onChange={(e) =>
                            setEmergencyContactRelationship(e.target.value)
                          }
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 4 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center title_width_text">
                      Insurance company
                    </h1>
                    <p className="text-center mb-5">
                      Please fill in your insurance company information
                    </p>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Insurance company name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="insuranceCompanyName"
                        label="insuranceCompanyName"
                        id="insuranceCompanyName"
                        value={insuranceCompanyName}
                        onKeyDown={handleKeyDown}
                        onChange={(e) =>
                          setInsuranceCompanyName(e.target.value)
                        }
                        placeholder="Enter insurance company name"
                        variant="outlined"
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Policy holder’s name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="policyHolderName"
                        label="policyHolderName"
                        id="policyHolderName"
                        value={policyHolderName}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setPolicyHolderName(e.target.value)}
                        placeholder="Enter policy holder’s name"
                        variant="outlined"
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Policy holder’s ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="policyHolderId"
                        label="policyHolderId"
                        id="policyHolderId"
                        value={policyHolderId}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setPolicyHolderId(e.target.value)}
                        placeholder="Please enter policy holder’s ID"
                        variant="outlined"
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Date of birth
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            className="form-control"
                            value={insurancedob}
                            onChange={handlePolicyHolerChange}
                            maxDate={dayjs()}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Group number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="groupNumber"
                        label="groupNumber"
                        id="groupNumber"
                        value={groupNumber}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setGroupNumber(e.target.value)}
                        placeholder="Enter group number"
                        variant="outlined"
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Relationship
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="relationship"
                        label="relationship"
                        id="relationship"
                        value={relationship}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setRelationship(e.target.value)}
                        placeholder="Enter relationship"
                        variant="outlined"
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Phone number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="insurancePolicyPhoneNumber"
                        label="insurancePolicyPhoneNumber"
                        id="insurancePolicyPhoneNumber"
                        onKeyDown={handleKeyDown}
                        value={insurancePolicyPhoneNumber}
                        onChange={(e) =>
                          setInsurancePolicyPhoneNumber(e.target.value)
                        }
                        placeholder="Enter phone number"
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 5 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth minWidth w-100">
                    <h1 className="text-center title_width_text">
                      Primary Concerns
                    </h1>
                    <p className="text-center mb-5">
                      Please select your primary concerns
                    </p>
                    {CONCERNS_DATA.map((item) => (
                      <div
                        key={item?.id}
                        className={`mb-3 border rounded-3 p-3 concern spacify ${
                          concerns.includes(item.label) ? "active" : ""
                        }`}
                      >
                        <div className={"d-flex align-items-center"}>
                          <div className="flex-shrink-0">
                            <input
                              className="me-2 form_checkbox"
                              type="checkbox"
                              id={item?.id}
                              name={item?.label}
                              value={item?.label}
                              checked={concerns.includes(item.label)}
                              onChange={handleConcernChange}
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <label htmlFor="Anxiety">{item?.label}</label>
                            <p>{item?.discription}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentStep === 6 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth minWidth w-100">
                    <h1 className="text-center title_width_text">
                      Do you have outpatient providers?
                    </h1>
                    <div>
                      <p className="text-center mb-5">Check what applies:</p>
                      <div className="w-75 m-auto">
                        <Form>
                          {CHECKBOX_OUTPATIENT.map((item) => (
                            <div key={item?.id} className="mb-3">
                              <Form.Check
                                type="checkbox"
                                id={item.id}
                                value={item?.name}
                                label={item?.name}
                                checked={outpatient === item?.name}
                                className="d-flex align-items-center gap-2 concern"
                                onChange={handleChange}
                              />
                            </div>
                          ))}
                        </Form>
                        {isOther && (
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control"
                              // name="yourResponse"
                              // label="yourResponse"
                              value={outpatient === "Other" ? "" : outpatient}
                              onKeyDown={handleKeyDown}
                              onChange={(e) => setOutpatient(e.target.value)}
                              placeholder="Enter your response"
                              variant="outlined"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 7 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center">Provider information</h1>
                    <p className="text-center mb-4">
                      Provide us details of your treatment
                    </p>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Provider name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="providerName"
                        label="providerName"
                        id="providerName"
                        value={providerName}
                        placeholder="Enter provider name"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setProviderName(e.target.value)}
                        variant="outlined"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Email address
                      </label>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="providerEmail"
                          label="providerEmail"
                          id="providerEmail"
                          placeholder="Enter email address"
                          value={providerEmail}
                          onKeyDown={handleKeyDown}
                          onChange={(e) => setProviderEmail(e.target.value)}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Treatment start date
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            className="form-control"
                            value={treatmentDateStart}
                            onChange={handleTreatmentDateChange}
                            maxDate={dayjs()}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>

                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Treatment end date
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            className="form-control"
                            value={treatmentEndDate}
                            onChange={handleTreatmentChange}
                            maxDate={dayjs()}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 8 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth minWidth w-100">
                    <h1 className="text-center">How did you hear about us?</h1>
                    <p className="text-center mb-5">
                      Please select your option
                    </p>
                    <div className="w-75 m-auto">
                      <Form>
                        {RADIO_DATA.map((item) => (
                          <div key={item?.id} className="mb-3">
                            <Form.Check
                              type="radio"
                              id={item.id}
                              value={item?.name}
                              label={item?.name}
                              checked={referralSelect === item?.name}
                              className="d-flex align-items-center gap-2"
                              onChange={handleSelectChange}
                            />
                          </div>
                        ))}
                      </Form>
                      {showFields && (
                        <>
                          <div className="mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              Referral source name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="referralName"
                              label="referralName"
                              id="referralName"
                              value={referralName}
                              onKeyDown={handleKeyDown}
                              onChange={(e) => setReferralName(e.target.value)}
                              placeholder="Enter referral source name"
                              variant="outlined"
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              Referral contact info (phone/email)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="referralInfo"
                              label="referralInfo"
                              id="referralInfo"
                              value={referralInfo}
                              onKeyDown={handleKeyDown}
                              onChange={(e) => setReferralInfo(e.target.value)}
                              placeholder="Enter referral contact info"
                              variant="outlined"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 9 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth w-100">
                    <h1 className="text-center">Sign up</h1>
                    <p className="text-center mb-5">Please fill your details</p>
                    <div className="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        label="Email Address"
                        name="email"
                        value={email}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        placeholder="Enter email address"
                      />
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          placeholder="Enter password"
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={handleChangePassword}
                          onKeyDown={handleKeyDown}
                          variant="outlined"
                        />
                        <p
                          type="button"
                          className="show_pass"
                          onClick={toggleShowPassword}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </p>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          placeholder="Enter confirm password"
                          name="password"
                          label="Password"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={handleChangeConfirmPassword}
                          onKeyDown={handleKeyDown}
                          variant="outlined"
                        />
                        <p
                          type="button"
                          className="show_pass"
                          onClick={toggleShowConfirmPassword}
                        >
                          {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                        </p>
                      </div>
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                      <div style={{ marginRight: "20px" }}>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                          {validationRules.slice(0, 3).map((rule, index) => (
                            <li key={index} style={{ fontSize: "12px" }}>
                              {rule.regex.test(password) ? (
                                <span style={{ color: "#00BB61" }}>
                                  <FaCheck style={{ marginRight: "5px" }} />
                                  {rule.name}
                                </span>
                              ) : (
                                <span style={{ color: "#8F99B0" }}>
                                  • {rule.name}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                          {validationRules.slice(3, 6).map((rule, index) => (
                            <li key={index} style={{ fontSize: "12px" }}>
                              {rule.regex.test(password) ? (
                                <span style={{ color: "#00BB61" }}>
                                  <FaCheck style={{ marginRight: "5px" }} />
                                  {rule.name}
                                </span>
                              ) : (
                                <span style={{ color: "#8F99B0" }}>
                                  • {rule.name}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 10 && (
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="maxWidth minWidth w-100">
                    <h1 className="text-center">Verify email</h1>
                    <p className="text-center mb-5">
                      We’ve sent you a 6-digit verification code to
                      <br />
                      <b>{email}</b>
                    </p>
                    <div className="row">
                      <div className="col otp">
                        <OTPInput
                          value={otp}
                          onChange={handleOtpChange}
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <Modal
                  style={customStyles}
                  isOpen={isModalOpen}
                  onRequestClose={() => setModalOpen(false)}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setModalOpen(false)}
                        ></button>
                      </div>
                      <img
                        src="/images/successful.png"
                        width={115}
                        height={88}
                        style={{ margin: "auto" }}
                      />
                      <div className="modal-body pt-0 pb-5 px-4">
                        <div className="headingSec mb-4">
                          <h1
                            className="mb-0 text-center"
                            style={{
                              color: "#18357A",
                              fontSize: "36px",
                              fontWeight: 700,
                            }}
                          >
                            Account was sent for approval
                          </h1>
                        </div>
                        <p
                          className="mb-2"
                          style={{
                            color: "#5B6680",
                            textAlign: "center",
                            fontSize: "14px",
                            width: "75%",
                            margin: "auto",
                          }}
                        >
                          Once our administrator will check provided information
                          we will send confirmation notice on your email that
                          your account has been approved.
                          <br />
                          Thank you for patience!
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div className="gap-4 d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn btn-primary px-5 btnSize modal_btnn rounded-3"
                                onClick={() => {
                                  setModalOpen(false);
                                  navigate("/login");
                                }}
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary mt-4 py-3 w-100 mb-4"
                  onClick={submitOtp}
                  disabled={currentStep >= 9 ? !isValid || !isMatching : ""}
                >
                  {" "}
                  {currentStep < 10 ? "Continue" : "Finish"}
                </button>
                {currentStep <= 4 && (
                  <p className="mt-2">
                    Already have an account?{" "}
                    <a href="#" onClick={navigateToLogin}>
                      Log in
                    </a>
                  </p>
                )}

                {currentStep === 10 && (
                  <p className="mt-2">
                    Didn’t receive a code?{" "}
                    <a className="cursor_click" onClick={handleSendOtp}>
                      Resend code
                    </a>
                  </p>
                )}
              </div>
            </form>
          </div>
        </Col>
      </Row>
      {loader && <Loader />}
    </div>
  );
};

export default PatientsSignUp;
