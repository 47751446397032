import React, { useEffect, useState } from "react";
import notificationsData from "../../data/notifications.json";
import Header from "../../components/Header/Header";
import profileData from "../../data/clinician.json";
import { Dropdown } from "../../components/FormComponents/FormComponents";
import "./Notifications.css";
import Layout from "components/layout/layout";

//TODO: need to add state to fetch data from backend api to display notifications data when backend is created. currently using test data from json file
const NotificationsList = () => {
  const [profile, setProfile] = useState(null);
  const [notifications, setNotifications] = useState(notificationsData);
  const [filteredNotifications, setFilteredNotifications] =
    useState(notifications);
  const [status, setStatus] = useState("All");
  const [type, setType] = useState("All");

  const statusOptions = ["All", "Read", "Unread"];
  const typeOptions = [
    "All",
    "Request",
    "Join Call",
    "Appointment",
    "Assignment",
  ];

  const fetchData = async () => {
    try {
      //TODO: needs to be get request from backend when API is integrated
      //TODO: change setProfile to get profile from ProfileContext when context branch is merged into main
      setProfile(profileData);
      setNotifications(notificationsData);
    } catch (error) {
      console.error("Error setting notifications:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleGetNewNotifications = () => {
    fetchData();
  };

  useEffect(() => {
    const filteredNotifications = notifications.filter((notification) => {
      if (status !== "All" && notification.read !== (status === "Read")) {
        return false;
      }
      if (type !== "All" && notification.type !== type) {
        return false;
      }
      return true;
    });
    setFilteredNotifications(filteredNotifications);
  }, [status, type, notifications]);

  //TODO: will need to send patch request to update data as read, then rerender td item to no longer be bold when item is marked as read
  const handleMarkAsRead = () => {
    //will need to change this to be a patch request to update the notifications array, rather than directly mutating it as below. currently directing mutating to show how it will rerender on frontend ui, but this will not work once a backend api is being fetched to/from
    const updatedNotifications = notificationsData.map((notification) => ({
      ...notification,
      read: true,
    }));
    setNotifications(updatedNotifications);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  return (
    <Layout>
      <div className="mainBglight vh-100">
        <Header
          pageTitle="My notifications"
          notifications={notifications.slice(0, 3)}
          onMarkAsRead={handleMarkAsRead}
        />
        <div className="cards-container">
          <div className="notifications-card d-block">
            <div className="d-flex justify-content-between align-items-center">
              {profile && (
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img
                      className="profile-img"
                      src={profile.imageSrc}
                      alt="profile-icon"
                    />
                  </div>
                  <div className="d-flex ms-3 align-items-center">
                    <div>
                      <h2>Hi {profile.firstName},</h2>
                      <span>View your notifications here</span>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <button
                  className="blueSubmitButton"
                  onClick={handleGetNewNotifications}
                >
                  Get new notifications
                </button>
              </div>
            </div>
            <table className="w-100">
              <tbody>
                <tr>
                  <div className="d-flex ms-3 align-items-center justify-content-between">
                    <td className="col-7">
                      <Dropdown
                        label="Status:"
                        name="status"
                        value={status}
                        options={statusOptions}
                        onChange={handleStatusChange}
                      />
                    </td>
                    <td className="col-3">
                      <Dropdown
                        label="Type:"
                        name="type"
                        value={type}
                        options={typeOptions}
                        onChange={handleTypeChange}
                      />
                    </td>
                    <td className="col-auto text-end">
                      <button
                        className="text-button"
                        onClick={handleMarkAsRead}
                      >
                        Mark all as read
                      </button>
                    </td>
                  </div>
                </tr>
              </tbody>
            </table>

            {/* notification list */}
            <table className="w-100">
              <tbody>
                {filteredNotifications.map((notification, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td className="pe-0">
                          <div className="d-flex align-items-center">
                            {/* TODO: make details link create modal/link to details
                                            TODO: make join now link open up meeting link
                                            TODO: modify pictures to change with specified doctor/notification */}
                            <div
                              className={notification.read ? "" : "blue-dot"}
                            ></div>
                            <img
                              className="small-list-img"
                              src={notification.imageSrc}
                              alt="profile-icon"
                            />
                            <div>
                              {notification.type !== "Join Call" && (
                                <span
                                  className={
                                    notification.read
                                      ? "assigner-text"
                                      : "bold-text"
                                  }
                                >
                                  {notification.assigner}
                                </span>
                              )}
                              <span
                                className={notification.read ? "" : "bold-text"}
                              >
                                {" "}
                                {notification.notificationMessage}
                              </span>
                              <br />
                              <span className="smaller-text light-grey-text">
                                {notification.createdAt}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="pe-3">{notification.type}</span>
                        </td>
                        <td className="text-end">
                          {notification.type !== "Join Call" ? (
                            //TODO: add link to details here
                            <a href="#">See details</a>
                          ) : (
                            //TODO: add link to meeting here
                            <button className="blueSubmitButton">
                              Join now
                            </button>
                          )}
                        </td>
                      </tr>
                      <tr className="horizontal-divider"></tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotificationsList;
