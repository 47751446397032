import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

interface RadioButtonProps {
  options: { value: string; label: string }[];
  name: string;
  row?: boolean;
  value?: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CommonRadioButton({
  options,
  name,
  row = false,
  value,
  onChange,
}: RadioButtonProps) {
  return (
    <FormControl>
      <RadioGroup
        row={row}
        aria-labelledby={`${name}-radio-buttons-group-label`}
        name={name}
        value={value}
        onChange={onChange}
        sx={{ fontStyle: "normal !important" }}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
