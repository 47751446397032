import  { useState, useEffect } from "react";
import "./Document.css";
import Header from "../../components/Header/Header";
import Layout from "components/layout/layout";
import { fetchDocuments } from "lib/api/service";
import FolderCard from "components/atoms/cards/FolderCard";

const DBTracker = () => {
  const [activeGroupData, setActiveGroup] = useState([]);
  const [archivedGroupData, setArchivedGroup] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1");
  const [nestedTab, setNestedTab] = useState("nestedTab1");
  const [users,setUsers]=useState([])
  const fetchUsers=async()=>{
     const response =await fetchDocuments();
     setUsers(response?.results)
  }

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);

    if (tabId === "tab1") {
      setNestedTab("nestedTab1");
    } else if (tabId === "tab2") {
      setNestedTab("nestedTab3");
    }
  };

  const handleNestedTabChange = (tabId) => {
    setNestedTab(tabId);
  };

  const IndividualsGroup = [
    {
      patientId: 1,
      imageSrc: "images/ellipse.png",
      patientName: "Lori Briggs",
      gender: "male",
      lastAddedDate: "24 Aug 1990",
    },
    {
      patientId: 2,
      imageSrc: "images/ellipse-2.png",
      patientName: "Jeremy Puckett",
      gender: "male",
      lastAddedDate: "24 Aug 1990",
    },
    {
      patientId: 3,
      imageSrc: "images/ellipse-3.png",
      patientName: "Edward Mann",
      gender: "male",
      lastAddedDate: "24 Aug 1990",
    },
    {
      patientId: 4,
      imageSrc: "images/ellipse-4.png",
      patientName: "Teresa Roberts",
      gender: "male",
      lastAddedDate: "24 Aug 1990",
    },
    {
      patientId: 5,
      imageSrc: "images/ellipse-5.png",
      patientName: "Isabella Hernandez",
      gender: "male",
      lastAddedDate: "24 Aug 1990",
    },
  ];

  const activeGroups = [
    {
      id: 1,
      imageSrc: "images/ellipse.png",
      name: "CBT Thursday Group",
      notes: "16",
      lastAddedDate: "24 Aug 1990",
    },
    {
      id: 1,
      imageSrc: "images/ellipse-2.png",
      name: "ACT Wednesday Group",
      notes: "3",
      lastAddedDate: "24 Aug 1990",
    },
    {
      id: 1,
      imageSrc: "images/ellipse-3.png",
      name: "RO-DBT Thursday Group",
      notes: "9",
      lastAddedDate: "24 Aug 1990",
    },
    {
      id: 1,
      imageSrc: "images/ellipse-4.png",
      name: "Family Dynamics Wednesday Group",
      notes: "10",
      lastAddedDate: "24 Aug 1990",
    },
    {
      id: 1,
      imageSrc: "images/ellipse-5.png",
      name: "RO-DBT Thursday Group",
      notes: "12",
      lastAddedDate: "24 Aug 1990",
    },
    {
      id: 1,
      imageSrc: "images/ellipse-6.png",
      name: "IOP Tuesday DBT Group",
      notes: "16",
      lastAddedDate: "24 Aug 1990",
    },
  ];

  const archivedGroup = [
    {
      id: 1,
      name: "ACT Wednesday Group",
      notes: "16",
      lastAddedDate: "24 Aug 1990",
    },
    {
      id: 1,
      name: "CBT Thursday Group",
      notes: "3",
      lastAddedDate: "24 Aug 1990",
    },
    {
      id: 1,
      name: "Family Dynamics Wednesday Group",
      notes: "9",
      lastAddedDate: "24 Aug 1990",
    },
    {
      id: 1,
      name: "RO-DBT Thursday Group",
      notes: "10",
      lastAddedDate: "24 Aug 1990",
    },
  ];

  useEffect(() => {

    const fetchActiveGroupData = async () => {
      try {
        setActiveGroup(activeGroups);
      } catch (error) {
        console.error("Error data:", error);
      }
    };

    const fetchArchivedGroupData = async () => {
      try {
        setArchivedGroup(archivedGroup);
      } catch (error) {
        console.error("Error data:", error);
      }
      fetchUsers()
    };
    fetchActiveGroupData();
    fetchArchivedGroupData();
  }, []);

  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight bg-white">
        <Header pageTitle={"Documents"} />
        <div className="main-content">
          <div className="headingTable cardPaitent">
            <div id="tracker">
              <div className="headingTable mb-4 px-3">
                <div className="col-md-12">
                  <div className=" rounded-3 px-5 pt-4 pb-5">
                    <div className="row justify-content-center">
                      <div className="col-auto mt-3">
                        <ul class="nav nav-pills mb-3 navTabUI" id="pills-tab">
                          <li class="nav-item flex-fill bg-light rounded-start-3">
                            <button
                              type="button"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              className="nav-link active px-5 py-2 w-100"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                              onClick={() => handleTabChange("tab1")}
                            >
                              Individuals
                            </button>
                          </li>
                          <li class="nav-item flex-fill bg-light rounded-end-3">
                            <button
                              type="button"
                              className="nav-link px-5 py-2 w-100"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                              onClick={() => handleTabChange("tab2")}
                            >
                              Groups
                            </button>
                          </li>
                        </ul>
                      </div>
                      {nestedTab === "nestedTab1" && (
                        <div class="tab-content">
                          <div
                            class="tab-pane fade show active"
                            aria-labelledby="pills-diryCard-tab"
                          >
                            <div className="tab-content" id="pills-tabContent">
                              <div
                                className="tab-pane fade show active"
                                id="pills-single"
                                role="tabpanel"
                                aria-labelledby="pills-single-tab"
                              >
                                <div className="px-3">
                                  <div className="row gap-4 mt-5">
                                    {
                                      users?.map(
                                        (items, index) => (
                                         <FolderCard  data={items} key={index}/>
                                        )
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {nestedTab === "nestedTab2" && <div></div>}
                    </div>
                    <div className="row">
                      {activeTab === "tab2" && (
                        <div>
                          <div>
                            <div className="w-100 border-bottom mb-5">
                              <button
                                className="border-0 bg-transparent ps-0"
                                onClick={() =>
                                  handleNestedTabChange("nestedTab3")
                                }
                              >
                                <div className="col-md">
                                  <ul
                                    class="nav nav-pills mb-0 dbSkillTab gap-2"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li class="nav-item" role="presentation">
                                      <button
                                        className={`nav-link ${
                                          nestedTab === "nestedTab3"
                                            ? "active"
                                            : ""
                                        }`}
                                        id="pills-diryCard-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-diryCard"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-diryCard"
                                        aria-selected={
                                          nestedTab === "nestedTab3"
                                        }
                                      >
                                        ACTIVE GROUPS
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </button>

                              <button
                                className="border-0 bg-transparent"
                                onClick={() =>
                                  handleNestedTabChange("nestedTab4")
                                }
                              >
                                <div className="col-md">
                                  <ul
                                    class="nav nav-pills mb-0 dbSkillTab gap-2"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li class="nav-item" role="presentation">
                                      <button
                                        className={`nav-link ${
                                          nestedTab === "nestedTab4"
                                            ? "active"
                                            : ""
                                        }`}
                                        id="pills-skill-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-skill"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-skill"
                                        aria-selected={
                                          nestedTab === "nestedTab4"
                                        }
                                      >
                                        ARCHIVED GROUPS
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </button>
                            </div>
                            {nestedTab === "nestedTab3" && (
                              <div>
                                <div
                                  className="tab-content"
                                  id="pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="pills-single"
                                    role="tabpanel"
                                    aria-labelledby="pills-single-tab"
                                  >
                                    <div className="px-3">
                                      <div className="row gap-4">
                                        {activeGroupData.map(
                                          (activeGroupData) => (
                                         <FolderCard data={activeGroupData}/>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {nestedTab === "nestedTab4" && (
                              <div>
                                <div
                                  className="tab-content"
                                  id="pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="pills-single"
                                    role="tabpanel"
                                    aria-labelledby="pills-single-tab"
                                  >
                                    <div className="px-3">
                                      <div className="row gap-4">
                                        {archivedGroupData.map(
                                          (archivedGroupData) => (
                                            <FolderCard data={archivedGroupData}/>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DBTracker;
