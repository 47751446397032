// lib/apiService.js
import toast from "react-hot-toast";
import axiosInstance from "lib/axiosInstance";

// Utility function to handle API errors
const handleApiError = (error) => {
  console.error("API Error:", error);
  // You can add more sophisticated error handling logic here
  toast.error(error.message);
  return { error: error.message || "An error occurred" };
};

// Utility function to handle API errors Custom
const handleApiErrorCustom = (error) => {
  console.error("API Error:", error);
  // You can add more sophisticated error handling logic here
  toast.error(error?.response?.data?.message || error?.response?.data?.error || error?.response?.data?.message);
  return { error: error?.response?.data?.message || error?.response?.data?.error || error?.response?.data?.message ||  "An error occurred" };
};

export const requestAppointment = async (data) => {
  try {
    const response = await axiosInstance.post("/schedule/calendar", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getParticipant = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/assignuser/sassignpatients",
      data
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getScheduleData = async (data) => {
  try {
    const response = await axiosInstance.post("/schedule/getschedule", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getOtp = async (data) => {
  try {
    const response = await axiosInstance.post("/users/create-otp", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const setScheduleStatus = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/schedule/acceptdeniedSchedule",
      data
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const addNotes = async (data) => {
  try {
    const response = await axiosInstance.post("/documentation/addnote", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const fetchDocuments = async () => {
  try {
    const response = await axiosInstance.get(
      "/documentation/assignedpatientdoc"
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const fetchNotesByUser = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/documentation/patientnotes",
      data
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const createDocument = async (data) => {
  try {
    const response = await axiosInstance.post("/documentation/createdoc", data);
    return response;
  } catch (error) {
    return handleApiError(error);
  }
};

export const patientDocumentations = async (id) => {
  try {
    const response = await axiosInstance.post(
      `/documentation/patientdocumentations/${id}`
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const documentationPatientNotes = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/documentation/patientnotes",
      data
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const documentationSave = async (data) => {
  try {
    const response = await axiosInstance.post("/documentation/save", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const deleteDocuments = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/documentation/deleteupdatenote",
      data
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const taskassessmentSave = async (data) => {
  try {
    const response = await axiosInstance.post("/taskassessment/save", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getPerformerLists = async (id) => {
  try {
    const response = await axiosInstance.get(`/assignuser/therapist/${id}`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const taskassessmentGetAssignedTask = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/taskassessment/get-assigned-task/${id}`
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    toast.error(errorMessage);
    return error;
  }
};

export const taskSaveComment = async (data) => {
  try {
    const response = await axiosInstance.post(
      "taskassessment/save-comment",
      data
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const taskGetAllcommentsById = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/taskassessment/get-all-comments/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const changeStatus = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/taskassessment/update-status",
      data
    );
    return response;
  } catch (error) {
    return handleApiError(error);
  }
};

export const preaAndPostSessionAssessment = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/assessments/addPrePostAssessment",
      data
    );
    return response;
  } catch (error) {
    return handleApiError(error);
  }
};
// DBT Track
export const addDbtTrack = async (data) => {
  try {
    const response = await axiosInstance.post("/dbt/assign-dbt-card", data);
    return response.data;
  } catch (error) {
    return handleApiErrorCustom(error);
  }
};

// dbt/get-therapist-dbt-card/:patientId

export const getDBtrackerPatient = async (patientId) => {
  try {
    const response = await axiosInstance.get(`dbt/get-patient-dbt-card`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const addDbtSaveTrack = async (data) => {
  try {
    const response = await axiosInstance.post("/dbt/save-dbt-card", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const addDbtCurrentTrack = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/dbt/get-current-dbt-card",
      data
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Get all DBT Track
export const getAssignedDbtTrack = async () => {
  try {
    const response = await axiosInstance.get("/dbt/get-assign-dbt-fields");
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Deny
export const DenyRequest = async (data) => {
  try {
    const response = await axiosInstance.post("/assignuser/denypatient", data);
    return response;
  } catch (error) {
    return handleApiError(error);
  }
};

// Get All DBT Track
export const dbtTrackerGetAllById = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/dbt/get-therapist-dbt-card/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAssignedPatientGraph = async (activeTab) => {
  try {
    const response = await axiosInstance.get(
      `/dbt/get-patient-dbt-card?isGraph=${activeTab === 'tab1' ? false : true}`
    );
    return response.data;
  } catch (error) {
    return handleApiErrorCustom(error);
  }
};

export const getAssignedTherapistGraph = async (patientId, activeTab) => {
  try {
    // const response = await axiosInstance.get(
    //   `/dbt/get-therapist-dbt-card/${patientId}?isGraph=true`
    // );

    const response = await axiosInstance.get(
      `/dbt/get-therapist-dbt-card/${patientId}?isGraph=${activeTab === 'tab1' ? false : true}`
    );
    return response.data;
  } catch (error) {
    return handleApiErrorCustom(error);
  }
};

export const getAssignUserAssignTherapist = async () => {
  try {
    const response = await axiosInstance.get("/assignuser/assigntherapist");
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getallTherapistAndPatients = async (data) => {
  try {
    const response = await axiosInstance.post(`/assignuser/allTherapistAndPatients`, data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const createInsuranceCorrespondence = async (data) => {
  try {
    const response = await axiosInstance.post("/insuranceCorrespondence/createInsuranceCorrespondence", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getInsuranceCorrespondenceBypatientId = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/insuranceCorrespondence/getInsuranceCorrespondenceBypatient/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllInsuranceCorrespondence = async () => {
  try {
    const response = await axiosInstance.get(
      `/insuranceCorrespondence/getAllInsuranceCorrespondence`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const assignUserGetDetails = async (data) => {
  try {
    const response = await axiosInstance.post("/assignuser/getdetails", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const usersAllPatients = async () => {
  try {
    const response = await axiosInstance.get(
      `/users/allpatients`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};


export const updateInsuranceCorrespondence = async (data) => {
  try {
    const response = await axiosInstance.post("/insuranceCorrespondence/updateInsuranceCorrespondence", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getAllAssingPatients = async (data) => {
  try {
    const response = await axiosInstance.post("/assignuser/allassingpatients", data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const assignUserToTherapist = async (data) => {
  try {
    const response = await axiosInstance.post("/assignuser/assign", data);
    return response.data;
  } catch (error) {
    return handleApiErrorCustom(error);
  }
};

export const saveInsuranceDetails = async (id, data) => {
  try {
    const response = await axiosInstance.post(
      `/assignuser/saveInsurance/${id}`, data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllDischargeFormData = async (patientId) => {
  try {
    const response = await axiosInstance.get(
      `/dischargeform/getAllDischargeForm/${patientId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllIntakeFormData = async (patientId) => {
  try {
    const response = await axiosInstance.get(
      `/intakeform/getAllIntakeForm/${patientId}`
    );
    return response.data;
  } catch (error) {
    return handleApiErrorCustom(error);
  }
};

export const approveDischargePatient = async (patientId) => {
  try {
    const response = await axiosInstance.post(
      `/dischargeform/approveDischarge/${patientId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllAssignUsers = async () => {
  try {
    const response = await axiosInstance.get(
      `assignuser/allassignusers`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTherapistFields = async (patientId) => {
  try {
    const response = await axiosInstance.post(`/dbt/get-therapist-fields`, patientId);
    return response.data;
  } catch (error) {
    return error;
  }
};