import { getInitials } from 'utils';

const ProfileAvatar = ({ imageSrc ,patient_name}) => {
  return (
    imageSrc ? (
        <img
          src={imageSrc}
          alt={`${patient_name}'s avatar`}
          className="profile-image rounded-circle"
        />
      ) : (
        <div
          className="profile-placeholder rounded-circle text-white d-flex justify-content-center align-items-center"
        >
          {getInitials(patient_name)}
        </div>
      )
  );
};

export default ProfileAvatar;
