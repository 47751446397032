import Loader from "components/loader/Loader";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Modal from "react-modal";

const AssessmentResultModal = ({ assessmentInfo }) => {
  const token = localStorage.getItem("token");
  const [isModalOpen, setModalOpen] = useState(false);
  const [progress, setProgress] = useState(
    assessmentInfo?.selectedAssessmentResultData?.questions
  );
  const [loader, setLoader] = useState(false);
  const handleResponseChange = (index, event) => {
    const updatedQuestions = [
      ...assessmentInfo.selectedAssessmentResultData.questions,
    ];
    setProgress(updatedQuestions);
    updatedQuestions[index].status = parseInt(event.target.value);
  };

  const calculatePatientResponse = (value) => {
    if (value <= 20) {
      return "0 (Not at all)";
    } else if (value <= 40) {
      return "1 (A little bit)";
    } else if (value <= 60) {
      return "2 (Moderately)";
    } else if (value <= 80) {
      return "3 (Quite a bit)";
    } else {
      return "4 (Extremely)";
    }
  };

  const { selectedAssessmentResultData, setIsAssessmentResultModalOpen } =
    assessmentInfo;

  if (!selectedAssessmentResultData) {
    return null;
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const getButtonDetails = (status) => {
    let className, buttonText;
    if (status === 0) {
      className = "pendingBtn";
      buttonText = "Pending";
    } else if (status === 1) {
      className = "completeBtn";
      buttonText = "Accepted";
    }
    return { className, buttonText };
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const handleQuestionSubmit = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/assessments/updateAssignedAssessment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            patient_id:
              assessmentInfo?.selectedAssessmentResultData?.patient_id,
            assessment_id:
              assessmentInfo?.selectedAssessmentResultData?.assessment_id,
            questions: progress,
          }),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        setLoader(false);
        setModalOpen(true);
        toast.success(responseData.message);
      } else {
        setLoader(false);
        const responseData = await response.json();
        toast.error(responseData.message);
      }
    } catch (e) {
      setLoader(false);
      toast.error(e?.message);
    }
  };

  return (
    <>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-bottom-0 px-4 pt-2 pb-0  justify-content-end mb-4">
            <button
              type="button"
              className="btn-close btnsiir"
              onClick={() => setIsAssessmentResultModalOpen(false)}
            ></button>
          </div>
          <div className="modal-body pt-0 pb-2 px-4">
            <div className="d-flex justify-content-between mt-2 mb-3">
              <div className="headingSec">
                <h2 className="mb-0">
                  {selectedAssessmentResultData?.assessmentName}
                </h2>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <p className="mb-0">
                  {selectedAssessmentResultData.status === 0
                    ? "Due to:"
                    : "Taken on:"}{" "}
                  <span>
                    {formatDate(selectedAssessmentResultData?.created_at)}
                  </span>
                </p>
                <button
                  type="button"
                  className={`btn ${
                    getButtonDetails(selectedAssessmentResultData.status)
                      .className
                  }`}
                >
                  {
                    getButtonDetails(selectedAssessmentResultData.status)
                      .buttonText
                  }
                </button>
              </div>
            </div>
            <div className="tableUI">
              <table className="table table-bordered">
                <tbody>
                  {progress.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <p className="mb-0">{data.description}</p>
                        <div className="range-slider px-4 mt-2">
                          <input
                            className="range-slider__range w-100"
                            type="range"
                            min="0"
                            max="100"
                            value={data.status}
                            onChange={(event) =>
                              handleResponseChange(index, event)
                            }
                          />
                          <div className="row mt-1">
                            <div className="col">
                              <p>Not at all</p>
                            </div>
                            <div className="col text-center">
                              <p>A little bit</p>
                            </div>
                            <div className="col text-center">
                              <p>Moderately</p>
                            </div>
                            <div className="col text-center">
                              <p>Quite a bit</p>
                            </div>
                            <div className="col text-end">
                              <p>Extremely</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        Patient’s response:
                        <br /> <b>{calculatePatientResponse(data.status)}</b>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="gap-4 d-flex justify-content-center">
                    <button
                      type="button"
                      onClick={() => setIsAssessmentResultModalOpen(false)}
                      className="btn btn-outline-secondary px-5 btnSize rounded-3"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleQuestionSubmit}
                      className="btn btn-primary px-5 btnSize rounded-3"
                    >
                      Finish assessment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for success */}
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 px-4 pt-2 pb-0 justify-content-end mb-4">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalOpen(false)}
              ></button>
            </div>
            <img
              src="/images/successful.png"
              width={115}
              height={88}
              style={{ margin: "auto" }}
            />
            <div className="modal-body pt-0 pb-5 px-4">
              <div className="headingSec mb-4">
                <h1
                  className="mb-0 text-center"
                  style={{
                    color: "#18357A",
                    fontSize: "36px",
                    fontWeight: 700,
                  }}
                >
                  Assessment completed
                </h1>
              </div>
              <p
                className="mb-2"
                style={{
                  color: "#5B6680",
                  textAlign: "center",
                  width: "75%",
                  margin: "auto",
                }}
              >
                Thank you for taking this step towards understanding yourself
                better and nurturing your well-being!
              </p>
            </div>
            <div className="col-12">
              <div className="row my-4">
                <div className="col-md-12">
                  <div className="gap-4 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary px-5 btnSize rounded-3"
                      onClick={() => {
                        setModalOpen(false);
                        setIsAssessmentResultModalOpen(false);
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loader && <Loader />}
      </Modal>
    </>
  );
};

export default AssessmentResultModal;
