import Header from "components/Header/Header";
import CardsComponent from "components/cards/CardsComponent";
import Layout from "components/layout/layout";
import React from "react";

const TherapistPatient = () => {
  return (
    <Layout>
      <div className="vh-100 w-100 mainBglight">
        <Header pageTitle={"Patients"} />
        <div className="main-content px-3">
          <CardsComponent />
        </div>
      </div>
    </Layout>
  );
};

export default TherapistPatient;
