export const SocketEvents = {
	ADDUSER: 'add-user',
	MESSAGE: 'message',
	SEND_MESSAGE: 'send-message',
	DISCONNECT: 'disconnect',
	ADDROOM: 'add-room',
	DELETE_MESSAGE: 'delete-message',
	EDIT_MESSAGE: 'edit-message',
	SENT_TYPING: 'sendtyping',
	TYPING: 'typing',
	SENT_NEW_PARTICIPANTS: 'sendparticipants',
	NEW_PARTICIPANTS: 'participants',
	SEND_SEEN_MESSAGE: 'seenMessage',
	SEEN_MESSAGE: 'seenmessage',
	SEEN_MSG: 'seen',
	NOTIFICATION: 'notifications',
	NOTIFICATION_USER: 'notification_all',
};

export default SocketEvents;