import React from "react";
import { Image, InputGroup, FormControl, Button } from "react-bootstrap";
import User from "../../assets/images/user.png";
import Send from "../../assets/images/send.png";
import Attechment from "../../assets/images/attechment.png";
import './Messages.css';

const MessageSend = ({handleSendMessage,messageValueDefault, handleChange, handleKeyUp}) => {

  
  
  return (
    <div className="d-flex gap-2 align-items-center">
      <Image src={User} width={33} height={33} alt="User" roundedCircle />
      <InputGroup className="chat_footer position-relative">
        <FormControl value={messageValueDefault?.message}  onChange={handleChange} onKeyUp={handleKeyUp} type="text" placeholder="Type your message here" />
      </InputGroup>
      <Button variant="light" className="p-0">
        <Image src={Attechment} alt="Attachment" />
      </Button>
      <Button variant="light" className="p-0 custom-send-button" onClick={handleSendMessage} >
        <Image src={Send} alt="Send" />
      </Button>
    </div>
  );
};

export default MessageSend;
