import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { ListGroup, Image, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ASSESSMENT_DASHBOARD_DATA } from "Data";
import note from "../assets/images/note.png";

const PatientAssessmentsTable = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white rounded-top-3 mt-4 p-3 box_radius">
      <div className="modal-content">
        <Navbar className="modal-header p-2 border-bottom-0">
          <Navbar.Brand>
            <h2 className="bold-text blue-text ">Assessments</h2>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="">
              <button
                className="text-button view_all_btn"
                onClick={() => navigate("/messages")}
              >
                View all
              </button>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>

        <ListGroup variant="flush" className="p-0 gap-2 d-grid ul_class">
          <ListGroup.Item className="d-flex flex-wrap align-items-center p-0">
            <span className="mx-2">
              Please complete the following questionnaires{" "}
            </span>
          </ListGroup.Item>
        </ListGroup>

        <div className="modal-body">
          <div className="tableList">
            <Table borderless>
              <tbody>
                {ASSESSMENT_DASHBOARD_DATA.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex gap-2">
                        <Image src={note} alt="" />
                        <h6 className="h6_font">{data.title}</h6>
                      </div>
                    </td>

                    <td className="text-center">
                      <h6
                        className={`h6_font col ${
                          index === 0 || index === 1 ? "text-danger" : ""
                        }`}
                      >
                        {data.due}
                      </h6>
                    </td>

                    <td className="text-center">
                      <button type="button" className="btn Denied">
                        {data.status}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientAssessmentsTable;
