import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AdminPatientsTable = ({ allPatientList }) => {
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  // Filter out entries where either patient or doctor is null
  const filteredList = allPatientList.filter(
    (data) => data.patient !== null && data.doctor !== null
  );

  return (
    <div>
      <Table borderless>
        <thead>
          <tr>
            <th scope="col">Patient name</th>
            <th scope="col">
              Admission date <img src="../../images/short-icon.svg" alt="" />
            </th>
            <th scope="col">
              Patient №: <img src="../../images/short-icon.svg" alt="" />
            </th>
            <th scope="col">LOC: </th>
            <th scope="col">Primary therapist:</th>
            <th scope="col">Psychiatrist: </th>
          </tr>
        </thead>
        <tbody>
          {filteredList.length > 0 ? (
            filteredList.map((data) => (
              <tr className="mb-3" key={data.patient.id}>
                <td>
                  <h6 onClick={() => navigate(`/profile/${data.patient.id}`)}>
                    <span className="curser-pointer text-capitalize">
                      {data.patient.firstName || "N/A"}{" "}
                      {data.patient.lastName || "N/A"}
                    </span>
                  </h6>
                </td>
                <td>
                  <h6>
                    {data.patient.createdAt
                      ? formatDate(data.patient.createdAt)
                      : "N/A"}
                  </h6>
                </td>
                <td>
                  <h6>{data.patient.id}</h6>
                </td>
                <td>
                  <h6>{"IOP"}</h6>
                </td>
                <td>
                  <h6>{`${data.doctor.firstName} ${data.doctor.lastName}`}</h6>
                </td>
                <td>
                  <h6>{data.doctor.specialty}</h6>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No patients found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminPatientsTable;
