import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import axios from "axios";
const AssignedClinicians = () => {
  const [listingallPatients, setListingallPatients] = useState(null);
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [selectedPsychiatrist, setSelectedPsychiatrist] = useState("");
  const token = localStorage.getItem("token");
  const validClinicians = Array.isArray(listingallPatients)
    ? listingallPatients
    : [];
  useEffect(() => {
    const fetchData = async () => {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API_URL}/users/allusers`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.request(config);
        setListingallPatients(response?.data?.users);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const filteredTherapists = validClinicians.filter(
    (clinician) => clinician.specialty === "Therapist"
  );
  const filteredPsychiatrists = validClinicians.filter(
    (clinician) => clinician.specialty === "Psychiatrist"
  );
  const handleTherapistChange = (event) => {
    const selectedClinician = listingallPatients.find(
      (clinician) => clinician.firstName === event.target.value
    );
    // Store selected clinician in local storage
    localStorage.setItem(
      "selectedClinician",
      JSON.stringify(selectedClinician)
    );
    setSelectedTherapist(event.target.value);
  };
  const handlePsychiatristChange = (event) => {
    const selectedClinician = listingallPatients.find(
      (clinician) => clinician.name === event.target.value
    );
    // Store selected clinician in local storage
    localStorage.setItem(
      "selectedClinician",
      JSON.stringify(selectedClinician)
    );
    setSelectedPsychiatrist(event.target.value);
  };
  return (
    <>
      <p
        className="mt-3"
        style={{
          color: "#5B6680",
          fontSize: "14px",
        }}
      >
        Please assign clinicians for this patient:
      </p>
      <h1 className="mt-3">Available therapists:</h1>
      <div className="main-content">
        <Row className="mb-3">
          <Col md={5}>
            <h6 className="title_therapist">Therapist</h6>
            <RadioGroup
              value={selectedTherapist}
              onChange={handleTherapistChange}
            >
              {filteredTherapists.map((clinicalData) => (
                <div className="row" key={clinicalData.user_id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <Radio
                          value={clinicalData.firstName}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                          }}
                        />
                      }
                      label={
                        <div className="position-relative">
                          <p className="mb-0 text_data">
                            <Image
                              src={"../../images/DrWilliams.svg"}
                              alt=""
                              width={24}
                              height={24}
                            />{" "}
                            {`${clinicalData.firstName} ${clinicalData.lastName}`}
                          </p>
                          <div>
                            {selectedTherapist === clinicalData.firstName && (
                              <p
                                style={{
                                  color: "#00BB61",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  position: "absolute",
                                  right: "0px !important",
                                  transform: "translate(444px, -23px)",
                                }}
                              >
                                Selected
                              </p>
                            )}
                          </div>
                        </div>
                      }
                      checked={selectedTherapist === clinicalData.firstName}
                    />
                  </div>
                </div>
              ))}
            </RadioGroup>
          </Col>
          <Col md={5}>
            <h6 className="title_therapist">Therapist availability</h6>
            <div className="row mt-3">
              <p
                style={{
                  color: "#00BB61",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                High (1 patient assigned)
              </p>
            </div>
            <div className="row mt-4">
              <p
                className="mt-2"
                style={{
                  color: "#00BB61",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                High (2 patients assigned)
              </p>
            </div>
            <div className="row mt-4">
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    color: "#00BB61",
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                >
                  High (2 patients assigned)
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <p
                className=""
                style={{
                  color: "#FEA324",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                Medium (3 patients assigned)
              </p>
            </div>
            <div className="row mt-4">
              <p
                className=""
                style={{
                  color: "#FF6760",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                Low (4 patients assigned)
              </p>
            </div>
          </Col>
        </Row>
        <h1 className="mt-4">Available psychiatrists:</h1>
        <Row className="mb-3">
          <Col md={5}>
            <h6 className="title_therapist">Psychiatrist</h6>
            <RadioGroup
              value={selectedPsychiatrist}
              onChange={handlePsychiatristChange}
            >
              {filteredPsychiatrists.map((clinicalData) => (
                <Row key={clinicalData.user_id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <Radio
                          value={clinicalData.firstName}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                          }}
                        />
                      }
                      label={
                        <>
                          <p
                            className="mb-0"
                            style={{
                              color: "#18357A",
                              fontSize: "14px",
                            }}
                          >
                            <img
                              src={"../../images/Drmason.svg"}
                              alt=""
                              width={24}
                              height={24}
                            />{" "}
                            {clinicalData.firstName + clinicalData.lastName}
                          </p>
                          {selectedPsychiatrist === clinicalData.firstName && (
                            <p
                              style={{
                                color: "#00BB61",
                                fontSize: "18px",
                                lineHeight: "21px",
                                marginLeft: "10px",
                                position: "absolute",
                                right: "62px",
                                marginTop: "-28px",
                              }}
                            >
                              Selected
                            </p>
                          )}
                        </>
                      }
                      checked={selectedPsychiatrist === clinicalData.firstName}
                    />
                  </div>
                </Row>
              ))}
            </RadioGroup>
          </Col>
          <Col md={5}>
            <h6 className="title_therapist">Psychiatrist availability</h6>
            <div className="row mt-3">
              <p
                style={{
                  color: "#00BB61",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                High (1 patient assigned)
              </p>
            </div>
            <div className="row mt-4">
              <p
                className="mt-2"
                style={{
                  color: "#00BB61",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                High (2 patients assigned)
              </p>
            </div>
            <div className="row mt-4">
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    color: "#00BB61",
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                >
                  High (2 patients assigned)
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <p
                className=""
                style={{
                  color: "#FEA324",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                Medium (3 patients assigned)
              </p>
            </div>
            <div className="row mt-4">
              <p
                className=""
                style={{
                  color: "#FF6760",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                Low (4 patients assigned)
              </p>
            </div>
          </Col>
        </Row>
        <div className="addmore-clinician">
          <h4 className="">+ Add other clinician</h4>
        </div>
      </div>
    </>
  );
};
export default AssignedClinicians;
