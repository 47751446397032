import React, { useState, useMemo } from "react";
import Modal from "react-modal";
import MessageTabs from "components/tabs/MessageTabs";
import { AddIcon } from "utils/svg";
import AddNewMessageModal from "modals/MessageModal/AddNewMessageModal";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PushPinIcon from "@mui/icons-material/PushPin";
import { getChatList } from "pages/lib/apis";
import { Avatar } from "@mui/material";
import {deepPurple } from '@mui/material/colors';

const MessageSidebar = ({ handleSelect, activeTab, doctorData, patientData, setValue, handleAddRoom, handleAddRoomSocket, setCheckMsgType, setSearching, handleSearch, setAllPartcipant, activeAgent, setActiveAgent, fetchgroup, setFetchgroup, roomId, setMsgData, socket, unreadCounts, onlineUsers, groupCount }) => {

  const [openAddMessageModal, setOpenAddMessageModal] = useState(false);
  const userData = localStorage.getItem("sbCalls");
  const englishNameUser = localStorage.getItem("name");
  const userId = JSON.parse(userData)?.userId;

console.log(groupCount, 'groupCountgroupCount')
  const sortDataByUnreadCount = (data) => {
    return [...data].sort((a, b) => {
      const unreadCountA = unreadCounts[userId]?.userUnreadCounts[a.value[0]] || 0;
      const unreadCountB = unreadCounts[userId]?.userUnreadCounts[b.value[0]] || 0;
      return unreadCountB - unreadCountA; // Sort in descending order
    });
  };

  // Memoized sorted data
  const sortedDoctorData = useMemo(() => sortDataByUnreadCount(doctorData), [doctorData, unreadCounts, userId]);
  const sortedPatientData = useMemo(() => sortDataByUnreadCount(patientData), [patientData, unreadCounts, userId]);

  const handleAgentClick = async (index, userdetail) => {
    console.log(userdetail, 'userdetail',userdetail?.value[0]);
    setActiveAgent(userdetail?.value[0]);
    try {
      const response = await getChatList({
        participants: [userId, userdetail?.value[0]],
        participantsName: [englishNameUser, userdetail?.value[1]],
        participantsLogin: userId,
        isDelete: false,
      });
      if (response) {
        if (!response?.data?.existingRow || !response?.data?.message) {
          const userNames =
            response?.data?.participantsName[0] === englishNameUser
              ? response?.data?.participantsName[1]
              : response?.data?.participantsName[0];
          handleAddRoom(response?.data, userNames, 'single');
          handleAddRoomSocket(response?.data, userNames, 'single');
          setCheckMsgType('singleMsg');
          setSearching(null);
        }
        if (response?.data?.message) {
          const userNames =
            response?.data?.existingRow?.participantsName[0] === englishNameUser
              ? response?.data?.existingRow?.participantsName[1]
              : response?.data?.existingRow?.participantsName[0];
          handleAddRoom(response?.data?.existingRow, userNames, 'single');
          handleAddRoomSocket(response?.data?.existingRow, userNames, 'single');
          setCheckMsgType('singleMsg');
          setSearching(null);
        }
      }
    } catch (error) {
      console.error(error, 'Error');
    }
  };

  const handleAgentGroupClick = (index, groupDetail) => {
    console.log(groupDetail, 'groupDetail', index);
    setActiveAgent(groupDetail?.id);
    handleAddRoom(groupDetail, groupDetail?.groupName, 'group');
    setCheckMsgType('groupMsg');
    setAllPartcipant(groupDetail);
    setSearching(null);
  }

  const tabs = [
    { eventKey: "THERAPISTS", label: "THERAPISTS" },
    { eventKey: "PATIENTS", label: "PATIENTS" },
  ];

  return (
    <div className="">
      <div className="side_agent">
        <div className="agent_search">
          <div className="input-group custominp">
            <span className="input-group-text border-end-0" id="basic-addon1">
              <i className="far fa-search"></i>
            </span>
            <input
              onChange={handleSearch}
              type="text"
              className="form-control ps-0 border-start-0"
              placeholder="Search..."
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <button className="btn btn_agent">
            <AddIcon
              onClick={() => {
                setOpenAddMessageModal(true);
              }}
            />
          </button>
        </div>

        <div className="agent_list py-1">
          <div className="agent_category ">
            <MessageTabs
              tabs={tabs}
              handleSelect={handleSelect}
              activeTab={activeTab}
            />
          </div>
        </div>

        {activeTab === "THERAPISTS" && (
          <div className="agent_list-height">
            {sortedDoctorData.map((item, index) => {
              const unreadCountForThread =
                unreadCounts[userId]?.userUnreadCounts[item.value[0]] || 0;

                

                const groupUnreadCount = groupCount[item.value[0]] || 0;

                console.log(groupUnreadCount, 'unreadCountForThread', item);

              return (
                <div
                  key={index}
                  className={`agent_item_list ${item.value[0] === activeAgent ? "active_agent" : ""
                    } py-3 `}
                  onClick={() => { item.value[1].d?.groupType === 'THERAPISTS' ? handleAgentGroupClick(index, item.value[1].d, item) : handleAgentClick(index, item) }}
                >
                  <div className="agent_name">
                    {item.value[1].d?.groupType === 'THERAPISTS' ? <img src='/images/GroupProfile.png' alt="group" /> : <img src='/images/ellipse-6.png' alt="group" />}
                    <div className="agent_text">
                      <div className="d-flex align-items-md-baseline">
                        <h5
                          className={`mb-0 ${item.value[0] === activeAgent ? "user_text" : ""
                            }`}
                        >
                          {item.label}
                        </h5>
                        <span>
                          <MedicalServicesIcon
                            className={`span_icon ${item.value[0] === activeAgent ? "kit_icon" : "default_color"
                              }`}
                          />
                        </span>
                      </div>
                      <p
                        className={`mb-0 ${item.value[0] === activeAgent ? "user_message" : ""
                          }`}
                      >
                        {item.userMessage}
                      </p>
                    </div>
                  </div>
                  <div className="agent_mode">
                    <p
                      className={`mb-0 ${item.value[0] === activeAgent ? "user_message" : ""
                        }`}
                    >
                      {item.userTime}
                    </p>
                    {index === 0 && (
                      <PushPinIcon
                        className={`span_pin ${item.value[0] === activeAgent ? "kit_icon" : "default_color"
                          }`}
                        onClick={() => handleAgentClick(index, item)}
                      />
                    )}
                  </div>

                  <div>
                    {(unreadCountForThread > 0 || (groupUnreadCount > 0 && activeAgent != item.value[0])) && (
                      <Avatar sx={{ bgcolor: deepPurple[500] }}>{groupUnreadCount && activeAgent != item.value[0] ? groupUnreadCount ?? null : unreadCountForThread}</Avatar>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {activeTab === "PATIENTS" && (
          <div className="agent_list-height">
            {sortedPatientData.map((item, index) => {
              const unreadCountForThread =
                unreadCounts[userId]?.userUnreadCounts[item.value[0]] || 0;
              return (
                <div
                  key={index}
                  className={`agent_item_list ${item.value[0] === activeAgent ? "active_agent" : ""
                    } py-3`}
                  onClick={() => { item.value[1].d?.groupType === 'PATIENTS' ? handleAgentGroupClick(index, item.value[1].d) : handleAgentClick(index, item) }}
                >
                  <div className="agent_name">
                    {item.value[1].d?.groupType === 'PATIENTS' ? <img src='/images/GroupProfile.png' alt="group" /> : <img src='/images/ellipse-6.png' alt="group" />}
                    <div className="agent_text">
                      <div className="d-flex align-items-md-baseline">
                        <h5
                          className={`mb-0 ${item.value[0] === activeAgent ? "user_text" : ""
                            }`}
                        >
                          {item.label}
                        </h5>
                      </div>
                      <p
                        className={`mb-0 ${item.value[0] === activeAgent ? "user_message" : ""
                          }`}
                      >
                        {item.userMessage}
                      </p>
                    </div>
                  </div>
                  <div className="agent_mode">
                    <p
                      className={`mb-0 ${item.value[0] === activeAgent ? "user_message" : ""
                        }`}
                    >
                      {item.userTime}
                    </p>
                    {index === 0 && (
                      <PushPinIcon
                        className={`span_pin ${item.value[0] === activeAgent ? "kit_icon" : "default_color"
                          }`}
                        onClick={() => handleAgentClick(index, item)}
                      />
                    )}
                  </div>

                  <div>
                    {unreadCountForThread > 0 && (
                      <Avatar sx={{ bgcolor: deepPurple[500] }}>{unreadCountForThread}</Avatar>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

    

      <Modal
        isOpen={openAddMessageModal}
        onRequestClose={() => setOpenAddMessageModal(false)}
      >
        <AddNewMessageModal
          assessmentInfo={{
            setOpenAddMessageModal: setOpenAddMessageModal,
          }}
          patientData={patientData}
          doctorData={doctorData}
          handleSearch={handleSearch}
          activeTabChat={activeTab}
          fetchgroup={fetchgroup}
          setFetchgroup={setFetchgroup}
          roomId={roomId}
          setMsgData={setMsgData}
          socket={socket}
          title={'Create Group'}
        />
      </Modal>
    </div>
  );
};

export default MessageSidebar;

























// import React, { useState } from "react";
// import Modal from "react-modal";
// import MessageTabs from "components/tabs/MessageTabs";
// import { MESSAGES_USER_LIST, MESSAGES_PATIENT_LIST } from "Data";
// import { AddIcon } from "utils/svg";
// import AddNewMessageModal from "modals/MessageModal/AddNewMessageModal";
// import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
// import PushPinIcon from "@mui/icons-material/PushPin";
// import { getChatList, getChatListAll } from "pages/lib/apis";
// import { Avatar, Badge } from "@mui/material";
// import { deepOrange, deepPurple } from '@mui/material/colors';


// const MessageSidebar = ({ handleSelect, activeTab, doctorData, patientData, setValue, handleAddRoom, handleAddRoomSocket, setCheckMsgType, setSearching, handleSearch, setAllPartcipant, activeAgent, setActiveAgent, fetchgroup, setFetchgroup, roomId, setMsgData, socket, unreadCounts }) => {

//   const [openAddMessageModal, setOpenAddMessageModal] = useState(false);
//   const userData = localStorage.getItem("sbCalls");
//   const englishNameUser = localStorage.getItem("name");
//   const userId = JSON.parse(userData)?.userId;



//   console.log(doctorData, 'doctorData', patientData);
//   const handleAgentClick = async (index, userdetail) => {
//     setActiveAgent(index);


//     try {
//       const response = await getChatList({
//         participants: [userId, userdetail?.value[0]],
//         participantsName: [englishNameUser, userdetail?.value[1]],
//         participantsLogin: userId,
//         isDelete: false,
//       });

//       if (response) {
//         // const resData = await getChatListAll(userId);
//         // setValue(resData?.data[0]);
//         // setSinglechatPluseIcon(false);

//         if (!response?.data?.existingRow || !response?.data?.message) {
//           const userNames =
//             response?.data?.participantsName[0] === englishNameUser
//               ? response?.data?.participantsName[1]
//               : response?.data?.participantsName[0];


//           handleAddRoom(response?.data, userNames, 'single');
//           handleAddRoomSocket(response?.data, userNames, 'single');
//           setCheckMsgType('singleMsg');
//           setSearching(null);
//           // close();
//           // scrollRef?.current?.scrollIntoView({
//           // 	behavior: 'smooth',
//           // 	block: 'end',
//           // });
//         }
//         if (response?.data?.message) {
//           const userNames =
//             response?.data?.existingRow?.participantsName[0] === englishNameUser
//               ? response?.data?.existingRow?.participantsName[1]
//               : response?.data?.existingRow?.participantsName[0];

//           handleAddRoom(response?.data?.existingRow, userNames, 'single');
//           handleAddRoomSocket(response?.data?.existingRow, userNames, 'single');
//           setCheckMsgType('singleMsg');
//           setSearching(null);
//           // close();
//           // scrollRef?.current?.scrollIntoView({
//           // 	behavior: 'smooth',
//           // 	block: 'end',
//           // });
//         }
//       }
//     } catch (error) {
//       console.error(error, 'Error');
//       // const resData = await getChatListAll(userId);
//       // setValue(resData?.data[0]);
//       // setSinglechatPluseIcon(false);
//     }
//   };


//   const handleAgentGroupClick = (index, groupDetail) => {
//     setActiveAgent(index);
//     handleAddRoom(groupDetail, groupDetail?.groupName, 'group');
//     setCheckMsgType('groupMsg');
//     setAllPartcipant(groupDetail);
//     setSearching(null);
//   }

//   const tabs = [
//     { eventKey: "THERAPISTS", label: "THERAPISTS" },
//     { eventKey: "PATIENTS", label: "PATIENTS" },
//   ];


//   return (
//     <div className="">
//       <div className="side_agent">
//         <div className="agent_search">
//           <div className="input-group custominp">
//             <span className="input-group-text border-end-0" id="basic-addon1">
//               <i className="far fa-search"></i>
//             </span>
//             <input
//               onChange={handleSearch}
//               type="text"
//               className="form-control ps-0 border-start-0"
//               placeholder="Search..."
//               aria-label="Username"
//               aria-describedby="basic-addon1"
//             />
//           </div>

//           <button className="btn btn_agent">
//             <AddIcon
//               onClick={() => {
//                 setOpenAddMessageModal(true);
//               }}
//             />
//           </button>
//         </div>

//         <div className="agent_list py-1">
//           <div className="agent_category ">
//             <MessageTabs
//               tabs={tabs}
//               handleSelect={handleSelect}
//               activeTab={activeTab}
//             />
//           </div>
//         </div>
//         {activeTab === "THERAPISTS" && (
//           <div className="agent_list-height">
//             {doctorData?.map((item, index) => {

//               const unreadCountForThread =
//                 unreadCounts[userId]?.userUnreadCounts[item.value[0]] || 0;
//               console.log(item, unreadCountForThread, 'itemitemitem', unreadCounts)
//               return (
//                 <div
//                   key={index}
//                   className={`agent_item_list ${index === activeAgent ? "active_agent" : ""
//                     } py-3 `}
//                   onClick={() => { item.value[1].d?.groupType == 'THERAPISTS' ? handleAgentGroupClick(index, item.value[1].d) : handleAgentClick(index, item) }}
//                 >
//                   <div className="agent_name">
//                     {item.value[1].d?.groupType == 'THERAPISTS' ? <img src='/images/GroupProfile.png' alt="group" /> : <img src='/images/ellipse-6.png' alt="group" />}
//                     <div className="agent_text">
//                       <div className="d-flex align-items-md-baseline">
//                         <h5
//                           className={`mb-0 ${index === activeAgent ? "user_text" : ""
//                             }`}
//                         >
//                           {item.label}
//                         </h5>
//                         <span>
//                           <MedicalServicesIcon
//                             className={`span_icon ${index === activeAgent ? "kit_icon" : "default_color"
//                               }`}
//                           />
//                         </span>
//                       </div>
//                       <p
//                         className={`mb-0 ${index === activeAgent ? "user_message" : ""
//                           }`}
//                       >
//                         {item.userMessage}
//                       </p>
//                     </div>

//                   </div>
//                   <div className="agent_mode">
//                     <p
//                       className={`mb-0 ${index === activeAgent ? "user_message" : ""
//                         }`}
//                     >
//                       {item.userTime}
//                     </p>
//                     {index === 0 && (
//                       <PushPinIcon
//                         className={`span_pin ${index === activeAgent ? "kit_icon" : "default_color"
//                           }`}
//                         onClick={() => handleAgentClick(index, item)}
//                       />
//                     )}
//                   </div>

//                   <div>
//                     {unreadCountForThread > 0 && (
//                       <Avatar sx={{ bgcolor: deepPurple[500] }}>{unreadCountForThread}</Avatar>


//                     )}
//                   </div>
//                 </div>
//               )
//             })}



//           </div>
//         )}

//         {activeTab === "PATIENTS" && (
//           <div className="agent_list-height">
//             {patientData.map((item, index) => {

//               const unreadCountForThread =
//                 unreadCounts[userId]?.userUnreadCounts[item.value[0]] || 0;

//               return (
//                 <div
//                   key={index}
//                   className={`agent_item_list ${index === activeAgent ? "active_agent" : ""
//                     } py-3`}
//                   onClick={() => { item.value[1].d?.groupType == 'PATIENTS' ? handleAgentGroupClick(index, item.value[1].d) : handleAgentClick(index, item) }}
//                 >
//                   <div className="agent_name">

//                     {item.value[1].d?.groupType == 'PATIENTS' ? <img src='/images/GroupProfile.png' alt="group" /> : <img src='/images/ellipse-6.png' alt="group" />}
                 
//                     <div className="agent_text">
//                       <div className="d-flex align-items-md-baseline">
//                         <h5
//                           className={`mb-0 ${index === activeAgent ? "user_text" : ""
//                             }`}
//                         >
//                           {item.label}
//                         </h5>
//                       </div>
//                       <p
//                         className={`mb-0 ${index === activeAgent ? "user_message" : ""
//                           }`}
//                       >
//                         {item.userMessage}
//                       </p>
//                     </div>
//                   </div>
//                   <div className="agent_mode">
//                     <p
//                       className={`mb-0 ${index === activeAgent ? "user_message" : ""
//                         }`}
//                     >
//                       {item.userTime}
//                     </p>
//                   </div>

//                   <div className="status">

//                     {unreadCountForThread > 0 && (
//                       <Avatar sx={{ bgcolor: deepPurple[500] }}>{unreadCountForThread}</Avatar>


//                     )}
//                   </div>
//                 </div>
//               )
//             }


//             )}
//           </div>
//         )
//         }
//       </div>
      // <Modal
      //   isOpen={openAddMessageModal}
      //   onRequestClose={() => setOpenAddMessageModal(false)}
      // >
      //   <AddNewMessageModal
      //     assessmentInfo={{
      //       setOpenAddMessageModal: setOpenAddMessageModal,
      //     }}
      //     patientData={patientData}
      //     doctorData={doctorData}
      //     handleSearch={handleSearch}
      //     activeTabChat={activeTab}
      //     fetchgroup={fetchgroup}
      //     setFetchgroup={setFetchgroup}
      //     roomId={roomId}
      //     setMsgData={setMsgData}
      //     socket={socket}
      //     title={'Create Group'}
      //   />
      // </Modal>
//     </div>
//   );
// };

// export default MessageSidebar;
